import React, { useEffect, useState,useRef } from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import SafetyALL from './companychart/safety/ESGpagesafety';
import ESGEnvironment from './companychart/environment/ESGpageenvio';
import QualityALL from './companychart/quality/ESGpagequality';
import { Checkbox,  NumericTextBox} from "@progress/kendo-react-inputs";
import useDocumentTitle from "./useDocumentTitle"

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const TabESG = () => {
    const [selected, setSelected] = React.useState(0);
    const [value, setValue] = useState(true);
    const [delay, setDelay] = useState(60000);
    const [visible, setVisible] = useState(false);

    const { height, width } = useWindowDimensions();

    useDocumentTitle("S.CON - ESG Dashboard")
  
    const handleSelect = (e) => {
      setSelected(e.selected);
    };


    useInterval(() => {
      setSelected((selected+1)%3);
    }, value ? delay:null);
  

  
    const handleChange = (event) => {
      setValue(event.value);
    };

  
    const ChangeSeconds = (e) => {
      setDelay(e.value !== null ? e.value * 1000 : null);
    };

    const toggleDiv = () => {
      setVisible(!visible);
  };


    return (
      <>
        <div>
      <TabStrip selected={selected} className='tab2' onSelect={handleSelect} tabPosition='left' dir="down" style={{height:height}}>
        <TabStripTab title="안전" >
          <SafetyALL/>
        </TabStripTab>
        <TabStripTab title="환경">
          <ESGEnvironment/>
        </TabStripTab>
        <TabStripTab title="품질">
          <QualityALL/>
        </TabStripTab>

      </TabStrip>

  
      </div>

      <div className='tabesgname' style={{position:'absolute', color:'snow', top:'170px', zIndex:'999', width:'200px', textAlign:'center'}}>
        <span onClick={toggleDiv} style={{fontSize:'1rem', fontWeight:'600'}}>ESG Dashboard</span>
      </div>

      <div style={{position:'absolute', color:'snow', bottom:'20px', left:'20px', zIndex:'999'}}>      
        
        <Checkbox
        value={value}
        onChange={handleChange}
        label={"자동 전환"}
      />
      <br/>
      <label style={{display:visible?'block':'none'}}>
      <NumericTextBox
          width={150}
          placeholder="please enter value"
          value={delay/1000}
          onChange={ChangeSeconds}
        />{" "}
        초
      </label>
      </div>

      </>
    );

};

export default TabESG;
  