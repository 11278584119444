import * as React from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

const BarChart = ({ height, data }) => {
  const SharedTooltip = (props) => {
    const { category, points } = props;
    return (
      <div>
        <div>{category}</div>
        {points.map((point, i) => (
          <div key={i}>
            {point.series.name} : {point.value} 건
          </div>
        ))}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <Chart style={{ height: height }}>
      <ChartTooltip
        shared={true}
        render={sharedTooltipRender}
        background="white"
      />
      <ChartSeries>
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={data.map((item) => item.B)}
          color="#4472C4"
          name="우수(B)"
          labels={{
            visible: (point) => point.value !== 0,
            position: "center",
            background: "transparent",
            color: "#fff",
          }}
        />
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={data.map((item) => item.CA)}
          color="#ED7D31"
          name="시정조치(CA)"
          labels={{
            visible: (point) => point.value !== 0,
            position: "center",
            background: "transparent",
            color: "#fff",
          }}
        />
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={data.map((item) => item.C)}
          color="#A5A5A5"
          name="시정(C)"
          labels={{
            visible: (point) => point.value !== 0,
            position: "center",
            background: "transparent",
            color: "#fff",
          }}
        />
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={data.map((item) => item.O)}
          color="#FFC000"
          name="관찰(O)"
          labels={{
            visible: (point) => point.value !== 0,
            position: "center",
            background: "transparent",
            color: "#fff",
          }}
        />
      </ChartSeries>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={data.map((item) => item.disc)}
          majorGridLines={{ visible: false }}
          baseUnitStep={2}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem majorUnit={1} minorGridLines={2} />
      </ChartValueAxis>
    </Chart>
  );
};

export default BarChart;
