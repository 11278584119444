import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { List, ListItem, ListItemText } from '@mui/material'
import { generateUpdatedDate } from '../common/datadate';

const {REACT_APP_URI2} = process.env;


const Intro = ({ positiondata, order, blockH }) => {

    const [BoxHeight, setBoxHeight] = useState(250);
    const [data, setdata] = useState([])
    const { project_code } = useParams();

    useEffect(() => {
        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);
    
    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "프로젝트 개요",
                "lineitem": "yes",
            }
            const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)

            setdata(_.sortBy(res.data.data.data.filter(v => v.status === 'Active'), 'record_no').reverse()[0])

        };

        fetchData();
    }, [project_code]);



    return (
        <>
                <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
        { generateUpdatedDate(new Date())}
        </div>
            <GridLayout style={{ height: BoxHeight&& BoxHeight , width:383 , margin:"0 auto"}}
                rows={[
                    {
                        height: '37%',
                    },
                    {
                        height: '28%',
                    },
                    {
                        height: '35%',
                    }

                ]}

                cols={[
                    {
                        width: '100%',
                    }
                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} col={1} style={{position:'relative'}}>
                    {data &&
                    <div dangerouslySetInnerHTML={{ __html: data.ProjectPhotoRT }} className='richimag2' style={{maxWidth:'400px',position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)'}}></div>
                    }
                    </GridLayoutItem>
                <GridLayoutItem row={2} col={1} style={{overflow:'auto'}}>
                    
                    <div style={{fontFamily:'Pretendard-B', fontSize:'16px'}}>Project Summary</div>
                    <List dense={true} style={{width:'90%', margin:'0 auto'}}>
                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)', paddingLeft:'0' }}>
                            <ListItemText className='listleft' style={{ width: '40%',color:'#707070', fontFamily:'Pretendard-B'  }}>Project Name</ListItemText>
                            <ListItemText className='listright'style={{ width: '60%' }}>{data && data.ugenProjectName}</ListItemText>
                        </ListItem>
                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)' , paddingLeft:'0' }}>

                            <ListItemText className='listleft' style={{ width: '40%' ,color:'#707070' }}>Location</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.address}</ListItemText>
                        </ListItem>
                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)' , paddingLeft:'0' }}>

                            <ListItemText className='listleft' style={{ width: '40%' ,color:'#707070' }}>Contract Type</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.genContractType}</ListItemText>
                        </ListItem>
                        <ListItem style={{paddingLeft:'0' }}>

                            <ListItemText className='listleft' style={{ width: '40%',color:'#707070'  }}>Client</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.genClient}</ListItemText>
                        </ListItem>
                    </List>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1} style={{overflow:'auto'}} >
                <div style={{fontFamily:'Pretendard-B', fontSize:'16px'}}>Construction Area</div>
                    <List dense={true} style={{width:'90%', margin:'0 auto', overflow:'auto'}}>
                        {_.get(data,'_bp_lineitems') && _.get(data,'_bp_lineitems').map((v, index) =>(
                        <ListItem key={index} style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)', paddingLeft:'0' }}>

                            <ListItemText className='listleft' style={{ width: '40%' ,color:'#707070' }}>{v.PJTItemName}</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{v.PJTItemDesc}</ListItemText>
                        </ListItem>
                        )
)}
                    </List>

                </GridLayoutItem>
            </GridLayout>

        </>
    );

}

export default Intro
