import React, { useEffect, useState } from 'react';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import PJTLoc from './common/pjtloc';
import PJTStatus from './common/status';
import KeyEvents from './common/key';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const PJTinfo = ({ positiondata, filter ,projeclist}) => {

    const [BoxHeight, setBoxHeight] = useState(250);
    const { height, width } = useWindowDimensions();


    useEffect(() => {
        const boxHeight = positiondata[0];
        setBoxHeight(((height-30)/6) * (boxHeight && boxHeight.rowSpan));
    }, [positiondata, height]);
    
    return (<>
   <GridLayout 
   style={{ height: BoxHeight-28, overflow:'unset'}}
                rows={[
                    {
                        height: '100%',
                    },
                ]}
                cols={[
                    {
                        width: '30%',
                    }, {
                        width: '40%',
                    },{
                        width: '30%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <GridLayoutItem col={1} row={1}>
                    <PJTLoc filter={filter} BoxHeight={BoxHeight}/>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1}>
                    <PJTStatus filter={filter} BoxHeight={BoxHeight} projeclist={projeclist}/>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1}> 
                    <KeyEvents filter={filter} BoxHeight={BoxHeight} projeclist={projeclist}/>
                </GridLayoutItem>

                </GridLayout>
</>)
}

export default PJTinfo
