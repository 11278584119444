import React, { useEffect, useState } from 'react';
import krMessages from "./kr.json";
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import 'hammerjs';
import 'hammerjs';
import { ResponsivePie } from '@nivo/pie';
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import { useMediaQuery } from 'react-responsive';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");



const ManCount = ({ positiondata, mandata2,projectlist, height }) => {

    const defaultvalue = new Date()
    const [value, setValue] = useState(defaultvalue);
    const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const divisionList = _.uniqBy(projectlist.filter(v=>v.division!=='반도체'), 'division').map(v => v.division).filter(v=>v !=='')



    // .map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.projectcode) }))
        // .map((v) => ({ ...v, uniq: v.직종 + "//" + v.division + "//" + v.d_reporting_date.slice(0,10) }))


    const cdate = moment(value).subtract(moment(value).isoWeekday() - 1, 'day').format("YYYY-MM-DD")
    const lastdate2 = moment(value).format("YYYY-MM-DD")
    const lastdate = moment(value).format("MM-DD-YYYY")


    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };


    var daylist = getDaysArray(new Date(cdate), new Date(lastdate2)).map(v => moment(v).format("MM-DD-YYYY"));

    const colmunNo = divisionList.length;

    const columnList = [];

    for (var i = 1; i <= colmunNo; i++) {
        columnList.push({ width: `${100 / colmunNo}%` });
    }

    var piedata =
        _(mandata2)
            .groupBy('uniq')
            .map((objs, key) => ({
                'id': key.split("//")[0],
                'value': _.sumBy(objs, '인원'),
                'division': key.split("//")[1],
                'd_reporting_date': key.split("//")[2],
            }))
            .value()
            .filter(v =>v.d_reporting_date===lastdate)



    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {
        const boxHeight = positiondata[2];
        setBoxHeight(((height-10)/7) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata,height]);

    const max = new Date();

    return (
        <>
            <GridLayout style={{ height: isDPI?  BoxHeight +20: BoxHeight -16}}
                rows={[
                    {
                        height: '90%',
                    },
                    {
                        height: '10%',
                    },
                ]}
                cols={columnList}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>
                <div style={{ position: 'absolute', top: '10px', right: '200px' }}>
                    <LocalizationProvider language="ko-KR">
                        <IntlProvider locale="ko-KR">
                            <div className="esg-datepick"><DatePicker max={max} format={"yyyy년 MM월 dd일 EEEE"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} /></div>
                        </IntlProvider>
                    </LocalizationProvider>
                </div>
                {divisionList.map((v, index) => (
                    <React.Fragment key={index}>

                        <GridLayoutItem
                            col={index}
                            row={1}
                            style={{ textAlign: "center" , position:'relative'}}
                        >

                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: '600' , color:'#F1F2F5'}}> {_.sumBy(piedata.filter(f => f.division === v), 'value').toLocaleString()}명</div>

                            <ResponsivePie
                                // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                                colors={{ scheme: 'nivo' }}
                                className='pie'
                                data={
                                    _(piedata.filter(f => f.division === v))
                                    .groupBy('id')
                                    .map((objs, key) => ({
                                        'id': key,
                                        'value': _.sumBy(objs, 'value'),
                                    }))
                                    .value()
                                
                                
                                }
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=" >-"
                                cornerRadius={0}
                                innerRadius={0.65}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                arcLinkLabel=                          {d => (
                                    <React.Fragment>
                                      <tspan style={{fontSize:10}} x="0" dy="-0.6em">{d.id}</tspan>
                                      <tspan style={{fontSize:10}} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(piedata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                                    </React.Fragment>
                                  )}
                                arcLinkLabelsSkipAngle={20}
                                arcLinkLabelsTextOffset={5}
                                arcLinkLabelsDiagonalLength={5}
                                arcLinkLabelsStraightLength={5}
                                arcLinkLabelsColor={{ from: 'color' }}
                                enableArcLabels={true}
                                arcLabel={function (e) { return e.value }}
                                arcLabelsRadiusOffset={0.5}
                                arcLabelsSkipAngle={20}
                                arcLinkLabelsTextColor='#F1F2F5'
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            3
                                        ]
                                    ]
                                }}

                                legends={[]}
                            />

                        </GridLayoutItem>
                        <GridLayoutItem
                            col={index}
                            row={2}
                            style={{ textAlign: "center" , color:'#F1F2F5', fontWeight:'600', fontSize:'1rem'}}
                        >
                            {v}
                        </GridLayoutItem>
                    </React.Fragment >
                ))}

            </GridLayout>
        </>
    );

}

export default ManCount