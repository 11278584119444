import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons
} from '@mui/x-data-grid-pro';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { read, utils, writeFile } from 'xlsx-js-style';
import dayjs from 'dayjs';
import { ModuleTable } from './moduletable';
import SearchIcon from '@mui/icons-material/Search';
import Swal from 'sweetalert2';
import _ from 'lodash';
import axios from "axios";
import { useHistory } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


dayjs.extend(isSameOrBefore);

// 헤더 스타일 정의
const headerStyle = {
  font: {
    name: 'Calibri',
    sz: 12,
    bold: true,
    color: { rgb: "FFFFFF" }
  },
  fill: {
    patternType: "solid",
    fgColor: { rgb: "4F81BD" }
  },
  border: {
    top: { style: "thin", color: { rgb: "000000" } },
    bottom: { style: "thin", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } }
  },
  alignment: {
    horizontal: "center",
    vertical: "center"
  }
};


const DatesOrderValid = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) {
    return true;
  }
  // 시간 부분을 제거하여 순수한 날짜만을 비교합니다.
  const pureFirstDate = dayjs(firstDate).startOf('day');
  const pureSecondDate = dayjs(secondDate).startOf('day');
  return pureFirstDate.isSameOrBefore(pureSecondDate);
};


export const FormTable = ({ tabeldata, moduleData, columnData, projectCode, columnData2 , documentStatus}) => {

  const staticFields = [
    { displayName: "Activity ID", key: "EPPMActivityBPK" },
    { displayName: "Activity Name", key: "uuu_P6ActivityName" },
    { displayName: "Unit", key: "EDPRUnit" },
    { displayName: "Discipline", key: "WBSDiscNM" },
    { displayName: "Class Code Name", key: "PMSClassNM" },
    { displayName: "Document No", key: "Doc_No" },
    { displayName: "Revision No", key: "RevNo" },
    { displayName: "Document Title", key: "Doc_Title" },
    // { displayName: "Doc. Weight Factor", key: "EDPRPRWeightFactor" },
    { displayName: "Doc. Manhour", key: "EDPRPRManhour" },
    { displayName: "Doc. Type", key: "DOC_TYPE_NM" },
    { displayName: "Plan Start", key: "emsPlanStartDO" },
    { displayName: "Plan Finish", key: "emsPlanFinishDO" },
    { displayName: "Remark", key: "EDPRRemark" },
  ];

  const [rows, setRows] = React.useState(tabeldata.map((v, index) => ({ ...v, id: index + 1 })));
  const [rowModesModel, setRowModesModel] = React.useState({});
  // let navigate = useNavigate();
  const history = useHistory();

  const downloadExcelPlan = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 정적 필드 정의
    const ActstaticFields = [
      { displayName: "Activity ID", key: "EPPMActivityBPK", required: true },
      { displayName: "Activity Name", key: "uuu_P6ActivityName" },
      { displayName: "Unit", key: "EDPRUnit" },
      { displayName: "Discipline", key: "WBSDiscNM" },
      { displayName: "Class Code Name", key: "PMSClassNM" },
      { displayName: "Document No", key: "Doc_No", required: true },
      { displayName: "Revision No", key: "RevNo" },
      { displayName: "Document Title", key: "Doc_Title", required: true },
      // { displayName: "Doc. Weight Factor", key: "EDPRPRWeightFactor" },
      { displayName: "Doc. Manhour", key: "EDPRPRManhour", required: true },
      { displayName: "Doc. Type", key: "DOC_TYPE_NM", required: true },
      { displayName: "Plan Start", key: "emsPlanStartDO" },
      { displayName: "Plan Finish", key: "emsPlanFinishDO" },
      { displayName: "Remark", key: "EDPRRemark" },
      { displayName: "Reason", key: "EDPRReason" },
    ];


    let validationType = {
      type: 'list',
      formulae: ['"Common,Drawing,Specification,Calculation,Datasheet,List&Summary,Report,BM,MR,TBE,Performance,Subcontract,Interface,Others,Construction Service,Procurement Service"'],
      showDropDown: true,
    };

    // 동적 필드 정의
    const dynamicFieldNamesNormal = columnData.flatMap(v =>
      suffixes2.map(suffix => `${v}//${suffix}`)
    ).filter(v => v.includes("Plan"));

    const dynamicFieldNamesCode = columnData.flatMap(v =>
      suffixes.map(suffix => `${v}//${suffix}`)
    ).filter(v => v.includes("Plan"));

    // 필드 결합
    const combinedFields = [...ActstaticFields.map(f => f.key), ...dynamicFieldNamesCode];

    // 상단 헤더 생성
    const topHeaders = ActstaticFields.map(f => "").concat(dynamicFieldNamesNormal.map(field => {
      const splitField = field.split("//");
      return splitField.length === 2 ? splitField[0] : field;
    }));

    // 하단 헤더 생성
    const bottomHeaders = ActstaticFields.map(f => f.required ? f.displayName + "*" : f.displayName)
      .concat(dynamicFieldNamesNormal.map(field => {
        const splitField = field.split("//");
        return splitField.length === 2 ? splitField[1] : field;
      }));


    worksheet.addRow(topHeaders);
    worksheet.addRow(bottomHeaders);
    const headerRow = worksheet.getRow(1);
    const headerRow2 = worksheet.getRow(2);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow2.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });

    // 데이터 행 추가 및 날짜 형식 확인을 위한 함수
    const isDate = (value) => {
      if (typeof value === 'string' || value instanceof String) {
        const date = new Date(value);
        return !isNaN(date.getTime());
      }
      return value instanceof Date;
    };


    worksheet.columns.forEach(column => {
      let maxWidth = 10; // 최소 너비
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      column.width = maxWidth + 2;
    });



    // 스타일 적용
    ActstaticFields.forEach((field, index) => {
      const style = {
        font: { color: { argb: field.required ? 'FFFF0000' : 'FF000000' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }
      };
      worksheet.getCell(2, index + 1).style = style;
    });

    // 데이터 행 추가
    const udpatedmoduleData = moduleData.map((v) => ({ ...v, EPPMActivityBPK: v.uuu_P6ActivityId, id: "" }));
    const dataRows = [...rows, ..._.differenceBy(udpatedmoduleData, rows, "EPPMActivityBPK")]
    .map(row => combinedFields.map(fieldName => {
      const value = row[fieldName];
      // 'Doc. Manhour' 필드의 경우 빈 값에 대해 0을 반환
      if (fieldName === "EDPRPRManhour") {
        return value || 0; // value가 'falsy' (null, undefined, 빈 문자열, 0, false 등)일 경우 0 반환
      }
      return value || ""; // 다른 필드는 빈 값인 경우 빈 문자열 반환
    }));
  

    // 데이터 행 추가 전에 날짜에 대한 타임존 오프셋 적용
// 데이터 행 추가 전에 날짜에 대한 타임존 오프셋 적용
dataRows.forEach(row => {
  row.forEach((cell, index) => {
    if (isDate(cell) && index > 9) { // 칼럼이 A부터 J까지일 때의 조건 추가
      // 날짜 객체의 경우, 9시간을 더해준다 (KST로 조정)
      let date = new Date(cell);
      date.setHours(date.getHours() + 9);
      row[index] = date;
    }
  });
});


    dataRows.forEach((row, rowIndex) => {
      worksheet.addRow(row);

      // rowIndex는 0부터 시작하지만, ExcelJS에서는 1부터 시작하므로 1을 더해줍니다.
      let excelRowIndex = rowIndex + 3; // 헤더가 있으므로 2를 더합니다.

      // PMSClassNM 값을 확인
      const pmsClassNM = worksheet.getCell(`E${excelRowIndex}`).value; // 5번째 열 (E열)의 값을 가져옵니다.
      const filterValue = columnData2.filter(v => v.PMSClassNM === pmsClassNM).map(v => v.PMSStageNM);



      for (let colIndex = 15; colIndex <= bottomHeaders.length; colIndex++) {
        let cell = worksheet.getCell(excelRowIndex, colIndex);
        let creteriaCell = worksheet.getCell(1, colIndex).value;

        if (filterValue.length > 0 && !filterValue.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } } // 페블색 배경
          };
        } else if (filterValue.length > 0 && filterValue.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFADD8E6' } } // 파란색 배경
          };
        }
      }


      for (let colIndex = 1; colIndex <= 14; colIndex++) {
        let cell = worksheet.getCell(excelRowIndex, colIndex);
        let creteriaCell = worksheet.getCell(2, colIndex).value;
        const noNeed = ["Activity Name", "Unit", "Discipline", "Class Code Name", "Revision No", "Plan Start", "Plan Finish"]
        const Need = ["Activity ID*", "Document No*", "Document Title*", "Doc. Manhour*", "Doc. Type*"]

        if (filterValue.length > 0 && noNeed.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
          }
        }
        else if (filterValue.length > 0 && Need.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFADD8E6' } } // 파란색 배경
          };
        }

      }
    });



    worksheet.views = [
      { state: 'frozen', ySplit: 2 }
    ];
    // 칼럼 너비 업데이트
    worksheet.columns.forEach((column, i) => {
      let maxWidth = 10; // 초기 최소 너비 설정

      // 헤더 행도 포함하여 너비 계산
      let headerLength = worksheet.getRow(1).getCell(i + 1).value
        ? worksheet.getRow(1).getCell(i + 1).value.toString().length
        : 0;
      if (headerLength > maxWidth) {
        maxWidth = headerLength;
      }

      // 각 셀의 값에 따라 최대 너비 계산 (날짜 형식 제외)
      column.eachCell({ includeEmpty: true }, cell => {
        if (!isDate(cell.value)) {
          let cellLength = cell.value ? cell.value.toString().length : 0;
          maxWidth = cellLength > maxWidth ? cellLength : maxWidth;
        }
      });

      column.width = maxWidth + 2; // 약간의 여백 추가
    });


    // A열의 1행부터 5000행까지 드롭다운 적용
    for (let i = 2; i <= 5000; i++) {
      worksheet.getCell(`J${i}`).dataValidation = validationType;
    }

    // 값이 있는 모든 셀에 대해 테두리 설정
worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    const border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
    cell.border = border;
  });
});


    // 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `계획 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };

  const downloadExcelActual = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const ActstaticFields = [
      { displayName: "Activity ID", key: "EPPMActivityBPK" },
      { displayName: "Activity Name", key: "uuu_P6ActivityName" },
      { displayName: "Unit", key: "EDPRUnit" },
      { displayName: "Discipline", key: "WBSDiscNM" },
      { displayName: "Class Code Name", key: "PMSClassNM" },
      { displayName: "Document No", key: "Doc_No" },
      { displayName: "Revision No", key: "RevNo" },
      { displayName: "Document Title", key: "Doc_Title" },
      // { displayName: "Doc. Weight Factor", key: "EDPRPRWeightFactor" },
      { displayName: "Doc. Manhour", key: "EDPRPRManhour" },
      { displayName: "Doc. Type", key: "DOC_TYPE_NM" },
      { displayName: "Plan Start", key: "emsPlanStartDO" },
      { displayName: "Plan Finish", key: "emsPlanFinishDO" },
      { displayName: "Remark", key: "EDPRRemark" },
    ];

    // Dynamic fields from columnsDate
    const dynamicFieldNamesNormal = columnData.flatMap(v =>
      suffixes2.map(suffix => `${v}//${suffix}`)
    ).filter(v => v.includes("Actual") || v.includes("Plan"));

    const dynamicFieldNamesCode = columnData.flatMap(v =>
      suffixes.map(suffix => `${v}//${suffix}`)
    ).filter(v => v.includes("Actual") || v.includes("Plan"));
    // Combine static and dynamic fields
    const combinedFields = [...ActstaticFields.map(f => f.key), ...dynamicFieldNamesCode];
    // 상단 헤더 생성
    const topHeaders = ActstaticFields.map(f => "").concat(dynamicFieldNamesNormal.map(field => {
      const splitField = field.split("//");
      return splitField.length === 2 ? splitField[0] : field;
    }));

    // 하단 헤더 생성
    const bottomHeaders = ActstaticFields.map(f => f.required ? f.displayName + "*" : f.displayName)
      .concat(dynamicFieldNamesNormal.map(field => {
        const splitField = field.split("//");
        return splitField.length === 2 ? splitField[1] : field;
      }));



    // 셀 병합을 위한 함수
    function mergeHeaderCells(headers, rowNumber) {
      let startColumn = 1;
      for (let i = 1; i <= headers.length; i++) {
        // 셀의 값이 비어있지 않은 경우에만 병합 검사
        if (headers[i - 1] !== "") {
          if (i === headers.length || headers[i] !== headers[i - 1]) {
            if (i - startColumn >= 1) {
              worksheet.mergeCells(rowNumber, startColumn, rowNumber, i);
            }
            startColumn = i + 1;
          }
        } else {
          // 셀의 값이 비어있는 경우, 다음 셀로 시작 열을 업데이트
          startColumn = i + 1;
        }
      }
    }



    worksheet.addRow(topHeaders);
    worksheet.addRow(bottomHeaders);
    const headerRow = worksheet.getRow(1);
    const headerRow2 = worksheet.getRow(2);

    mergeHeaderCells(topHeaders, 1);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow2.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });

    const startColumnIndex = 15; // 테두리를 시작할 열 인덱스

    // 첫 번째 헤더 행의 테두리 설정
    headerRow.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 두 번째 헤더 행의 테두리 설정
    headerRow2.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 데이터 행 추가 및 날짜 형식 확인을 위한 함수
    const isDate = (value) => {
      if (typeof value === 'string' || value instanceof String) {
        const date = new Date(value);
        return !isNaN(date.getTime());
      }
      return value instanceof Date;
    };


    worksheet.columns.forEach(column => {
      let maxWidth = 10; // 최소 너비
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      column.width = maxWidth + 2;
    });


    // 스타일 적용
    ActstaticFields.forEach((field, index) => {
      const style = {
        font: { color: { argb: field.required ? 'FFFF0000' : 'FF000000' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }
      };
      worksheet.getCell(2, index + 1).style = style;
    });

    const udpatedmoduleData = moduleData.map((v) => ({ ...v, EPPMActivityBPK: v.uuu_P6ActivityId, id: "" }))
    const dataRows = [...rows, ..._.differenceBy(udpatedmoduleData, rows, "EPPMActivityBPK")]
    .map(row => combinedFields.map(fieldName => {
      // 숫자 필드 확인 및 빈 값 처리
      const value = row[fieldName];
      if (fieldName === "EDPRPRManhour" ) {
        // return 0; // 'Doc. Manhour' 같은 숫자 필드는 빈 값인 경우 0을 반환
        return value || 0; // value가 'falsy' (null, undefined, 빈 문자열, 0, false 등)일 경우 0 반환
      }
      return value || "";
    }));




    // 데이터 행 추가 전에 날짜에 대한 타임존 오프셋 적용
    dataRows.forEach(row => {
      row.forEach((cell, index) => {
        if (isDate(cell) && index > 9) { // 칼럼이 A부터 J까지일 때의 조건 추가
          // 날짜 객체의 경우, 9시간을 더해준다 (KST로 조정)
          let date = new Date(cell);
          date.setHours(date.getHours() + 9);
          row[index] = date;
        }
      });
    });


    dataRows.forEach((row, rowIndex) => {
      worksheet.addRow(row);


      let excelRowIndex = rowIndex + 3; // 헤더가 있으므로 2를 더합니다.

      // 15번째 열부터 21번째 열까지 스타일 적용
      for (let colIndex = 14; colIndex <= bottomHeaders.length; colIndex++) {
        let cell = worksheet.getCell(excelRowIndex, colIndex);
        let creteriaCell = worksheet.getCell(1, colIndex).value;
        let referenceCell = worksheet.getCell(excelRowIndex, 5).value;

        const filterValue = _.uniq(columnData2.filter(v =>   v.FormatType !== "Issue" && v.PMSClassNM === referenceCell).map(v => v.PMSStageNM));


        if ((filterValue.length > 0 && !filterValue.includes(creteriaCell))||filterValue.length ===0 ) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb:  'E0E0E0' } }
          };
        } else if (filterValue.length > 0 && filterValue.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFADD8E6' } } // 파란색 배경
          };
        }

        if (worksheet.getCell(2, colIndex).value === "Plan") {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
          };
        }
      }

      for (let colIndex = 1; colIndex <= 13; colIndex++) {
        let cell = worksheet.getCell(excelRowIndex, colIndex);
        let creteriaCell = worksheet.getCell(2, colIndex).value;
        const noNeed = ["Activity ID", "Document No", "Activity Name", "Unit", "Discipline", "Class Code Name", "Doc. Manhour", "Doc. Type", "Remark", "Revision No", "Document Title", "", "Doc. Weight Factor", "Plan Start", "Plan Finish"]
        // const Need = [ "Document No*"]

        if (noNeed.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
          };
        }
      }
    });

    worksheet.views = [
      { state: 'frozen', ySplit: 2 }
    ];

    // 칼럼 너비 업데이트
    worksheet.columns.forEach((column, i) => {
      let maxWidth = 10; // 초기 최소 너비 설정

      // 헤더 행도 포함하여 너비 계산
      let headerLength = worksheet.getRow(1).getCell(i + 1).value
        ? worksheet.getRow(1).getCell(i + 1).value.toString().length
        : 0;
      if (headerLength > maxWidth) {
        maxWidth = headerLength;
      }

      // 각 셀의 값에 따라 최대 너비 계산 (날짜 형식 제외)
      column.eachCell({ includeEmpty: true }, cell => {
        if (!isDate(cell.value)) {
          let cellLength = cell.value ? cell.value.toString().length : 0;
          maxWidth = cellLength > maxWidth ? cellLength : maxWidth;
        }
      });

      column.width = maxWidth + 2; // 약간의 여백 추가
    });

    // 값이 있는 모든 셀에 대해 테두리 설정
worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    const border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
    cell.border = border;
  });
});



    // 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `실적 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };

  const downloadExcelForecast = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const ActstaticFields = [
      { displayName: "Activity ID", key: "EPPMActivityBPK" },
      { displayName: "Activity Name", key: "uuu_P6ActivityName" },
      { displayName: "Unit", key: "EDPRUnit" },
      { displayName: "Discipline", key: "WBSDiscNM" },
      { displayName: "Class Code Name", key: "PMSClassNM" },
      { displayName: "Document No", key: "Doc_No" },
      { displayName: "Revision No", key: "RevNo" },
      { displayName: "Document Title", key: "Doc_Title" },
      // { displayName: "Doc. Weight Factor", key: "EDPRPRWeightFactor" },
      { displayName: "Doc. Manhour", key: "EDPRPRManhour" },
      { displayName: "Doc. Type", key: "DOC_TYPE_NM" },
      { displayName: "Plan Start", key: "emsPlanStartDO" },
      { displayName: "Plan Finish", key: "emsPlanFinishDO" },
      { displayName: "Remark", key: "EDPRRemark" },
    ];

    // Dynamic fields from columnsDate
    const dynamicFieldNamesNormal = columnData.flatMap(v =>
      suffixes2.map(suffix => `${v}//${suffix}`)
    ).filter(v => v.includes("Actual") || v.includes("Plan") || v.includes("Forecast"));

    const dynamicFieldNamesCode = columnData.flatMap(v =>
      suffixes.map(suffix => `${v}//${suffix}`)
    ).filter(v => v.includes("Actual") || v.includes("Plan") || v.includes("Forecast"));
    // Combine static and dynamic fields
    const combinedFields = [...ActstaticFields.map(f => f.key), ...dynamicFieldNamesCode];

    const topHeaders = ActstaticFields.map(f => "").concat(dynamicFieldNamesNormal.map(field => {
      const splitField = field.split("//");
      return splitField.length === 2 ? splitField[0] : field;
    }));

    // 하단 헤더 생성
    const bottomHeaders = ActstaticFields.map(f => f.required ? f.displayName + "*" : f.displayName)
      .concat(dynamicFieldNamesNormal.map(field => {
        const splitField = field.split("//");
        return splitField.length === 2 ? splitField[1] : field;
      }));

    // 셀 병합을 위한 함수
    function mergeHeaderCells(headers, rowNumber) {
      let startColumn = 1;
      for (let i = 1; i <= headers.length; i++) {
        // 셀의 값이 비어있지 않은 경우에만 병합 검사
        if (headers[i - 1] !== "") {
          if (i === headers.length || headers[i] !== headers[i - 1]) {
            if (i - startColumn >= 1) {
              worksheet.mergeCells(rowNumber, startColumn, rowNumber, i);
            }
            startColumn = i + 1;
          }
        } else {
          // 셀의 값이 비어있는 경우, 다음 셀로 시작 열을 업데이트
          startColumn = i + 1;
        }
      }
    }
    worksheet.addRow(topHeaders);
    worksheet.addRow(bottomHeaders);
    const headerRow = worksheet.getRow(1);
    const headerRow2 = worksheet.getRow(2);

    mergeHeaderCells(topHeaders, 1);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow2.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });

    const startColumnIndex = 15; // 테두리를 시작할 열 인덱스

    // 첫 번째 헤더 행의 테두리 설정
    headerRow.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 두 번째 헤더 행의 테두리 설정
    headerRow2.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 데이터 행 추가 및 날짜 형식 확인을 위한 함수
    const isDate = (value) => {
      if (typeof value === 'string' || value instanceof String) {
        const date = new Date(value);
        return !isNaN(date.getTime());
      }
      return value instanceof Date;
    };
    // 스타일 적용
    ActstaticFields.forEach((field, index) => {
      const style = {
        font: { color: { argb: field.required ? 'FFFF0000' : 'FF000000' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }
      };
      worksheet.getCell(2, index + 1).style = style;
    });

    const udpatedmoduleData = moduleData.map((v) => ({ ...v, EPPMActivityBPK: v.uuu_P6ActivityId, id: "" }))
    const dataRows = [...rows, ..._.differenceBy(udpatedmoduleData, rows, "EPPMActivityBPK")]
    .map(row => combinedFields.map(fieldName => {
      // 숫자 필드 확인 및 빈 값 처리
      const value = row[fieldName];
      if (fieldName === "EDPRPRManhour" ) {
        // return 0; // 'Doc. Manhour' 같은 숫자 필드는 빈 값인 경우 0을 반환
        return value || 0; // value가 'falsy' (null, undefined, 빈 문자열, 0, false 등)일 경우 0 반환
      }
      return value || "";
    }));


    // 데이터 행 추가 전에 날짜에 대한 타임존 오프셋 적용
    dataRows.forEach(row => {
      row.forEach((cell, index) => {
        if (isDate(cell) && index > 9) { // 칼럼이 A부터 J까지일 때의 조건 추가
          // 날짜 객체의 경우, 9시간을 더해준다 (KST로 조정)
          let date = new Date(cell);
          date.setHours(date.getHours() + 9);
          row[index] = date;
        }
      });
    });


    dataRows.forEach((row, rowIndex) => {
      worksheet.addRow(row);


      let excelRowIndex = rowIndex + 3; // 헤더가 있으므로 2를 더합니다.

      const pmsClassNM = worksheet.getCell(`E${excelRowIndex}`).value; // 5번째 열 (E열)의 값을 가져옵니다.
      const filterValue = columnData2.filter(v => v.PMSClassNM === pmsClassNM).map(v => v.PMSStageNM);


      // 15번째 열부터 21번째 열까지 스타일 적용
      for (let colIndex = 14; colIndex <= bottomHeaders.length; colIndex++) {
        let cell = worksheet.getCell(excelRowIndex, colIndex);
        let creteriaCell = worksheet.getCell(1, colIndex).value;


        if ((filterValue.length > 0 && !filterValue.includes(creteriaCell))) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
          };
        } else if (filterValue.length > 0 && filterValue.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFADD8E6' } } // 파란색 배경
          };
        }


        if (worksheet.getCell(2, colIndex).value !== "Forecast") {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
          };
        }
      }

      for (let colIndex = 1; colIndex <= 13; colIndex++) {
        let cell = worksheet.getCell(excelRowIndex, colIndex);
        let creteriaCell = worksheet.getCell(2, colIndex).value;
        const noNeed = ["Activity ID", "Activity Name", "Unit", "Document No", "Discipline", "Class Code Name", "Doc. Manhour", "Doc. Type", "Remark", "Revision No", "Document Title", "", "Doc. Weight Factor", "Plan Start", "Plan Finish"]
        if (noNeed.includes(creteriaCell)) {
          cell.style = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
          };
        }
      }



    });

    worksheet.views = [
      { state: 'frozen', ySplit: 2 }
    ];

    // 칼럼 너비 업데이트
    worksheet.columns.forEach((column, i) => {
      let maxWidth = 10; // 초기 최소 너비 설정

      // 헤더 행도 포함하여 너비 계산
      let headerLength = worksheet.getRow(1).getCell(i + 1).value
        ? worksheet.getRow(1).getCell(i + 1).value.toString().length
        : 0;
      if (headerLength > maxWidth) {
        maxWidth = headerLength;
      }

      // 각 셀의 값에 따라 최대 너비 계산 (날짜 형식 제외)
      column.eachCell({ includeEmpty: true }, cell => {
        if (!isDate(cell.value)) {
          let cellLength = cell.value ? cell.value.toString().length : 0;
          maxWidth = cellLength > maxWidth ? cellLength : maxWidth;
        }
      });

      column.width = maxWidth + 2; // 약간의 여백 추가
    });


    // 값이 있는 모든 셀에 대해 테두리 설정
worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    const border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
    cell.border = border;
  });
});


    // 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `Forecast 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputPlanRef = useRef(null);

  const handlePlanButtonClick = () => {
    if (fileInputPlanRef.current) {
      fileInputPlanRef.current.click();
    }
  };

  const fileInputActualRef = useRef(null);

  const handleActualButtonClick = () => {
    if (fileInputActualRef.current) {
      fileInputActualRef.current.click();
    }
  };


  const fileInputForecastRef = useRef(null);

  const handleForecastButtonClick = () => {
    if (fileInputForecastRef.current) {
      fileInputForecastRef.current.click();
    }
  };

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Doc_Title' },
      }));
    };



    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', position: "absolute", right: 8 }}>
          <ButtonGroup size="small" style={{ display: moduleData.length === 0 ? "none" : 'block' }} variant="outlined" aria-label="outlined button group">
            <Button type='button' onClick={downloadExcelPlan} startIcon={<DownloadIcon />}>Plan Export</Button>
            <Button type='button' onClick={handlePlanButtonClick} startIcon={<UploadIcon />}>Plan Import
              <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputPlanRef}
                onChange={onAddPlan}
              /></Button>
          </ButtonGroup>
          <ButtonGroup size="small" color="secondary" style={{ display: moduleData.length === 0 ? "none" : 'block' }} variant="outlined" aria-label="outlined button group">
            <Button type='button' onClick={downloadExcelActual} startIcon={<DownloadIcon />}>Actual Export</Button>
            <Button type='button' onClick={handleActualButtonClick} startIcon={<UploadIcon />}>Actual Import
              <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputActualRef}
                onChange={onAddActual}
              /></Button>
          </ButtonGroup>

          <ButtonGroup size="small" color="success" style={{ display: moduleData.length === 0 ? "none" : 'block' }} variant="outlined" aria-label="outlined button group">
            <Button type='button' onClick={downloadExcelForecast} startIcon={<DownloadIcon />}>Forecast Export</Button>
            <Button type='button' onClick={handleForecastButtonClick} startIcon={<UploadIcon />}>Forecast Import
              <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputForecastRef}
                onChange={onAddForecast}
              /></Button>
          </ButtonGroup>
          {/* 
          <Button style={{ display: columnData.length === 0 ? "none" : "block" }}type='button' onClick={downloadExcel}>
전체 다운로드
          </Button> */}


          <Button type='button' style={{ display: columnData.length === 0 ? "none" : "block" }} variant="outlined" onClick={downloadExcel} size="small">전체 다운로드</Button>
          <Button type='button' style={{ display: columnData.length === 0 ? "none" : "block" }} variant="contained" onClick={handlerequest} size="small">프로젝트 적용</Button>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const suffixes = ["EPPMPlanDate", "EPPMActualDate", "EPPMForecastDate", "In_TR_No", "Out_TR_No", "Result"];
  const suffixes2 = ["Plan", "Actual", "Forecast", "In TR No", "Out TR No", "Result"];


  const isDateNotEditable = (params) => {
    return !params.row.EDPRReason;
  };

  const columnsDate = columnData.flatMap(v =>
    suffixes.map((suffix, index) => ({
      field: v + "//" + suffix,
      group: v,
      width: 100,
      type: suffixes2[index] === "Plan" || suffixes2[index] === "Forecast" || suffixes2[index] === "Actual" ? "date" : "text",
      editable: (suffixes2[index] === "Plan" && documentStatus.FINAL_YN !== "Y" )||(suffixes2[index] === "Plan" && documentStatus.LOCK_YN === "Y" && !isDateNotEditable) ||  (suffixes2[index] === "Plan" && documentStatus.LOCK_YN !== "Y" ) || suffixes2[index] === "Forecast" ? true : false,
      headerName: suffixes2[index],
      cellClassName: (params) => {
        if (columnData2.filter(stage => stage.PMSClassNM === params.row.PMSClassNM).map(name => name.PMSStageNM).includes(v)) {
          return '';
        } else {
          return 'notmatched';
        }

      },
    }))
  );

  const columnGroupingModel = columnData.map(v => ({
    groupId: v, // Use the value from columnData as groupId
    headerName: v,
    freeReordering: true,
    description: '', // You can add descriptions if needed
    children: suffixes.map((suffix, index) => ({
      field: v + "//" + suffix,
      // Include other properties as needed
    }))
  }));

  const [moduleTabeData, setModuleTabeData] = React.useState(moduleData);
  const [currentData, setCurrentData] = React.useState(null);


  const handleModuleData = (data) => {
    if (data.filter(v => v.selected).length > 0) {
      const activity = data.find(v => v.selected);
      currentData.EPPMActivityBPK = activity.uuu_P6ActivityId;
      currentData.uuu_P6ActivityName = activity.uuu_P6ActivityName;
      currentData.WBSDiscNM = activity.WBSDiscNM;
      currentData.PMSClassNM = activity.PMSClassNM;
      currentData.emsPlanStartDO = activity.emsPlanStartDO;
      currentData.emsPlanFinishDO = activity.emsPlanFinishDO;


      const updatedDataState = rows.map(item => {
        if (item.id === currentData.id) {
          return { ...item, ...currentData };
        }
        return item;
      });

      setRows(updatedDataState);
    }

    setOpen(false);

  };

  const isDocNoEditable = (record_no) => {
    console.log(record_no)
    return !record_no;
  };


  const columnsData = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: 'activity',
      type: 'actions',
      headerName: 'Activity 조회',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SearchIcon />}
              label="Search"
              className="textPrimary"
              onClick={() => handleShowModal(id)}
              color="inherit"
            />,
          ];
        }

        return [];
      },
    },
    { field: 'record_no', headerName: "레코드 번호", width: 100, editable: false },
    { field: 'EPPMActivityBPK', headerName: "Activity ID", width: 180, editable: false },
    { field: 'uuu_P6ActivityName', headerName: "Activity Name", width: 250, editable: false },
    { field: 'EDPRUnit', headerName: "Unit", width: 100, editable: true },
    { field: 'WBSDiscNM', headerName: "Discipline", width: 100, editable: false },
    { field: 'PMSClassNM', headerName: "Class Code Name", width: 100, editable: false },
    { field: 'Doc_No', headerName: "Document No", width: 100,  editable: (params) => isDocNoEditable(params.row.record_no),},
    { field: 'RevNo', headerName: "Revision No", width: 100, editable: false },
    { field: 'Doc_Title', headerName: "Document Title", width: 480, editable: true },
    // { field: 'EDPRPRWeightFactor', headerName: "Doc. Weight Factor", width: 100, editable: true },
    { field: 'EDPRPRManhour', headerName: "Doc. Manhour", width: 100, editable: true },
    { field: 'DOC_TYPE_NM', headerName: "Doc. Type", width: 100, editable: true },
    { field: 'emsPlanStartDO', headerName: "Plan Start", type: 'date', width: 100, editable: false },
    { field: 'emsPlanFinishDO', headerName: "Plan Finish", type: 'date', width: 100, editable: false },
    { field: 'EDPRRemark', headerName: "Remark", width: 100, editable: false },
    { field: 'EDPRReason', headerName: "Reason", width: 100, editable: true },
  ]

  const columns = [...columnsData, ...columnsDate];


  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = (newRow) => {
    // Define the required fields and their error messages
    const requiredFields = [
      { field: 'EPPMActivityBPK', message: "액티비티 아이디는 필수값입니다." },
      { field: 'Doc_No', message: "도면 번호는 필수값입니다." },
      { field: 'Doc_Title', message: "도면 이름은 필수값입니다." },
    ];


    // Check each required field
    for (const { field, message } of requiredFields) {
      // Check if the value is not present
      if (!newRow[field]) {
        throw new Error(`레코드 저장 중 에러 발생: ${message}`);
      }

      // Special check for date fields
      if (field === 'WStart') {
        const date = new Date(newRow[field]);
        if (isNaN(date.getTime())) { // Check if date is not valid
          throw new Error(`레코드 저장 중 에러 발생: ${message}`);
        }
      } else {
        // Check if the value is not a string or if the string value is empty
        if (typeof newRow[field] !== 'string' || newRow[field].trim() === '') {
          throw new Error(`레코드 저장 중 에러 발생: ${message}`);
        }
      }
    }

    const dateFields = columnsDate.map(v => v.field).filter(v => v.includes("Plan"));
    const hasAtLeastOneDate = dateFields.some(field => newRow[field]);
    if (!hasAtLeastOneDate) {
      throw new Error(("적어도 하나의 날짜 필드에 값이 있어야 합니다."));
    }

    for (let i = 0; i < dateFields.length; i++) {
      if (i > 0 && (
        !DatesOrderValid(newRow[dateFields[i - 1]], newRow[dateFields[i]]) ||
        !DatesOrderValid(newRow["emsPlanStartDO"], newRow[dateFields[i]]) ||
        !DatesOrderValid(newRow[dateFields[i]], newRow["emsPlanFinishDO"])
      )) {
        throw new Error("계획 날짜에 오류가 있습니다. 계획날짜는 전 단계의 날짜보다 같거나 커야합니다.");
      }
    }
    // If all checks pass, update the row
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };


  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const downloadExcel = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    // Dynamic fields from columnsDate
    const dynamicFieldNamesNormal = columnData.flatMap(v =>
      suffixes2.map(suffix => `${v}//${suffix}`)
    );

    const dynamicFieldNamesCode = columnData.flatMap(v =>
      suffixes.map(suffix => `${v}//${suffix}`)
    );
    // Combine static and dynamic fields
    const combinedFields = [...staticFields.map(f => f.key), ...dynamicFieldNamesCode];

    const topHeaders = staticFields.map(f => "").concat(dynamicFieldNamesNormal.map(field => {
      const splitField = field.split("//");
      return splitField.length === 2 ? splitField[0] : field;
    }));

    // 하단 헤더 생성
    const bottomHeaders = staticFields.map(f => f.displayName)
      .concat(dynamicFieldNamesNormal.map(field => {
        const splitField = field.split("//");
        return splitField.length === 2 ? splitField[1] : field;
      }));


    // 셀 병합을 위한 함수
    function mergeHeaderCells(headers, rowNumber) {
      let startColumn = 1;
      for (let i = 1; i <= headers.length; i++) {
        // 셀의 값이 비어있지 않은 경우에만 병합 검사
        if (headers[i - 1] !== "") {
          if (i === headers.length || headers[i] !== headers[i - 1]) {
            if (i - startColumn >= 1) {
              worksheet.mergeCells(rowNumber, startColumn, rowNumber, i);
            }
            startColumn = i + 1;
          }
        } else {
          // 셀의 값이 비어있는 경우, 다음 셀로 시작 열을 업데이트
          startColumn = i + 1;
        }
      }
    }
    worksheet.addRow(topHeaders);
    worksheet.addRow(bottomHeaders);
    const headerRow = worksheet.getRow(1);
    const headerRow2 = worksheet.getRow(2);

    mergeHeaderCells(topHeaders, 1);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow2.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });

    const startColumnIndex = 15; // 테두리를 시작할 열 인덱스

    // 첫 번째 헤더 행의 테두리 설정
    headerRow.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 두 번째 헤더 행의 테두리 설정
    headerRow2.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 데이터 행 추가 및 날짜 형식 확인을 위한 함수
    const isDate = (value) => {
      if (typeof value === 'string' || value instanceof String) {
        const date = new Date(value);
        return !isNaN(date.getTime());
      }
      return value instanceof Date;
    };
    // 스타일 적용
    staticFields.forEach((field, index) => {
      const style = {
        font: { color: { argb: field.required ? 'FFFF0000' : 'FF000000' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }
      };
      worksheet.getCell(2, index + 1).style = style;
    });

    const udpatedmoduleData = moduleData.map((v) => ({ ...v, EPPMActivityBPK: v.uuu_P6ActivityId, id: "" }))
    const dataRows = [...rows, ..._.differenceBy(udpatedmoduleData, rows, "EPPMActivityBPK")]
    .map(row => combinedFields.map(fieldName => {
      const value = row[fieldName];
      // 'Doc. Manhour' 필드의 경우 빈 값에 대해 0을 반환
      if (fieldName === "EDPRPRManhour") {
        return value || 0; // value가 'falsy' (null, undefined, 빈 문자열, 0, false 등)일 경우 0 반환
      }
      return value || ""; // 다른 필드는 빈 값인 경우 빈 문자열 반환
    }));
  // Helper function to determine if the header contains any of the specified keywords
const isTimeSensitiveColumn = (index) => {
  const headerValue = bottomHeaders[index].toLowerCase();
  return headerValue.includes("plan") || headerValue.includes("actual") || headerValue.includes("forecast");
};

dataRows.forEach(row => {
  row.forEach((cell, index) => {
    // Check if the cell date should be adjusted based on the header content
    if (isDate(cell) && isTimeSensitiveColumn(index)) {
      let date = new Date(cell);
      date.setHours(date.getHours() + 9); // Add 9 hours for KST adjustment
      row[index] = date;
    }
  });
});


    dataRows.forEach((row, rowIndex) => {
      worksheet.addRow(row);
    });


    worksheet.views = [
      { state: 'frozen', ySplit: 2 }
    ];

    // 칼럼 너비 업데이트
    worksheet.columns.forEach((column, i) => {
      let maxWidth = 10; // 초기 최소 너비 설정

      // 헤더 행도 포함하여 너비 계산
      let headerLength = worksheet.getRow(1).getCell(i + 1).value
        ? worksheet.getRow(1).getCell(i + 1).value.toString().length
        : 0;
      if (headerLength > maxWidth) {
        maxWidth = headerLength;
      }

      // 각 셀의 값에 따라 최대 너비 계산 (날짜 형식 제외)
      column.eachCell({ includeEmpty: true }, cell => {
        if (!isDate(cell.value)) {
          let cellLength = cell.value ? cell.value.toString().length : 0;
          maxWidth = cellLength > maxWidth ? cellLength : maxWidth;
        }
      });

      column.width = maxWidth + 2; // 약간의 여백 추가
    });

    // 값이 있는 모든 셀에 대해 테두리 설정
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
        const border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.border = border;
      });
    });
    
    // 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `전체 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);

  };

  const parseExcelDateWithOneMinuteAdjustment = (excelDate) => {
    const date = new Date(dayjs(excelDate).add(1, "minutes"))
    return date;
  };



  const onAddPlan = (event) => {
    try {

      if (!event.target.files) {
        return;
      }

      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onloadend = (ev) => {
        if (!ev?.target?.result) {
          return;
        }

        const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
        const sheets = wb.SheetNames;

        const headers = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, range: 0, blankrows: false }).slice(0, 2);
        // Combine the two header rows
        const combinedHeader = headers[0].map((topCell, index) => {
          const bottomCell = headers[1][index];
          const newIndex = _.findIndex(suffixes2, s => s === bottomCell);
          const bottomCell2 = newIndex !== -1 ? suffixes[newIndex] : bottomCell;

          // 필요한 경우, topCell과 bottomCell2에서 별표(*) 제거
          const cleanTopCell = topCell ? topCell.replace(/\*/g, '') : topCell;
          const cleanBottomCell2 = bottomCell2.replace(/\*/g, '');

          return cleanTopCell ? `${cleanTopCell}//${cleanBottomCell2}` : cleanBottomCell2;
        });


        const plancolumns = [...columnsData, ...columnsDate.filter(v => v.field.includes("Plan"))];

        const originColumns = plancolumns.map(v => v.field).filter(v => v !== "actions" && v !== "activity" && v !== "record_no");


        const headerKeyMapping = combinedHeader.reduce((acc, headerName, index) => {
          const staticField = staticFields.find(field => field.displayName === headerName);
          if (staticField) {
            acc[headerName] = staticField.key;
          } else {
            // For headers with '//', use the headerName itself as key
            acc[headerName] = headerName;
          }
          return acc;
        }, {});

        const headerValues = Object.values(headerKeyMapping).filter(v => v !== "id");

        if (sheets.length) {
          if (headerValues.every(function (value, index) { return value === originColumns[index] })) {

            // 모든 데이터를 읽기 (헤더 포함)
            const allData = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, blankrows: false });

            // 첫 번째와 두 번째 행을 추출du
            const firstRow = allData[0].map((topCell) => (topCell ? topCell.replace(/\*/g, '') : topCell));
            const secondRow = allData[1].map(v => {
              const index = _.findIndex(suffixes2, s => s === v);
              return index !== -1 ? suffixes[index] : v; // 일치하는 요소가 없을 경우 원래 값을 반환
            }).map((Cell) => (Cell ? Cell.replace(/\*/g, '') : Cell));


            // 결합된 헤더 생성
            const combinedHeaderExcel = firstRow.map((cell, index) => {
              if (cell !== "") {
                return cell + '//' + secondRow[index];
              }
              return staticFields.find(v => v.displayName === secondRow[index]).key;
            });


            // 실제 데이터 행들만 추출 (첫 번째와 두 번째 행 제외)
            const dataRows = allData.slice(2);

            // 결합된 헤더를 사용하여 각 행의 데이터 추출 및 변환
            const excelrows = dataRows.map(row => {
              return combinedHeaderExcel.reduce((newRow, header, index) => {
                newRow[header] = row[index];
                return newRow;
              }, {});
            })

            const isValidDate = (dateString) => {
              const date = new Date(dateString);
              return !isNaN(date.getTime());
            };
            excelrows.forEach(row => {
              // row가 유효하고 MDModuleNoDPK 속성이 존재하며, 이 속성이 null이나 undefined가 아닌지 확인
              if (row && row.hasOwnProperty("EPPMActivityBPK") && row["EPPMActivityBPK"]) {
                const activityID = row["EPPMActivityBPK"];
            
                // 일치하는 MDModuleNumber 항목 필터링
                const matchedActivity = moduleData.find(act =>
                  activityID === act.uuu_P6ActivityId
                );
            
                // matchedActivity가 없을 경우 처리
                if (!matchedActivity) {
                  // 예외 처리 또는 기본값 설정 등을 수행할 수 있습니다.
                  // 이 예시에서는 모든 필드를 초기화합니다.
                  row["uuu_P6ActivityName"] = null;
                  row["WBSDiscNM"] = null;
                  row["PMSClassNM"] = null;
                  row["emsPlanStartDO"] = null;
                  row["emsPlanFinishDO"] = null;
                } else {
                  // 필요한 경우 row 업데이트
                  row["uuu_P6ActivityName"] = matchedActivity.uuu_P6ActivityName;
                  row["WBSDiscNM"] = matchedActivity.WBSDiscNM;
                  row["PMSClassNM"] = matchedActivity.PMSClassNM;
                  row["emsPlanStartDO"] = matchedActivity.emsPlanStartDO;
                  row["emsPlanFinishDO"] = matchedActivity.emsPlanFinishDO;
                }
              }
              Object.keys(row).forEach(key => {
                if (key.includes("Plan")) {
                  // 여기에 각 "Plan" 필드에 대한 처리 로직 추가
                  // 예: 날짜 형식을 확인하고 변환하는 로직
                  if (row[key]) {
                    row[key] = parseExcelDateWithOneMinuteAdjustment(row[key]);
                  }
                }
              });
            });
            

            const errorMessages = [];

            ///엑셀 Validataion시작
            const allowRecord = rows.filter(v => v.record_no !== undefined && v.record_no !== null).map((v => v.record_no));
            const requiredColumns = ["EPPMActivityBPK", "Doc_No", "Doc_Title", "DOC_TYPE_NM"];
            const convertedColumns = ["Activity ID", "Document No", "Document Title", "Doc. Type"];


            // Check each row for valid values
            for (let i = 0; i < excelrows.length; i++) {
              const row = excelrows[i];
              let errorsForRow = [];

              requiredColumns.forEach((col, index) => {
                if (!row[col] && row[col] !== 0) {
                  // missingColumnIndex = index;
                  errorsForRow.push(`'${convertedColumns[index]}' 필드가 누락되었습니다.`);
                }
              });

              if (row.record_no !== "" && row.record_no !== undefined && !allowRecord.includes(row.record_no)) {
                errorsForRow.push("'레코드 번호'가 유효하지 않습니다.");
              }

              // 날짜 필드에 대한 유효성 검사
              const dateFields = columnsDate.map(v => v.field).filter(v => v.includes("Plan"));

              for (let j = 0; j < dateFields.length; j++) {

                if (row[dateFields[j]] && !isValidDate(row[dateFields[j]])) {

                  errorsForRow.push(`'${dateFields[j]}' 날짜가 유효하지 않습니다.`);

                }

                if (j > 0 &&
                  (!DatesOrderValid(row[dateFields[j - 1]], row[dateFields[j]]) ||
                    !DatesOrderValid(row["emsPlanStartDO"], row[dateFields[j]]) ||
                    !DatesOrderValid(row[dateFields[j]], row["emsPlanFinishDO"]))
                ) {

                  errorsForRow.push(`'${dateFields[j]}' 날짜 순서가 올바르지 않습니다.`);
                }
              }

              if (errorsForRow.length > 0) {
                errorMessages.push({ row: i, errors: errorsForRow });
              }


            }

            if (errorMessages.length > 0) {

              // Dynamic fields from columnsDate
              const dynamicFieldNames = columnData.flatMap(v =>
                suffixes2.map(suffix => `${v}//${suffix}`)
              ).filter(v => v.includes("Plan"));

              const dynamicFieldNamesCode = columnData.flatMap(v =>
                suffixes.map(suffix => `${v}//${suffix}`)
              ).filter(v => v.includes("Plan"));
              // Combine static and dynamic fields
              const combinedFields = [...staticFields.map(f => f.key), ...dynamicFieldNamesCode, "error"];


              // Create two arrays for the two header rows
              const topHeaders = staticFields.map(f => "").concat(dynamicFieldNames.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[0] : "";
              }));

              const bottomHeaders = staticFields.map(f => f.displayName).concat(dynamicFieldNames.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[1] : field;
              }));

              // Create the worksheet with headers
              const worksheet = utils.aoa_to_sheet([topHeaders, bottomHeaders]);

              // Find and merge cells with the same value in the top header
              let currentMerge = null;
              topHeaders.forEach((header, index) => {
                if (header !== "" && (index === 0 || topHeaders[index - 1] !== header)) {
                  currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
                }
                if (header !== "" && (index === topHeaders.length - 1 || topHeaders[index + 1] !== header)) {
                  if (currentMerge) {
                    currentMerge.e.c = index;
                    if (!worksheet["!merges"]) worksheet["!merges"] = [];
                    worksheet["!merges"].push(currentMerge);
                  }
                }
              });

              excelrows.forEach((row, index) => {
                const errorForRow = errorMessages.find(e => e.row === index);
                if (errorForRow) {
                  row['error'] = errorForRow.errors.join('; ');
                }
              });


              // Append your data rows
              const dataRows = excelrows.map(row => combinedFields.map(fieldName => row[fieldName] || ""));
              utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

              const workbook = utils.book_new();
              utils.book_append_sheet(workbook, worksheet, "Sheet1");
              writeFile(workbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);


              // 오류 알림
              Swal.fire({
                icon: 'error',
                title: '오류가 발견되었습니다. 다시 다운로드된 엑셀 파일을 확인하세요.',
                confirmButtonText: '확인'
              });
            } else {

              const add_row = _.differenceBy(excelrows, rows, 'Doc_No').map((v) => { delete v.record_no; return v; })
              .map((v)=>({...v, EDPRPRManhour:0}))


              const exist = _.differenceBy(excelrows, add_row, 'Doc_No');

              const updatedData = exist.map((v) => {
                const recordNo = rows.find(item => item.Doc_No === v.Doc_No)?.record_no || '';
                return { ...v, record_no: recordNo };
              })

              // 새로운 행 추가
              const newDataState = [...updatedData, ...add_row];
              // setRows(newDataState.map((v) => ({ ...v ,id: rows.find(item => item.Doc_No === v.Doc_No)?.id || randomId() })));
              setRows((prevRows) => {
                const newDataState = excelrows.map((row, index) => ({
                  ...prevRows.find(v => v.record_no === row.record_no),
                  ...row,
                  ...{ id: randomId() }
                }));
    
                newDataState.forEach((row) => {
                  const isLocked = documentStatus.LOCK_YN === "Y";
                  const isFinal = documentStatus.FINAL_YN === "Y";
                  const hasReason = !!row.EDPRReason;
    
                  if ((isLocked && !hasReason) || isFinal) {
                    Object.keys(row).forEach(key => {
                      if (key.includes("Plan") || key === "EDPRPRManhour") {
                        delete row[key];
                      }
                    });
                  }
                });
    
                return newDataState;
              });

              
              Swal.fire({
                icon: 'success',
                title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 프로젝트 적용 버튼을 클릭하세요',
                confirmButtonText: '확인'
              });

            }



          }

          else {
            Swal.fire({
              icon: 'error',
              title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
              confirmButtonText: '확인'
            });
          }
        }

      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      // 에러 발생 시 Swal 팝업 띄우기
      Swal.fire({
        icon: 'error',
        title: '오류가 발생했습니다!',
        text: error.message,
        confirmButtonText: '확인'
      });
    }
  };

  const onAddActual = (event) => {
    try {

      if (!event.target.files) {
        return;
      }

      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onloadend = (ev) => {
        if (!ev?.target?.result) {
          return;
        }

        const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
        const sheets = wb.SheetNames;

        const headers = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, range: 0, blankrows: false }).slice(0, 2);

        // 병합된 셀 처리 및 마지막 셀에 이전 셀 값 추가
        headers.forEach(row => {
          for (let i = 0; i < row.length; i++) {
            // 빈 셀인 경우 이전 셀의 값을 사용
            if (row[i] === undefined || row[i] === null || row[i].trim() === '') {
              row[i] = row[i - 1];
            }
          }
          // 마지막 셀 다음에 이전 셀의 값을 하나 더 추가
          const lastValue = row[row.length - 1];
          row.push(lastValue, lastValue); // 마지막 값 복사하여 추가
        });


        // Combine the two header rows
        const combinedHeader = headers[0].map((topCell, index) => {
          const bottomCell = headers[1][index];


          const newIndex = _.findIndex(suffixes2, s => s === bottomCell);
          const bottomCell2 = newIndex !== -1 ? suffixes[newIndex] : bottomCell;


          // 필요한 경우, topCell과 bottomCell2에서 별표(*) 제거
          const cleanTopCell = (topCell ? topCell.replace(/\*/g, '') : topCell);
          const cleanBottomCell2 = bottomCell2.replace(/\*/g, '');

          return cleanTopCell ? `${cleanTopCell}//${cleanBottomCell2}` : cleanBottomCell2;
        });


        const plancolumns = [...columnsData, ...columnsDate.filter(v => (v.field.includes("Actual") || v.field.includes("Plan")))];

        const originColumns = plancolumns.map(v => v.field).filter(v => v !== "actions" && v !== "activity" && v !== "record_no");



        const headerKeyMapping = combinedHeader.reduce((acc, headerName, index) => {
          const staticField = staticFields.find(field => field.displayName === headerName);
          if (staticField) {
            acc[headerName] = staticField.key;
          } else {
            // For headers with '//', use the headerName itself as key
            acc[headerName] = headerName;
          }
          return acc;
        }, {});

        const headerValues = Object.values(headerKeyMapping).filter(v => v !== "id");


        if (sheets.length) {
          if (headerValues.every(function (value, index) { return value === originColumns[index] })) {

            // 모든 데이터를 읽기 (헤더 포함)
            const allData = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, blankrows: false });

            // 첫 번째와 두 번째 행을 추출
            const firstRow = allData[0];

            // 병합된 셀 처리 및 마지막 셀에 이전 셀 값 추가
            for (let i = 0; i < firstRow.length; i++) {
              // 빈 셀인 경우 이전 셀의 값을 사용
              if (firstRow[i] === undefined || firstRow[i] === null || (typeof firstRow[i] === 'string' && firstRow[i].trim() === '')) {
                firstRow[i] = firstRow[i - 1];
              }
            }

            // 마지막 셀 다음에 이전 셀의 값을 하나 더 추가
            const lastValue = firstRow[firstRow.length - 1];
            firstRow.push(lastValue);

            const secondRow = allData[1].map(v => {
              const index = _.findIndex(suffixes2, s => s === v);
              return index !== -1 ? suffixes[index] : v; // 일치하는 요소가 없을 경우 원래 값을 반환
            }).map((Cell) => (Cell ? Cell.replace(/\*/g, '') : Cell));

            // 결합된 헤더 생성
            const combinedHeaderExcel = firstRow.map((cell, index) => {
              if (cell !== "" && cell !== undefined) {
                return cell + '//' + secondRow[index];
              }
              return staticFields.find(v => v.displayName === secondRow[index]).key;
            });



            // 실제 데이터 행들만 추출 (첫 번째와 두 번째 행 제외)
            const dataRows = allData.slice(2);

            // 결합된 헤더를 사용하여 각 행의 데이터 추출 및 변환
            const excelrows = dataRows.map(row => {
              return combinedHeaderExcel.reduce((newRow, header, index) => {
                newRow[header] = row[index];
                return newRow;
              }, {});
            })

            excelrows.forEach(row => {

              Object.keys(row).forEach(key => {
                if (key.includes("Actual")) {
                  // 여기에 각 "Plan" 필드에 대한 처리 로직 추가
                  // 예: 날짜 형식을 확인하고 변환하는 로직
                  if (row[key]) {
                    row[key] = parseExcelDateWithOneMinuteAdjustment(row[key]);
                  }
                }
              });

            });

            const errorMessages = [];

            ///엑셀 Validataion시작
            const allowRecord = rows.filter(v => v.record_no !== undefined && v.record_no !== null).map((v => v.record_no));
            const requiredColumns = ["Doc_No"];
            const convertedColumns = ["Document No"];

            const isValidDate = (dateString) => {
              const date = new Date(dateString);
              return !isNaN(date.getTime());
            };

            // Check each row for valid values
            for (let i = 0; i < excelrows.length; i++) {
              const row = excelrows[i];
              let errorsForRow = [];

              requiredColumns.forEach((col, index) => {
                if (!row[col] && row[col] !== 0) {
                  // missingColumnIndex = index;
                  errorsForRow.push(`'${convertedColumns[index]}' 필드가 누락되었습니다.`);
                }
              });

              if (row.record_no !== "" && row.record_no !== undefined && !allowRecord.includes(row.record_no)) {
                errorsForRow.push("'레코드 번호'가 유효하지 않습니다.");
              }

              // 날짜 필드에 대한 유효성 검사
              const dateFields = columnsDate.map(v => v.field).filter(v => v.includes("Actual"));

              for (let j = 0; j < dateFields.length; j++) {

                if (row[dateFields[j]] && !isValidDate(row[dateFields[j]])) {

                  errorsForRow.push(`'${dateFields[j]}' 날짜가 유효하지 않습니다.`);

                }

                if (j > 0 && (
                  !DatesOrderValid(row[dateFields[j - 1]], row[dateFields[j]]) )
                ) {

                  errorsForRow.push(`'${dateFields[j]}' 날짜 순서가 올바르지 않습니다.`);
                }
              }

              if (errorsForRow.length > 0) {
                errorMessages.push({ row: i, errors: errorsForRow });
              }


            }

            if (errorMessages.length > 0) {

              // Dynamic fields from columnsDate
              const dynamicFieldNames = columnData.flatMap(v =>
                suffixes2.map(suffix => `${v}//${suffix}`)
              ).filter(v => v.includes("Actual") || v.includes("Plan"));

              const dynamicFieldNamesCode = columnData.flatMap(v =>
                suffixes.map(suffix => `${v}//${suffix}`)
              ).filter(v => v.includes("Actual") || v.includes("Plan"));

              // Combine static and dynamic fields
              const combinedFields = [...staticFields.map(f => f.key), ...dynamicFieldNamesCode, "error"];


              // Create two arrays for the two header rows
              const topHeaders = staticFields.map(f => "").concat(dynamicFieldNames.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[0] : "";
              }));

              const bottomHeaders = staticFields.map(f => f.displayName).concat(dynamicFieldNames.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[1] : field;
              }));

              // Create the worksheet with headers
              const worksheet = utils.aoa_to_sheet([topHeaders, bottomHeaders]);

              // Find and merge cells with the same value in the top header
              let currentMerge = null;
              topHeaders.forEach((header, index) => {
                if (header !== "" && (index === 0 || topHeaders[index - 1] !== header)) {
                  currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
                }
                if (header !== "" && (index === topHeaders.length - 1 || topHeaders[index + 1] !== header)) {
                  if (currentMerge) {
                    currentMerge.e.c = index;
                    if (!worksheet["!merges"]) worksheet["!merges"] = [];
                    worksheet["!merges"].push(currentMerge);
                  }
                }
              });

              excelrows.forEach((row, index) => {
                const errorForRow = errorMessages.find(e => e.row === index);
                if (errorForRow) {
                  row['error'] = errorForRow.errors.join('; ');
                }
              });


              // Append your data rows
              const dataRows = excelrows.map(row => combinedFields.map(fieldName => row[fieldName] || ""));
              utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

              const workbook = utils.book_new();
              utils.book_append_sheet(workbook, worksheet, "Sheet1");
              writeFile(workbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);


              // 오류 알림
              Swal.fire({
                icon: 'error',
                title: '오류가 발견되었습니다. 다시 다운로드된 엑셀 파일을 확인하세요.',
                confirmButtonText: '확인'
              });
            } else {

              const add_row = _.differenceBy(excelrows, rows, 'Doc_No').map((v) => { delete v.record_no; return v; })
              // .map(row => {
              //   // 새 객체를 생성하여 "Forecast"가 포함된 키와 "Doc_No"를 포함시킵니다.
              //   const filteredRow = _.reduce(row, (result, value, key) => {
              //     if (key.includes('Actual') || key === 'Doc_No') {
              //       result[key] = value;
              //     }
              //     return result;
              //   }, {});

              //   // 'record_no' 키가 존재하면 삭제합니다.
              //   delete filteredRow.record_no;
              //   return filteredRow;
              // });


              const exist = _.differenceBy(excelrows, add_row, 'Doc_No');

              const updatedData = exist.map((v) => {
                const recordNo = rows.find(item => item.Doc_No === v.Doc_No)?.record_no || '';
                return { ...v, record_no: recordNo };
              })
              // .map(row => {
              //   // 새 객체를 생성하여 "Forecast"가 포함된 키와 "Doc_No"를 포함시킵니다.
              //   const filteredRow = _.reduce(row, (result, value, key) => {
              //     if (key.includes('Actual') || key === 'Doc_No'|| key === 'record_no') {
              //       result[key] = value;
              //     }
              //     return result;
              //   }, {});

              //   // 'record_no' 키가 존재하면 삭제합니다.
              //   return filteredRow;
              // });

              
              // newDataState 준비
              const newDataState = updatedData.map(row => {
                const filteredRow = Object.keys(row).reduce((newObj, key) => {
                  if (key.includes('Actual') || key === 'record_no') {
                    newObj[key] = row[key];
                  }
                  return newObj;
                }, {});

                return filteredRow;
              })

              const updatedRows = rows.filter(row => 
                newDataState.some(newDataRow => newDataRow.record_no === row.record_no)
              ).map(row => {
                // newDataState에서 같은 record_no를 가진 객체를 찾음
                const updateData = newDataState.find(newDataRow => newDataRow.record_no === row.record_no);
              
                // 업데이트할 데이터가 존재하면
                if (updateData) {
                  // updateData의 각 키에 대해 row 객체를 업데이트
                  Object.keys(updateData).forEach(key => {
                    row[key] = updateData[key];
                  });
                }
              
                // 업데이트된 row 객체 반환
                return row;
              });
              
              setRows(updatedRows);

              Swal.fire({
                icon: 'success',
                title: '엑셀을 테이블로 로드했습니다.',
                confirmButtonText: '확인'
              });

            }



          }

          else {
            Swal.fire({
              icon: 'error',
              title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
              confirmButtonText: '확인'
            });
          }
        }

      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      // 에러 발생 시 Swal 팝업 띄우기
      Swal.fire({
        icon: 'error',
        title: '오류가 발생했습니다!',
        text: error.message,
        confirmButtonText: '확인'
      });
    }
  };

  const onAddForecast = (event) => {
    try {

      if (!event.target.files) {
        return;
      }

      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onloadend = (ev) => {
        if (!ev?.target?.result) {
          return;
        }

        const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
        const sheets = wb.SheetNames;

        const headers = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, range: 0, blankrows: false }).slice(0, 2);

        // 병합된 셀 처리 및 마지막 셀에 이전 셀 값 추가
        headers.forEach(row => {
          for (let i = 0; i < row.length; i++) {
            // 빈 셀인 경우 이전 셀의 값을 사용
            if (row[i] === undefined || row[i] === null || row[i].trim() === '') {
              row[i] = row[i - 1];
            }
          }
          // 마지막 셀 다음에 이전 셀의 값을 하나 더 추가
          const lastValue = row[row.length - 1];
          row.push(lastValue, lastValue);
        });


        // Combine the two header rows
        const combinedHeader = headers[0].map((topCell, index) => {
          const bottomCell = headers[1][index];
          const newIndex = _.findIndex(suffixes2, s => s === bottomCell);
          const bottomCell2 = newIndex !== -1 ? suffixes[newIndex] : bottomCell;

          // 필요한 경우, topCell과 bottomCell2에서 별표(*) 제거
          const cleanTopCell = topCell ? topCell.replace(/\*/g, '') : topCell;
          const cleanBottomCell2 = bottomCell2.replace(/\*/g, '');

          return cleanTopCell ? `${cleanTopCell}//${cleanBottomCell2}` : cleanBottomCell2;
        });


        const plancolumns = [...columnsData, ...columnsDate.filter(v => (v.field.includes("Actual") || v.field.includes("Plan") || v.field.includes("Forecast")))];

        const originColumns = plancolumns.map(v => v.field).filter(v => v !== "actions" && v !== "activity" && v !== "record_no");



        const headerKeyMapping = combinedHeader.reduce((acc, headerName, index) => {
          const staticField = staticFields.find(field => field.displayName === headerName);
          if (staticField) {
            acc[headerName] = staticField.key;
          } else {
            // For headers with '//', use the headerName itself as key
            acc[headerName] = headerName;
          }
          return acc;
        }, {});

        const headerValues = Object.values(headerKeyMapping).filter(v => v !== "id");


        if (sheets.length) {
          if (headerValues.every(function (value, index) { return value === originColumns[index] })) {

            // 모든 데이터를 읽기 (헤더 포함)
            const allData = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, blankrows: false });

            // 첫 번째와 두 번째 행을 추출
            const firstRow = allData[0];

            // 병합된 셀 처리 및 마지막 셀에 이전 셀 값 추가
            for (let i = 0; i < firstRow.length; i++) {
              // 빈 셀인 경우 이전 셀의 값을 사용
              if (firstRow[i] === undefined || firstRow[i] === null || (typeof firstRow[i] === 'string' && firstRow[i].trim() === '')) {
                firstRow[i] = firstRow[i - 1];
              }
            }

            // 마지막 셀 다음에 이전 셀의 값을 하나 더 추가
            const lastValue = firstRow[firstRow.length - 1];
            firstRow.push(lastValue, lastValue);


            const secondRow = allData[1].map(v => {
              const index = _.findIndex(suffixes2, s => s === v);
              return index !== -1 ? suffixes[index] : v; // 일치하는 요소가 없을 경우 원래 값을 반환
            }).map((Cell) => (Cell ? Cell.replace(/\*/g, '') : Cell));

            // 결합된 헤더 생성
            const combinedHeaderExcel = firstRow.map((cell, index) => {
              if (cell !== "" && cell !== undefined) {
                return cell + '//' + secondRow[index];
              }
              return staticFields.find(v => v.displayName === secondRow[index]).key;
            });



            // 실제 데이터 행들만 추출 (첫 번째와 두 번째 행 제외)
            const dataRows = allData.slice(2);

            // 결합된 헤더를 사용하여 각 행의 데이터 추출 및 변환
            const excelrows = dataRows.map(row => {
              return combinedHeaderExcel.reduce((newRow, header, index) => {
                newRow[header] = row[index];
                return newRow;
              }, {});
            })

            excelrows.forEach(row => {
              Object.keys(row).forEach(key => {
                if (key.includes("Forecast")) {
                  // 여기에 각 "Plan" 필드에 대한 처리 로직 추가
                  // 예: 날짜 형식을 확인하고 변환하는 로직
                  if (row[key]) {
                    row[key] = parseExcelDateWithOneMinuteAdjustment(row[key]);
                  }
                }
              });

            });

            const errorMessages = [];

            ///엑셀 Validataion시작
            const allowRecord = rows.filter(v => v.record_no !== undefined && v.record_no !== null).map((v => v.record_no));
            const requiredColumns = ["Doc_No"];
            const convertedColumns = ["Document No"];

            const isValidDate = (dateString) => {
              const date = new Date(dateString);
              return !isNaN(date.getTime());
            };

            // Check each row for valid values
            for (let i = 0; i < excelrows.length; i++) {
              const row = excelrows[i];
              let errorsForRow = [];

              requiredColumns.forEach((col, index) => {
                if (!row[col] && row[col] !== 0) {
                  // missingColumnIndex = index;
                  errorsForRow.push(`'${convertedColumns[index]}' 필드가 누락되었습니다.`);
                }
              });

              if (row.record_no !== "" && row.record_no !== undefined && !allowRecord.includes(row.record_no)) {
                errorsForRow.push("'레코드 번호'가 유효하지 않습니다.");
              }

              // 날짜 필드에 대한 유효성 검사
              const dateFields = columnsDate.map(v => v.field).filter(v => v.includes("Forecast"));

              for (let j = 0; j < dateFields.length; j++) {

                if (row[dateFields[j]] && !isValidDate(row[dateFields[j]])) {

                  errorsForRow.push(`'${dateFields[j]}' 날짜가 유효하지 않습니다.`);

                }

                if (j > 0 && (
                  !DatesOrderValid(row[dateFields[j - 1]], row[dateFields[j]]) ||
                  !DatesOrderValid(row["emsPlanStartDO"], row[dateFields[j]]) ||
                  !DatesOrderValid(row[dateFields[j]], row["emsPlanFinishDO"]))
                ) {

                  errorsForRow.push(`'${dateFields[j]}' 날짜 순서가 올바르지 않습니다.`);
                }
              }

              if (errorsForRow.length > 0) {
                errorMessages.push({ row: i, errors: errorsForRow });
              }


            }

            if (errorMessages.length > 0) {

              // Dynamic fields from columnsDate
              const dynamicFieldNames = columnData.flatMap(v =>
                suffixes2.map(suffix => `${v}//${suffix}`)
              ).filter(v => v.includes("Actual") || v.includes("Plan") || v.includes("Forecast"));

              const dynamicFieldNamesCode = columnData.flatMap(v =>
                suffixes.map(suffix => `${v}//${suffix}`)
              ).filter(v => v.includes("Actual") || v.includes("Plan") || v.includes("Forecast"));

              // Combine static and dynamic fields
              const combinedFields = [...staticFields.map(f => f.key), ...dynamicFieldNamesCode, "error"];


              // Create two arrays for the two header rows
              const topHeaders = staticFields.map(f => "").concat(dynamicFieldNames.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[0] : "";
              }));

              const bottomHeaders = staticFields.map(f => f.displayName).concat(dynamicFieldNames.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[1] : field;
              }));

              // Create the worksheet with headers
              const worksheet = utils.aoa_to_sheet([topHeaders, bottomHeaders]);

              // Find and merge cells with the same value in the top header
              let currentMerge = null;
              topHeaders.forEach((header, index) => {
                if (header !== "" && (index === 0 || topHeaders[index - 1] !== header)) {
                  currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
                }
                if (header !== "" && (index === topHeaders.length - 1 || topHeaders[index + 1] !== header)) {
                  if (currentMerge) {
                    currentMerge.e.c = index;
                    if (!worksheet["!merges"]) worksheet["!merges"] = [];
                    worksheet["!merges"].push(currentMerge);
                  }
                }
              });

              excelrows.forEach((row, index) => {
                const errorForRow = errorMessages.find(e => e.row === index);
                if (errorForRow) {
                  row['error'] = errorForRow.errors.join('; ');
                }
              });


              // Append your data rows
              const dataRows = excelrows.map(row => combinedFields.map(fieldName => row[fieldName] || ""));
              utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

              const workbook = utils.book_new();
              utils.book_append_sheet(workbook, worksheet, "Sheet1");
              writeFile(workbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);


              // 오류 알림
              Swal.fire({
                icon: 'error',
                title: '오류가 발견되었습니다. 다시 다운로드된 엑셀 파일을 확인하세요.',
                confirmButtonText: '확인'
              });
            } else {

              const add_row = _.differenceBy(excelrows, rows, 'Doc_No')
              // .map(row => {
              //   // 새 객체를 생성하여 "Forecast"가 포함된 키와 "Doc_No"를 포함시킵니다.
              //   const filteredRow = _.reduce(row, (result, value, key) => {
              //     if (key.includes('Forecast') || key === 'Doc_No') {
              //       result[key] = value;
              //     }
              //     return result;
              //   }, {});

              //   // 'record_no' 키가 존재하면 삭제합니다.
              //   delete filteredRow.record_no;
              //   return filteredRow;
              // });


              const exist = _.differenceBy(excelrows, add_row, 'Doc_No');

              const updatedData = exist.map((v) => {
                const recordNo = rows.find(item => item.Doc_No === v.Doc_No)?.record_no || '';
                return { ...v, record_no: recordNo };
              })
              // .map(row => {
              //   // 새 객체를 생성하여 "Forecast"가 포함된 키와 "Doc_No"를 포함시킵니다.
              //   const filteredRow = _.reduce(row, (result, value, key) => {
              //     if (key.includes('Forecast') || key === 'Doc_No'|| key === 'record_no') {
              //       result[key] = value;
              //     }
              //     return result;
              //   }, {});

              //   // 'record_no' 키가 존재하면 삭제합니다.
              //   return filteredRow;
              // });


              const newDataState = updatedData.map(row => {
                const filteredRow = Object.keys(row).reduce((newObj, key) => {
                  if (key.includes('Forecast') || key === 'record_no') {
                    newObj[key] = row[key];
                  }
                  return newObj;
                }, {});

                return filteredRow;
              })

              const updatedRows = rows.filter(row => 
                newDataState.some(newDataRow => newDataRow.record_no === row.record_no)
              ).map(row => {
                // newDataState에서 같은 record_no를 가진 객체를 찾음
                const updateData = newDataState.find(newDataRow => newDataRow.record_no === row.record_no);
              
                // 업데이트할 데이터가 존재하면
                if (updateData) {
                  // updateData의 각 키에 대해 row 객체를 업데이트
                  Object.keys(updateData).forEach(key => {
                    row[key] = updateData[key];
                  });
                }
              
                // 업데이트된 row 객체 반환
                return row;
              });
              
              
              setRows(updatedRows);

              Swal.fire({
                icon: 'success',
                title: '엑셀을 테이블로 로드했습니다.',
                confirmButtonText: '확인'
              });

            }



          }

          else {
            Swal.fire({
              icon: 'error',
              title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
              confirmButtonText: '확인'
            });
          }
        }

      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      // 에러 발생 시 Swal 팝업 띄우기
      Swal.fire({
        icon: 'error',
        title: '오류가 발생했습니다!',
        text: error.message,
        confirmButtonText: '확인'
      });
    }
  };

  const handlerequest = () => {
    const updateData = rows
      .filter(v => v.record_no !== undefined) // record_no가 정의된 요소만 필터링
      .map(row => {
        return Object.entries(row).reduce((newRow, [key, value]) => {
          if (value instanceof Date) {
            newRow[key] = dayjs(value).format("MM-DD-YYYY");
          } else {
            newRow[key] = value;
          }
          return newRow;
        }, {});
      });

    const transformedUpdateData = updateData.map(item => {
      const bpLineItems = {};
      const restItems = {};

      Object.entries(item).forEach(([key, value]) => {
        if (key.includes('//')) {
          const [stage, newKey] = key.split('//');
          if (!bpLineItems[stage]) {
            bpLineItems[stage] = { PMSStageNM: stage };
          }

          // 새로운 키와 값을 해당 stage의 객체에 할당
          bpLineItems[stage][newKey] = value;
        } else {
          // "//"가 없는 항목은 그대로 restItems에 할당
          restItems[key] = value;
        }
      });

      // 최종적으로 변환된 객체 반환
      return {
        ...restItems,
        _bp_lineitems: Object.values(bpLineItems)
      };
    })
      .map((v) => ({
        ...v, _bp_lineitems: v._bp_lineitems
          .filter(c => c.EPPMPlanDate !== undefined && c.EPPMPlanDate !== null && c.EPPMPlanDate !== "")
          .map((vf) => ({ ...vf, EPPMPMSBPK: v.PMSClassNM + " | " + vf.PMSStageNM }))
      }))


    const createData = rows.filter(v => v.record_no === undefined)
      .map(row => {
        return Object.entries(row).reduce((newRow, [key, value]) => {
          if (value instanceof Date) {
            newRow[key] = dayjs(value).format("MM-DD-YYYY");
          } else {
            newRow[key] = value;
          }
          return newRow;
        }, {});
      });

    const transformedCreateData = createData.map(item => {
      const bpLineItems = {};
      const restItems = {};

      Object.entries(item).forEach(([key, value]) => {
        if (key.includes('//')) {
          const [stage, newKey] = key.split('//');
          if (!bpLineItems[stage]) {
            bpLineItems[stage] = { PMSStageNM: stage };
          }

          // 새로운 키와 값을 해당 stage의 객체에 할당
          bpLineItems[stage][newKey] = value;
        } else {
          // "//"가 없는 항목은 그대로 restItems에 할당
          restItems[key] = value;
        }
      })

      // 최종적으로 변환된 객체 반환
      return {
        ...restItems,
        _bp_lineitems: Object.values(bpLineItems)
      };
    }).map((v) => ({
      ...v, _bp_lineitems: v._bp_lineitems.filter(c => c.EPPMPlanDate !== undefined && c.EPPMPlanDate !== null && c.EPPMPlanDate !== "")
        .map((vf) => ({ ...vf, EPPMPMSBPK: v.PMSClassNM + " | " + vf.PMSStageNM }))
    }))


    Swal.fire({
      title: '정말 SCON으로 보내겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("http://43.200.223.224:5000/submitEDP", { project: projectCode, updateData: transformedUpdateData, createData: transformedCreateData })
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                title: '성공적으로 제출되었습니다',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(() => {
                window.location.reload();
              });
            } else {
              Swal.fire({
                title: '에러 발생',
                icon: 'error',
                text: response.data.data,
                confirmButtonText: '확인'
              })
            }
          })
          .catch((err) => {
            Swal.fire({
              title: '문제가 발생했습니다',
              icon: 'error',
              text: err
            });
          });
      }
    });

  }

  const [open, setOpen] = React.useState(false);


  const handleShowModal = (id) => {
    setOpen(true);
    setCurrentData(rows.find(vf => vf.id === id));
    setModuleTabeData(moduleTabeData.map((v) => ({ ...v, selected: false })))
  };


  const handleClose = () => {
    setOpen(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  };


  return (
    <>
      <Dialog
        open={open}
        maxWidth="800px"
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {currentData && currentData["Doc_Title"] + "를 위한 액티비티 조회"}
        </DialogTitle>
        <DialogContent>
          <ModuleTable moduletabledata={moduleData} onModuleDataHandle={handleModuleData} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
      {columnData.length > 0 ?
        <Box
          sx={{
            height: "76vh",
            width: '100%',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
            },
            '& .MuiDataGrid-cell': {
              fontSize: 12,
            },
            '& .notmatched': {
              backgroundColor: '#f2f3f3',
            },
            '& .notmatched.MuiDataGrid-cell--editing': {
              backgroundColor: '#f2f3f3',
              color: '#f2f3f3'
            },
            '& .notmatched input': {
              // backgroundColor: '#F0EDE5', 
              fontSize: 0
            },
            '& .notmatched.MuiDataGrid-cell': {
              backgroundColor: '#f2f3f3',
            },
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >

          <DataGridPro
            rows={rows}
            initialState={{ pinnedColumns: { left: ['actions'] } }}
            columns={columns}
            density="compact"
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            pagination
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
          />
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Box>
        :
        <div>
          PMS 셋팅이 되어있지 않습니다.
        </div>
      }
    </>
  );
};