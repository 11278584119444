import React, { useEffect, useState, useRef } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import { Button } from "@progress/kendo-react-buttons";
import html2canvas from "html2canvas";
import moment from "moment";
import _ from "lodash";
import "hammerjs";

const before_month = moment(new Date())
  .subtract(1, "month")
  .format("YYYY-MM-01");

// const today_month = moment(new Date()).format("YYYY-MM-01");

const EMSManHourDetail = ({
  positiondata,
  blockH,
  order,
  originalData,
  originalDataPlan,
  comboBoxValues,
}) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const chart = useRef(null);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  const [chartList, setChartList] = useState([]);
  const [columnWidthList, setColumnWidthList] = useState([]);

  useEffect(() => {
    const columnListArr = [];

    const colmunNo = Math.round(comboBoxValues.length / 2);

    for (var i = 1; i <= colmunNo * 2; i++) {
      columnListArr.push({ width: `${100 / colmunNo / 2}%` });
    }

    setColumnWidthList(columnListArr);

    const acutal_date_arr = originalData.map(
      (com) => new Date(com.emsWorkingDate_date)
    );

    const plan_date_arr = originalDataPlan.map((com) => new Date(com.months));

    const total_date_arr = [...acutal_date_arr, ...plan_date_arr];

    if (total_date_arr.length > 0) {
      const minMonth = _.min(total_date_arr);
      const maxMonth = _.max(total_date_arr);

      const baseDataArr = createDateRange(
        new Date(minMonth),
        new Date(maxMonth)
      );

      const shift_comboBoxValues = [...comboBoxValues];

      shift_comboBoxValues.shift();

      const chart_list = baseDataArr.map((com) => {
        const base_months = new Date(
          moment(new Date(com.months)).add(1, "months").format("YYYY-MM-DD")
        );

        base_months.setDate(0);

        const before_month = new Date(
          moment(new Date(com.months)).format("YYYY-MM-DD")
        );

        before_month.setDate(0);

        const date_fiter_actual = originalData.filter(
          (com2) => new Date(com2.emsWorkingDate_date) <= base_months
        );

        const date_fiter_plan = originalDataPlan.filter(
          (com2) => new Date(com2.months) <= base_months
        );

        const disc_arr = shift_comboBoxValues
          .map((com2) => {
            return {
              name: com2,
              total_man_hour_plan: _.sumBy(
                originalDataPlan.filter(
                  (com3) => com3.Disc_txt.toUpperCase() === com2
                ),
                "days"
              ),
              actual_sum: _.sumBy(
                date_fiter_actual.filter(
                  (com3) => com3.Disc_txt.toUpperCase() === com2
                ),
                "emsTMSActualHour"
              ),
              plan_sum: _.sumBy(
                date_fiter_plan.filter(
                  (com3) => com3.Disc_txt.toUpperCase() === com2
                ),
                "days"
              ),
            };
          })
          .map((com) => ({
            ...com,
            plan: Number(
              ((com.plan_sum / com.total_man_hour_plan) * 100).toFixed(2)
            ),
            actual: Number(
              ((com.actual_sum / com.total_man_hour_plan) * 100).toFixed(2)
            ),
          }))
          .map((com2) => ({
            ...com2,
            plan:
              com2.plan === Infinity || Number.isNaN(com2.plan) ? 0 : com2.plan,
            actual:
              com2.actual === Infinity || Number.isNaN(com2.actual)
                ? 0
                : com2.actual,
          }))
          .map((com2) => ({
            ...com2,
            dev: com2.actual - com2.plan,
            prod_p: com2.plan / com2.actual,
          }))
          .map((com2) => ({
            ...com2,
            prod_p:
              com2.prod_p === Infinity || Number.isNaN(com2.prod_p)
                ? 0
                : Number(com2.prod_p.toFixed(2)),
          }));

        return { ...com, disc_arr };
      });

      const max_months = _.maxBy(chart_list, "months");

      const find_today_month = chart_list.find((com) => {
        return com.months === before_month;
      });

      // const finalData =
      //   find_today_month?.disc_arr !== undefined
      //     ? find_today_month.disc_arr
      //     : new Date(chart_list[chart_list.length - 1]["months"]) <=
      //       new Date(today_month)
      //     ? chart_list[chart_list.length - 1]?.["disc_arr"]
      //     : chart_list[0]?.["disc_arr"];

      setChartList(
        find_today_month !== undefined
          ? find_today_month["disc_arr"]
          : max_months !== undefined
          ? max_months["disc_arr"]
          : []
      );
    }
  }, [comboBoxValues, originalData, originalDataPlan]);

  const SharedTooltip = (props) => {
    const { points } = props;

    return (
      <div>
        {points.map((com, idx) => {
          const plan_tooltip =
            com.value.target === undefined ? 0 : com.value.target;
          const act_tooltip =
            com.value.current === undefined ? 0 : com.value.current;

          const dev_tooltip = (act_tooltip - plan_tooltip).toFixed(2);
          const prod_p_tooltip = (act_tooltip / plan_tooltip).toFixed(2);

          return (
            <div key={idx}>
              <div>계획: {plan_tooltip}%</div>
              <div>실적: {act_tooltip}%</div>
              <div>차이: {dev_tooltip}%</div>
              <div>Productivity: {prod_p_tooltip}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const hidden = {
    visible: false,
  };

  const onImageExportClick = () => {
    html2canvas(chart.current).then((com) =>
      onSaveAs(com.toDataURL("image/png"), `manhour_detail.png`)
    );
  };

  const onSaveAs = (uri, filename) => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };

  console.log(columnWidthList);

  return (
    <div className="eMSProgressDetail" ref={chart}>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnWidthList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            zIndex: "100",
          }}
        >
          <Button
            title="이미지로 저장"
            iconClass="k-icon k-font-icon k-i-image-export"
            fillMode="flat"
            onClick={onImageExportClick}
          ></Button>
        </div>
        {chartList.length > 0 &&
          chartList.map((v, index) => (
            <GridLayoutItem
              key={index}
              col={
                index + 1 > columnWidthList.length * 0.5
                  ? index * 2 + 1 - columnWidthList.length
                  : index * 2 + 1
              }
              row={index + 1 > columnWidthList.length * 0.5 ? 5 : 3}
              colSpan={2}
              rowSpan={2}
              style={{ textAlign: "center" }}
            >
              <Chart
                style={{
                  height: BoxHeight * 0.52,
                }}
              >
                <ChartTooltip shared={true} render={sharedTooltipRender} />

                <ChartTitle
                  text={`${v.name} \n ${v.dev.toFixed(
                    2
                  )}% \n (계획:${v.plan.toFixed(2)}% 실적:${v.actual.toFixed(
                    2
                  )}%)`}
                  font="0.7rem pretendard-B"
                  position="top"
                />
                <ChartSeries>
                  <ChartSeriesItem
                    type="bullet"
                    color={v.dev < 0 ? "#00A170" : "#CD212A"}
                    data={[v.actual, v.plan]}
                    overlay={{ gradient: "glass" }}
                  />
                </ChartSeries>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    majorGridLines={hidden}
                    minorTicks={hidden}
                    min={0}
                    max={100}
                    labels={{ font: "0.7rem pretendard-R" }}
                  />
                </ChartValueAxis>
              </Chart>
              {/* {!visibleDialog && (
              <Dialog
                title={"공정별 공정률 상세"}
                onClose={toggleDialog}
                width={"60%"}
              >
                <EMSOverallTable
                  chartList={chartList}
                  today={today}
                  lastWeek={lastWeek}
                />
              </Dialog>
            )} */}
            </GridLayoutItem>
          ))}
      </GridLayout>
    </div>
  );
};

export default EMSManHourDetail;

const createDateRange = (startDate, endDate) => {
  const end_date = endDate;
  const end_date_month = end_date.getMonth();

  end_date.setMonth(end_date_month + 1);
  end_date.setDate(0);

  const monthDiff = moment(end_date).diff(
    moment(startDate).format("YYYY-MM-01"),
    "months"
  );

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    monthRange.push({
      months: moment(startDate).add(i, "months").format("YYYY-MM-01"),
    });
  }

  return monthRange;
};
