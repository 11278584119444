import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const EnvUpdateLog = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [data, setData] = useState([]);

  useEffect(() => {
    const boxHeight = positiondata[order];

    // setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 35);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetch_data = async () => {
      try {
        const res = await axios
          .get(`http://43.200.223.224:5000/getenvUpdateLog?path=${project_code}`)
          .then((data) => data.data);

        setData(res.data);
      } catch (err) {
        console.log(err);
        setData([]);
      }
    };

    fetch_data();
  }, [project_code]);

  return (
    <div>
      {data.length === 0 ? (
        <div style={{ font: "0.8rem pretendard-R" }}>Update 사항 없음</div>
      ) : (
        <ul style={{ paddingLeft: 16, height: BoxHeight, overflow: "auto" }}>
          {[...data].map((c, i) => {
            const { EMULCreationDate, EMULBPName } = c;

            return (
              <li
                style={{ font: "0.8rem pretendard-R", paddingBottom: "5px" }}
                key={i}
              >
                <div>
                  {moment(EMULCreationDate).format("YYYY. MM. DD")} {EMULBPName}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default EnvUpdateLog;
