import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import "hammerjs";
import krMessages from "./kr.json";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import "./Quality.css";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import { DateRangePicker  } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';
import { useParams } from "react-router-dom";
 import axios from "axios";import Cookies from 'js-cookie';
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;;

const Ncr = ({ positiondata, order, blockH}) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const { t, i18n } = useTranslation();
  const { project_code } = useParams();
  
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const requestArr2 = await axios.get(
        `http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/qualitydaily/ncr/${project_code}.json`,
      );

      const reslist2 = requestArr2.data

      setdata(reslist2);
    };

    fetchData();
  }, [project_code]);



  const defaultvalue = 
  {
      start:new Date(Date.now() - 864000000),
      end: new Date(moment().add(-1, 'days')),
    };
  const [value, setValue] = useState(defaultvalue);

  
  var getDaysArray = function (start, end) {
      for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
          arr.push(new Date(dt));
      }
      return arr;
  };


  var daylist = getDaysArray(new Date(value.start), new Date(value.end)).map(v => moment(v).format("MM-DD-YYYY"));

  const ncrdatau = _.uniqBy(
    _.sortBy(
      data.map((v) => ({
        ...v,
        order:
          v.record_no +
          v.uuu_record_last_update_date.slice(6, 10) +
          v.uuu_record_last_update_date.slice(0, 2) +
          v.uuu_record_last_update_date.slice(3, 5) +
          v.uuu_record_last_update_date.slice(11, 19),
      })),
      "order"
    ).reverse(),
    "record_no"
  ).filter((v) => v.status !== "Terminated" && v.NCRTitle !== "Terminated" );


  const data_fitler = _.filter(ncrdatau, function (o) { return  daylist.includes(o.NCPIssueDP.slice(0,10))  })

  const handleChange = (event) => {
    setValue(event.target.value);
};

const max = new Date(moment().add(-1, 'days'));


  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);
  const chartdata = [
    {
      type: "NCR",
      Open: _.filter(data_fitler, function (o) {
        return o.status !== "Closed" && o.NCPTypeSPD === "NCR";
      }).length,
      Close: _.filter(data_fitler, function (o) {
        return o.status === "Closed" && o.NCPTypeSPD === "NCR";
      }).length,
    },
    {
      type: "CAR",
      Open: _.filter(data_fitler, function (o) {
        return o.status !== "Closed" && o.NCPTypeSPD === "CAR";
      }).length,
      Close: _.filter(data_fitler, function (o) {
        return o.status === "Closed" && o.NCPTypeSPD === "CAR";
      }).length,
    },
    {
      type: "PAR",
      Open: _.filter(data_fitler, function (o) {
        return o.status !== "Closed" && o.NCPTypeSPD === "PAR";
      }).length,
      Close: _.filter(data_fitler, function (o) {
        return o.status === "Closed" && o.NCPTypeSPD === "PAR";
      }).length,
    },
  ];


  const tabledata = _(data_fitler)
    .groupBy("Subcontractor_dpk")
    .map((objs, key) => ({
      vendor: key,
      Open: _.filter(objs, function (o) {
        return o.Subcontractor_dpk === key && o.status !== "Closed";
      }).length,
      Close: _.filter(objs, function (o) {
        return o.Subcontractor_dpk === key && o.status === "Closed";
      }).length,
      total: _.filter(objs, { Subcontractor_dpk: key }).length,
    }))
    .value();

  const COLORS = ["rgb(247, 251, 255)", "rgb(222, 235, 247)"];

  const [sort, setSort] = useState([]);

  const SimpleSumCell = (props) => {
    const field = props.field || "";
    const total = tabledata.reduce((acc, current) => acc + current[field], 0);
    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        {total}
      </td>
    );
  };
  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];
    return <td style={{ fontSize: "0.8rem", textAlign: "center" }}>{cell}</td>;
  };

  const HeaderCell = (props) => {
    return (
      <span
        style={{ fontSize: "0.8rem", textAlign: "center", margin: "0 auto" }}
      >
        {props.title}
      </span>
    );
  };

  const labelContent2 = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value;
  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 3,
    font: "bold 0.8rem Arial, sans-serif",
    position: "insideBase",
    background: "none",
    content: labelContent2,
  };

  const SharedTooltip = (props) => {
    const { category, points } = props;
    return (
      <div>
        <div>{category}</div>
        {points.map(function (point, index) {
          return point.series.field === "count" ? (
            <div key={index}>
              {point.category} {point.series.name} 합계: {point.value}
            </div>
          ) : (
            <div key={index}>
              {point.category} {point.series.name} {point.series.field}:{" "}
              {point.value}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "35%",
          },
          {
            width: "65%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
              <div style={{ position: 'absolute', top: '-10px', right: '235px' }}>
                    <LocalizationProvider language={i18n.language ==="ko"?"ko-KR":"en"}>
                        <IntlProvider locale={i18n.language ==="ko"?"ko-KR":"en"}>
                            <div className="datepick"><DateRangePicker max={max} format={i18n.language ==="ko" ?"yy년 MM월 dd일":"MMM dd yyyy"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} /></div>
                        </IntlProvider>
                    </LocalizationProvider>
                </div>
        <GridLayoutItem>
          <Chart>
            <ChartArea height={BoxHeight} margin={0} />
            <ChartTooltip shared={true} render={sharedTooltipRender} />
            <ChartSeries>
              <ChartSeriesItem
                type="column"
                stack={true}
                data={chartdata}
                field="Open"
                categoryField="type"
                labels={seriesLabels2}
                color={COLORS[0]}
              />
              <ChartSeriesItem
                type="column"
                stack={true}
                data={chartdata}
                field="Close"
                categoryField="type"
                labels={seriesLabels2}
                color={COLORS[1]}
              />
            </ChartSeries>

            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                labels={{
                  rotation: "auto",
                }}
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
              />
            </ChartCategoryAxis>

            <ChartValueAxis>
              <ChartValueAxisItem
                visible={false}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
              ></ChartValueAxisItem>
            </ChartValueAxis>
          </Chart>
        </GridLayoutItem>
        <GridLayoutItem>
          <Grid
            // data={tabledata}
            data={orderBy(tabledata, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            style={{ height: BoxHeight, width: "100%" }}
            className="punchTable2"
          >
            <GridColumn
              field="vendor"
              title={t("업체명")}
              cell={normalcell}
              headerCell={HeaderCell}
            />
            <GridColumn
              field="Open"
              title="Open"
              footerCell={SimpleSumCell}
              cell={normalcell}
              headerCell={HeaderCell}
            />
            <GridColumn
              field="Close"
              title="Close"
              footerCell={SimpleSumCell}
              cell={normalcell}
              headerCell={HeaderCell}
            />
            <GridColumn
              field="total"
              title="Total"
              footerCell={SimpleSumCell}
              cell={normalcell}
              headerCell={HeaderCell}
            />
          </Grid>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default Ncr;
