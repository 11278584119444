import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { Dialog } from "@progress/kendo-react-dialogs";
import { ResponsivePie } from '@nivo/pie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { generateUpdatedDate } from '../common/datadate';
import { useTranslation } from 'react-i18next';

const {REACT_APP_URI2,} = process.env;


const Equip1P = ({ positiondata, data11, dailyreport, order, blockH}) => {
    const [BoxHeight, setBoxHeight] = useState(250);
    const { project_code } = useParams();
    const { t } = useTranslation();
    
    const [data, setdata] = useState([]);

    const kr_currnet_time = moment(new Date()).tz("Asia/Seoul").subtract(5,'day').format("MM-DD-YYYY");
    const kr_currnet_time_a = moment(new Date()).tz("Asia/Seoul").add(1, 'day').format("MM-DD-YYYY HH:mm:ss");

    useEffect(() => {
        const fetchData = async () => {

          let body=  
          {
            "bpname": "Daily Equipment Input",
            "lineitem": "no",
            "filter_criteria": {
              "join": "AND",
              "filter": [
                {
        
                  "field": "d_reporting_date",
                  "value": kr_currnet_time,
                  "value2": kr_currnet_time_a,
                  "condition_type": "range"
                },
        
                {
                  "field": "status",
                  "value": "Active",
                  "condition_type": "eq"
                }
              ]
            }
          }
        
        const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
        setdata(res.data.data.data.map((v)=>({...v, date:v.d_reporting_date.slice(6, 10) + '년 ' + v.d_reporting_date.slice(0, 2) + '월 ' + v.d_reporting_date.slice(3, 5) + '일'
          
    })))
    
        };
    
        fetchData();
    }, [project_code, kr_currnet_time_a, kr_currnet_time]);

    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);

    const linedata1 = _.filter(_.uniqBy(data11,'record_no'), function (o) { return typeof o._bp_lineitems !== 'undefined'&& o.d_reporting_date !== null; }).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { d_reporting_date: v.d_reporting_date, d_company_name: v.d_company_name }))), [])
        .filter(f => f.uuu_tab_id === '투입장비' || f.uuu_tab_id === 'Equipment(Picker)');

    const linedata2 = _.filter(_.uniqBy(dailyreport,'record_no'), function (o) { return typeof o._bp_lineitems !== 'undefined'&& o.d_reporting_date !== null; }).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { d_reporting_date: v.d_reporting_date, d_company_name: v.d_company_name }))), [])
        .filter(f => f.uuu_tab_id === '투입장비' || f.uuu_tab_id === 'Equipment(Picker)');

    const linedata = _.sortBy([...linedata1, ...linedata2].map((v) => ({
        ...v,
        date: v.d_reporting_date.slice(6, 10) + '년 ' + v.d_reporting_date.slice(0, 2) + '월 ' + v.d_reporting_date.slice(3, 5) + '일'
    })), 'date').reverse()


    const data2 = data.map((v)=> ({...v,'d_company_name':v.d_subcontractor_bpk}))

    // const lastdate = _.get(linedata[0], 'date')

    // const lastdate = _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0]['date']

    // const lastdate = _.uniqBy(_.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse(), "date")?.[1]?.['date']
 
    // const lastdate = moment(new Date()).add(-1, 'days').format('YYYY년 MM월 DD일')

    const today = moment(new Date()).format("YYYY년 MM월 DD일")
 
    const lastdate = _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0] && 
    _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0]['date'] === today 
    ? _.uniqBy(_.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0] 
    && _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse(), "date")?.[1]?.['date'] :  
    _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0] && 
    _.sortBy([...linedata,...data2].map((v)=>({date:v.date})),'date').reverse()[0]['date']

    const tabledata =
        _( _.filter([...linedata,...data2], { date: lastdate }))
        .groupBy('d_company_name')
            .map((objs, key) => ({
                'd_company_name': key,
                'count': 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_xcounter !== 'undefined'), 'd_eq_xcounter') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_ncounter !== 'undefined'), 'd_eq_ncounter') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_mcounter !== 'undefined'), 'd_eq_mcounter') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_int_tearly !== 'undefined'), 'd_eq_int_tearly') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_ecounter !== 'undefined'), 'd_eq_ecounter') || 0)+
                (_.sumBy(objs.filter(v=>typeof v.d_eq_dcounter !== 'undefined'), 'd_eq_dcounter') || 0)
                    // +_.filter(objs,{'d_equip_mid_irb':'투입'}).length +_.filter(objs,{'d_equip_ext_irb':'투입'}).length  
                    + _.filter(objs, { 'd_equip_day_irb': '투입' }).length
                // +_.filter(objs,{'d_equip_night_irb':'투입'}).length +_.filter(objs,{'d_equip_early_irb':'투입'}).length 
                +_.sumBy(objs.filter(v=>typeof v.d_today_etotal !== 'undefined'), 'd_today_etotal')

            }))
            .value();

    const chartdata =
    _( _.filter([...linedata,...data2], { date: lastdate }))
    .groupBy('equipment_code_name')
            .map((objs, key) => ({
                'id': key,
                'value':  
                (_.sumBy(objs.filter(v=>typeof v.d_eq_xcounter !== 'undefined'), 'd_eq_xcounter') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_ncounter !== 'undefined'), 'd_eq_ncounter') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_mcounter !== 'undefined'), 'd_eq_mcounter') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_int_tearly !== 'undefined'), 'd_eq_int_tearly') || 0) + 
                (_.sumBy(objs.filter(v=>typeof v.d_eq_ecounter !== 'undefined'), 'd_eq_ecounter') || 0)+
                (_.sumBy(objs.filter(v=>typeof v.d_eq_dcounter !== 'undefined'), 'd_eq_dcounter') || 0)
                    // +_.filter(objs,{'d_equip_mid_irb':'투입'}).length +_.filter(objs,{'d_equip_ext_irb':'투입'}).length  
                    + _.filter(objs, { 'd_equip_day_irb': '투입' }).length
                // +_.filter(objs,{'d_equip_night_irb':'투입'}).length +_.filter(objs,{'d_equip_early_irb':'투입'}).length 
                +_.sumBy(objs.filter(v=>typeof v.d_today_etotal !== 'undefined'), 'd_today_etotal')

            }))
            .value().filter(v=>v.value !== 0);;



    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const [sort, setSort] = useState([{}]);



    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
         
                    {
                        height: '90%',
                    },
                    {
                        height: '10%',
                    }
                ]}

                cols={[
                    {
                        width: '100%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} rowSpan={2} col={1} style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' ,zIndex:'999'}}><span style={{ fontSize:_.sumBy(tabledata, 'count').length >3?'1.5rem' :'2rem' }} className='hovertext' onClick={toggleDialog}>{_.sumBy(tabledata, 'count')}</span></div>
                    {_.sumBy(chartdata, 'value') === 0 ? '':
                    <ResponsivePie
                        // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}

                        colors={{ scheme: 'blues' }}
                        className='pie'
                        data={chartdata}
                        margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
                        valueFormat=" >-"
                        cornerRadius={2}
                        innerRadius={0.7}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabel=                          {d => (
                            <React.Fragment>
                              <tspan style={{fontSize:10}} x="0" dy="-0.6em">{d.id}</tspan>
                              <tspan style={{fontSize:10}} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(chartdata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                            </React.Fragment>
                          )}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextOffset={5}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={5}
                        arcLinkLabelsStraightLength={5}
                        arcLinkLabelsColor={{ from: 'color' }}
                        enableArcLabels={true}
                        arcLabel={function (e) { return e.value }}
                        arcLabelsRadiusOffset={0.5}
                        arcLabelsSkipAngle={0}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}

                        legends={[]}
                    />}
                </GridLayoutItem>
                <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
        {lastdate && generateUpdatedDate(lastdate.slice(0,4)+"-"+lastdate.slice(6,8)+"-"+lastdate.slice(10,12))}
        </div>

            </GridLayout>



            {!visibleDialog && (
                <Dialog title={t("장비상세현황")} onClose={toggleDialog} width='40%' height= {tabledata.length >10? "600px":"auto"}>
                <Grid

                    data={orderBy(tabledata, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    className='mancount'
                    style={{height:"100%", overflowY:'auto', overflowX:'hidden'}}


                >
                        <GridColumn field="d_company_name" title={t("업체명")} />
                        <GridColumn field="count" title={t("장비 대수")} />

                    </Grid>
                </Dialog>
            )}
        </>)


}

export default Equip1P
