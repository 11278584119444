import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import axios from "axios";
import "./SkoneEMSOverall.scss";
import OverrallTableLegend from "./OverrallTableLegend";
import ActionList from "./ActionList/ActionList";
import ToggleButton from "@mui/material/ToggleButton";
import { Dialog } from "@progress/kendo-react-dialogs";
import _ from "lodash";

import { ColumnMenu } from "./ColumnMenu/ColumnMenu";
import { process } from "@progress/kendo-data-query";

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

const folder_path =
  `http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/overallData/ems_overall_data.json`;

const url = `http://43.200.223.224:5000`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const SkoneEMSOverall = () => {
  const { height } = useWindowDimensions();

  const [sourceData, setSourceData] = useState([]);

  const menuRef = useRef();

  const [buttonToggle, setButtonToggle] = useState(false);

  const [result, setResult] = useState({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState({});

  const handleToggle = () => {
    setButtonToggle((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetch_overall_data = await axios.get(folder_path);

      const fetch_overall_data_result = fetch_overall_data.data;

      setResult({
        data: fetch_overall_data_result,
        total: fetch_overall_data_result.length,
      });

      setSourceData(fetch_overall_data_result);
    };

    fetchData();
  }, []);

  const createDataState = (dataState) => {
    return {
      result: process(sourceData, dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);

    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const percentCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];

    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        {typeof Dev === "undefined" ? "" : Dev.toFixed(2)}
        {field === "prod_p" ? null : "%"}
      </td>
    );
  };

  const stringCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return <td style={{ fontSize: "0.8rem", textAlign: "center" }}>{Dev}</td>;
  };

  const normalCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        {Number(Number(Dev).toFixed(0)).toLocaleString()}
      </td>
    );
  };

  const eval_gradeCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "0.8rem",
              height: "0.8rem",
              borderRadius: "0.4rem",
              backgroundColor: handleEvalGrade(field, Dev),
            }}
          ></div>
        </div>
      </td>
    );
  };

  return (
    <div className="skoneEMSOverall">
      <div className="skoneEMSOverallLegendsBox">
        <ToggleButton
          value="check"
          selected={buttonToggle}
          onChange={handleToggle}
          sx={{
            height: "40px",
          }}
          ref={menuRef}
        >
          <div>Legend</div>
        </ToggleButton>
      </div>
      <Grid
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        style={{
          height: height - 210 - 40,
        }}
        // className="progressCWP"
      >
        <GridColumn
          field="ugenProjectNumber"
          title="PJT Code"
          cell={stringCell}
          // headerCell={single_sort_headerCell}
          columnMenu={ColumnMenu}
        />
        <GridColumn
          field="ugenProjectName"
          title="Project"
          cell={stringCell}
          columnMenu={ColumnMenu}
          // headerCell={single_sort_headerCell}
        />
        <GridColumn title={"Progress"}>
          <GridColumn
            field="progress_p"
            title="Planned"
            cell={percentCell}
            // footerCell={SimpleSumCell}
            // headerCell={single_sort_headerCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
          />
          <GridColumn
            field="progress_a"
            title="Acutall"
            cell={percentCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          />
          <GridColumn
            field="progress_d"
            title="Deviation"
            cell={percentCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          />
          <GridColumn
            field="progress_d"
            title="Eval.Grade"
            cell={eval_gradeCell}

            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          />
        </GridColumn>

        <GridColumn title={"Man-Hours [1,000MH]"}>
          <GridColumn
            field="m_h_t_p"
            title="Planned"
            cell={normalCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={SimpleSumCell}
            // headerCell={single_sort_headerCell}
          />
          <GridColumn
            field="m_h_p"
            title="Earned"
            cell={normalCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={SimpleSumCell}
            // headerCell={single_sort_headerCell}
          />
          <GridColumn
            field="m_h_a"
            title="Actual"
            cell={normalCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          />
          <GridColumn
            field="m_h_r"
            title="Reduction"
            cell={percentCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          />
          {/* <GridColumn
            field="m_h_rate"
            title="MH Rate(E/C)"
            cell={percentCell}
            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          /> */}
        </GridColumn>
        <GridColumn title={"Productivity"}>
          <GridColumn
            field="prod_p"
            title="Productivity"
            cell={percentCell}
            filter={"numeric"}
            columnMenu={ColumnMenu}
            // footerCell={SimpleSumCell}
            // headerCell={single_sort_headerCell}
          />
          <GridColumn
            field="prod_p"
            title="Eval.Grade"
            cell={eval_gradeCell}
            // footerCell={RateSumCell}
            // headerCell={single_sort_headerCell}
          />
        </GridColumn>
      </Grid>
      {buttonToggle && (
        <Dialog title={"Legend"} onClose={handleToggle} width={"30%"}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <OverrallTableLegend />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default SkoneEMSOverall;

// const color_code = [
//   {
//     color: "blue",
//     name: "Excellent",
//   },
//   {
//     color: "green",
//     name: "Good",
//   },
//   {
//     color: "yellow",
//     name: "Moderate",
//   },
//   {
//     color: "red",
//     name: "Bad",
//   },
// ];

const colors1 = {
  qualityBlue: "rgba(0, 176, 240, 0.4)",
  qualityGreen: "rgba(112, 173, 71, 0.4)",
  qualityOrange: "rgba(255, 192, 0, 0.4)",
  qualityRed: "rgba(255, 0, 0, 0.4)",
};

const handleEvalGrade = (type, Dev) => {
  if (type === "progress_d") {
    if (Dev > 5) {
      return colors1.qualityBlue;
    } else if (Dev > 0 && Dev <= 5) {
      return colors1.qualityGreen;
    } else if (Dev > -5 && Dev <= 0) {
      return colors1.qualityOrange;
    } else if (Dev <= -5) {
      return colors1.qualityRed;
    }
  } else if (type === "prod_p") {
    if (Dev > 1.21) {
      return colors1.qualityBlue;
    } else if (Dev > 0.95 && Dev <= 1.21) {
      return colors1.qualityGreen;
    } else if (Dev > 0.81 && Dev <= 0.95) {
      return colors1.qualityOrange;
    } else if (Dev <= 0.81) {
      return colors1.qualityRed;
    }
  } else if (type === "qua") {
    if (Dev >= 90) {
      return colors1.qualityBlue;
    } else if (Dev >= 80 && Dev < 90) {
      return colors1.qualityGreen;
    } else if (Dev >= 60 && Dev < 80) {
      return colors1.qualityOrange;
    } else if (Dev < 60) {
      return colors1.qualityRed;
    }
  }
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const sameple_number = () => {
  return Number((Math.random() * 100).toFixed(0));
};

// const multi_sort_headerCell = (props) => {
//   const { field, title } = props;

//   const handleSort = (field) => {
//     if (!mainHeader) {
//       const findSortIndex = sort.findIndex((e) => e.field === field);

//       const findSort = sort.find((e) => e.field === field);

//       if (findSort === undefined) {
//         if (sort[0]["field"] === undefined) {
//           sort.push({ field: field, dir: "asc" });
//           setSort([...sort]);
//         }
//       } else {
//         if (findSort["dir"] === "asc") {
//           sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
//           setSort([...sort]);
//         } else if (findSort["dir"] === "desc") {
//           sort.splice(findSortIndex, 1);
//           setSort([...sort]);
//         }
//       }
//     }
//   };

//   const hadleSortArrow = (field) => {
//     const findSort = sort.find((e) => e.field === field);

//     return findSort !== undefined ? findSort["dir"] : false;
//   };

//   const hadleSortIndex = (field) => {
//     const findSortIndex = sort.findIndex((e) => e.field === field);

//     return findSortIndex === -1 ? "" : findSortIndex;
//   };

//   const mainHeader = [
//     "Progress",
//     "Man-Hours [1,000MH]",
//     "Productivity",
//     "Quality",
//   ].some((com) => com === title);

//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         font: "normal normal normal 0.8rem Pretendard",
//         cursor: mainHeader ? "default" : "pointer",
//       }}
//       onClick={() => {
//         handleSort(field);
//       }}
//     >
//       <p style={{ margin: "0" }}>{title} </p>
//       <div
//         className={
//           hadleSortArrow(field) === "asc"
//             ? "k-icon k-font-icon k-i-sort-asc-sm"
//             : hadleSortArrow(field) === "desc"
//             ? "k-icon k-font-icon k-i-sort-desc-sm"
//             : ""
//         }
//       ></div>
//       {!mainHeader && (
//         <div style={{ color: "#ff6358" }}>{hadleSortIndex(field)}</div>
//       )}
//     </div>
//   );
// };

// const createDateRangeData = (startDate, endDate) => {
//   const start_date = new Date(startDate.slice(0, 10));
//   const end_date = new Date(endDate.slice(0, 10));

//   const end_date_month = end_date.getMonth();

//   end_date.setMonth(end_date_month + 1);
//   end_date.setDate(0);

//   const monthDiff = moment(end_date).diff(
//     moment(start_date).format("YYYY-MM-01"),
//     "months"
//   );

//   const monthRange = [];

//   for (let i = 0; i <= monthDiff; i++) {
//     const base_months = moment(start_date)
//       .add(i, "months")
//       .format("YYYY-MM-01");

//     if (i === 0) {
//       const start_date_base_1 = new Date(
//         moment(start_date).add(i, "months").format("YYYY-MM-DD")
//       );

//       const start_date_base_2 = new Date(
//         moment(start_date)
//           .add(i + 1, "months")
//           .format("YYYY-MM-DD")
//       );

//       start_date_base_2.setDate(0);

//       monthRange.push({
//         months: base_months,
//         days:
//           manMonthToManHour *
//           ((start_date_base_2.getDate() - start_date_base_1.getDate() + 1) /
//             start_date_base_2.getDate()),
//       });
//     } else if (i === monthDiff) {
//       const start_date_base_1 = new Date(
//         moment(base_months).format("YYYY-MM-DD")
//       );

//       const start_date_base_2 = new Date(
//         moment(new Date(endDate.slice(0, 10))).format("YYYY-MM-DD")
//       );

//       const start_date_base_3 = new Date(
//         moment(new Date(base_months.slice(0, 10)))
//           .add(1, "months")
//           .format("YYYY-MM-DD")
//       );

//       start_date_base_3.setDate(0);

//       monthRange.push({
//         months: base_months,
//         days:
//           manMonthToManHour *
//           ((start_date_base_2.getDate() - start_date_base_1.getDate() + 1) /
//             start_date_base_3.getDate()),
//       });
//     } else {
//       const start_date_base_2 = new Date(
//         moment(base_months).add(1, "months").format("YYYY-MM-DD")
//       );

//       start_date_base_2.setDate(0);

//       monthRange.push({
//         months: base_months,
//         days: manMonthToManHour,
//       });
//     }
//   }

//   return monthRange;
// };

// const manMonthToManHour = 153;

// const requestBodyArr = [
//   {
//     bpname: "Manhour Update",
//     lineitem: "no",
//     filter_condition: "status=Active",
//   },
//   {
//     bpname: "유저 정보",
//     lineitem: "no",
//     filter_condition: "status=Active",
//   },
//   {
//     bpname: "Job Mapping",
//     lineitem: "no",
//     filter_condition: "status=Active",
//   },
// ];

// const requestArr = requestBodyArr.map(async (com) => {
//   return await axios
//     .post(`${url}/getemsbprecord?path=SKEC0001`, com)
//     .then((com) => com.data.data.data);
// });

// const requset_result = await Promise.all(requestArr);

// const [
//   fetch_manhour_update_data,
//   fetch_user_information_data,
//   fetch_job_mapping_data,
// ] = requset_result;

// let manhour_update_body = {
//   bpname: "Manhour Update",
//   lineitem: "no",
//   filter_condition: "status=Active",
// };

// let user_information_body = {
//   bpname: "유저 정보",
//   lineitem: "no",
//   filter_condition: "status=Active",
// };

// let job_mapping_body = {
//   bpname: "Job Mapping",
//   lineitem: "no",
//   filter_condition: "status=Active",
// };

// const fetch_manhour_update_data = await axios
//   .post(`${url}/getemsbprecord?path=SKEC0001`, manhour_update_body)
//   .then((com) => com.data.data.data);

// const fetch_user_information_data = await axios
//   .post(`${url}/getemsbprecord?path=SKEC0001`, user_information_body)
//   .then((com) => com.data.data.data);

// const fetch_job_mapping_data = await axios
//   .post(`${url}/getemsbprecord?path=SKEC0001`, job_mapping_body)
//   .then((com) => com.data.data.data);

// const data_mixed = fetch_manhour_update_data
//   .map((com) => ({
//     ...com,
//     ...fetch_user_information_data.find(
//       (com2) => com2.empEmployeeIDTB50 === com.empEmployeeIDTB50
//     ),
//   }))
//   .map((com) => ({
//     ...com,
//     ...fetch_job_mapping_data.find(
//       (com2) => com2.JOBNAME === com.JOBNAME
//     ),
//   }));

// const man_hour_data_trans = _.uniqBy(data_mixed, "emsOrgChargeCode").map(
//   (com) => ({
//     emsOrgChargeCode: com.emsOrgChargeCode,
//     man_hour_data: _.uniqBy(
//       data_mixed.filter(
//         (com2) => com2.emsOrgChargeCode === com.emsOrgChargeCode
//       ),
//       "empEmployeeIDTB50"
//     ).map((com2) => ({
//       empEmployeeIDTB50: com2.empEmployeeIDTB50,
//       work_data: data_mixed
//         .filter(
//           (com3) =>
//             com3.emsOrgChargeCode === com.emsOrgChargeCode &&
//             com3.empEmployeeIDTB50 === com2.empEmployeeIDTB50
//         )
//         .map((com3) => ({
//           emsOrgChargeCode: com3.emsOrgChargeCode,
//           empEmployeeIDTB50: com3.empEmployeeIDTB50,
//           emsWorkingDate: com3.emsWorkingDate,
//           emsTMSActualHour: com3.emsTMSActualHour,
//         })),
//     })),
//   })
// );

// const handle_eng_progress = (base_arr, target_obj, type) => {
//   const project_filter = _.sortBy(
//     base_arr.filter((com) => com.PJT_CD === target_obj.ugenProjectNumber),
//     "cut_off_date"
//   ).reverse();

//   if (project_filter.length > 0) {
//     return project_filter[0][type];
//   } else {
//     return 0;
//   }
// };
