import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const Tab = () => {
  let { project_code } = useParams();
  let history = useHistory();

  useEffect(() => {
    history.push(`/${project_code}/overview`);
  }, [project_code, history]);

 return(<></>);
};

export default Tab;