import React, { useEffect, useState } from "react";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import { Card, CardHeader, CardSubtitle, CardTitle, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { TableBody, TableCell, TableHead, TableRow, Table, TableContainer } from "@mui/material";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { useMediaQuery } from 'react-responsive';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const SafetyMan = ({ positiondata, projectlist, height }) => {
    const [BoxHeight, setBoxHeight] = useState(250);
    const [data, setdata] = useState([]);
    const [projectCodeList, setprojectCodeList] = useState([]);

    const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

    useEffect(() => {
        const boxHeight = positiondata[1];
        setBoxHeight(((height-10)/7) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata,height]);



    useEffect(() => {
        setprojectCodeList(projectlist.map(v => v.projectcode))
    }, [projectlist]);



    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "안전보건 인력 선임현황",
                "lineitem": "no",
                "filter_condition": "status=Active",
            }

            
      const requestArr =projectCodeList.map(async data => {
        return await axios.post(`http://43.200.223.224:5000/getbprecord?path=${data}`, body  , )
    });

    const res = await Promise.all(requestArr)
    const reslist = res.map(v => v.data.data.data).flat(1)


            setdata(reslist)

        };

        fetchData();
    }, [projectCodeList]);

    const withdata = data.map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.ugenProjectNumber) }))


    var tabledata =
        _(withdata)
            .groupBy('division')
            .map((objs, key) => ({
                'division': key,
                '현안전': _.sumBy(objs, 'SafetyActualDA'),
                '법안전': _.sumBy(objs, 'SafetyLegalDA'),
                '현보건': _.sumBy(objs, 'HealthActualDA'),
                '법보건': _.sumBy(objs, 'HealthLegalDA'),
            }))
            .value()

    const [visibleDialog, setVisibleDialog] = useState(true);
    const [clickvalue, setclickvalue] = useState('');


    const toggleDialog = (v) => {
        setVisibleDialog(!visibleDialog);
        setclickvalue(v.division);
    };

    const popuptable = withdata.filter(v => v.division === clickvalue)
    const [sort, setSort] = useState([{}]);


    return (
        <>
            <GridLayout style={{ height:isDPI? BoxHeight+20 :BoxHeight-16 }}
                rows={[
                    {
                        height: '49.25%',
                    }, {
                        height: '49.25%',
                    },

                ]}

                cols={[
                    {
                        width: '30%',
                    },
                    {
                        width: '69%',
                    },

                ]}
                gap={{
                    rows: 10,
                    cols: 10,
                }}>
                <GridLayoutItem row={1} col={1} rowSpan={1} style={{ position: 'relative' }}>
                    <Card className='esg-card'
                        style={{
                            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                            textAlign: 'center', height: isDPI? ((BoxHeight+20)/2)-7:((BoxHeight-16)/2)-7
                        }}>
                        <CardHeader style={{ borderStyle: 'none' ,paddingBottom:'0px',color:'#F1F2F5', padding:isDPI?'6px 16px 0 16px':'12px 16px 5px 16px'}}>안전관리자</CardHeader>
                        <CardSubtitle style={{color:'#F1F2F5', margin:isDPI? '0':'0 0 4px'}}>(現 선임/법적)</CardSubtitle>
                        <CardTitle style={{color:'#B0B8C2'}}><span style={{color:'rgb(244, 155, 66)'}}>{_.sumBy(data, 'SafetyActualDA')} </span> / {_.sumBy(data, 'SafetyLegalDA')}</CardTitle>

                    </Card>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} rowSpan={1} style={{ position: 'relative' }}>
                    <Card className='esg-card'
                        style={{
                            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                            textAlign: 'center', height: isDPI? ((BoxHeight+20)/2)-7:((BoxHeight-16)/2)-7

                        }}>
                        <CardHeader style={{ borderStyle: 'none' ,paddingBottom:'0px',color:'#F1F2F5', padding:isDPI?'6px 16px 0 16px':'12px 16px 5px 16px'}}>보건관리자</CardHeader>
                        <CardSubtitle style={{color:'#F1F2F5',  margin:isDPI? '0':'0 0 4px'}}>(現 선임/법적)</CardSubtitle>
                        <CardTitle style={{color:'#B0B8C2'}}><span style={{color:'rgb(244, 155, 66)'}}>{_.sumBy(data, 'HealthActualDA')} </span>/ {_.sumBy(data, 'HealthLegalDA')}</CardTitle>

                    </Card>

                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} rowSpan={2} style={{ position: 'relative' }}>
                    <TableContainer style={{ height:isDPI? BoxHeight+20:BoxHeight-16}} sx={{ border: 1 , borderColor:'#8E959F', paddingLeft:'20px', paddingRight:'20px'}}>
                        <Table aria-label="collapsible table" size='small' style={{ overflow: 'auto' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' , paddingBottom:'0px', borderColor:'#31363D', color:'#B0B8C2'}} rowSpan={2}>구분</TableCell>
                                    <TableCell style={{ textAlign: 'center' , borderBottom:'none', paddingBottom:'0px', color:'#B0B8C2'}}>안전관리자</TableCell>
                                    <TableCell style={{ textAlign: 'center' , borderBottom:'none', paddingBottom:'0px', color:'#B0B8C2'}}>보건관리자</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' , paddingTop:'0px', borderColor:'#31363D', top:30, color:'#B0B8C2'}}>(現 선임/법적)</TableCell>
                                    <TableCell style={{ textAlign: 'center' , paddingTop:'0px', borderColor:'#31363D', top:30, color:'#B0B8C2'}}>(現 선임/법적)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tabledata.map((v, index) => (
                                    <TableRow key={index}>
                                        <TableCell className="hovertext" style={{ textAlign: 'center' , bordeerColor:'#8E959F' , color:'#B0B8C2'}} onClick={() => { toggleDialog(v) }}>{v.division}</TableCell>
                                        <TableCell style={{ textAlign: 'center'  , bordeerColor:'#8E959F' , color:'#B0B8C2'}}>{v.현안전} / {v.법안전}</TableCell>
                                        <TableCell style={{ textAlign: 'center'  , bordeerColor:'#8E959F' , color:'#B0B8C2'}}>{v.현보건} / {v.법보건}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </GridLayoutItem>


            </GridLayout>

            {!visibleDialog && (
                <Dialog title={"안전보건 인력 부문별 상세 현황"} onClose={toggleDialog} width='40%'>
                    <Grid

                        data={orderBy(popuptable, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}

                    >

                        <GridColumn field="projectname" title="프로젝트" />
                        <GridColumn title="안전관리자">
                        <GridColumn field="SafetyActualDA" title="현 선임" />
                        <GridColumn field="SafetyLegalDA" title="법적" />
                        </GridColumn>
                        <GridColumn title="보건관리자">

                        <GridColumn field="HealthActualDA" title="현 선임" />
                        <GridColumn field="HealthLegalDA" title="법적" />
                        </GridColumn>


                    </Grid>

                </Dialog>
            )}
        </>
    );
};

export default SafetyMan;
