import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import 'react-medium-image-zoom/dist/styles.css'
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Dialog } from "@progress/kendo-react-dialogs";

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const HistroyImg = ({ positiondata , order, blockH}) => {
  const { width } = useWindowDimensions();

  const [imgsrc, setimgsrc] = useState("");
  const { project_code } = useParams();
  const [imagemeta, setimagemeta] = useState([]);

  const [imgsrc2, setimgsrc2] = useState("");
  const [imagemeta2, setimagemeta2] = useState([]);


  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "현장 사진 업로드",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": "Active",
              "condition_type": "eq"
            }
          ]
        }
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)

      const res2 = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/photohistory/${project_code}/`,)


      const bpfiles = res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined')
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no }))), [])
        .map(v => ({
          genPhotoCatSRB:v.genPhotoCatSRB, 
          uniq_thum: "thum_gubun_" + v.record_no + "_gubun_lineitem_0_" + v.li_num,
          uniq_m: "medium_gubun_" + v.record_no + "_gubun_lineitem_0_" + v.li_num,
          date: v.genPhotoTakenDO.slice(6, 10) + '.' + v.genPhotoTakenDO.slice(0, 2) + '.' + v.genPhotoTakenDO.slice(3, 5), title: v.genPhotoTitle, description: v.genPhotoDesc
        })).filter(v=>v.genPhotoCatSRB=== 'Photo History')


        const thumimageList = res2.data.fileList.filter(v => v.name.includes('thum_gubun')).filter(v=>bpfiles.map(v=>v.uniq_thum).includes(v.name.split('.')[0]))
        const mimageList = res2.data.fileList.filter(v => v.name.includes('medium_gubun')).filter(v=>bpfiles.map(v=>v.uniq_m).includes(v.name.split('.')[0]))



      const imageList_thum = thumimageList.map(v => ({ filename: v.name, date: bpfiles[_.findIndex(bpfiles, function (o) { return o.uniq_thum === v.name.split('.')[0]; })]['date'] }))
        .map(v => ({ ...v, uniq: v.date + v.filename }))




      const IMGList = _.sortBy(imageList_thum, 'uniq').filter(v=>bpfiles.map(v=>v.uniq_thum).includes(v.filename.split('.')[0]))



      const imageList_m = mimageList.map(v => ({ filename: v.name, date: bpfiles[_.findIndex(bpfiles, function (o) { return o.uniq_m === v.name.split('.')[0]; })]['date'] }))
        .map(v => ({ ...v, uniq: v.date + v.filename }))

      const IMGList2 = _.sortBy(imageList_m, 'uniq').filter(v=>bpfiles.map(v=>v.uniq_m).includes(v.filename.split('.')[0]))



      setimagemeta(
        _.sortBy(bpfiles.map(v => ({ ...v, uniq3: v.date + v.uniq_thum })), 'uniq3').reverse()
      )


      setimagemeta2(
        _.sortBy(bpfiles.map(v => ({ ...v, uniq3: v.date + v.uniq_m })), 'uniq3').reverse()
      )


      let imagesrc = [];
      for (const image of IMGList.reverse().map(v => v.filename)) {
        const dr = await axios.get(`http://43.200.223.224:5000/getFile?path=/${REACT_APP_PATH2}/photohistory/${project_code}/${image}`, {
          responseType: 'arraybuffer'
        })
        const dr_data = window.URL.createObjectURL(new Blob([dr.data]))
        imagesrc.push(dr_data)
      }

      setimgsrc(imagesrc)

      let imagesrc2 = [];
      for (const image of IMGList2.reverse().map(v => v.filename)) {
        const dr = await axios.get(`http://43.200.223.224:5000/getFile?path=/${REACT_APP_PATH2}/photohistory/${project_code}/${image}`, {
          responseType: 'arraybuffer',
        })
        const dr_data = window.URL.createObjectURL(new Blob([dr.data]))
        imagesrc2.push(dr_data)
      }

      setimgsrc2(imagesrc2)


    };

    fetchData();
  }, [project_code]);


  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);

  function launchGalary(_i, changeCurrentIndex, changeGalOpen) {
    if(imgsrc2.length>0){
    changeCurrentIndex(_i);
    changeGalOpen(true);
    }
    else {
      alert('고화질은 아직 로딩 중입니다. 돋보기 아이콘이 파란색으로 활성화되면 다시 눌러보세요.')
    }
  }

  let images = imgsrc2 && imgsrc2.map((v, index) => ({ url: v, title: imagemeta2[index].title + " " + imagemeta2[index].date }))
  let [galopen, changeGalOpen] = useState(false);
  let [currentIndex, changeCurrentIndex] = useState(0);



  return (
    <>
    <div style={{width:width-200}}>
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>  { imgsrc2.length > 0 ? <span className="k-icon k-font-icon k-i-zoom-in" style={{color:'#34568B', fontSize:'1.2rem'}}></span>: <span className="k-icon k-font-icon k-i-zoom-in" style={{color:'lightgray', fontSize:'1rem'}}></span>} </div>
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        pagination={{
          clickable: true, type: 'fraction',
        }}
        modules={[Pagination]}
      >
        {imgsrc && imgsrc.map((v, idx) => (
          <SwiperSlide key={idx}>
            {/* <Zoom> */}
            <img src={v} style={{ height: BoxHeight * 0.9, objectFit: 'cover', }} alt={idx} onClick={e => launchGalary(idx, changeCurrentIndex, changeGalOpen)} />
            {/* </Zoom> */}
            <div style={{ position: 'relative', top: '0px', height: '15px' }}>
              <span style={{ float: 'left' }}>{imagemeta[idx].title && imagemeta[idx].title} </span> <span style={{ float: 'right' }}>{imagemeta[idx].date && imagemeta[idx].date}</span>
            </div>
          </SwiperSlide>

        ))}

        {galopen &&
          <Dialog>
            <Lightbox startIndex={currentIndex} images={images} onClose={e => changeGalOpen(false)} />
          </Dialog>
        }



      </Swiper>

      {/* <div>
      {images && images.map((_im,idx)=><img key={_im.url} src={_im.url} alt={_im.title} onClick={e=>launchGalary(idx,changeCurrentIndex, changeGalOpen)}/>)}
    </div> */}

</div>
    </>
  );
};

export default HistroyImg;