import * as React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "./EQCTable.scss";
const EQCTable = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No data available.</div>;
  }

  const keys = Object.keys(data[0]);

  const columns = keys.map((key, index) => (
    <GridColumn
      key={key}
      field={key === "" ? "-" : key}
      title={index === 0 ? " " : key}
      width={120}
      headerCell={headerCell}
    />
  ));

  return (
    <Grid
      data={data}
      style={{ maxWidth: "1000px" }}
      className="qualityEQCTable"
    >
      {columns}
    </Grid>
  );
};
export default EQCTable;

const headerCell = (props) => {
  return (
    <div className="k-link" onClick={props.onClick}>
      <span title={props.title}>{props.title}</span>
      {props.children}
    </div>
  );
};
