import React, { useEffect, useState } from 'react';
import krMessages from "./kr.json";
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import 'hammerjs';
import 'hammerjs';
import { ResponsivePie } from '@nivo/pie';
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import { useMediaQuery } from 'react-responsive';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const PTWWorker = ({ positiondata, safetyddata , height}) => {

    const defaultvalue = new Date()

    const [value, setValue] = useState(defaultvalue);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

    const lastdate = moment(value).format("MM-DD-YYYY")

    const cdate = moment(value).subtract(moment(value).isoWeekday() - 1, 'day').format("YYYY-MM-DD")
    const lastdate2 = moment(value).format("YYYY-MM-DD")



    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };


    var daylist = getDaysArray(new Date(cdate), new Date(lastdate2)).map(v => moment(v).format("MM-DD-YYYY"));


    const workercount = safetyddata.filter(v=>v.type ==='특별' && (v.projectcode !== '18154D' || v.projectcode !== '20027D' || v.projectcode !== '21036D'|| v.projectcode !== '21077D'))
    .filter(v => v.workercount !== 'null' && v.IssueDate === lastdate ).map((v) => ({ ...v, WorkType2: v.WorkType2 === '' ? v.WorkType : v.WorkType2 }))
  

    var piedata =
        _(workercount)
            .groupBy('WorkType2')
            .map((objs, key) => ({
                'id': key,
                'value': _.sumBy(objs, 'workercount')
            }))
            .value()

    const totoal_worker = _.sumBy(workercount, 'workercount')

    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {
        const boxHeight = positiondata[2];
        setBoxHeight(((height-10)/7) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata,height]);

    const max = new Date();

    return (
        <>
            <GridLayout style={{ height: isDPI ? BoxHeight+20 :BoxHeight-16 }}
                rows={[
                    {
                        height: '100%',
                    },

                ]}

                cols={[
                    {
                        width: '100%',
                    },

                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>
                {/* <div style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '0.8rem' }}><span style={{ fontWeight: '600' }}><span style={{ color: '#939597' }}>작업건수 </span>총 <span style={{ color: 'red', fontWeight: '600' }}>{data.length}</span> 건</span></div> */}
                <div style={{ position: 'absolute', top: '10px', right: '200px' }}>
                            <LocalizationProvider language="ko-KR">
                                <IntlProvider locale="ko-KR">
                                    <div className="esg-datepick"><DatePicker max={max} format={"yyyy년 MM월 dd일 EEEE"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} /></div>
                                </IntlProvider>
                            </LocalizationProvider>
                </div>

                <GridLayoutItem row={1} col={1} rowSpan={1} style={{ position: 'relative' }}>


                    <>

                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: '600',color:'#F1F2F5' }}> {totoal_worker.toLocaleString()}명</div>

                        <ResponsivePie
                            // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                            colors={{ scheme: 'nivo' }}
                            className='pie'
                            data={piedata}
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                            valueFormat=" >-"
                            cornerRadius={0}
                            innerRadius={0.65}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabel=
                            {d => (
                                <React.Fragment>
                                  <tspan style={{fontSize:10}} x="0" dy="-0.6em">{d.id}</tspan>
                                  <tspan style={{fontSize:10}} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(piedata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                                </React.Fragment>
                              )}
                            arcLinkLabelsSkipAngle={20}
                            arcLinkLabelsTextOffset={5}
                            arcLinkLabelsTextColor='#F1F2F5'
                            arcLinkLabelsDiagonalLength={5}
                            arcLinkLabelsStraightLength={5}
                            arcLinkLabelsColor={{ from: 'color' }}
                            enableArcLabels={true}
                            arcLabel={function (e) { return e.value }}
                            arcLabelsRadiusOffset={0.5}
                            arcLabelsSkipAngle={20}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        3
                                    ]
                                ]
                            }}

                            legends={[]}
                        />

                    </>


                </GridLayoutItem>

            </GridLayout>
        </>
    );

}

export default PTWWorker