import React from "react";

const SelectSectorList = ({ sectorList, setBtnValue, sector_color_list }) => {
  return (
    <div className="areaChartTotalSelectBtnBox">
      {sectorList.map((com, idx) => {
        return (
          <button
            key={idx}
            className="areaChartTotalSelectBtn"
            style={{ backgroundColor: sector_color_list[idx] }}
            onClick={() => setBtnValue(com)}
          >
            {com.field_name}
          </button>
        );
      })}
    </div>
  );
};

export default SelectSectorList;
