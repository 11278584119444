import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Calendar } from "@progress/kendo-react-dateinputs";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import krMessages from "./kr.json";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const YearPicker = ({ value, setValue }) => {
  const handleChange = (event) => {
    setValue(event.value);
  };

  return (
    <DatePicker
      width={150}
      value={value}
      onChange={handleChange}
      format={"yyyy년"}
      calendar={CuscumCalendor}
      size={"small"}
    />
  );
};

export default YearPicker;

const CuscumCalendor = ({ value, onChange }) => {
  return (
    <LocalizationProvider language="ko-KR">
      <IntlProvider locale="ko-KR">
        <Calendar
          bottomView={"decade"}
          topView={"century"}
          value={value}
          onChange={onChange}
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};
