import React, { useEffect, useState } from 'react';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import PTW from './common/ptw';
import LTIR from './common/ltir';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Safetyinfo = ({ positiondata, filter ,projeclist}) => {

    const [BoxHeight, setBoxHeight] = useState(250);
    const { height, width } = useWindowDimensions();


    useEffect(() => {
        const boxHeight = positiondata[1];
        setBoxHeight(((height-30)/6) * (boxHeight && boxHeight.rowSpan));
    }, [positiondata,height]);
    
    return (<>
   <GridLayout style={{ height: BoxHeight-56, }}
                rows={[
                    {
                        height: '50%',
                    },                    {
                        height: '50%',
                    },
                ]}
                cols={[
                    {
                        width: '100%',
                    }
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <GridLayoutItem col={1} row={1}> 
                    <LTIR filter={filter} BoxHeight={BoxHeight} projeclist={projeclist}/>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2}>
                    <PTW filter={filter} BoxHeight={BoxHeight} projeclist={projeclist}/>
                </GridLayoutItem>


                </GridLayout>
</>)
}

export default Safetyinfo
