import React, { useState, useEffect, useMemo } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { TileLayout } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import EMSManHourSCurveChart from "./Components/EMSManHourSCurveChart";
import EMSManHourDetail from "./Components/EMSManHourDetail";

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

const url = `http://43.200.223.224:5000/getemsJSON?path=`;

// const url = "http://localhost:8080http://43.200.223.224:5000/getemsJSON?path=";

const folder_path = `/${REACT_APP_PATH2}/file/emsmanhour`;
// const folder_path =
//   "/Users/gimgiman/Desktop/DTS/0.FrontEndDevelop/SKDashboard/Server/download/file/emsmanhour";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const SkoneEMSManHour = () => {
  const { project_code } = useParams();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  // const [blockH, setblockH] = useState(140);
  const blockH = 140;
  const [positiondata, setPositionData] = useState(grid["desktop"]);
  const [fixblock, setfixblock] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [originalDataPlan, setOriginalDataPlan] = useState([]);
  const [comboBoxValues, setComboBoxValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const download_data = await axios
        .get(`${url + folder_path}/${project_code}_manhour.json`)
        .then((data) => {
          return data.data;
        });

      if (download_data.act === undefined) {
        setOriginalData([]);
        setOriginalDataPlan([]);
        setComboBoxValues([]);
      } else {
        const { act, plan, combobox } = download_data;
        const add_overall_combox = [...combobox].map((com) =>
          com.toUpperCase()
        );

        add_overall_combox.unshift("OVERALL");

        setOriginalData(act);
        setOriginalDataPlan(plan);
        setComboBoxValues(add_overall_combox);
      }
    };
    fetchData();
  }, [project_code]);

  const [status_grid, setstatusgrid] = useState([
    { status: true },
    { status: true },
    { status: true },
  ]);

  useEffect(() => {
    setPositionData(grid[breakpoint]);
  }, [breakpoint]);

  const togglefix = () => {
    setfixblock(!fixblock);
  };

  const tiles = useMemo(
    () => [
      {
        header: <div style={{ position: "relative" }}>Manhour S-Curve</div>,
        body: (
          <EMSManHourSCurveChart
            order={0}
            positiondata={positiondata}
            blockH={blockH}
            originalData={originalData}
            originalDataPlan={originalDataPlan}
            comboBoxValues={comboBoxValues}
          />
        ),

        status: true,
        key: "EMS Progress",
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: (
          <div style={{ position: "relative" }}>Manhour Detail (by Disc.)</div>
        ),
        body: (
          <EMSManHourDetail
            order={1}
            positiondata={positiondata}
            blockH={blockH}
            originalData={originalData}
            originalDataPlan={originalDataPlan}
            comboBoxValues={comboBoxValues}
          />
        ),
        status: true,
        key: "Progress Detail(by Disc.)",
        resizable: fixblock,
        reorderable: fixblock,
      },
    ],
    [
      fixblock,
      positiondata,
      blockH,
      originalData,
      comboBoxValues,
      originalDataPlan,
    ]
  );

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(
      tiles.map((v, index) => ({
        ...v,
        status: _.get(status_grid[index], "status"),
      }))
    );
  }, [status_grid, fixblock, tiles]);

  const tile_filtered = _.filter(tile, ["status", true]);

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  return (
    <div className="skoneEMSProgress">
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "20px",
          zIndex: "100",
        }}
      >
        <ButtonGroup>
          <Button
            title="고정하기"
            iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"}
            togglable={true}
            onClick={togglefix}
          ></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={16}
        rowHeight={140}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{ marginTop: "30px" }}
      />
    </div>
  );
};

export default SkoneEMSManHour;

const grid = {
  mobile: [
    {
      col: 1,
      colSpan: 16,
      rowSpan: 2,
      status: true,
    },
    {
      col: 1,
      colSpan: 16,
      status: true,
      rowSpan: 2,
    },
  ],

  tablet: [
    {
      col: 1,
      colSpan: 16,
      rowSpan: 2,
      status: true,
    },
    {
      col: 1,
      colSpan: 16,
      status: true,
      rowSpan: 2,
    },
  ],

  desktop: [
    {
      col: 1,
      colSpan: 16,
      rowSpan: 4,
      status: true,
    },
    {
      col: 1,
      colSpan: 16,
      status: true,
      rowSpan: 2,
    },
  ],
};

const createDateRangeData = (startDate, endDate) => {
  const start_date = new Date(startDate.slice(0, 10));
  const end_date = new Date(endDate.slice(0, 10));

  const end_date_month = end_date.getMonth();

  end_date.setMonth(end_date_month + 1);
  end_date.setDate(0);

  const monthDiff = moment(end_date).diff(
    moment(start_date).format("YYYY-MM-01"),
    "months"
  );

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    const base_months = moment(start_date)
      .add(i, "months")
      .format("YYYY-MM-01");

    if (i === 0) {
      const start_date_base_1 = new Date(
        moment(start_date).add(i, "months").format("YYYY-MM-DD")
      );

      const start_date_base_2 = new Date(
        moment(start_date)
          .add(i + 1, "months")
          .format("YYYY-MM-DD")
      );

      start_date_base_2.setDate(0);

      monthRange.push({
        months: base_months,
        days:
          manMonthToManHour *
          ((start_date_base_2.getDate() - start_date_base_1.getDate() + 1) /
            start_date_base_2.getDate()),
      });
    } else if (i === monthDiff) {
      const start_date_base_1 = new Date(
        moment(base_months).format("YYYY-MM-DD")
      );

      const start_date_base_2 = new Date(
        moment(new Date(endDate.slice(0, 10))).format("YYYY-MM-DD")
      );

      const start_date_base_3 = new Date(
        moment(new Date(base_months.slice(0, 10)))
          .add(1, "months")
          .format("YYYY-MM-DD")
      );

      start_date_base_3.setDate(0);

      monthRange.push({
        months: base_months,
        days:
          manMonthToManHour *
          ((start_date_base_2.getDate() - start_date_base_1.getDate() + 1) /
            start_date_base_3.getDate()),
      });
    } else {
      const start_date_base_2 = new Date(
        moment(base_months).add(1, "months").format("YYYY-MM-DD")
      );

      start_date_base_2.setDate(0);

      monthRange.push({
        months: base_months,
        days: manMonthToManHour,
      });
    }
  }

  return monthRange;
};

const manMonthToManHour = 153;

const filterArr = [
  "EMT",
  "Architecture",
  "Civil",
  "Instrument",
  "Electrical",
  "HVAC",
  "Fire Fighting",
  "Mechanical",
  "Piping",
  "Process",
];
