import React, { useEffect, useState } from 'react';
import { useHistory, useLocation,useParams } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled , createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import useDocumentTitle from './useDocumentTitle';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';


const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'black!important',
        backgroundColor: 'snow',
    },
    '&.MuiToggleButton-root': {
      color:"snow",
        fontSize: '0.7rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 50,
        borderRadius: '0px 0px 0px 0px!important',
        border: '1px solid #fff!important'
  
    }
  }));
  
  
  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
  }));
  
  const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
  });

  

const NavSidebar = () => {

    const history = useHistory();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { t, i18n } = useTranslation();
    const { project_code } = useParams();
    const [loading, setLoading] = useState(true);

    const [projectName, setprojectName] = useState("");
    const [pprogress, setPprogress] = useState("Off");
    const [selectedValue, setSelectedValue] = React.useState("ko");


    useEffect(() => {
      const fetchData = async () => {

        let body = {
          bpname: "ESG 대시보드 프로젝트 관리",
          lineitem: "no",
          "filter_criteria": {
            "join": "AND",
            "filter": [
              {
      
                "field": "ugenProjectNumber",
                "value": project_code,
                "condition_type": "eq"
              },
      
              {
                "field": "status",
                "value": "Active",
                "condition_type": "eq"
              }
            ]
          }
        };

        let body2={
          bpname: "EPC Progress Ratio",
          lineitem: "no",
        };

        const [res, res2] = await Promise.all([
          axios.post(`http://43.200.223.224:5000/getemsbprecord?path=SKEC0001`, body),
          axios.post(`http://43.200.223.224:5000/getemsbprecord?path=${project_code}`, body2)
        ]);
        
        setPprogress(res2.data.data.data[0]["ProcurementProgressRB"])
        setprojectName(res.data.data.data[0]["ugenProjectName"])
        setSelectedValue(res.data.data.data[0]["DashboardLanguagePD"] === "English"? "en":"ko")

        i18n.changeLanguage(res.data.data.data[0]["DashboardLanguagePD"] === "English"? "en":"ko");

        setLoading(false);



      }
      fetchData();
  
    }, [project_code]);
    
    useDocumentTitle("S.CON - "+projectName+" Dashboard")
  
    
    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
            i18n.changeLanguage(selectedValue);
        }
      };



  if (loading) {
    return <div><div
    onClick={() => setIsSidebarOpen(false)}
    className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
      isSidebarOpen ? "block" : "hidden"
    }`}
/>

<div style={{position:'sticky', width:'14rem', height:"100hv"}}>
    <div className="flex flex-col items-center justify-center text-center py-6" style={{ marginTop: 60, marginBottom: 40 }}>
    <img src='/image/skonlogo.png' alt='스콘로고' width={100} />
    <div style={{ marginTop: 30, fontSize:'1.1rem', color:'snow' }}> {/* 이미지 아래 여백을 위한 스타일 */}
    </div>
    </div>


</div></div>;
  }



    return(
    <>

    <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
          isSidebarOpen ? "block" : "hidden"
        }`}
    />

    <div style={{position:'sticky', width:'14rem', height:"100hv"}}>
        <div className="flex flex-col items-center justify-center text-center py-6" style={{ marginTop: 60, marginBottom: 40 }}>
        <img src='/image/skonlogo.png' alt='스콘로고' width={100} />
        <div style={{ marginTop: 30, fontSize:'1.1rem', color:'snow' }}> {/* 이미지 아래 여백을 위한 스타일 */}
            {projectName}
        </div>
        </div>

        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
          }}
          items={[
            {
              title: t('프로젝트 개요'),
              itemId: `/${project_code}/overview`,
            },

            {
              title: t("공정률"),
              itemId: `/${project_code}/progress`,
              subNav:pprogress === "Off" ?[
                {
                  title: t("설계 공정률"),
                  itemId: `/${project_code}/eprogress`,
                },
                {
                  title: t("시공 공정률"),
                  itemId: `/${project_code}/cprogress`,
                },
              ]:[
                {
                  title: t("설계 공정률"),
                  itemId: `/${project_code}/eprogress`,
                },
                {
                  title: t("구매 공정률"),
                  itemId: `/${project_code}/pprogress`,
                },
                {
                  title: t("시공 공정률"),
                  itemId: `/${project_code}/cprogress`,
                },
              ]
            },
            {
              title: t('CWP Status'),
              itemId: `/${project_code}/cwp`,
            },
            {
              title: t('인원/장비 현황'),
              itemId: `/${project_code}/mp`,
            },
            {
              title: t('안전'),
              itemId: `/${project_code}/safety`,
            },
            {
              title: t('품질'),
              itemId: `/${project_code}/quality`,
            },
            {
              title: t('환경'),
              itemId: `/${project_code}/environment`,
            },
            {
              title: t('현장 사진'),
              itemId: `/${project_code}/photo`,
            },
            {
              title: t('Area of Concern'),
              itemId: `/${project_code}/concern`,
            },

          ]}
        />

        <div className="absolute bottom-0" style={{marginBottom:"1rem", display:"grid", justifyItems:'center', width:'14rem'}}>
        <ThemeProvider theme={theme}>
            <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="언어">
                <ToggleButton value="ko" aria-label='한국어' >
                    한국어
                </ToggleButton>
                <ToggleButton value="en" aria-label="english">
                    ENG
                </ToggleButton>
            </ToggleButtonGroup>
        </ThemeProvider>
        </div>

    </div>
    </>
    );
}

export default NavSidebar;
