import React from "react";
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import moment from "moment";
import SelectSectorList from "./SelectSectorList";

const SectorDetailAreaChart = ({
  chartData,
  btnValue,
  classList,
  sectorList,
  setBtnValue,
  sector_color_list,
}) => {
  const labelContent_0 = (e) => {
    const index = e.index;

    return e.value === 0 ||
      e.value === null ||
      typeof e.value === "undefined" ||
      index % 12 !== 0
      ? ""
      : moment(new Date(e.value)).format("YY-MM");
  };

  const chartValueAxisContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString();
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "1rem Arial, sans-serif",
    position: "top",
    background: "none",
    rotation: { angle: "315" },
    content: labelContent_0,
  };

  const SharedTooltip = (props) => {
    const { points } = props;

    const target_date = new Date(points[0].category);

    return (
      <div>
        <div>
          {moment(target_date).format("YY년 MM월") +
            " " +
            Math.ceil(target_date.getDate() / 7) +
            "주"}
        </div>
        {points.map((point, index) => {
          return (
            <div key={index}>
              {point.series.name} :{" "}
              {Number(Number(point.value).toFixed(2)).toLocaleString()}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <div className="sectorDetailAreaChart">
      {chartData.length > 0 && classList.length > 0 && (
        <Chart className="skoneAreaChartArea">
          <ChartTitle
            text={`${chartData[0]["weeks"].slice(2, 4)}년 ~ ${chartData[
              chartData.length - 1
            ]["weeks"].slice(2, 4)}년 ${
              btnValue.field_name
            } Demand Expextion[누적]`}
          />
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={seriesLabels_0}
              majorGridLines={{ step: 100 }}
              minorGridLines={{ step: 100 }}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{
                font: "1rem pretendard-R",
                content: chartValueAxisContents,
              }}
              // majorTicks={{ step: 100 }}
              // minorTicks={{ step: 100 }}
              // majorGridLines={{ step: 10 }}
              // minorGridLines={{ step: 10 }}
            />
          </ChartValueAxis>
          <ChartTooltip shared={true} render={sharedTooltipRender} />
          <ChartSeries>
            {classList.map((com, idx) => {
              return (
                <ChartSeriesItem
                  key={idx}
                  data={chartData}
                  field={com.field_name}
                  name={com.field_name}
                  color={class_color_list[idx]}
                  categoryField="weeks"
                  type="area"
                  stack={{
                    type: "area",
                  }}
                />
              );
            })}
          </ChartSeries>
        </Chart>
      )}
      <SelectSectorList
        sectorList={sectorList}
        setBtnValue={setBtnValue}
        sector_color_list={sector_color_list}
      />
    </div>
  );
};

export default SectorDetailAreaChart;

const class_color_list = [
  "rgb(241, 205, 177)",
  "rgb(220, 227, 241)",
  "rgb(148, 169, 216)",
  "rgb(228, 239, 219)",
  "rgb(228, 239, 219)",
  "rgb(218, 218, 218)",
];
