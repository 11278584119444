import React, { useEffect, useState, useMemo } from 'react';
import krMessages from "./kr.json";
import _ from 'lodash';
import {
    Card,
    CardTitle,
    CardBody,
    CardSubtitle,
    GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from 'moment';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
import { Sparkline, ChartValueAxis, ChartValueAxisItem, ChartTooltip } from '@progress/kendo-react-charts';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useTranslation } from 'react-i18next';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PJTStatus = ({ filter, BoxHeight, projeclist }) => {

    const { t, i18n } = useTranslation();
    const [data, setdata] = useState([]);
    const kr_currnet_time = moment().format("MM-DD-YYYY");
    const kr_currnet_time2 = moment().subtract(2, 'months').format("MM-DD-YYYY");

    //temp
    const [filelist, setfilelist] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/temp/status`)
            setfilelist(res.data.fileList)
        };
        fetchData();
    }, []);

    const filelist2 = useMemo(() => filelist, [filelist]);

    // const [data2, setdata2] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            const newarray = [];
            for (let i of filelist2) {
                const res = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/status/${i.name}`)
                const data_with_pjtinfo = res.data
                    .map(v => ({ ...v, ...projeclist.find(sp => sp.ugenProjectNumber === v.projectcode), Epc_reportdate: moment(v.Epc_reportdate).format('MM-DD-YYYY') , record_no:v.projectcode+v.Epc_reportdate}))
                    .filter(v => v.division && v.division.includes(filter))
                newarray.push(...data_with_pjtinfo)

            }

            // setdata2(newarray)


            let body =
            {
                "bpname": "트랜잭션_EPC Progress",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [

                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },

                        {
                            "field": "epc_category_pd",
                            "value": "Overall",
                            "condition_type": "eq"
                        }
                    ]
                }
            }


            const requestArr = projeclist.filter(v => v.division && v.division.includes(filter)).map(async data => {
                return axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=${data.ugenProjectNumber}`, body)
            });

            const res = await Promise.all(requestArr)
            const reslist = res.map((v) => ({ projectcode: v.config.url.split(`http://43.200.223.224:5000/getbprecordtotal?path=`)[1], data: v.data.data.data })).flat(1)
                .reduce((c, v) => c.concat(v.data.map(o => Object.assign(o, { projectcode: v.projectcode, }))), [])
                .map((v) => ({ ...v, ...projeclist.find(vf => vf.ugenProjectNumber === v.projectcode) }))
                .map((v) => ({ ...v, Epc_reportdate: v.Epc_reportdate.slice(0, 10) }))

            setdata([...reslist,...newarray])

        };

        fetchData();
    }, [kr_currnet_time, kr_currnet_time2, filter, projeclist,filelist2]);


    const total_data = data

    const defaultvalue = new Date()
    const [value, setValue] = useState(defaultvalue);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const max = new Date();
    const min = new Date(moment().add(-2, 'months'));


    const from_date = moment(value).startOf('week');
    const to_date = moment(value).endOf('week');

    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };


    var daylist = getDaysArray(new Date(from_date), new Date(to_date)).map(v => moment(v).format("MM-DD-YYYY"));

    const projectstatuslist = _.uniqBy(total_data.filter(v => v.use !== '제외').map((v) => ({ projectname: v.projectname, division: v.division, projectcode: v.projectcode, use: v.use })), 'projectname')


    const data_status = projectstatuslist.map((v) => ({
        ...v, data: _.filter(_.filter(total_data, function (o) { return o.projectname === v.projectname }), function (o) { return daylist.includes(o.Epc_reportdate) }).length > 0
            ? _.sortBy(_.filter(_.filter(total_data, function (o) { return o.projectname === v.projectname }), function (o) { return daylist.includes(o.Epc_reportdate) }), 'record_no').reverse()[0] :
            _.sortBy(_.filter(total_data, function (o) { return o.projectname === v.projectname }), 'record_no').reverse()[0]
    }))
    // .map((f)=>({...f, data:f.data && 
    //     f.data.map((v)=>({...v, bulletdata:[v.epc_Actual,v.epc_plan], color:v.epc_Actual - v.epc_plan >= 3? '#006B54': v.epc_Actual - v.epc_plan <= -3?'#FA7A35':'#363945'}))

    // }))


    const data_status_all = data_status.map((v) => ({
        ...v,
        bulletdata: [_.get(v.data, 'epc_Actual'), _.get(v.data, 'epc_plan')],
        color: _.get(v.data, 'epc_Actual') - _.get(v.data, 'epc_plan') >= 3 ? '#006B54' : _.get(v.data, 'epc_Actual') - _.get(v.data, 'epc_plan') <= -3 ? '#FA7A35' : '#363945',
        epc_Actual: _.get(v.data, 'epc_Actual'),
        epc_plan: _.get(v.data, 'epc_plan'),
        record_no: _.get(v.data, 'record_no'),
    }))


    var data_status_g = _(data_status_all)
    .groupBy('projectname')
    .map((objs, key) => {
        const sortedFilteredObjs = _.sortBy(_.filter(objs, { 'projectname': key }), 'record_no').reverse();
        const firstObj = sortedFilteredObjs[0] || {};  // 이 부분이 중요합니다. 배열이 비어 있으면 기본 빈 객체를 사용합니다.

        return {
            'projectname': key,
            'division': firstObj['division'] || 'DEFAULT_VALUE',
            'bulletdata': firstObj['bulletdata'] || [],
            'epc_Actual': firstObj['epc_Actual'] || 0,
            'epc_plan': firstObj['epc_plan'] || 0,
            'projectcode': firstObj['projectcode'] || 'DEFAULT_PROJECT_CODE',
            'color': firstObj['color'] || '#363945',
            'use': firstObj['use'] || 'DEFAULT_USE',
        };
    })
    .value();

    const bulletValueAxis = {
        min: 0,
        max: 100,
        plotBands: [{
            from: 0,
            to: 100,
            color: '#787878',
            opacity: 0.15
        }]
    };

    const tooltipRender = (props) => {
        return (
            <span>
                {t("계획")}: {props.points[0].value.target}%
                <br />
                {t("실적")}: {props.points[0].value.current}%
                <br />
                {t("차이")}: {(parseFloat(props.points[0].value.current) - parseFloat(props.points[0].value.target)).toFixed(1)}%
            </span>
        );
    };

    return (<>

        <Card style={{ height: BoxHeight - 62, margin: '12px' }}>
            <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
                <span style={{ float: 'left' }}>Project Status</span>
                <span style={{ width: '200px', position: 'absolute', right: '12px' }} >
                    <LocalizationProvider language={i18n.language ==="ko"?"ko-KR":"en"}>
                        <IntlProvider locale={i18n.language ==="ko"?"ko-KR":"en"}>
                            <DatePicker min={min} max={max} format={ i18n.language ==="ko"? "yyyy년 MM월 dd일 EEEE":"MMM dd yyyy EEEE"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} />
                        </IntlProvider>
                    </LocalizationProvider>
                </span>
            </CardTitle>
            <CardSubtitle style={{ position: 'absolute', top: '40px', left: '50%', transform: 'translateX(-50%)' }}>
                <span style={{ fontSize: '1.6rem', color: '#FA7A35', fontWeight: '600' }}>{_.filter(data_status_g, function (o) { return o.epc_Actual - o.epc_plan <= -3 }).length}<span style={{ fontSize: '1rem', color: '#FA7A35' }}> Behind </span></span>
                <span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>&nbsp;&nbsp;&nbsp;&nbsp;{_.filter(data_status_g, function (o) { return o.epc_Actual - o.epc_plan < 3 && o.epc_Actual - o.epc_plan > -3 }).length} <span style={{ fontSize: '1rem' }}>In progress</span></span>
                <span style={{ fontSize: '1.6rem', color: '#006B54', fontWeight: '600' }}>&nbsp;&nbsp;&nbsp;&nbsp;{_.filter(data_status_g, function (o) { return o.epc_Actual - o.epc_plan >= 3 }).length} <span style={{ fontSize: '1rem' }}>Ahead</span></span>
            </CardSubtitle>
            <CardBody style={{ position: 'absolute', top: '80px', width: '100%', height: BoxHeight - 152, margin: '12px' }}>
                <GridLayout style={{ width: '100%', height: BoxHeight - 152 }} rows={[
                    {
                        height: '100%',
                    }
                ]}
                    cols={[
                        {
                            width: '50%',
                        }, {
                            width: '50%',
                        },
                    ]}
                    gap={{
                        rows: 0,
                        cols: 0,
                    }}>
                    <GridLayoutItem row={1} col={1}>
                        <div style={{ width: '100%', float: 'right' }}>

                            <ul style={{ padding: '0', listStyle: 'none', margin: '0', overflowY: 'auto', width: '98%', height: BoxHeight - 160 }}>
                                {data_status_g.filter(v => v.use === '운용') && _.sortBy(data_status_g.filter(v => v.use === '운용'),"projectname").map((v, index) => (
                                    <Tooltip key={index} anchorElement="target" position="top">

                                        <li >
                                            <span title={v.projectname} style={{ width: '80px', display: 'inline-block', textAlign: 'right', fontSize: '0.75rem', padding: 2, whiteSpace: 'nowrap', verticalAlign: 'top' }}>{v.projectname}</span>
                                            <Sparkline data={v.bulletdata} type="bullet" seriesColors={[v.color]} className='bspark'>
                                                <ChartValueAxis>
                                                    <ChartValueAxisItem {...bulletValueAxis} />
                                                </ChartValueAxis>
                                                <ChartTooltip render={tooltipRender} />
                                            </Sparkline>
                                            <span style={{ width: '70px', display: 'inline-block', fontSize: '0.75rem', padding: 2, verticalAlign: 'top', whiteSpace: 'nowrap' }}>{v.epc_Actual && v.epc_Actual.toFixed(1)}% ({(v.epc_Actual - v.epc_plan).toFixed(1)}%)</span>
                                        </li>
                                    </Tooltip>

                                ))}



                            </ul>
                        </div>
                    </GridLayoutItem>

                    <GridLayoutItem row={1} col={2}>
                        <div style={{ width: '100%', float: 'right' }}>
                            <ul style={{ padding: '0', listStyle: 'none', margin: '0', overflowY: 'auto', width: '98%', height: BoxHeight - 160 }}>

                                {data_status_g.filter(v => v.use === '미운용') &&_.sortBy(data_status_g.filter(v => v.use === '미운용'),"projectname" ).map((v, index) => (
                                    <Tooltip key={index} anchorElement="target" position="top">

                                        <li>
                                            <span title={v.projectname + ' (SKONe 미운용 프로젝트)'} style={{ width: '80px', display: 'inline-block', textAlign: 'right', color: '#939597', fontSize: '0.75rem', padding: 2, whiteSpace: 'nowrap', verticalAlign: 'top' }}>{v.projectname}</span>
                                            <Sparkline data={v.bulletdata} type="bullet" seriesColors={[v.color]} className='bspark'>
                                                <ChartValueAxis>
                                                    <ChartValueAxisItem {...bulletValueAxis} />
                                                </ChartValueAxis>
                                                <ChartTooltip render={tooltipRender} />
                                            </Sparkline>
                                            <span style={{ width: '70px', display: 'inline-block', fontSize: '0.75rem', color: '#939597', padding: 2, verticalAlign: 'top', whiteSpace: 'nowrap' }}>{v.epc_Actual && v.epc_Actual.toFixed(1)}% ({(v.epc_Actual - v.epc_plan).toFixed(1)}%)</span>
                                        </li>
                                    </Tooltip>

                                ))}
                            </ul>
                        </div>
                    </GridLayoutItem>

                </GridLayout>

            </CardBody>
        </Card>

    </>);
}

export default PJTStatus
