import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import dayjs from 'dayjs';
import _ from 'lodash';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


dayjs.extend(isSameOrBefore);


export const RPPFormTableR = ({ tabeldata, moduleData, columnData, projectCode, columnData2 }) => {

  const staticFields = [
    { displayName: "Activity ID", key: "EPPMActivityBPK" },
    { displayName: "Activity Name", key: "uuu_P6ActivityName" },
    { displayName: "Unit", key: "EDPRUnit" },
    { displayName: "Discipline", key: "WBSDiscNM" },
    { displayName: "Class Code Name", key: "PMSClassNM" },
    { displayName: "MR No", key: "PRPRPJTMRNo" },
    { displayName: "Revision No", key: "RevNo" },
    { displayName: "MR Title", key: "PRPRMRTitle" },
    { displayName: "Price", key: "PRPRPrice" },
    // { displayName: "Doc. Weight Factor", key: "EDPRPRWeightFactor" },
    { displayName: "Doc. Manhour", key: "EDPRPRManhour" },
    { displayName: "Doc. Type", key: "DOC_TYPE_NM" },
    { displayName: "Plan Start", key: "emsPlanStartDO" },
    { displayName: "Plan Finish", key: "emsPlanFinishDO" },
    { displayName: "Remark", key: "EDPRRemark" },
  ];

  const [rows, setRows] = React.useState(tabeldata.map((v, index) => ({ ...v, id: index + 1 })));


  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Doc_Title' },
      }));
    };



    return (
      <GridToolbarContainer>
        <Button type='button' style={{ display: columnData.length === 0 ? "none" : "block" }} variant="outlined" onClick={downloadExcel} size="small">전체 다운로드</Button>
      </GridToolbarContainer>
    );
  }

  const suffixes = ["EPPMPlanDate", "EPPMActualDate", "EPPMForecastDate", "In_TR_No", "Out_TR_No", "Result"];
  const suffixes2 = ["Plan", "Actual", "Forecast", "In TR No", "Out TR No", "Result"];


  const columnsDate = columnData.flatMap(v =>
    suffixes.map((suffix, index) => ({
      field: v + "//" + suffix,
      group: v,
      width: 100,
      type: suffixes2[index] === "Plan" || suffixes2[index] === "Forecast" || suffixes2[index] === "Actual" ? "date" : "text",
      editable: suffixes2[index] === "Plan" || suffixes2[index] === "Forecast" ? true : false,
      headerName: suffixes2[index],
      cellClassName: (params) => {
        if (columnData2.filter(stage => stage.PMSClassNM === params.row.PMSClassNM).map(name => name.PMSStageNM).includes(v)) {
          return '';
        } else {
          return 'notmatched';
        }

      },
    }))
  );

  const columnGroupingModel = columnData.map(v => ({
    groupId: v, // Use the value from columnData as groupId
    headerName: v,
    freeReordering: true,
    description: '', // You can add descriptions if needed
    children: suffixes.map((suffix, index) => ({
      field: v + "//" + suffix,
      // Include other properties as needed
    }))
  }));


  const columnsData = [
    { field: 'record_no', headerName: "레코드 번호", width: 100, editable: false },
    { field: 'EPPMActivityBPK', headerName: "Activity ID", width: 180, editable: false },
    { field: 'uuu_P6ActivityName', headerName: "Activity Name", width: 250, editable: false },
    { field: 'EDPRUnit', headerName: "Unit", width: 100, editable: true },
    { field: 'WBSDiscNM', headerName: "Discipline", width: 100, editable: false },
    { field: 'PMSClassNM', headerName: "Class Code Name", width: 100, editable: false },
    { field: 'PRPRPJTMRNo', headerName: "MR No", width: 100, editable: true },
    { field: 'RevNo', headerName: "Revision No", width: 100, editable: false },
    { field: 'PRPRMRTitle', headerName: "MR Title", width: 480, editable: true },
    { field: 'PRPRPrice', headerName: "Price", width: 100, editable: true },
    // { field: 'EDPRPRWeightFactor', headerName: "Doc. Weight Factor", width: 100, editable: true },
    { field: 'EDPRPRManhour', headerName: "Doc. Manhour", width: 100, editable: true },
    { field: 'DOC_TYPE_NM', headerName: "Doc. Type", width: 100, editable: true },
    { field: 'emsPlanStartDO', headerName: "Plan Start", type: 'date', width: 100, editable: false },
    { field: 'emsPlanFinishDO', headerName: "Plan Finish", type: 'date', width: 100, editable: false },
    { field: 'EDPRRemark', headerName: "Remark", width: 100, editable: false },
  ]

  const columns = [...columnsData, ...columnsDate];


  const downloadExcel = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    // Dynamic fields from columnsDate
    const dynamicFieldNamesNormal = columnData.flatMap(v =>
      suffixes2.map(suffix => `${v}//${suffix}`)
    );

    const dynamicFieldNamesCode = columnData.flatMap(v =>
      suffixes.map(suffix => `${v}//${suffix}`)
    );
    // Combine static and dynamic fields
    const combinedFields = [...staticFields.map(f => f.key), ...dynamicFieldNamesCode];

    const topHeaders = staticFields.map(f => "").concat(dynamicFieldNamesNormal.map(field => {
      const splitField = field.split("//");
      return splitField.length === 2 ? splitField[0] : field;
    }));

    // 하단 헤더 생성
    const bottomHeaders = staticFields.map(f => f.displayName)
      .concat(dynamicFieldNamesNormal.map(field => {
        const splitField = field.split("//");
        return splitField.length === 2 ? splitField[1] : field;
      }));


    // 셀 병합을 위한 함수
    function mergeHeaderCells(headers, rowNumber) {
      let startColumn = 1;
      for (let i = 1; i <= headers.length; i++) {
        // 셀의 값이 비어있지 않은 경우에만 병합 검사
        if (headers[i - 1] !== "") {
          if (i === headers.length || headers[i] !== headers[i - 1]) {
            if (i - startColumn >= 1) {
              worksheet.mergeCells(rowNumber, startColumn, rowNumber, i);
            }
            startColumn = i + 1;
          }
        } else {
          // 셀의 값이 비어있는 경우, 다음 셀로 시작 열을 업데이트
          startColumn = i + 1;
        }
      }
    }
    worksheet.addRow(topHeaders);
    worksheet.addRow(bottomHeaders);
    const headerRow = worksheet.getRow(1);
    const headerRow2 = worksheet.getRow(2);

    mergeHeaderCells(topHeaders, 1);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow2.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });

    const startColumnIndex = 16; // 테두리를 시작할 열 인덱스

    // 첫 번째 헤더 행의 테두리 설정
    headerRow.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 두 번째 헤더 행의 테두리 설정
    headerRow2.eachCell((cell, colNumber) => {
      if (colNumber >= startColumnIndex) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      }
    });

    // 데이터 행 추가 및 날짜 형식 확인을 위한 함수
    const isDate = (value) => {
      if (typeof value === 'string' || value instanceof String) {
        const date = new Date(value);
        return !isNaN(date.getTime());
      }
      return value instanceof Date;
    };
    // 스타일 적용
    staticFields.forEach((field, index) => {
      const style = {
        font: { color: { argb: field.required ? 'FFFF0000' : 'FF000000' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }
      };
      worksheet.getCell(2, index + 1).style = style;
    });

    const udpatedmoduleData = moduleData.map((v) => ({ ...v, EPPMActivityBPK: v.uuu_P6ActivityId, id: "" }))
    const dataRows = [...rows, ..._.differenceBy(udpatedmoduleData, rows, "EPPMActivityBPK")]
      .map(row => combinedFields.map(fieldName => row[fieldName] || ""))
      .map(row => combinedFields.map(fieldName => {
        const value = row[fieldName];
        // 'Doc. Manhour' 필드의 경우 빈 값에 대해 0을 반환
        if (fieldName === "EDPRPRManhour" || fieldName ==="PRPRPrice") {
          return value || 0; // value가 'falsy' (null, undefined, 빈 문자열, 0, false 등)일 경우 0 반환
        }
        return value || ""; // 다른 필드는 빈 값인 경우 빈 문자열 반환
      }));

    // Helper function to determine if the header contains any of the specified keywords
    const isTimeSensitiveColumn = (index) => {
      const headerValue = bottomHeaders[index].toLowerCase();
      return headerValue.includes("plan") || headerValue.includes("actual") || headerValue.includes("forecast");
    };

    dataRows.forEach(row => {
      row.forEach((cell, index) => {
        // Check if the cell date should be adjusted based on the header content
        if (isDate(cell) && isTimeSensitiveColumn(index)) {
          let date = new Date(cell);
          date.setHours(date.getHours() + 9); // Add 9 hours for KST adjustment
          row[index] = date;
        }
      });
    });


    dataRows.forEach((row, rowIndex) => {
      worksheet.addRow(row);

    });

    worksheet.views = [
      { state: 'frozen', ySplit: 2 }
    ];

    // 칼럼 너비 업데이트
    worksheet.columns.forEach((column, i) => {
      let maxWidth = 10; // 초기 최소 너비 설정

      // 헤더 행도 포함하여 너비 계산
      let headerLength = worksheet.getRow(1).getCell(i + 1).value
        ? worksheet.getRow(1).getCell(i + 1).value.toString().length
        : 0;
      if (headerLength > maxWidth) {
        maxWidth = headerLength;
      }

      // 각 셀의 값에 따라 최대 너비 계산 (날짜 형식 제외)
      column.eachCell({ includeEmpty: true }, cell => {
        if (!isDate(cell.value)) {
          let cellLength = cell.value ? cell.value.toString().length : 0;
          maxWidth = cellLength > maxWidth ? cellLength : maxWidth;
        }
      });

      column.width = maxWidth + 2; // 약간의 여백 추가
    });
    // 값이 있는 모든 셀에 대해 테두리 설정
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
        const border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.border = border;
      });
    });

    // 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `전체 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);

  };






  return (
    <>

      {columnData.length > 0 ?
        <Box
          sx={{
            height: "76vh",
            width: '100%',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
            },
            '& .MuiDataGrid-cell': {
              fontSize: 12,
            },
            '& .notmatched': {
              backgroundColor: '#f2f3f3',
            },
            '& .notmatched.MuiDataGrid-cell--editing': {
              backgroundColor: '#f2f3f3',
              color: '#f2f3f3'
            },
            '& .notmatched input': {
              // backgroundColor: '#F0EDE5', 
              fontSize: 0
            },
            '& .notmatched.MuiDataGrid-cell': {
              backgroundColor: '#f2f3f3',
            },
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >

          <DataGridPro
            rows={rows}
            initialState={{ pinnedColumns: { left: ['actions'] } }}
            columns={columns}
            density="compact"
            slots={{
              toolbar: EditToolbar,
            }}
            pagination
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
          />
        </Box>
        :
        <div>
          PMS 셋팅이 되어있지 않습니다.
        </div>
      }
    </>
  );
};