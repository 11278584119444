import React, { useState, useCallback, useEffect } from "react";
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import moment from "moment";
import axios from "axios";
import SectorDetailAreaChart from "./SectorDetailAreaChart";
import "./SkoneAreaChart.scss";

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

const folder_path =
  `http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/resource_area_chart`;
  

const SkoneAreaChart = () => {
  const [chartData, setChartData] = useState([]);
  const [chartDatas, setChartDatas] = useState([]);
  const [btnValue, setBtnValue] = useState({});
  const [sectorList, setSectorList] = useState([]);
  const [classList, setClassList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetch_overall_data = await axios.get(
        `${folder_path}/area_chart_overall_data.json`
      );

      const fetch_sector_data = await axios.get(
        `${folder_path}/area_chart_sector_data.json`
      );

      const fetch_class_list_data = await axios.get(
        `${folder_path}/skclasslist.json`
      );

      const fetch_sector_list_data = await axios.get(
        `${folder_path}/sectorlist.json`
      );

      const fetch_overall_data_result = fetch_overall_data.data;

      const fetch_sector_data_result = fetch_sector_data.data;

      const fetch_class_list_data_result = fetch_class_list_data.data;

      const fetch_sector_list_data_result = fetch_sector_list_data.data;

      setChartData(fetch_overall_data_result);
      setChartDatas(fetch_sector_data_result);
      setSectorList(fetch_sector_list_data_result);
      setClassList(fetch_class_list_data_result);
      setBtnValue(fetch_sector_list_data_result[0]);
    };
    fetchData();
  }, []);

  const labelContent_0 = (e) => {
    const index = e.index;

    return e.value === 0 ||
      e.value === null ||
      typeof e.value === "undefined" ||
      index % 12 !== 0
      ? ""
      : moment(new Date(e.value)).format("YY-MM");
  };

  const chartValueAxisContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString();
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "1rem Arial, sans-serif",
    position: "top",
    background: "none",
    rotation: { angle: "315" },
    content: labelContent_0,
  };

  const findSectorChartData = useCallback(() => {
    if (btnValue.field_name === undefined) {
      return [];
    } else {
      const find_index = chartDatas.find(
        (com) => com.field_name === btnValue.field_name
      );
      return find_index === undefined ? [] : find_index.chartData;
    }
  }, [chartDatas, btnValue]);

  const SharedTooltip = (props) => {
    const { points } = props;

    const target_date = new Date(points[0].category);

    return (
      <div>
        <div>
          {moment(target_date).format("YY년 MM월") +
            " " +
            Math.ceil(target_date.getDate() / 7) +
            "주"}
        </div>
        {points.map((point, index) => {
          return (
            <div key={index}>
              {point.series.name} :{" "}
              {Number(Number(point.value).toFixed(2)).toLocaleString()}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  console.log(classList);

  return (
    <div className="skoneAreaChartTotal">
      <div className="skoneAreaChart">
        {chartData.length > 0 && sectorList.length > 0 && (
          <Chart className="skoneAreaChartArea" style={{ width: "100%" }}>
            <ChartTitle
              text={`${chartData[0]["weeks"].slice(2, 4)}년 ~ ${chartData[
                chartData.length - 1
              ]["weeks"].slice(2, 4)}년 Demand Expextion[누적]`}
            />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                labels={seriesLabels_0}
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                labels={{
                  font: "1rem pretendard-R",
                  content: chartValueAxisContents,
                }}
              />
            </ChartValueAxis>
            <ChartTooltip shared={true} render={sharedTooltipRender} />
            <ChartSeries>
              {sectorList.map((com, idx) => {
                return (
                  <ChartSeriesItem
                    key={idx}
                    data={chartData}
                    field={com.field_name}
                    name={com.field_name}
                    color={sector_color_list[idx]}
                    categoryField="weeks"
                    type="area"
                    stack={{
                      type: "area",
                    }}
                  />
                );
              })}
            </ChartSeries>
          </Chart>
        )}
      </div>
      <div className="skoneSectorAreaChart">
        <SectorDetailAreaChart
          chartData={findSectorChartData()}
          btnValue={btnValue}
          classList={classList.filter(
            (com) => com.field_name === "EMT" || com.field_name === "설계"
          )}
          sectorList={sectorList}
          setBtnValue={setBtnValue}
          sector_color_list={sector_color_list}
        />
      </div>
    </div>
  );
};

export default SkoneAreaChart;

const sector_color_list = [
  "rgb(241, 205, 177)",
  "rgb(220, 227, 241)",
  "rgb(148, 169, 216)",
  "rgb(228, 239, 219)",
  "rgb(218, 218, 218)",
];
