import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
  Card, CardBody,
  CardTitle,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Chart, ChartLegend,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";


const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const Einfo = ({ filter, projeclist, BoxHeight }) => {
  const { t, i18n } = useTranslation();

  const [chartdata, setChartData] = useState([]);
  const [wfolderList, setWfolderList] = useState([]);
  
  // Fetch File Folder List
  useEffect(() => {
    const fetchFolderList = async () => {
      const res = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/allbaro`);
      const filteredFolders = res.data.folderList.map(v => v.name).filter(v => v !== "AWPTEST");
      setWfolderList(filteredFolders);
    }
    fetchFolderList();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      const otherData = await getOtherData();
      const wasteData = await processWasteData(wfolderList);
      const finalChartData = compileChartData(otherData, wasteData, filter,projeclist);
      setChartData(finalChartData);
    };
    fetchData();
  }, [projeclist, filter, wfolderList,i18n.language]);
  
  const getOtherData = async () => {
    const validProjects = projeclist.filter(v => v.division && !v.division.includes('반도체') && !v.ugenProjectName.includes('TEST'));
      
    const body = {
      "bpname": "환경배출물 관리",
      "lineitem": "yes",
      "filter_criteria": {
        "join": "AND",
        "filter": [
          {
            "field": "ugenProjectName",
            "value": 'TEST',
            "condition_type": "neq"
          },
          {
            "field": "status",
            "value": "Active",
            "condition_type": "eq"
          }
        ]
      }
    };
  
    const fetchOthersData = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body);
    return filterProjectData(validProjects, fetchOthersData);
  };
  
  const filterProjectData = (projects, othersData) => {
    return projects && othersData.data.data.data
      .filter(
        com => com.ueffectyearPD === String(moment(new Date()).format("YYYY"))
      )
      .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ueffectyearPD: v.ueffectyearPD, umuMonthPD: v.umuMonthPD }))), [])
      .map(v => ({ ...v, ...projects.find(vf => vf.ugenProjectNumber === v.ugenProjectNumber) }))
      .filter(v => v.division && v.division.includes(filter));
  };
  
  const getSiteDevelopmentEndDate = async (wasteDetail) => {
    const body = {
        bpname: "ESG 대시보드 프로젝트 관리",
        lineitem: "no",
        filter_criteria: {
            filter: [
                {
                    status: "Active",
                },
            ],
        },
    };

    const response = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body);
    return response.data.data.data.find(com => com.ugenProjectNumber === wasteDetail)?.["SiteDevelopmentEndDate"];
};

const fetchData = async (wasteDetail) => {
    return axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/allbaro/${wasteDetail}/allbaro.json`);
};

const filterData = (data, site_development_end_date) => {
    return data
        .filter(com => 
            (!site_development_end_date || Number(com.ESG_GIVE_DATE.slice(0, 8)) > Number(site_development_end_date.slice(6, 10) + site_development_end_date.slice(0, 2) + site_development_end_date.slice(3, 5))) &&
            com.ESG_TRTM_WAYS !== null &&
            Number(com.ESG_GIVE_QUNT) !== 0 &&
            com.ESG_WSTE_CATE !== null &&
            com.ESG_GIVE_DATE.slice(0, 4) === String(moment(new Date()).format("YYYY"))
        )
        .map(com => ({
            ...com,
            give_qunt_unit_chage: changeCount(com),
        }));
};

const processWasteData = async (wfolders) => {
    if (!wfolders || !wfolders.length) return [];

    const promises = wfolders.map(async (wasteDetail) => {
        const [site_development_end_date, fetchWasteData] = await Promise.all([
            getSiteDevelopmentEndDate(wasteDetail),
            fetchData(wasteDetail)
        ]);

        if (fetchWasteData.data.length > 0) {
            return filterData(fetchWasteData.data, site_development_end_date);
        }
        return [];
    });

    const wasteSumArr2 = (await Promise.all(promises)).flat(1);
    return wasteSumArr2;
};
  
  const compileChartData = (othersDataYearFilter, wasteData, filter, projeclist) => {

    const wasteData_with_division = wasteData.map((v)=>({...v, ...projeclist.find(vf=>vf.ugenProjectNumber===v.project_projectnumber)}))


    const wasteSummary = _(wasteData_with_division)
    .groupBy(filter ===""?'division':"projectname")
    .map((objs, key) => ({
      'key': key === "수소친환경" && i18n.language !=="ko"?"H2/Recycling":key,
      '폐기물': Number(_.sumBy(objs, 'give_qunt_unit_chage').toFixed(0))  
    }))
    .value();

    const etcSummary = _(othersDataYearFilter)
      .groupBy(filter ===""?'division':"ugenProjectName")
      .map((objs, key) => ({
        'key': key === "수소친환경" && i18n.language !=="ko"?"H2/Recycling":key,
        '용수': Number(_.sumBy(objs.filter(v => v.Envclass1 === '용수'), 'Envamount').toFixed(0)),
        '온실가스': Number(_.sumBy(objs.filter(v => v.Envclass1 === '온실가스'), 'Envamount').toFixed(0)),
        '에너지': Number(_.sumBy(objs.filter(v => v.Envclass1 === '에너지'), 'Envamount').toFixed(0)),
      }))
      .value();


  
    // Combining datasets
    const result = etcSummary.map((v) => {
      const wasteEntry = wasteSummary.find(w => w.key === v.key);
      return {
        ...v,
        '폐기물': wasteEntry ? wasteEntry.폐기물 : 0
      };
    });
  
    return result;
  };


    const SharedTooltip = (props) => {
      const { points } = props;

      return (
        <>
        <div>{points[0].category}</div>
        {points.map(function (point, index) {
          return point.series.field === "count" ? (
            <div key={index}>
            </div>
          ) : (
            <div key={index}>
              {point.series.name} : {point.value.toLocaleString()}
            </div>
          );
        })}
      </>
      );
    };
  
    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;
  
  return (
    <>
      <Card style={{ height: (BoxHeight - 70) / 2, margin: '12px', fontSize: '12px' }}>
        <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
          <span style={{ float: 'left' }}>{t("누적 소비 규모")} </span>
          <span style={{ position: 'absolute', fontWeight: '500', right: '12px', fontSize:"0.8rem" }}>{moment().startOf('year').format( i18n.language ==="ko"?'YYYY년 MM월':"MMM YYYY")} - {moment().format(i18n.language ==="ko"?'YYYY년 MM월':"MMM YYYY")}</span>

        </CardTitle>
        <CardBody style={{ height: ((BoxHeight - 70) / 2) - 47, margin: '12px' }}>
          <GridLayout style={{ width: '100%', height: ((BoxHeight - 62) / 2) - 40 }}
           rows={[
            {
              height: '100%',
            },
            
          ]}
            cols={[
              {
                width: '100%',
              }, 



            ]}
            gap={{
              rows: 0,
              cols: 0,
            }}>
     
     <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
            <Chart>
            <ChartArea height={((((BoxHeight - 62) / 2)) * 0.85 )} margin={{ bottom: 5, top: 20 }} />

            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    // labels={seriesLabels_0}
                    majorGridLines={{ step: 1000000 }}
                  // baseUnitStep='auto' 
                  // maxDateGroups={9} 
                  />
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem labels={{ visible: false }} majorGridLines={{ step: 1000000 }} visible={false} line={{ width:0}}></ChartValueAxisItem>
                </ChartValueAxis>

            <ChartTooltip shared={true} render={sharedTooltipRender} />

            <ChartSeries>

                        <ChartSeriesItem
                          type="column"
                          stack={true}
                          data={chartdata}
                          field="폐기물"
                          categoryField="key"
                          border={{ width: "0" }}
                          name={t("폐기물 (톤)")}
                        />
              
              <ChartSeriesItem
                          type="column"
                          stack={true}
                          data={chartdata}
                          field="용수"
                          categoryField="key"
                          border={{ width: "0" }}
                          name={t("용수 (m3)")}
                        />                        
                        <ChartSeriesItem
                        type="column"
                        stack={true}
                        data={chartdata}
                        field="온실가스"
                        categoryField="key"
                        border={{ width: "0" }}
                        name={t("온실가스 (tCO2e)")}
                      />
                        <ChartSeriesItem
                          type="column"
                          stack={true}
                          data={chartdata}
                          field="에너지"
                          categoryField="key"
                          border={{ width: "0" }}
                          name={t("에너지 (GJ)")}
                        />
              </ChartSeries>

            </Chart>
</GridLayoutItem>
          </GridLayout>

        </CardBody>
      </Card>
    </>
  );
}

export default Einfo;


const changeCount = (baseArr) => {
  if (baseArr.ESG_GIVE_QUNT_UNIT === "Ton") {
    return Number(baseArr.ESG_GIVE_QUNT);
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "kg") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000;
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "g") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000000;
  }
};
