import React, { useEffect, useState } from "react";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import { Card, CardHeader, CardSubtitle, CardTitle, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { TableBody, TableCell, TableHead, TableRow, Table, TableContainer } from "@mui/material";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import moment from 'moment';

const NCRAll = ({ positiondata , ncrdata, projectlist,height}) => {
    const [BoxHeight, setBoxHeight] = useState(250);



    useEffect(() => {
        const boxHeight = positiondata[2];
        setBoxHeight(height * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata,height]);

    const thisyear = moment(new Date()).format('YYYY')

    const data = _.uniqBy(ncrdata.map((v)=>({...v, uniq:v.projectcode+v.record_no, year:moment(v.NCPIssueDP).format('YYYY')})), 'uniq').filter(v=>v.year === thisyear)
    .filter(v=> projectlist.some(vf=>vf.projectcode === v.projectcode))
    .map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.projectcode) })).filter(v=>v.projectcode !== 'AWPTEST'&&v.NCRTitle !=='Terminated' && v.status !=='Terminated')


    var groupdata =
    _(data)
      .groupBy('projectname')
      .map((objs, key) => ({
        'projectname': key,
        'ncr': objs.filter(v=>v.NCPTypeSPD ==='NCR' && v.status ==='Closed').length+'/'+objs.filter(v=>v.NCPTypeSPD ==='NCR').length,
        'car': objs.filter(v=>v.NCPTypeSPD ==='CAR' && v.status ==='Closed').length+'/'+objs.filter(v=>v.NCPTypeSPD ==='CAR').length,
        'par': objs.filter(v=>v.NCPTypeSPD ==='PAR' && v.status ==='Closed').length+'/'+objs.filter(v=>v.NCPTypeSPD ==='PAR').length,
      }))
      .value();


    const [sort, setSort] = useState([{}]);

    return (<>
        <Grid
            data={orderBy(groupdata, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
                setSort(e.sort);
            }}
            style={{maxHeight:BoxHeight}}
            className='esgtable'

        >

            <GridColumn field="projectname" title="프로젝트" />
            <GridColumn title={thisyear}>
                <GridColumn field="ncr" title="NCR" />
                <GridColumn field="car" title="CAR" />
                <GridColumn field="par" title="PAR" />
            </GridColumn>
       

        </Grid>

    </>);

};

export default NCRAll;
