import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useTranslation } from 'react-i18next';


const CWPoverview = ({ BoxHeight, datafrom, value1, value2, value3, value4 }) => {



    const { t, i18n } = useTranslation();

    const f_datafrom = datafrom && datafrom.filter(v =>

        value4 === '' ?

            (value1 !== '' && value2 === '' && value3 === '' ?
                v.Lv_1_Name === value1 :

                value1 !== '' && value2 !== '' && value3 === '' ?
                    v.Lv_1_Name === value1 && v.Lv_2_Name === value2 :
                    value1 !== '' && value2 !== '' && value3 !== '' ?
                        v.Lv_1_Name === value1 && v.Lv_2_Name === value2 && v.Lv_3_Name === value3 : v

            )
            :

            (
                value1 === '' && value2 === '' && value3 === '' ?
                    v.Lv_4_Name === value4 :
                    value1 !== '' && value2 === '' && value3 === '' ?
                        v.Lv_1_Name === value1 && v.Lv_4_Name === value4 :

                        value1 !== '' && value2 !== '' && value3 === '' ?
                            v.Lv_1_Name === value1 && v.Lv_2_Name === value2 && v.Lv_4_Name === value4 :

                            v.Lv_1_Name === value1 && v.Lv_2_Name === value2 && v.Lv_3_Name === value3 && v.Lv_4_Name === value4)


    )

    const c_count = _.filter(f_datafrom, function (elem) { return elem.d_activity_status === 'Completed'; }).length;
    const n_count = _.filter(f_datafrom, function (elem) { return elem.d_activity_status === 'Not Started'; }).length;
    const i_count = _.filter(f_datafrom, function (elem) { return elem.d_activity_status === 'In Progress'; }).length;
    const i_data = _.filter(f_datafrom, function (elem) { return elem.d_activity_status === 'In Progress'; });
    const a_count = _.filter(f_datafrom, function (elem) { return elem.d_activity_status === 'In Progress' && (elem.d_actual_progress - elem.d_plan_progress) >= 3; }).length;
    const d_count = _.filter(f_datafrom, function (elem) { return (elem.d_actual_progress - elem.d_plan_progress) <= -3; }).length;
    const o_count = _.filter(f_datafrom, function (elem) { return elem.d_activity_status === 'In Progress' && (elem.d_actual_progress - elem.d_plan_progress) > -3 && (elem.d_actual_progress - elem.d_plan_progress) < 3; }).length;

    const myString =
        `Ahead: ${t("Actual Progress가 Plan Progress 대비 +3% 이상인 경우")} \nOn Schedule: ${t("Actual Progress가 Plan Progress 대비 -3% ~ +3% 이상인 경우")}\nBehind: ${t("Actual Progress가 Plan Progress 대비 -3% 이하인 경우")}\nCompleted: ${t("Actual Finish가 있는 경우")}\nNot Started: ${t("Actual Start가 없는 경우")}\nIn Progress: ${t("Actual Start는 있지만 Actual Finish가 없는 경우")}`

    //   

    return (
        <>

            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}

                cols={[
                    {
                        width: '9.09%',
                    }, {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    }, {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    },
                    {
                        width: '9.09%',
                    },
                    {
                        width: '5.09%',
                    }, 
                    {
                        width: '4.09%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                  
                <GridLayoutItem col={2} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem' }}>Total</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{c_count + n_count + i_count}</div>
                </GridLayoutItem>

                <GridLayoutItem col={3} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ borderLeft: '1px solid', height: '30px' }}></div>
                </GridLayoutItem>
                <GridLayoutItem col={4} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem' }}>Not Started</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{n_count}</div>
                </GridLayoutItem>
                <GridLayoutItem col={5} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem' }}>In Progress</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{i_count}</div>
                </GridLayoutItem>


                <GridLayoutItem col={6} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem' }}>Completed</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{c_count}</div>
                </GridLayoutItem>

                <GridLayoutItem col={7} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ borderLeft: '1px solid', height: '30px' }}></div>
                </GridLayoutItem>
                <GridLayoutItem col={8} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem',color:"#088208a3" }}>On Schedule</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' ,color:"#088208a3" }}>{o_count}</div>
                </GridLayoutItem>

                <GridLayoutItem col={9} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem', color: '#f44336' }}>Behind</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 'bolder', color: '#f44336' }}>{d_count}</div>
                </GridLayoutItem>


                <GridLayoutItem col={10} row={1} colSpan={1} rowSpan={1} style={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: '0.8rem', color: '#9BB7D4' }}>Ahead</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 'bolder', color: '#9BB7D4' }}>{a_count}</div>
                </GridLayoutItem>


                <GridLayoutItem col={11} row={1} colSpan={1} rowSpan={1}>
                <span title={myString} style={{marginTop:30}} className="k-icon k-font-icon k-i-question"></span>

                </GridLayoutItem>


            </GridLayout>


        </>
    )

}
export default CWPoverview


