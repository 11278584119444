import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import LessonLearnPieChart from "./LessonLearnPieChart";
import LessonLearnBarChart from "./LessonLearnBarChart";
import { Dialog } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import { Button } from "@progress/kendo-react-buttons";
import { useParams } from "react-router-dom";

const { REACT_APP_URI2 } = process.env;

const url = `http://43.200.223.224:5000`;

const LessonLearn = ({ positiondata, blockH, order }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [visible, setVisible] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let ll_application_body = {
        bpname: "LL Application",
        lineitem: "no",
      };

      const fetch_ll_result_body = await axios
        .post(`${url}/getemsbprecord?path=${project_code}`, ll_application_body)
        .then((com) => {
          return com.data.data.data;
        });

      const ll_result_closed = fetch_ll_result_body.filter(
        (c) => c.status === "Closed"
      );

      const ll_result_opened = fetch_ll_result_body.filter(
        (c) => c.status !== "Closed"
      );

      setPieData([
        {
          id: "보류",
          label: "보류",
          value: ll_result_opened.length,
          color: "hsl(121, 70%, 50%)",
        },
        {
          id: "승인",
          label: "승인",
          value: ll_result_closed.length,
          color: "hsl(258, 70%, 50%)",
        },
      ]);

      const uniq_disc = _.uniqBy(fetch_ll_result_body, "SKMHDisc_spd")
        .map((com) => com.SKMHDisc_spd)
        .sort()
        .map((com) => {
          const filter_table_data_arr = fetch_ll_result_body.filter(
            (com2) => com2.SKMHDisc_spd === com
          );

          const obj = {
            disc: com,
            open: filter_table_data_arr.filter((com) => com.status !== "Closed")
              .length,
            closed: filter_table_data_arr.filter(
              (com) => com.status === "Closed"
            ).length,
          };

          return { ...obj };
        });

      setTableData(uniq_disc);
    };
    fetchData();
  }, [project_code]);

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "10%",
          },
          {
            height: "90%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <TitleDiv>
            <Title style={{ fontSize: "16px" }}> LL 적용 현황</Title>
          </TitleDiv>
          <ButtonDiv>
            <CustomButton
              title="블록 온오프"
              iconClass="k-icon k-font-icon k-i-grid"
              onClick={toggleDialog}
            ></CustomButton>
          </ButtonDiv>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1}>
          <LessonLearnPieChart data={pieData} />
          {/* <LessonLearnBarChart height={BoxHeight * 0.9} /> */}
          {visible && (
            <Dialog
              title={"Lessons Learn"}
              onClose={toggleDialog}
              width="1000px"
            >
              <LessonLearnBarChart height={BoxHeight} data={tableData} />
            </Dialog>
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default LessonLearn;
const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
`;
const ButtonDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
`;

const Title = styled.div`
  flex: 1;
  font-family: "Pretendard-B";
  font-size: "16px";
`;

const CustomButton = styled(Button)`
  background-color: #7f84ab;
  border: none;
  box-shadow: none;
  color: white;
`;
