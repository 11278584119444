export const unifier_date_format_transducer = (date) => {
  if (date && date.length >= 10) {
    const date_obj = {
      year: date.slice(6, 10),
      month: date.slice(0, 2),
      day: date.slice(3, 5),
    };

    return new Date(`${date_obj.year}-${date_obj.month}-${date_obj.day}`);
  }

  return null;
};
