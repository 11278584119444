import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import LTIR from '../charts/ltir';
import { generateUpdatedDate } from '../common/datadate';

const LTIR_S = ({positiondata,blockH, order }) => {

    const [lastdate, setChildData] = useState("");
    const [BoxHeight, setBoxHeight] = useState(0);
    const handleChildData = (data) => {
        setChildData(data);
    }

    useEffect(() => {
      const boxHeight = positiondata[order];
      setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
  }, [positiondata, order, blockH]);

return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
   
          {
              height: '100%',
          },

      ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
<div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
          {lastdate && generateUpdatedDate(lastdate.slice(0,10))}
        </div>
<GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
<LTIR order={order} boxHeight={BoxHeight} positiondata={positiondata} blockH={blockH} sendDataToParent={handleChildData}/>
</GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default LTIR_S;
