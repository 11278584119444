import { Milestones } from 'react-milestones-vis';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { generateUpdatedDate } from '../common/datadate';

const {REACT_APP_URI2} = process.env;

const Milestone = ({ positiondata , order, blockH}) => {


    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);

    const { project_code } = useParams();
    const [sourcedata, setdata2] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Project Milestone",
                "lineitem": "no",
                "filter_condition": "status=Active",
            
            }
            const res = await axios.post(`http://43.200.223.224:5000/getemsbprecord?path=${project_code}`, body)
            setdata2(res.data.data.data)

        };

        fetchData();
    }, [project_code]);



    // const data = [
    //     {date:'2017-04-14',title:'착공'},
    //     {date:'2021-01-10',title:'Hauling System'},
    //     {date:'2021-06-22',title:'PPWS Cable/Compaction'},
    //     {date:'2021-07-20',title:'Clamp & Hanger'},
    //     {date:'2021-12-19',title:'Deck Erection & Welding'},
    //     {date:'2022-01-17',title:'조기개통'},
    //     {date:'2022-06-17',title:'준공'},
    //     {date:'2022-08-17',title:''},

    // ]

    const data =[...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
    .map((v) => ({ date: moment(v.plan_date).format('YYYY-MM-DD'), 
    title: v.genMilestoneDesc

    , 
    
    titleStyle: {color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date),'days')) > 0 ? '#A2242F' :v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date),'days')) <= 0?'#00A170': 'black'}, })) 
    ,
    ...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
    .map((v) => ({ date: moment(v.plan_date).format('YYYY-MM-DD'), 
    title: v.genActualDate !== null?
  '(Actual: '+moment(v.genActualDate).format('YYYY-MM-DD')+')'   :""
    , 
  
    textStyle: {
        // 'font-style': 'italic',
        'font-size':'8px'
    
      },
    titleStyle: {color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date),'days')) > 0 ? '#A2242F' :v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date),'days')) <= 0?'#00A170': 'black'}, })) 
    ]


    return (
        <>        
            <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
        {generateUpdatedDate(new Date())}
        </div>

               <div style={{ height: BoxHeight/2 , marginTop: ( BoxHeight) / 2}}>

                {/* <div style={{ height: (0.3 * BoxHeight) / 2 }}> */}
                    <Milestones
                        aggregateBy="day"
                        data={data}
                        mapping={{
                            text: 'title',
                            timestamp: 'date'
                        }}
                        optimize={true}
                        parseTime="%Y-%m-%d"
                        distribution='top-bottom'
                        autoResize={true}
                    />
                {/* </div> */}
                {/* <div style={{ height: (0.1 * BoxHeight) / 2, marginTop: (-0.3 * BoxHeight) / 2 }}>
                    <Milestones
                        aggregateBy="day"
                        data={data}
                        mapping={{
                            text: 'title',
                            timestamp: 'date'
                        }}
                        optimize={true}
                        parseTime="%Y-%m-%d"
                        distribution='bottom'
                        autoResize={true}
                        style={{overflow:'auto'}}

                    />
                </div> */}
            </div>
        </>
    );

};

export default Milestone
