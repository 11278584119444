import * as React from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import styled from "styled-components";

const LessonLearnBarChart = ({ data }) => {
  const SharedTooltip = (props) => {
    const { category, points } = props;
    return (
      <div>
        <div>{category}</div>
        {points.map((point, i) => (
          <div key={i}>
            {point.series.name} : {point.value} 건
          </div>
        ))}
      </div>
    );
  };
  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;
  return (
    <>
      <div
        style={{
          fontFamily: "Pretendard-B",
          fontSize: "16px",
          textAlign: "center",
          paddingTop: "10px",
        }}
      >
        공종별 LL 적용 현황
      </div>
      <ChartDiv>
        <Chart style={{ margin: "50px" }}>
          <ChartTooltip
            shared={true}
            render={sharedTooltipRender}
            background="white"
            fontFamily="Pretendard-R"
          />
          <ChartSeries>
            <ChartSeriesItem
              stack="true"
              type="column"
              data={data.map((item) => item.open)}
              name="보류"
              labels={{
                visible: function (point) {
                  return point.value !== 0;
                },
                position: "center",
                background: "transparent",
                color: "#fff",
              }}
              color="#4472C4"
            />
            <ChartSeriesItem
              type="column"
              data={data.map((item) => item.closed)}
              name="승인"
              labels={{
                visible: function (point) {
                  return point.value !== 0;
                },
                position: "center",
                background: "transparent",
                color: "#fff",
                fontSize: "16px",
              }}
              color="#ED7D31"
            />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={data.map((item) => item.disc)}
                majorGridLines={{ visible: false }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem majorUnit={5} />
            </ChartValueAxis>
          </ChartSeries>
        </Chart>
      </ChartDiv>
    </>
  );
};
export default LessonLearnBarChart;

const ChartDiv = styled.div`
  margin: "50px";
`;
