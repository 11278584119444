import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-react-data-tools";
import _ from "lodash";
import moment from "moment";

const DetailResourcePlanTable = ({ planStaff, currentStaff, height }) => {
  const [tableData, setTableData] = useState([]);
  const [monthsBase, setMonthsBase] = useState([]);
  const [sort, setSort] = useState([{ field: "SKRoleClass2", dir: "asc" }]);

  useEffect(() => {
    if (planStaff.length > 0 && currentStaff.length > 0) {
      const dateSorting = _.sortBy(planStaff, "months");

      const dateRangeSet = {
        min: dateSorting[0].months,
        max: dateSorting[dateSorting.length - 1].months,
      };

      const months_data_base = create_months_data(
        dateRangeSet.min,
        dateRangeSet.max
      );

      const group_data = _(planStaff)
        .groupBy((keys) => {
          return keys.SKRoleClass2 + "|" + keys.SKRoleClass3;
        })
        .map((objs, keys) => {
          const [SKRoleClass2, SKRoleClass3] = keys.split("|");

          const current_staff_arr = currentStaff.filter(
            (com) =>
              com.SKRoleClass2 === SKRoleClass2 &&
              com.SKRoleClass3 === SKRoleClass3
          );

          return {
            SKRoleClass2,
            SKRoleClass3,
            current_staff: current_staff_arr.length,
            data: objs,
          };
        })
        .value();

      const months_data_insert = _.sortBy(group_data, "SKRoleClass2").map(
        (com) => {
          const { data, SKRoleClass2, SKRoleClass3, current_staff } = com;

          const new_obj = { SKRoleClass2, SKRoleClass3, current_staff };

          months_data_base.forEach(
            (com2) =>
              (new_obj[com2.months_moment] = Number(
                _.sumBy(
                  data.filter(
                    (com3) => com3.months_moment === com2.months_moment
                  ),
                  "value"
                ).toFixed(1)
              ))
          );

          const chiceMax = () =>
            months_data_base.reduce((prev, curr) => {
              if (
                curr.months > new Date(moment(new Date()).format("YYYY-MM-01"))
              ) {
                return prev > new_obj[curr.months_moment]
                  ? prev
                  : new_obj[curr.months_moment];
              } else {
                return null;
              }
            }, 0);

          return { ...new_obj, peaknum: chiceMax() };
        }
      );

      setTableData(months_data_insert);
      setMonthsBase(months_data_base);
    }
  }, [planStaff, currentStaff]);

  const totalSumFooterCell = (props) => {
    const { field, className } = props;
    return (
      <td colSpan={props.colSpan} style={props.style} className={className}>
        <div>
          {Number(_.sumBy(tableData, field).toFixed(1)).toLocaleString()}
        </div>
      </td>
    );
  };

  const nomalNumberCell = (props) => {
    const { field, className } = props;
    const value = props.dataItem[field];

    return (
      <td colSpan={props.colSpan} style={props.style} className={className}>
        <div>{value === 0 ? null : value.toLocaleString()}</div>
      </td>
    );
  };

  return (
    <div>
      <Grid
        style={{
          height: height,
        }}
        data={orderBy(tableData, sort)}
        sortable={(true, { mode: "multiple" })}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridColumn
          field="SKRoleClass2"
          title="소속명"
          width="150px"
          locked={true}
        />
        <GridColumn
          field="SKRoleClass3"
          title="직무명"
          width="150px"
          locked={true}
        />
        <GridColumn
          field="current_staff"
          title="현 인원"
          width="70px"
          locked={true}
          cell={nomalNumberCell}
          footerCell={totalSumFooterCell}
        />
        <GridColumn
          field="peaknum"
          title="Peak"
          width="70px"
          locked={true}
          cell={nomalNumberCell}
          footerCell={totalSumFooterCell}
        />
        {monthsBase.map((com, idx) => {
          return (
            <GridColumn
              key={idx}
              field={com.months_moment}
              width="70px"
              cell={nomalNumberCell}
              footerCell={totalSumFooterCell}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default DetailResourcePlanTable;

const create_months_data = (start_date, end_date) => {
  const monthDiff = moment(end_date).diff(moment(start_date), "months");

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    const base_months = moment(start_date)
      .add(i, "months")
      .format("YYYY-MM-01");

    monthRange.push({
      months: new Date(base_months),
      months_moment: moment(new Date(base_months)).format("YYYY-MM"),
    });
  }

  return monthRange;
};
