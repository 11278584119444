import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  GridLayout,
  GridLayoutItem,

} from "@progress/kendo-react-layout";
import axios from 'axios';
import LTIR from '../charts/ltir';
import PTW1 from '../safety/ptw1';
import moment from 'moment';
import { generateUpdatedDate } from '../common/datadate';
import { useTranslation } from 'react-i18next';

const {REACT_APP_URI2} = process.env;

const SafetyAct = ({ positiondata,order, blockH }) => {


  const { t } = useTranslation();


  const { project_code } = useParams();

  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);
  const [data, setdata] = useState([]);


  useEffect(() => {
    const fetchData = async () => {


      let body =
      {
        "bpname": "안전불합리",
        "lineitem": "no",
        "record_fields": "accident_type_pd;safety_type_pd;Subcontractor_dpk",
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
      setdata(res.data.data.data)


    };

    fetchData();
  }, [project_code]);


  const [childData1, setChildData1] = useState("");
  const [childData2, setChildData2] = useState("");

  const lastdate = Number(moment(childData1).format("YYYYMMDD")) >=  Number(moment(childData2).format("YYYYMMDD")) ? childData1:childData2


  const handleChildData = (data) => {
      setChildData1(data);
  }

  const handleChildData2 = (data) => {
    setChildData2(data);
}

  return (

    <GridLayout style={{ height: BoxHeight }}
      rows={[
        {
          height: '10%',
        }, {
          height: '10%',
        },
        {
          height: '80%',
        }


      ]}

      cols={[

        {
          width: '50%',
        },
        
        {
          width: '50%',
        },


      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}>

<div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
        {generateUpdatedDate(lastdate)}
        </div>
      <GridLayoutItem row={1} col={1} colSpan={1} style={{ textAlign: 'center', fontSize: '0.8rem', color: '#363945', marginBottom: '3px', fontWeight: 'bold' }}>
        LTIR
      </GridLayoutItem>


      <GridLayoutItem row={2} col={1} colSpan={1} rowSpan={2} style={{ fontSize: '0.8rem', margin: '0 auto' }}>
        <LTIR boxHeight={BoxHeight} sendDataToParent={handleChildData2} />
      </GridLayoutItem>

      <GridLayoutItem row={1} col={2} colSpan={1} rowSpan={2} style={{ textAlign: 'center', fontSize: '0.8rem', color: '#363945', marginBottom: '3px', fontWeight: 'bold' }}>
        {t("작업허가")}
      </GridLayoutItem>

      <GridLayoutItem row={2} col={2} colSpan={1} rowSpan={2} style={{ fontSize: '0.8rem', margin: '0 auto' }}>
        <PTW1 positiondata={{ col: 1, colSpan: 4, rowSpan: 2, status: true }} sendDataToParent={handleChildData} />
      </GridLayoutItem>


    </GridLayout>
  )


}

export default SafetyAct

