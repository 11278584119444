import krMessages from "./kr.json";
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import {
    Collapse, IconButton, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TableFooter
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {
    Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartCategoryAxis, ChartCategoryAxisItem
} from "@progress/kendo-react-charts";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import { useMediaQuery } from 'react-responsive';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const ProjectDetail = ({ positiondata, safetyddata, mandata2, ltirdata, projectlist, Division, equipdata }) => {

    const { height, width } = useWindowDimensions();
    const [visibleDialog, setVisibleDialog] = useState(true);
    
    const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })
    const [BoxHeight, setBoxHeight] = useState(250);
    const defaultvalue = new Date()
    const [value, setValue] = useState(defaultvalue);

    const equipdata_with = equipdata.map(v => ({ ...v, ...projectlist.filter(item => item.division !== '반도체').find(sp => sp.projectcode === v.projectcode) })).filter(v=>v.projectcode !== 'AWPTEST')

    // console.log(equipdata_with.filter(v=>v.d_reporting_date ==='11-01-2022'))
    // console.log(equipdata_with.filter(v=>v.projectcode ==='22105D'))


    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    useEffect(() => {
        const boxHeight = positiondata[4];
        setBoxHeight(((height-10)/7) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata,height]);



    //전체 dr

    const total_man =mandata2.filter(v=>v.projectcode !== 'AWPTEST')
//   .map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.projectcode) }))



    const chart_data = _.sortBy(total_man.map((v) => ({ ...v, month: v.d_reporting_date.slice(8, 10) + '년 ' + v.d_reporting_date.slice(0, 2) + '월', uniq: v.projectname + '||' + v.d_reporting_date.slice(8, 10) + '년 ' + v.d_reporting_date.slice(0, 2) + '월' })).filter(item => item.month !== '09년 12월' && item.month !== '10년 01월'), 'month')

    const chart_data_group =
        _(chart_data)
            .groupBy('uniq')
            .map((objs, key) => ({
                'project': key.slice(0, key.indexOf("||")),
                'month': key.slice(key.indexOf("||") + 2),
                '인원': _.sumBy(objs, '인원'),
            }))
            .value();


    const man_result = chart_data_group.map(function (obj) {
        var index = obj.project;
        obj[index] = obj.인원;
        return obj;
    });


    var man_result2 = _(man_result)
        .groupBy('month')
        .map(_.spread(_.assign))
        .value();

    var chart_acc =
        _(chart_data)
            .groupBy('month')
            .map((objs, key) => ({
                'month': key,
                '누적인원': _.sumBy(objs, '인원'),
            }))
            .value();

    let cumsum = 0;
    const chart_cum = chart_acc.map(({ month, 누적인원 }) => ({ month, 누적인원, 누적인원cum: cumsum += 누적인원 }));


    const chart_result = man_result2.map(v => ({ ...v, ...chart_cum.find(sp => sp.month === v.month) }))

    const list = _.uniq(total_man.map(v => v.projectname))




    //작업허가현황 과거파일


    const uniq_safety = safetyddata.map((v) => { return { ...v, uniq: v.type + '//' + v.IssueDate + '//' + v.projectcode, workercount:v.workercount === null ? 0: v.workercount} })


    var countbyuniqsafety =
        _(uniq_safety)
            .groupBy('uniq')
            .map((objs, key) => ({
                'uniq': key,
                'count': _.sumBy(objs, 'count'),
                'workercount': _.sumBy(objs, 'workercount'),
                'date': key.split('//')[1],
                'projectcode': key.split('//')[2],
                'type': key.split('//')[0],
            }))
            .value();


    const safety_result = countbyuniqsafety
        .map(v => ({ ...v, ...projectlist.filter(item => item.division !== '반도체').find(sp => sp.projectcode === v.projectcode) }))
        .filter(v => v.projectcode !== 'AWPTEST' )


    var DivisionLTIR =
        _(ltirdata.map((v) => ({ ...v, uniq: v.date.slice(0, 10) + v.SKEcoDivisionSPD })))
            .groupBy('uniq')
            .map((objs, key) => ({
                'uniq': key,
                '부문누적ltir': _.sumBy(objs, 'LTICumulShRatioDA') / _.sumBy(objs, 'CumulTotalMH') * 200000
            }))
            .value();



    const DivisionLTIR_date = DivisionLTIR.map((v) => { return { date: v.uniq.slice(0, 10), division: v.uniq.slice(10, 20), 누적ltir: v.부문누적ltir.toFixed(3) } })




    const handleChange = (event) => {
        setValue(event.target.value);
    };



    const lastdate = moment(value).format("MM-DD-YYYY")

    const cdate = moment(value).startOf('week').format("YYYY-MM-DD")
    const lastdate2 = moment(value).format("YYYY-MM-DD")



    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };


    var daylist = getDaysArray(new Date(cdate), new Date(lastdate2)).map(v => moment(v).format("MM-DD-YYYY"));


    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(false);


        return (
            <React.Fragment>

                <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
                    <TableCell style={{ fontSize: '1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                            value={true}
                            style={{color:'#E4E8ED'}}
                        >
                            {open ? <Remove /> : <Add />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>
                        {row}
                    </TableCell>

                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>
                        {_.filter(total_man, function (o) { return daylist.includes(o.d_reporting_date)  && o.division === row }).length > 0 ?

                            _.sumBy(_.filter(total_man, function (o) { return daylist.includes(o.d_reporting_date) && o.division === row }), '인원').toLocaleString('ko-KR') + '명'
                            : '-'

                        }
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>

                        {_.filter(total_man, function (o) { return o.d_reporting_date === lastdate && o.division === row }).length > 0 ?

                            _.sumBy(_.filter(total_man, function (o) { return o.d_reporting_date === lastdate && o.division === row }), '인원').toLocaleString('ko-KR') + '명'
                            : '-'

                        }
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>

                        {_.filter(safety_result, function (o) { return  daylist.includes(o.date)   && o.division === row }).length > 0 ?

                            _.sumBy(_.filter(safety_result, function (o) { return  daylist.includes(o.date)  && o.division === row && o.type === '특별' }), 'count').toLocaleString('ko-KR') + '건 / ' + _.sumBy(_.filter(safety_result, function (o) { return daylist.includes(o.date)  && o.division === row && o.type === '일반' }), 'count').toLocaleString('ko-KR') + '건'
                            : '-'
                        }
                    </TableCell>

                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>

                        {_.filter(safety_result, function (o) { return o.date === lastdate && o.division === row }).length > 0 ?

                            _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.division === row && o.type === '특별' }), 'count').toLocaleString('ko-KR') + '건 / ' + _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.division === row && o.type === '일반' }), 'count').toLocaleString('ko-KR') + '건'
                            : '-'
                        }
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>

                        {_.filter(safety_result, function (o) { return o.date === lastdate && o.division === row }).length > 0 ?

                            _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.division === row && o.type === '특별' }), 'workercount').toLocaleString('ko-KR') + '명'
                            : '-'
                        }

                    </TableCell>
                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600', color:'#B0B8C2'}}>

                    {_.filter(equipdata_with, function (o) { return o.d_reporting_date === lastdate && o.division === row }).length > 0 ?
                                    
                                    _.sumBy(_.filter(equipdata_with, function (o) { return o.d_reporting_date === lastdate  && o.division === row}), '장비대수').toLocaleString('ko-KR') + '대'
                                    :'-'
                                    }
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: isDPI?'0.8rem':'1rem', border: 0, fontWeight: '600' , color:'#B0B8C2'}}>


                        {_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).length === 0

                            ? '-' :

                            parseFloat(_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) > 0 &&
                                parseFloat(_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) < 0.6
                                ?

                                <span style={{ color: '#FF6F61', fontWeight: '600' }}>{_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)}</span> :

                                parseFloat(_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) > 0.6 &&
                                    parseFloat(_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) < 1
                                    ?

                                    <span style={{ color: '#DD4132', fontWeight: '600' }}>{_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)}</span> :


                                    parseInt(_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)) === 0 ?

                                        <span style={{ color: 'black', fontWeight: '500' }}>{_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)}</span> :

                                        <span style={{ color: '#CD212A', fontWeight: '700' }}>{_.filter(DivisionLTIR_date, function (o) { return o.date === lastdate && o.division === row }).map(v => v.누적ltir)}</span>


                        }

                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>

                            <Table  >
                                <TableBody>
                                    {_.filter(projectlist, { 'division': row }).map((f, index) => (
                                        <TableRow key={index}>
                                            {/* <TableCell width='5%' style={{ borderBottom: 'none', paddingTop: '5px', paddingBottom: '5px' }}></TableCell> */}
                                            <TableCell width='24%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none', paddingLeft: '10%' , color:'#B0B8C2'}}>{f.projectname}</TableCell>

                                            <TableCell width='9%' align="center" style={{ fontSize: '0.8rem', border: 0, paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none' , color:'#B0B8C2'}}>

                                                {_.filter(total_man, function (o) { return daylist.includes(o.d_reporting_date) && o.projectcode === f.projectcode }).length > 0 ?
                                                    _.sumBy(_.filter(total_man, function (o) { return daylist.includes(o.d_reporting_date)  && o.projectcode === f.projectcode }), '인원').toLocaleString('ko-KR') + '명' : '-'}


                                            </TableCell>

                                            <TableCell width='9%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none' , color:'#B0B8C2'}} align="center">

                                                {_.filter(total_man, function (o) { return o.d_reporting_date === lastdate && o.projectcode === f.projectcode }).length > 0 ?
                                                    _.sumBy(_.filter(total_man, function (o) { return o.d_reporting_date === lastdate && o.projectcode === f.projectcode }), '인원').toLocaleString('ko-KR') + '명' : '-'}

                                            </TableCell>
                                            <TableCell width='9%' align="center" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', border: 0, borderBottom: 'none' , color:'#B0B8C2'}}>

                                                {_.filter(safety_result, function (o) { return daylist.includes(o.date)  && o.projectcode === f.projectcode }).length > 0 ?


                                                    _.sumBy(_.filter(safety_result, function (o) { return daylist.includes(o.date)  && o.projectcode === f.projectcode && o.type === '특별' }), 'count').toLocaleString('ko-KR') + '건 / ' + _.sumBy(_.filter(safety_result, function (o) { return daylist.includes(o.date) && o.projectcode === f.projectcode && o.type === '일반' }), 'count').toLocaleString('ko-KR') + '건'
                                                    :
                                                    '-'

                                                }

                                            </TableCell>

                                            <TableCell width='9%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none' , color:'#B0B8C2'}} align="center">

                                                {_.filter(safety_result, function (o) { return o.date === lastdate && o.projectcode === f.projectcode }).length > 0 ?


                                                    _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.projectcode === f.projectcode && o.type === '특별' }), 'count').toLocaleString('ko-KR') + '건 / ' + _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.projectcode === f.projectcode && o.type === '일반' }), 'count').toLocaleString('ko-KR') + '건'
                                                    :
                                                    '-'

                                                }

                                            </TableCell>
                                            <TableCell width='18%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none' , color:'#B0B8C2'}} align="center">
                                                {_.filter(safety_result, function (o) { return o.date === lastdate && o.projectcode === f.projectcode }).length > 0 ?


                                                    _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.projectcode === f.projectcode && o.type === '특별' }), 'workercount').toLocaleString('ko-KR') + '명' 
                                                    // + _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.projectcode === f.projectcode && o.type === '일반' }), 'workercount').toLocaleString('ko-KR') + '명'
                                                    :
                                                    '-'

                                                }
                                            </TableCell>
                                            <TableCell width='16%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none', color:'#B0B8C2' }} align="center">
                                            {_.filter(equipdata_with, function (o) { return o.d_reporting_date === lastdate && o.projectcode === f.projectcode  }).length > 0 ?
                                    
                                    _.sumBy(_.filter(equipdata_with, function (o) { return o.d_reporting_date === lastdate  && o.projectcode === f.projectcode }), '장비대수').toLocaleString('ko-KR') + '대'
                                    :'-'
                                    }
                                            
                                            </TableCell>

                                            <TableCell width='16%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', borderBottom: 'none' , color:'#B0B8C2'}} align="center">

                                                {_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).length === 0

                                                    ? '-' :

                                                    parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) > 0 &&
                                                        parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) < 0.6
                                                        ?

                                                        <span style={{ color: '#FF6F61', fontWeight: '600' }}>{_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))}</span> :

                                                        parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) > 0.6 &&
                                                            parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))) - parseFloat(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate }).map(v => v.전사ltir)[0]) < 1
                                                            ?

                                                            <span style={{ color: '#DD4132', fontWeight: '600' }}>{_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))}</span> :


                                                            parseInt(_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA)) === 0 ?

                                                                <span style={{ color: 'black', fontWeight: '500' }}>{_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))}</span> :

                                                                <span style={{ color: '#CD212A', fontWeight: '700' }}>{_.filter(ltirdata, function (o) { return o.date.slice(0, 10) === lastdate && o.ugenProjectName=== f.projectname }).map(v => v.LTIRCumulDA.toFixed(3))}</span>


                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
    const max = new Date();


    const labelContent = (e) => e.value === 0 || e.value === null || typeof e.value === 'undefined' ? '' : Number.parseFloat(e.value / 10000).toFixed(2) + '만명';
    const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 0.8rem Arial, sans-serif",
        position: 'top',
        background: 'none',
        content: labelContent
    };


    const SharedTooltip = (props) => {
        const { points } = props;
        return (
            <div>
                <div>{points[0].category}</div>
                {points.map((point, index) => (
                    <div key={index}>
                        {point.series.name} : {Number.parseFloat(point.value / 100).toFixed(0) + '백명'}
                    </div>

                ))}
            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const axisCrossingValue = [0, chart_result.length]



    return (
        <>
            <div style={{ position: 'absolute', top: '10px', right: '200px' }}>
                <LocalizationProvider language="ko-KR">
                    <IntlProvider locale="ko-KR">
                        <div className="esg-datepick"><DatePicker style={{color:'#F1F2F5', backgroundColor:'#31363D'}} max={max} format={"yyyy년 MM월 dd일 EEEE"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} /></div>
                    </IntlProvider>
                </LocalizationProvider>
            </div>


            <div>
                <TableContainer style={{ height: isDPI? BoxHeight+20:BoxHeight-16}}>
                    <Table stickyHeader aria-label="collapsible table" size='small' className="proejctdetail" style={{ overflow: 'auto' }}>
                        <TableHead>

                            <TableRow >
                                <TableCell width='4%' style={{ borderBottom: 'none', backgroundColor:'#464B52', color:'#E4E8ED' }} />
                                <TableCell width='20%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none' , backgroundColor:'#464B52'  }}> </TableCell>
                                <TableCell width='18%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} className='hovertext' align="center" onClick={toggleDialog} colSpan={2}>출입현황</TableCell>
                                <TableCell width='18%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center" colSpan={2}>작업현황</TableCell>
                                <TableCell width='18%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">위험작업 인력투입현황</TableCell>
                                <TableCell width='16%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">주요장비 사용현황</TableCell>
                                <TableCell width='16%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">LTIR</TableCell>
                            </TableRow>
                            <TableRow>

                                <TableCell width='4%' style={{ backgroundColor:'#464B52',border:0 , top:30}}/>
                                <TableCell width='20%' style={{ fontSize: '1rem', paddingBottom: '0px', paddingTop: '0px' , backgroundColor:'#464B52' ,border:0, top:30}}> </TableCell>
                                <TableCell width='9%' style={{ fontSize: '0.8rem', paddingBottom: '0px', paddingTop: '0px' , backgroundColor:'#464B52' ,color:'#F1F2F5',border:0, top:30}} align="center">주간누적</TableCell>
                                <TableCell width='9%' style={{ fontSize: '0.8rem', paddingBottom: '0-px', paddingTop: '0px' , backgroundColor:'#464B52' ,color:'#F1F2F5',border:0, top:30}} align="center">금일</TableCell>
                                <TableCell width='9%' style={{ fontSize: '0.8rem', paddingBottom: '0px', paddingTop: '0px', backgroundColor:'#464B52'  ,color:'#F1F2F5',border:0, top:30}} align="center">주간누적<br/>(특별/일반)</TableCell>
                                <TableCell width='9%' style={{ fontSize: '0.8rem', paddingBottom: '0px', paddingTop: '0px' , backgroundColor:'#464B52' ,color:'#F1F2F5',border:0, top:30}} align="center">금일<br/>(특별/일반)</TableCell>
                                <TableCell width='18%' style={{ fontSize: '0.8rem', paddingBottom: '0px', paddingTop: '0px', backgroundColor:'#464B52'  ,color:'#F1F2F5',border:0, top:30}} align="center">(작업허가서 기준)</TableCell>
                                <TableCell width='16%' style={{ fontSize: '0.8rem', paddingBottom: '0px', paddingTop: '0px' , backgroundColor:'#464B52' ,color:'#F1F2F5',border:0, top:30}} align="center"></TableCell>
                                <TableCell width='16%' style={{ fontSize: '1rem', paddingBottom: '0px', paddingTop: '0px' , backgroundColor:'#464B52' ,border:0, top:30}} align="center"></TableCell>

                            </TableRow>


                        </TableHead>
                        <TableBody style={{ border: 0 }} >
                            <TableRow>

                                <TableCell width='4%' style={{border:0, background:'#676D77 0% 0% no-repeat padding-box'}} />
                                <TableCell width='20%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop:isDPI? '10px':'5px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}}> 전 현장 </TableCell>
                                <TableCell width='9%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                    {_.filter(total_man, function (o) { return daylist.includes(o.d_reporting_date) }).length > 0 ?

                                        _.sumBy(_.filter(total_man, function (o) { return daylist.includes(o.d_reporting_date)}), '인원').toLocaleString('ko-KR') + '명'
                                        : '-'

                                    }
                                </TableCell>
                                <TableCell width='9%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                    {_.filter(total_man, function (o) { return o.d_reporting_date === lastdate }).length > 0 ?

                                        _.sumBy(_.filter(total_man, function (o) { return o.d_reporting_date === lastdate }), '인원').toLocaleString('ko-KR') + '명'
                                        : '-'

                                    }
                                </TableCell>
                                <TableCell width='9%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                    {_.filter(safety_result, function (o) { return daylist.includes(o.date)  }).length > 0 ?

                                        _.sumBy(_.filter(safety_result, function (o) { return daylist.includes(o.date) && o.type === '특별' }), 'count').toLocaleString('ko-KR') + '건 / ' + _.sumBy(_.filter(safety_result, function (o) { return daylist.includes(o.date)  && o.type === '일반' }), 'count').toLocaleString('ko-KR') + '건'
                                        : '-'
                                    }
                                </TableCell>
                                <TableCell width='9%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                    {_.filter(safety_result, function (o) { return o.date === lastdate }).length > 0 ?

                                        _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.type === '특별' }), 'count').toLocaleString('ko-KR') + '건 / ' + _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.type === '일반' }), 'count').toLocaleString('ko-KR') + '건'
                                        : '-'
                                    }
                                </TableCell>
                                <TableCell width='18%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                    {_.filter(safety_result, function (o) { return o.date === lastdate }).length > 0 ?

                                        _.sumBy(_.filter(safety_result, function (o) { return o.date === lastdate && o.type === '특별' }), 'workercount').toLocaleString('ko-KR') + '명'
                                        : '-'
                                    }
                                </TableCell>
                                <TableCell width='16%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                    {_.filter(equipdata_with, function (o) { return o.d_reporting_date === lastdate }).length > 0 ?
                                    
                                    _.sumBy(_.filter(equipdata_with, function (o) { return o.d_reporting_date === lastdate }), '장비대수').toLocaleString('ko-KR') + '대'
                                    :'-'
                                    }
                                </TableCell>
                                <TableCell width='16%' style={{border:0, fontSize: isDPI?'0.8rem':'1rem', paddingBottom: '5px', paddingTop: isDPI? '10px':'5px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                {_.filter(ltirdata.map((v)=>({...v, date2:moment(v.date).format("MM-DD-YYYY")})), function (o) { return o.date2 === lastdate })[0] && _.filter(ltirdata.map((v)=>({...v, date2:moment(v.date).format("MM-DD-YYYY")})), function (o) { return o.date2 === lastdate })[0]['전사ltir'] }

                                </TableCell>

                            </TableRow>

                            {_.filter(Division, function (o) { return o.division !== '반도체' }).map((pi, index) => (
                                <Row key={pi.division} row={pi.division} index={index} />
                            ))}
                        </TableBody>
                        <TableFooter>

                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>

            {!visibleDialog && (
                <Dialog title={"SK에코엔지니어링 월간 출력"} onClose={toggleDialog} width={width * 0.8} height={height * 0.5}>
                    <Chart>
                        <ChartArea margin={10} />
                        <ChartSeries>
                            {list.map((row, index) => (
                                <ChartSeriesItem key={index} spacing={0} type="column" data={chart_result} field={row} categoryField="month" name={row} autoFit={true} axis='월간' />
                            ))}
                            <ChartSeriesItem type="line" data={chart_result} field='누적인원cum' categoryField="month" name='누적인원' autoFit={true} labels={seriesLabels} axis='누적' />


                        </ChartSeries>

                        <ChartTooltip shared={true} render={sharedTooltipRender} />


                        <ChartValueAxis>
                            <ChartValueAxisItem majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 100 }} name='월간' />
                            <ChartValueAxisItem majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 100 }} name='누적' />
                        </ChartValueAxis>


                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem labels={{ font: '0.8rem Arial' }} axisCrossingValue={axisCrossingValue} />
                        </ChartCategoryAxis>

                        <ChartLegend position="bottom" orientation="horizontal" labels={{ font: '0.8rem san-serif' }} />


                    </Chart>

                </Dialog>
            )
            }
        </>
    );
}

export default ProjectDetail
