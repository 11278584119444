import React, { useEffect, useState, useRef } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartCategoryAxisItem,
  ChartCategoryAxis,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  exportVisual,
  ChartTitle,
  ChartPlotArea,
} from "@progress/kendo-react-charts";
import moment from "moment";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import "hammerjs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";
import _ from "lodash";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: '#4B5335',
  },
  '&.MuiToggleButton-root': {
      fontSize: '0.7rem',
      fontFamily: 'Pretendard-R',
      padding: 0,
      width: 75,
      borderRadius: '5px 5px 5px 5px!important',
      border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
          marginRight: theme.spacing(1)
      }
  }
}));

const theme = createTheme({
  palette: {
      text: {
          primary: '#00ff00',
      },
  },
});

const today = new Date();

const SCurveChart = ({ height, emsData, emsWeightValue,part }) => {
  const chart = useRef(null);

  const [cardData, setCardData] = useState({
    actualpercent: 0,
    planpercent: 0,
  });

  const [discDataValue, setDiscDataValue] = useState("OVERALL");
  const [typeDataValue, setTypeDataValue] = useState(part === "Engineering"? "E + R":"P");
  const [selectedValue, setSelectedValue] = React.useState("monthly");

  const [chartRange, setChartRange] = useState({ min: "", max: "" });
  const [chartData, setChartData] = useState([]);

  const cutOffData = selectedValue === 'monthly'  ?  

    // _(emsData.filter(v => v.WeekMonthCheck === "W"))
    // .groupBy((v) => moment(v.CUT_OFF_DT).startOf('month').format("YYYY-MM")) // 월별로 그룹화
    // .map((values, key, group) => {
    //     const index = _.keys(group).sort().indexOf(key) + 1; // 월의 인덱스를 계산
    //     const firstDate = moment(values[0].CUT_OFF_DT).startOf('month');
    //     const lastDate = moment(values[0].CUT_OFF_DT).endOf('month');
    //     return {
    //         date: key, // 월을 나타내는 YYYY-MM 형식
    //         desc: `${firstDate.format("YYYY년 MM월")} (${index}M: ${firstDate.format("M/DD")}~${lastDate.format("M/DD")})`
    //     };
    // })
    // .sortBy('date').reverse() // 날짜 기준으로 정렬
    // .value() // lodash chain을 종료하고 결과 값을 추출
    _.sortBy(
      _.uniqBy(emsData.filter(v=>v.WeekMonthCheck === "M"  ), "CUT_OFF_DT").map((v, index)=>({
              date:v.CUT_OFF_DT, desc:moment(v.CUT_OFF_DT).format("YYYY년 MM월")+" ("+Number(Number(index)+1)+"M: " +moment(v.CUT_OFF_DT).add(-1, "month").format("M/DD") +"~"+ moment(v.CUT_OFF_DT).format("M/DD")+")"}))
              , "date").reverse()
    :
    
    _.sortBy(
  _.uniqBy(emsData.filter(v=>v.WeekMonthCheck === "W"  ), "CUT_OFF_DT").map((v, index)=>({
          date:v.CUT_OFF_DT, desc:moment(v.CUT_OFF_DT).format("YYYY년 WW주")+" ("+Number(Number(index)+1)+"W: " +moment(v.CUT_OFF_DT).add(-7, "days").format("M/DD") +"~"+ moment(v.CUT_OFF_DT).format("M/DD")+")"}))
          , "date").reverse();


      //ComboBoxValues
      const discData = _.uniqBy(emsData, "DISC_NM").map(
        (com) => com.DISC_NM
      );

      const typeData = _.uniqBy(emsData, "PROG_TYPE").map(
        (com) => com.PROG_TYPE
      );
      
      if(part === "Engneering"){
        typeData.unshift("E + R");}



  const [cutOffValue, setCutOffValue] = useState([]);
  const [zoomableyn, setzoomableyn] = useState(false);
  const [dateRangeSlider, setDateRangeSlider] = useState({ min: "", max: "" });


  useEffect(() => {
    const fetchData = async () => {


      let disc_filter_data = [];
      let max_actual_data = { date: today };

      if (typeDataValue !== "E + R") {
        const ems_filter_data = emsData
          .filter((com) => com.DISC_NM === discDataValue)
          .filter((com) => com.PROG_TYPE === typeDataValue);

        max_actual_data = _.maxBy(
          [...ems_filter_data].reverse(),
          "ACTUAL_PROG"
        );

        disc_filter_data = ems_filter_data.map((com, idx, arr) => {
          if (
            (idx > 0 && com["ACTUAL_PROG"] === 0) ||
            (idx > 0 && arr[idx - 1]["ACTUAL_PROG"] > com["ACTUAL_PROG"])
          ) {
            const slice_arr = arr.slice(0, idx + 1);
            const max_obj = _.maxBy(slice_arr, "ACTUAL_PROG")["ACTUAL_PROG"];

            const change_com = {
              ...com,
              ACTUAL_PROG: max_obj,
              act_data: 0,
            };

            return { ...change_com };
          } else {
            return { ...com };
          }
        });

      } else {
        const find_weight_value = emsWeightValue.find(
          (com) =>
            com.EPRDiscipline.toUpperCase() === discDataValue.toUpperCase()
        );

        const emsDataSum =  
        emsData
          .filter((com) => com.DISC_NM === discDataValue)
          .filter((com) => com.PROG_TYPE === "E" || com.PROG_TYPE === "R")
        const emsDataSum_factor = 
        _.sortBy(
          emsDataSum.map((com) => {
            return {
              ...com,
              PLAN_PROG_FACTOR_VALUE:
                com.PROG_TYPE === "E"
                  ? com["PLAN_PROG"] * (find_weight_value?.["EDPRRatio"] / 100)
                  : com["PLAN_PROG"] * (find_weight_value?.["RPRRatio"] / 100),
              ACTUAL_PROG_FACTOR_VALUE:
                com.PROG_TYPE === "E"
                  ? com["ACTUAL_PROG"] *
                    (find_weight_value?.["EDPRRatio"] / 100) 
                  : com["ACTUAL_PROG"] *
                    (find_weight_value?.["RPRRatio"] / 100),
            };
          }),
          "date"
        )

        const emsDataSum_factor_e = emsDataSum_factor.filter(
          (com) => com.PROG_TYPE === "E"
        );

        const emsDataSum_factor_r = emsDataSum_factor.filter(
          (com) => com.PROG_TYPE === "R"
        );


        const uniqWeeksData = _.uniqBy(emsDataSum, "CUT_OFF_DT").map((com) => {
          const cut_off_filter = _.uniqBy(
            [...emsDataSum_factor_e, ...emsDataSum_factor_r].filter(
              (com2) => com2.CUT_OFF_DT === com.CUT_OFF_DT
            ),
            "PROG_TYPE"
          );

          return {
            ...com,
            PLAN_PROG: _.sumBy(cut_off_filter, "PLAN_PROG_FACTOR_VALUE"),
            ACTUAL_PROG: _.sumBy(cut_off_filter, "ACTUAL_PROG_FACTOR_VALUE"),
          };
        });

        max_actual_data = _.maxBy([...uniqWeeksData].reverse(), "ACTUAL_PROG");

        disc_filter_data = uniqWeeksData
          .map((com, idx, arr) => {
            if (
              (idx > 0 && com["ACTUAL_PROG"] === 0) ||
              (idx > 0 && arr[idx - 1]["ACTUAL_PROG"] > com["ACTUAL_PROG"])
            ) {
              const slice_arr = arr.slice(0, idx + 1);
              const max_obj = _.maxBy(slice_arr, "ACTUAL_PROG")["ACTUAL_PROG"];

              const change_com = {
                ...com,
                ACTUAL_PROG: max_obj,
                act_data: 0,
              };

              return { ...change_com };
            } else {
              return { ...com };
            }
          })
          .map((com, idx, arr) => {
            if (
              (idx > 0 && com["PLAN_PROG"] === 0) ||
              (idx > 0 && arr[idx - 1]["PLAN_PROG"] > com["PLAN_PROG"])
            ) {
              const slice_arr = arr.slice(0, idx + 1);
              const max_obj = _.maxBy(slice_arr, "PLAN_PROG")["PLAN_PROG"];

              const change_com = {
                ...com,
                PLAN_PROG: max_obj,
                plan_data: 0,
              };

              return { ...change_com };
            } else {
              return { ...com };
            }
          });
      }


      const calculation_data = _.sortBy(
        disc_filter_data
          .map((com, idx) => {
            if (idx === 0) {
              return {
                ...com,
                plan_data: com.PLAN_PROG === null ? 0 : com.PLAN_PROG,
                act_data: com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG,
              };
            } else {
              return {
                ...com,
                plan_data:
                  com.PLAN_PROG === null
                    ? 0
                    : Number(
                        (
                          com.PLAN_PROG - disc_filter_data[idx - 1]["PLAN_PROG"]
                        ).toFixed(2)
                      ),
                act_data:
                  com.ACTUAL_PROG === null
                    ? 0
                    : Number(
                        (
                          com.ACTUAL_PROG -
                          disc_filter_data[idx - 1]["ACTUAL_PROG"]
                        ).toFixed(2)
                      ),
              };
            }
          })
          .map((com) => {
            if (com.date > max_actual_data.date) {
              const delete_com = { ...com };

              delete delete_com.act_data;
              delete delete_com.ACTUAL_PROG;

              return { ...delete_com };
            } else {
              return { ...com };
            }
          }),
        "date"
      );

      const lastData = cutOffValue === null || cutOffValue.length === 0 ? calculation_data:calculation_data.filter((com) => Number(com.CUT_OFF_DT) <= Number(cutOffValue.date))


      if (lastData.length > 0) {
        const max_actual = _.maxBy(
          [...lastData].reverse(),
          "ACTUAL_PROG"
        );

        setCardData({
          actualpercent: max_actual?.["ACTUAL_PROG"] ?? 0,
          planpercent: max_actual?.["PLAN_PROG"] ?? 0,
        });
      } else {
        setCardData({
          actualpercent: 0,
          planpercent: 0,
        });
      }

      setChartData(lastData);
    };

    fetchData();
  }, [discDataValue, typeDataValue, emsData, selectedValue , emsWeightValue, part,cutOffValue]);

  useEffect(() => {
    const date_arr = chartData.map((com) => com.date);

    const minDate = new Date(Math.min(...date_arr));
    const maxDate = new Date(Math.max(...date_arr));

    setChartRange({ min: minDate, max: maxDate });

    setDateRangeSlider({
      min: new Date(moment(minDate).add(-1, "days").format("YYYY-MM-DD")),
      max: new Date(moment(maxDate).add(1, "days").format("YYYY-MM-DD")),
    });
  }, [chartData]);

  const handleDiscComboBox = (e) => {
    if (e.value === null) {
      setDiscDataValue("OVERALL");
    } else {
      setDiscDataValue(e.value);
    }
  };

  const handleTypeComboBox = (e) => {
    if (e.value === null) {
      part =="Engineering"? setTypeDataValue("E + R"):setTypeDataValue("P");
    } else {
      setTypeDataValue(e.value);
    }
  };

  const handleCutOffComboBox = (e) => {
      setCutOffValue(e.value);
  };

  const toggleZoom = () => {
    setzoomableyn(!zoomableyn);
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        {selectedValue === 'monthly'  ? (
          <div>{moment(points[0].category).format("YY년 MM월")}</div>
        ) : (
          <div>
            {moment(points[0].category).format("YY년 MM월") +
              " " +
              Math.ceil(points[0].category.getDate() / 7) +
              "주"}
          </div>
        )}
        {points.map((point, index) => {
          return (
            <div key={index}>
              {point.series.name} : {point.value.toFixed(2)}%
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const labelContentAxis1 = (e) => (e.value > 100 ? "" : e.value + "%");

  const labelContent_0 = (e) => {
    const idx = e.index;

    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : selectedValue === 'monthly' 
      ? moment(new Date(e.value)).format("YY년 MM월")
      : chartData.length <= 50
      ? moment(new Date(e.value)).format("YY년 MM월") +
        "\n" +
        Math.ceil(new Date(e.value).getDate() / 7) +
        "주"
      : idx % 4 === 0
      ? moment(new Date(e.value)).format("YY년 MM월") +
        "\n" +
        Math.ceil(new Date(e.value).getDate() / 7) +
        "주"
      : "";
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.7rem Arial, sans-serif",
    position: "top",
    background: "none",
    // rotation: { angle: "auto" },
    content: labelContent_0,
  };

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chart.current);

    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) =>
        saveAs(dataURI, "EMSSCurvechart.png")
      );
    }
  };

  const handledateRange = (e) => {
    const { start, end } = e.value;

    const customStart = new Date(moment(new Date(start)).format("YYYY-MM-DD"));
    const customEnd = new Date(moment(new Date(end)).format("YYYY-MM-DD"));

    setChartRange({ min: customStart, max: customEnd });
  };


  
  
  const handleChangeChart = (event, selectedValue) => {
    if (selectedValue !== null) {
        setSelectedValue(selectedValue);
    }
};

  return (
    <div className="eMSSCurveChart">
      <GridLayout
        style={{ height:350 }}
        rows={[
          {
            height: "45%",
          },
          {
            height: "15%",
          },
          {
            height: "15%",
          },
          {
            height: "15%",
          },
          {
            height: "10%",
          },
        ]}
        cols={[
          {
            width: "18%",
          },
          {
            width: "82%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <GridLayoutItem col={1} row={1}>
          <Card style={{ width: "100%", height: height * 0.45 }}>
            <CardBody style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "50%",
                  transform: "translate(-50%, -30%)",
                }}
              >
                <CardTitle style={{ textAlign: "center", color: "#363945" }}>
                  Dev.
                </CardTitle>

                <CardTitle
                  style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  <span
                    style={{
                      color:
                        cardData.actualpercent - cardData.planpercent >= 0
                          ? "#00539C"
                          : "#9E1030",
                    }}
                  >
                    {(cardData.actualpercent - cardData.planpercent).toFixed(2)}
                    %
                  </span>
                </CardTitle>
              </div>
              <Tooltip anchorElement="target" position="top">
                <div
                  style={{ position: "absolute", left: "5px", bottom: "5px" }}
                  title="현재일까지 일할 계산된 계획값입니다."
                >
                  <CardSubtitle
                    style={{
                      color: "#939597",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Plan
                  </CardSubtitle>

                  <CardSubtitle
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {cardData.planpercent && cardData.planpercent.toFixed(2)}%
                  </CardSubtitle>
                </div>
              </Tooltip>

              <div
                style={{ position: "absolute", right: "5px", bottom: "5px" }}
              >
                <CardSubtitle
                  style={{
                    textAlign: "center",
                    color: "#939597",
                    fontSize: "1rem",
                  }}
                >
                  Actual
                </CardSubtitle>
                <CardSubtitle
                  style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {cardData.actualpercent && cardData.actualpercent.toFixed(2)}%
                </CardSubtitle>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>

        <GridLayoutItem col={1} row={2} rowSpan={4}>
          <div style={{height:"100%"}}>
            <div style={{ marginLeft: "5px" ,marginTop:10}}>Discipline</div>
            <ComboBox
              style={{
                width: "100%",
                marginRight: "5px",
                marginBottom: "10px",
              }}
              data={discData}
              value={discDataValue}
              onChange={handleDiscComboBox}
              className="disc"
            />
            <div style={{ marginLeft: "5px" }}>Type</div>
            <ComboBox
              style={{
                width: "100%",
                marginRight: "5px",
                marginBottom: "10px",
              }}
              data={typeData}
              value={typeDataValue}
              onChange={handleTypeComboBox}
              className="disc"
            />
            <div style={{ marginLeft: "5px" }}>Cut Off</div>
            <ComboBox
                style={{
                  width: "100%",
                  marginRight: "5px",
                }}
                data={cutOffData}
                textField="desc"
                dataItemKey="date"
                value={cutOffValue}
                onChange={handleCutOffComboBox}
                className="disc"
              />
          </div>
        </GridLayoutItem>

        <GridLayoutItem col={2} row={1} rowSpan={4}>
          <div style={{ position: "absolute", top: "10px", right: "40px" }}>
            <ButtonGroup>
              <Button
                title="확대/이동 켜기"
                iconClass="k-icon k-font-icon k-i-zoom-in"
                themeColor={zoomableyn ? "primary" : null}
                fillMode="flat"
                onClick={toggleZoom}
              ></Button>

              <Button
                title="이미지로 저장"
                iconClass="k-icon k-font-icon k-i-image-export"
                fillMode="flat"
                onClick={onImageExportClick}
              ></Button>
            </ButtonGroup>
          </div>


          <div style={{ position: 'absolute', right: '12px', zIndex: 9999999, display: 'flex', alignItems: 'center' }} className='manRB'>
                    <ThemeProvider theme={theme}>
                        <ToggleButtonGroup size="small" onChange={handleChangeChart} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="weekly" aria-label="weekly">
                            weekly
                        </ToggleButton>
                        <ToggleButton value="monthly" aria-label="monthly">
                            monthly
                        </ToggleButton>
                        </ToggleButtonGroup>

                    </ThemeProvider>
          </div>
          <Chart
            ref={chart}
            pannable={zoomableyn}
            zoomable={
              zoomableyn
                ? {
                    mousewheel: {
                      lock: "y",
                    },
                    selection: {
                      lock: "y",
                    },
                  }
                : zoomableyn
            }
          >
            <ChartTitle
              text={
                discDataValue === null
                  ? part +" Progress S-Curve (All)"
                  : part + " Progress S-Curve (" + discDataValue + ")"
              }
              font="1rem pretendard-R"
            />
            <ChartArea height={335} margin={{bottom:20}} />
            <ChartPlotArea
              border={{ width: 0, color: "#ffffff", dashType: "dot" }}
              padding={2000}
            />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                min={chartRange.min}
                max={chartRange.max}
                labels={seriesLabels_0}
                baseUnit={selectedValue === 'monthly' ?  "months":"weeks" }
                axisCrossingValue={[0, chartData.length]}
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                name="월간"
                visible={false}
                line={{ visible: false }}
                majorTicks={{ step: 100 }}
                minorTicks={{ step: 100 }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
              />
              <ChartValueAxisItem
                name="누적(%)"
                min={0}
                labels={{
                  content: labelContentAxis1,
                  font: "0.7rem Arial, pretendard-R",
                }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
                visible={true}
                border="none"
              />
            </ChartValueAxis>
            <ChartTooltip shared={true} render={sharedTooltipRender} />
            <ChartSeries>
              <ChartSeriesItem
                color="#DBF3FF"
                type="column"
                data={chartData}
                field="plan_data"
                categoryField="date"
                name={!selectedValue === 'monthly' ? "주간 계획" : "월간 계획"}
                autoFit={true}
                axis="월간"
                aggregate="sum"
              />
              <ChartSeriesItem
                color="#42B7F4"
                type="column"
                data={chartData}
                field="act_data"
                categoryField="date"
                name={!selectedValue === 'monthly' ? "주간 실적" : "월간 실적"}
                autoFit={true}
                axis="월간"
                aggregate="sum"
              />

              <ChartSeriesItem
                color="#B1CCE4"
                type="line"
                data={chartData}
                field="PLAN_PROG"
                categoryField="date"
                name="누적 계획"
                autoFit={true}
                axis="누적(%)"
              />

              <ChartSeriesItem
                color="#5E8EFF"
                type="line"
                data={chartData}
                field="ACTUAL_PROG"
                categoryField="date"
                name="누적 실적"
                autoFit={true}
                axis="누적(%)"
              />
            </ChartSeries>
          </Chart>
        </GridLayoutItem>
        <GridLayoutItem col={2} row={5}>
          {chartRange.min !== "" &&
            chartRange.max !== "" &&
            dateRangeSlider.min !== "" &&
            dateRangeSlider.max !== "" && (
              <RangeSlider
                min={dateRangeSlider.min.getTime()}
                max={dateRangeSlider.max.getTime()}
                value={{
                  start: chartRange.min.getTime(),
                  end: chartRange.max.getTime(),
                }}
                onChange={(e) => handledateRange(e)}
                style={{
                  width: "100%",
                  fontSize: "10px",
                }}
              >
                {_.uniqBy(chartData, "month_date").map((com, idx, arr) => {
                  if (idx !== 0 && idx !== arr.length - 1) {
                    return (
                      <SliderLabel
                        key={idx}
                        position={new Date(`${com.month_date}-01`).getTime()}
                      >
                        {moment(com.date).format("YY/MM")}
                      </SliderLabel>
                    );
                  } else {
                    return null;
                  }
                })}
              </RangeSlider>
            )}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default SCurveChart;
