import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { ListBox } from "@progress/kendo-react-listbox";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import "./CWP.css";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridPro } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import CWPoverview from "./actall";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: `1px solid ${theme.palette.divider}`,
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 26,
        borderRadius: 2,
      },
      value: {
        position: "absolute",
        lineHeight: "24px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
      bar: {
        height: "100%",
        "&.low": {
          backgroundColor: "#f44336",
        },
        "&.medium": {
          backgroundColor: "#088208a3",
        },
        "&.high": {
          backgroundColor: "#9BB7D4",
        },
      },
    }),
  { defaultTheme }
);

const ProgressBar = React.memo(function ProgressBar(props) {
  const value = props.value.value !== undefined ? props.value.value : { value: "N/A", status: "none" };
  const valueInPercent = isNaN(value.value) ? "N/A" : value.value * 100;
  const valueInPercenttext = isNaN(value.value) ? "N/A" : `${valueInPercent.toFixed(1)} %`

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>{valueInPercenttext}</div>
      <div
        className={clsx(classes.bar, {
          low: value.status === "low",
          medium: value.status === "medium",
          high: value.status === "high",
        })}
        style={{ maxWidth: `${valueInPercent}%` }}
      />
    </div>
  );
});

function renderProgress(params) {
  return <ProgressBar value={(params)} />;
}



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const ActStatus = ({ datafrom, progressSource, value1, value2, value3, value4, setvalue1, setvalue2, setvalue3, setvalue4 }) => {
  const [datafrom2, setdatafrom2] = useState([]);
  const { height, width } = useWindowDimensions();
  const { t, i18n } = useTranslation();


  useEffect(() => {
    const formatDate = (dateStr) => {
      if (!dateStr) return null;
      return `${dateStr.slice(6, 10)}-${dateStr.slice(0, 5)}`;
    };

    const mapBasedOnProgressSource = (v) => {
      if (progressSource === '작업일보 기반') {
        return {
          ...v,
          d_evm_plan_start: formatDate(v.d_evm_plan_start),
          d_evm_plan_finish: formatDate(v.d_evm_plan_finish),
          d_evm_actual_start: formatDate(v.d_evm_actual_start),
          d_evm_actual_finish: formatDate(v.d_evm_actual_finish),
        };
      }

      return {
        ...v,
        d_evm_actual_start: v.ManualActualStart !== undefined ? formatDate(v.ManualActualStart) : formatDate(v.d_evm_actual_start),
        d_evm_actual_finish: v.ManualActualFinish !== undefined ? formatDate(v.ManualActualFinish) : formatDate(v.d_evm_actual_finish),
        d_actual_progress: v.ManualActualProgress !== undefined ? v.ManualActualProgress : v.d_actual_progress,
        d_evm_plan_start: formatDate(v.d_evm_plan_start),
        d_evm_plan_finish: formatDate(v.d_evm_plan_finish),
      };
    };

    const processedData = datafrom.map(mapBasedOnProgressSource);
    setdatafrom2(processedData);

  }, [progressSource, datafrom]);



  var datawithstatus1 = _(
    datafrom2
      .filter((f) => f.Lv_1_Name !== null)
      .map((v) => ({
        ...v, uniq: v.Lv_1_Name,
        actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      }))
  )
    .groupBy("uniq")
    .map((objs, key) => ({
      uniq: key,
      level: key,
      Dev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100,
    }))
    .value();

  var datawithstatus2 = _(
    datafrom2
      .filter((f) => f.Lv_2_Name !== null)
      .map((v) => ({
        ...v, uniq: v.Lv_1_Name + '//' + v.Lv_2_Name,
        actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      }))
  )
    .groupBy("uniq")
    .map((objs, key) => ({
      uniq: key,
      level1: key.split('//')[0],
      level2: key.split('//')[1],
      Dev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100,
    }))
    .value();




  var datawithstatus3 = _(
    datafrom2
      .filter((f) => f.Lv_3_Name !== null)
      .map((v) => ({
        ...v, uniq: v.Lv_1_Name + '//' + v.Lv_2_Name + '//' + v.Lv_3_Name,
        actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      }))
  )
    .groupBy("uniq")
    .map((objs, key) => ({
      uniq: key,
      level1: key.split('//')[0],
      level2: key.split('//')[1],
      level3: key.split('//')[2],
      Dev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100,
    }))
    .value();



  var datawithstatus4 = _(
    datafrom2
      .filter((f) => f.Lv_3_Name !== null)
      .map((v) => ({
        ...v,
        actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      }))
  )
    .groupBy("Lv_4_Name")
    .map((objs, key) => ({
      level: key,
      uniq: key,
      Dev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "d_planned_value") / _.sumBy(objs, "d_weight_value")) *
        100,
    }))
    .value();


  const new_level2 = datawithstatus2.filter(v => v.level1 === value1).map((v) => ({ ...v, level: v.level2 }))


  const new_level3 = datawithstatus3.filter(v => v.level1 === value1 && v.level2 === value2).map((v) => ({ ...v, level: v.level3 }))


  const level1 = datawithstatus1;


  const handleItemClick1 = (e) => {
    setvalue1(e.dataItem.level);
  };
  const handleItemClick2 = (e) => {
    setvalue2(e.dataItem.level);
  };
  const handleItemClick3 = (e) => {
    setvalue3(e.dataItem.level);
  };

  const handleItemClick4 = (e) => {
    setvalue4(e.dataItem.level);
  };


  const selectedValue = [value1, value1 + '//' + value2, value1 + '//' + value2 + '//' + value3, value4];
  const datafrom3 = datafrom2.map((v) => ({ ...v, dev: parseFloat(v.d_actual_progress) - parseFloat(v.d_plan_progress) }))

  const [tabledata, settabledata] = useState(datafrom3);


  const planpercent = ((_.sumBy(tabledata, "d_planned_value") / _.sumBy(tabledata, "d_weight_value")))*100

  const actualpercent = (_.sumBy(tabledata.map((v) => ({ d_actual_value: v.d_weight_value *  v.d_actual_qty/v.d_forecast_qty})), "d_actual_value") / _.sumBy(tabledata, "d_weight_value"))*100


  function filterData(data, values) {
    const filterObj = {};
    ['Lv_1_Name', 'Lv_2_Name', 'Lv_3_Name', 'Lv_4_Name'].forEach((key, i) => {
      if (values[`value${i + 1}`] !== "") {
        filterObj[key] = values[`value${i + 1}`];
      }
    });

    return data.filter(item => {
      return Object.keys(filterObj).every(key => item[key] === filterObj[key]);
    });
  }



  useEffect(() => {

    const values = {
      value1: value1,
      value2: value2,
      value3: value3,
      value4: value4
    };

    settabledata(
      filterData(datafrom2, values)
    );
  }, [datafrom2, value1, value2, value3, value4]);



  const [sort, setSort] = useState([{ field: 'uuu_P6ActivityId', dir: "asc" }]);

  const MyCustomItem = (props) => {
    let { dataItem, ...others } = props;


    return (
      <>
        <li
          {...others}
          style={{
            backgroundColor: selectedValue.includes(props.dataItem.uniq)
              ? "#B0B5DC"
              : "none",
          }}
        >
          <div style={{ textAlign: "left" }}>
            <span
              style={{
                color:
                  props.dataItem.Dev > 0
                    ? "#00539C"
                    : (props.dataItem.Dev) === 0
                      ? "black"
                      : "#D51818",
              }}
            >
              {props.dataItem.level}
            </span>
          </div>
        </li>
      </>
    );
  };

  const toggleDialog = () => {
    settabledata(datafrom2);
    setvalue1("");
    setvalue2("");
    setvalue3("");
    setvalue4("");
  };

  const toggleDialogL4 = () => {
    settabledata(datafrom2);
    setvalue4("");
  };

  const toggleDialogL3 = () => {
    settabledata(datafrom2);
    setvalue3("");
    setvalue4("");

  };

  const toggleDialogL2 = () => {
    setvalue2("");
    setvalue3("");
    setvalue4("");
  };

  const toggleDialogL1 = () => {
    setvalue1("");
    setvalue2("");
    setvalue3("");
    setvalue4("");
  };


  var isNumber = function isNumber(value) {
    return typeof value === "number" && isFinite(value);
  };

  var isNumberObject = function isNumberObject(n) {
    return Object.prototype.toString.apply(n) === "[object Number]";
  };

  var isCustomNumber = function isCustomNumber(n) {
    return isNumber(n) || isNumberObject(n);
  };

  const datecell = (props) => {
    const field = props.field || "";
    const variance =
      parseFloat(props.dataItem.d_actual_progress) -
      parseFloat(props.dataItem.d_plan_progress);
    const value = props.dataItem[field];

    return (
      <td
        style={{
          color:
            variance >= 3 ? "#00539C" : variance <= -3 ? "#D51818" : "black",
          font: "normal normal normal 0.8rem Pretendard",
          textAlign: "center",
        }}
      >
        {value === null ? "" : value}
      </td>
    );
  };

  const gridcell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const status = props.dataItem.d_activity_status;

    const variance =
      parseFloat(props.dataItem.d_actual_progress) -
      parseFloat(props.dataItem.d_plan_progress);

    const fieldName1 = field !== "uuu_P6ActivityId";
    const fieldName2 = field !== "uuu_P6ActivityName";

    return (
      <td
        style={{
          font: "normal normal normal 0.8rem Pretendard",
          textAlign:
            (fieldName1 && fieldName2) || props.title === "차이"
              ? "center"
              : "left",
          color:
            variance >= 3 ? "#00539C" : variance <= -3 ? "#D51818" : "black",
          font: "normal normal normal 0.8rem Pretendard",
        }}
      >
        {isCustomNumber(value) ? value.toFixed(2) + "%" : value}
      </td>
    );
  };


  const gridcell2 = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const status = props.dataItem.d_activity_status;

    const variance =
      parseFloat(props.dataItem.d_actual_progress) -
      parseFloat(props.dataItem.d_plan_progress);

    const fieldName1 = field !== "uuu_P6ActivityId";
    const fieldName2 = field !== "uuu_P6ActivityName";

    return (
      <td
        style={{
          font: "normal normal normal 0.8rem Pretendard",
          textAlign:
            (fieldName1 && fieldName2) || props.title === "차이"
              ? "center"
              : "left",
          color:
            variance >= 3 ? "#00539C" : variance <= -3 ? "#D51818" : "black",
          font: "normal normal normal 0.8rem Pretendard",
        }}
      >
        {isCustomNumber(value) ? value.toFixed(2) : value}
      </td>
    );
  };



  const headerCell = (props) => {
    const { field, title } = props;


    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          font: "normal normal normal 0.8rem Pretendard",
        }}
      >
        <p style={{ margin: "0" }} >
          {title}
        </p>
      </div>
    );

  };
  function getActivityStatus(value1, value2, value3, value4) {
    const values = [value1, value2, value3, value4].filter(v => v !== "");
    if (values.length === 0) {
      return (
        <span>
          Activity Status ({tabledata.length}{t("개")}){" "}
        </span>
      );
    } else {
      return (
        <span>
          ({values.join('-')}) Activity Status ({tabledata.length}개){" "}
        </span>
      );
    }
  }

  const table_title = getActivityStatus(value1, value2, value3, value4);

  const determineGroupingKey = (item, value1, value2) => {
    if (value1 && !value2) {
      return item.Lv_2_Name; // value1이 있고 value2가 없을 때 Lv_2_Name으로 그룹화
    } else if (value1 && value2) {
      return item.Lv_3_Name; // value1과 value2 둘 다 있을 때 Lv_3_Name으로 그룹화
    }
    return item.Lv_1_Name; // 기본적으로 Lv_1_Name으로 그룹화
  };

  const processData = (dataArray, value1, value2) => {
    const groupedData = dataArray.reduce((acc, item) => {
      const groupName = determineGroupingKey(item, value1, value2);
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);
      return acc;
    }, {});

    return Object.entries(groupedData).map(([groupName, items]) => {
      const lv4Names = [...new Set(items.map(item => item.Lv_4_Name))];
      const result = { groupName };

      lv4Names.forEach(lv4Name => {
        const filteredItems = items.filter(item => item.Lv_4_Name === lv4Name);
        const totalWeight = filteredItems.reduce((sum, item) => sum + item.d_weight_value, 0);
        const weightedProgress = filteredItems.reduce((sum, item) => sum + (item.d_weight_value * item.d_actual_progress / 100), 0);
        const PlanProgress = filteredItems.reduce((sum, item) => sum + (item.d_weight_value * item.d_plan_progress / 100), 0);

        const actualProgressRatio = totalWeight > 0 ? weightedProgress / totalWeight : 0;
        const planProgressRatio = totalWeight > 0 ? PlanProgress / totalWeight : 0;
        const progressDifference = actualProgressRatio - planProgressRatio;

        let status = "medium";
        if (progressDifference >= 0.03) {
          status = "high";
        } else if (progressDifference <= -0.03) {
          status = "low";
        }

        result[lv4Name] = {
          value: actualProgressRatio,
          status: status
        };
      });

      return result;
    });
  };

  const processedData = processData(tabledata, value1, value2);


  // 고유 키를 찾는 함수
  const findUniqueKeys = (data) => {
    const keys = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        keys.add(key);
      });
    });
    keys.delete('groupName'); // 'groupName'을 제외
    return Array.from(keys);
  };

  // 칼럼 객체를 생성하는 함수
  const createColumns = (keys, totalWidth) => {
    const widthPerColumn = (width - 550) / keys.length; // groupName 열의 너비를 뺀 후 나눔
    return keys.map(key => ({
      field: key,
      headerName: key,
      renderCell: renderProgress, // renderProgress는 여러분의 렌더링 함수입니다
      type: 'number',
      width: widthPerColumn,
    }));
  };

  const uniqueKeys = findUniqueKeys(processedData);

  const columns = [
    {
      field: "groupName",
      headerName: "",
      width: 250
    }, ...createColumns(uniqueKeys)

  ];

  const rows = processedData.map((v, index) => ({ ...v, id: index }))



  return (
    <div>
      <GridLayout
        style={{ height: height }}
        rows={[
          {
            height: "25%",
          },
          {
            height: "30%",
          },
          {
            height: "20%",
          },
          {
            height: "25%",
          },
        ]}
        cols={[
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "24.3%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            zIndex: "100",
          }}
        >
          <Button
            title={t("초기화")}
            iconClass="k-icon k-font-icon k-i-reload-sm"
            fillMode="flat"
            onClick={toggleDialog}
          ></Button>
        </div>

        <GridLayoutItem
          col={1}
          row={1}
          colSpan={1}
          rowSpan={1}
          style={{ textAlign: "center" }}
        >
          <Card style={{ width: '100%', height: height * 0.24 }}>
            <CardBody style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -30%)' }}>
                <CardTitle style={{ textAlign: 'center', color: '#363945' }}>Dev.</CardTitle>

                <CardTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>

                  {planpercent && actualpercent && actualpercent - planpercent > 0 ?
                    <span style={{ color: '#00539C' }}>{(actualpercent - planpercent).toFixed(2)}</span> :
                    <span style={{ color: '#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>}%</CardTitle>
              </div>
              <Tooltip anchorElement="target" position="top">
                <div style={{ position: 'absolute', left: '16px', bottom: '16px' }} title="">

                  <CardSubtitle style={{ color: '#939597', fontSize: '1rem', textAlign: 'center' }}>Plan</CardSubtitle>

                  <CardSubtitle style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>  {planpercent && planpercent.toFixed(2)}%</CardSubtitle>
                </div>
              </Tooltip>

              <div style={{ position: 'absolute', right: '16px', bottom: '16px' }}>
                <CardSubtitle style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>Actual</CardSubtitle>
                <CardSubtitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>{actualpercent && actualpercent.toFixed(2)}%</CardSubtitle>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>

        <GridLayoutItem
          col={2}
          row={1}
          colSpan={1}
          rowSpan={1}
          style={{ textAlign: "center", position: "relative" }}
        >
          <div style={{ textAlign: "left", fontWeight: "600" }}>CWA (WBS Lv.1)
            <Button
              title={t("레벨1 초기화")}
              iconClass="k-icon k-font-icon k-i-reload-sm"
              fillMode="flat"
              onClick={toggleDialogL1}
            // style={{ position: "absolute", right: "0", top: "-5px" }}
            ></Button>
          </div>

          <ListBox
            className="cwpList"
            data={level1}
            textField="level"
            style={{ width: "100%", height:height*0.2 }}
            name="Level 1"
            item={MyCustomItem}
            onItemClick={(e) => handleItemClick1(e)}
          />
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          row={1}
          colSpan={1}
          rowSpan={1}
          style={{ textAlign: "center", position: "relative" }}
        >
          <div style={{ textAlign: "left", fontWeight: "600" }}>Sub-CWA1 (WBS Lv.2)
            <Button
              title={t("레벨2 초기화")}
              iconClass="k-icon k-font-icon k-i-reload-sm"
              fillMode="flat"
              onClick={toggleDialogL2}
            // style={{ position: "absolute", right: "0", top: "-5px" }}
            ></Button>
          </div>

          <ListBox
            className="cwpList"
            data={_.sortBy(new_level2, 'level2')}
            textField="level"
            style={{ width: "100%", height:height*0.2 }}
            name="Level 2"
            item={MyCustomItem}
            onItemClick={(e) => handleItemClick2(e)}
          />
        </GridLayoutItem>
        <GridLayoutItem
          col={4}
          row={1}
          colSpan={1}
          rowSpan={1}
          style={{ textAlign: "center", position: "relative" }}
        >
          <div style={{ textAlign: "left", fontWeight: "600" }}>Sub-CWA2 (WBS Lv.3)
            <Button
              title={t("레벨3 초기화")}
              iconClass="k-icon k-font-icon k-i-reload-sm"
              fillMode="flat"
              onClick={toggleDialogL3}
            // style={{ position: "absolute", right: "0", top: "-5px" }}
            ></Button>
          </div>

          <ListBox
            className="cwpList"
            data={_.sortBy(new_level3, 'level3')}
            textField="level"
            style={{ width: "100%", height:height*0.2 }}
            name="Level 3"
            item={MyCustomItem}
            onItemClick={(e) => handleItemClick3(e)}
          />
        </GridLayoutItem>
        

        <GridLayoutItem
          col={1}
          row={2}
          colSpan={4}
          rowSpan={1}
          style={{ textAlign: "center" }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex', // flex 레이아웃 사용
              flexDirection: 'column', // 세로 방향 배치
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                justifyContent: 'center',
                alignItems: 'center',
              },
              // '& .MuiDataGrid-columnHeader': {
              //   padding: '0px 4px', // 헤더 셀의 패딩 조정
              // },
              // '& .MuiDataGrid-cell': {
              //   padding: '0px 4px', // 데이터 셀의 패딩 조정
              // },
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
              {/* 세 개의 div */}
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#9BB7D4',
                width: '80px',
                height: '25px',
                marginRight: '8px',
                color: "snow",
                fontWeight: "bold",
                fontSize: 12
              }}>Ahead</div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#088208a3',
                width: '80px',
                height: '25px',
                marginRight: '8px',
                color: "snow",
                fontWeight: "bold",
                fontSize: 12
              }}>On-Schedule</div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#f44336',
                width: '80px',
                height: '25px',
                color: "snow",
                fontWeight: "bold",
                fontSize: 12
              }}>Behind</div>
            </div>

            <DataGridPro density="compact" hideFooter={true} columns={columns} rows={rows} />
          </Box>
        </GridLayoutItem>

        <GridLayoutItem
          col={1}
          row={3}
          colSpan={4}
          rowSpan={1}
          style={{ textAlign: "center" }}>
          <CWPoverview BoxHeight={height * 0.2} datafrom={datafrom} value1={value1} value2={value2} value3={value3} value4={value4} />,
        </GridLayoutItem>

        <GridLayoutItem
          col={1}
          row={4}
          colSpan={4}
          rowSpan={1}
          style={{ textAlign: "center" }}>
          <div style={{ fontWeight: "600" }}>
            {
              table_title
            }
          </div>

          <Grid
            // data={tabledata}
            data={orderBy(tabledata, sort)}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            sortable={{ mode: "multiple", allowUnsort: true }}
            style={{ width: "100%", height: height * 0.75 / 2 }}
            className="activityCWP"
          >
            <GridColumn title="Activity" headerCell={headerCell}>
              <GridColumn
                field="uuu_P6ActivityId"
                title="ID"
                cell={gridcell}
              />
              <GridColumn
                field="uuu_P6ActivityName"
                title="Name"
                cell={gridcell}
              />
            </GridColumn>

            <GridColumn title={t("진도율")} headerCell={headerCell}>
              <GridColumn
                field="d_plan_progress"
                title={t("계획(%)")}
                cell={gridcell}
              />
              <GridColumn
                field="d_actual_progress"
                title={t("실적(%)")}
                cell={gridcell}
              />
              <GridColumn
                field="dev"
                title={t("차이(%)")}
                cell={gridcell}
              />
            </GridColumn>

            <GridColumn title={t("대표물량")} headerCell={headerCell}>
              <GridColumn
                field="d_sep_uom_spd"
                title={t("단위")}
                cell={gridcell}
              />
              <GridColumn
                field="d_forecast_qty"
                title={t("예상물량")}
                cell={gridcell2}
              />
              <GridColumn
                field="d_actual_qty"
                title={t("실적물량")}
                cell={gridcell2}
              />
              <GridColumn
                field="d_remaining_qty"
                title={t("잔여물량")}
                cell={gridcell2}
              />
            </GridColumn>

            <GridColumn title={t("계획일")} headerCell={headerCell}>
              <GridColumn
                field="d_evm_plan_start"
                title={t("시작")}
                cell={datecell}
              />
              <GridColumn
                field="d_evm_plan_finish"
                title={t("종료")}
                cell={datecell}
              />
            </GridColumn>

            <GridColumn title={t("실적일")} headerCell={headerCell}>
              <GridColumn
                field="d_evm_actual_start"
                title={t("시작")}
                cell={datecell}
              />
              <GridColumn
                field="d_evm_actual_finish"
                title={t("종료")}
                cell={datecell}
              />
            </GridColumn>
          </Grid>
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
export default ActStatus;
