import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE, 
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from "moment";

import _ from "lodash";

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const AreaofConcern = ({ positiondata  , order, blockH}) => {
  const params = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [monthlyTableData, setMonthlyTableData] = useState([]);
  const [weeklyTableData, setWeeklyTableData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dialogData, setDialogData] = useState({
    path: "",
    files: [],
  });
  const [selected, setSelected] = useState(false);
  const [sort, setSort] = useState([{}]);
  const findParentNode = useRef();

  useEffect(() => {
    const addClassName = " areaofConcernBody";
    const parentNode = findParentNode.current.parentElement;
    parentNode.className = parentNode.className + addClassName;
  }, []);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await axios.get(
        `http://43.200.223.224:5000/getAreaofConCern/${params.project_code}`, 
      );

      const todayMonth = {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      };

      const { unifierData } = fetchData.data.data;

      const monthlyData = unifierData
        .map((com) => {
          return {
            ...com,
            monthly: {
              year: new Date(com.genWeeklyReportDO).getFullYear(),
              month: new Date(com.genWeeklyReportDO).getMonth(),
            },
          };
        })
        .filter((com) => com._bp_lineitems !== undefined)
        .filter((com) => {
          return (
            com.monthly.year === todayMonth.year &&
            com.monthly.month === todayMonth.month
          );
        });

      const monthlyBaseTableData = [];

      monthlyData.forEach((com) => {
        com._bp_lineitems.forEach((com2) => {
          monthlyBaseTableData.push({
            genDisciplineText: com2.genDisciplineText,
            genAoCMTL: com2.genAoCMTL.replaceAll("\\n", "<br />"),
            li_num: com2.li_num,
            d_reporting_date: new Date(com.genWeeklyReportDO),
          });
        });
      });

      const monthlyBaseTableDataSort = _.sortBy(
        monthlyBaseTableData,
        "genDisciplineText"
      );

      const uniqDataTable = _.uniqBy(
        monthlyBaseTableDataSort,
        "genDisciplineText"
      ).map((com, idx) => {
        return { ...com, id: idx + 1 };
      });

      const getFileList = async (filename) => {
        const pathName1 = moment(new Date(filename.d_reporting_date)).format(
          "YY년MM월DD일"
        );

        const config = {
          
          method: "GET",
          url: `http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/AreasofConcern/${params.project_code}/${pathName1}/lineitem_0_${filename.li_num}`,
        };

        const fetchData = await axios(config);

        return fetchData.data.fileList;
      };

      const monthlyTableDataFinal2 = [];

      for (const monthlyBaseTableData of monthlyBaseTableDataSort) {
        monthlyTableDataFinal2.push({
          ...monthlyBaseTableData,
          id: uniqDataTable.find(
            (com2) =>
              com2.genDisciplineText === monthlyBaseTableData.genDisciplineText
          )["id"],
          fileList: await getFileList(monthlyBaseTableData),
        });
      }

      // const monthlyTableDataFinal = monthlyBaseTableDataSort.map((com) => {
      //   return {
      //     ...com,
      //     id: uniqDataTable.find(
      //       (com2) => com2.genDisciplineText === com.genDisciplineText
      //     )["id"],
      //     fileList: getFileList(com),
      //   };
      // });

      setMonthlyTableData(monthlyTableDataFinal2);

      const weeklyUnifierDataSort = _.sortBy(
        unifierData.map((com) => {
          return { ...com, genWeeklyReportDO: new Date(com.genWeeklyReportDO) };
        }).filter((com) => com._bp_lineitems !== undefined),
        "genWeeklyReportDO"
      );

      const weeklyUnifierDataSortArr = weeklyUnifierDataSort.reverse()[0];

      // const weeklyBaseTableData = [];

      // if (weeklyUnifierDataSortArr._bp_lineitems !== undefined) {
      //   weeklyUnifierDataSortArr._bp_lineitems.forEach((com2, idx) => {
      //     weeklyBaseTableData.push({
      //       id: idx + 1,
      //       genDisciplineText: com2.genDisciplineText,
      //       genAoCMTL: com2.genAoCMTL.replaceAll("\\n", "<br />"),
      //       li_num: com2.li_num,
      //       d_reporting_date: new Date(
      //         weeklyUnifierDataSortArr.genWeeklyReportDO
      //       ),
      //       fileList: getFileList(com2),
      //     });
      //   });
      // }

      const weeklyBaseTableData2 = [];
      if (weeklyUnifierDataSortArr && weeklyUnifierDataSortArr._bp_lineitems !== undefined) {
        for (
          let i = 0;
          i < weeklyUnifierDataSortArr._bp_lineitems.length;
          i++
        ) {
          weeklyBaseTableData2.push({
            id: i + 1,
            genDisciplineText:
              weeklyUnifierDataSortArr._bp_lineitems[i].genDisciplineText,
            genAoCMTL: weeklyUnifierDataSortArr._bp_lineitems[
              i
            ].genAoCMTL.replaceAll("\\n", "<br />"),
            li_num: weeklyUnifierDataSortArr._bp_lineitems[i].li_num,
            d_reporting_date: new Date(
              weeklyUnifierDataSortArr.genWeeklyReportDO
            ),
            fileList: await getFileList(
              weeklyUnifierDataSortArr._bp_lineitems[i]
            ),
          });
        }
      }

      setWeeklyTableData(weeklyBaseTableData2);
    };

    fetchData();
  }, [params]);

  const handleDownloadInfo = async (filename) => {
    const pathName1 = moment(new Date(filename.d_reporting_date)).format(
      "YY년MM월DD일"
    );

    const config = {
      
      method: "GET",
      url: `http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/AreasofConcern/${params.project_code}/${pathName1}/lineitem_0_${filename.li_num}`,
    };

    const fetchData = await axios(config);

    setDialogData({
      path: `${params.project_code}/${pathName1}/lineitem_0_${filename.li_num}`,
      files: fetchData.data.fileList,
    });
    setVisible(!visible);
  };

  const toggleDialog = () => {
    setVisible(!visible);
    setDialogData([]);
  };

  const goDownload = async (fileName) => {
    const config = {
      method: "GET",
      
      url: `http://43.200.223.224:5000/getAreaofConCern/download/${fileName}?path=${dialogData.path}`,
      responseType: "blob",
    };

    const fetchData = await axios(config);

    const name = fileName;
    const downloadurl = window.URL.createObjectURL(new Blob([fetchData.data]));
    const link = document.createElement("a");

    link.href = downloadurl;

    link.setAttribute("download", name);
    link.style.cssText = "display:none";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  /**
   *  다운로드 Cell Component
   * @param {*} props
   * @returns
   */
  const CustomCell = (props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const tableDataFilter = monthlyTableData.filter(
      (com) => com.genDisciplineText === props.dataItem.genDisciplineText
    );

    const tableFindIndex = tableDataFilter.findIndex(
      (com) => com === props.dataItem
    );

    return (
      <>
        <td
          style={{
            border:
              !selected &&
              tableFindIndex !== tableDataFilter.length - 1 &&
              "none",
          }}
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.dataItem.fileList.length !== 0 && (
              <p
                className="k-icon k-font-icon k-i-save k-i-floppy"
                onClick={() => handleDownloadInfo(props.dataItem)}
                style={{ margin: "5px 0px" }}
              ></p>
            )}
          </div>
        </td>
      </>
    );
  };

  /**
   *  Areas of Concern Cell Component
   * @param {*} props
   * @returns
   */
  const CustomCell2 = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const tableDataFilter = monthlyTableData.filter(
      (com) => com.genDisciplineText === props.dataItem.genDisciplineText
    );

    const tableFindIndex = tableDataFilter.findIndex(
      (com) => com === props.dataItem
    );

    function createMarkup(text) {
      return { __html: text };
    }

    function MyComponent(text) {
      return (
        <div
          style={{ lineHeight: "20px" }}
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      );
    }

    return (
      <td
        style={{
          border:
            !selected &&
            tableFindIndex !== tableDataFilter.length - 1 &&
            "none",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ margin: "5px 0px" }}>{MyComponent(value)}</div>
      </td>
    );
  };

  /**
   *  Date of Concern Cell Component
   * @param {*} props
   * @returns
   */
  const CustomCell3 = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const tableDataFilter = monthlyTableData.filter(
      (com) => com.genDisciplineText === props.dataItem.genDisciplineText
    );

    const tableFindIndex = tableDataFilter.findIndex(
      (com) => com === props.dataItem
    );

    return (
      <td
        style={{
          border:
            !selected &&
            tableFindIndex !== tableDataFilter.length - 1 &&
            "none",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "5px 0px" }}>
            {moment(new Date(value)).format("YYYY년 MM월 DD일")}
          </p>
        </div>
      </td>
    );
  };

  const CustomCell4 = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ margin: "5px " }}>{value}.</div>
      </td>
    );
  };

  const cellRender = (cell, props) => {
    if (props.field === "genDisciplineText") {
      const filterRow = monthlyTableData.filter(
        (com) => com.genDisciplineText === props.dataItem[props.field]
      );

      const filterRowSort = _.sortBy(filterRow, "d_reporting_date");

      if (
        props.dataItem.d_reporting_date === filterRowSort[0].d_reporting_date
      ) {
        return (
          <td
            rowSpan={filterRowSort.length}
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            {props.dataItem[props.field]}
          </td>
        );
      } else {
        return null;
      }
    } else if (props.field === "id") {
      const filterRow = monthlyTableData.filter(
        (com) => com.id === props.dataItem[props.field]
      );

      const filterRowSort = _.sortBy(filterRow, "d_reporting_date");

      if (
        props.dataItem.d_reporting_date === filterRowSort[0].d_reporting_date
      ) {
        return (
          <td
            rowSpan={filterRowSort.length}
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            <div style={{ paddingLeft: "5px" }}>
              {props.dataItem[props.field]}.
            </div>
          </td>
        );
      } else {
        return null;
      }
    } else {
      return cell;
    }
  };

  const CustomHeaderCell = (props) => {
    const { field, title } = props;

    const handleSort = (field) => {
      if (title === "No." || title === "Project Management") {
        const findSortIndex = sort.findIndex((e) => e.field === field);

        const findSort = sort.find((e) => e.field === field);

        if (findSort === undefined) {
          if (sort[0]["field"] === undefined) {
            sort.push({ field: field, dir: "asc" });
            setSort([...sort]);
          }
        } else {
          if (findSort["dir"] === "asc") {
            sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
            setSort([...sort]);
          } else if (findSort["dir"] === "desc") {
            sort.splice(findSortIndex, 1);
            setSort([...sort]);
          }
        }
      }
    };

    const hadleSortArrow = (field) => {
      const findSort = sort.find((e) => e.field === field);

      return findSort !== undefined ? findSort["dir"] : false;
    };

    return (
      <div
        className="customHeaderCell"
        style={{
          cursor:
            (title === "No." || title === "Project Management") && "pointer",
        }}
        onClick={() => {
          handleSort(field);
        }}
      >
        <div className="customHeaderCell_t">
          {props.title}{" "}
          <div
            className={
              hadleSortArrow(field) === "asc"
                ? "k-icon k-font-icon k-i-sort-asc-sm"
                : hadleSortArrow(field) === "desc"
                ? "k-icon k-font-icon k-i-sort-desc-sm"
                : ""
            }
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div ref={findParentNode}>
      <div className="concernSwitch">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={selected}
                onChange={() => setSelected(!selected)}
              />
            }
            label={
              selected ? (
                <div className="concernSwitchDetail">Weekly</div>
              ) : (
                <div className="concernSwitchDetail">Monthly</div>
              )
            }
          />
        </FormGroup>
      </div>
      <Grid
        className="areasofConcren"
        data={
          selected
            ? orderBy(weeklyTableData, sort)
            : orderBy(monthlyTableData, sort)
        }
        cellRender={!selected && cellRender}
        style={{ maxHeight: BoxHeight }}
        sortable={{
          allowUnsort: false,
          mode: "multiple",
        }}
        sort={sort}
      >
        <GridColumn
          field="id"
          title="No."
          cell={selected && CustomCell4}
          headerCell={CustomHeaderCell}
        />
        <GridColumn
          field="genDisciplineText"
          title="Discipline"
          headerCell={CustomHeaderCell}
        />
        <GridColumn
          field="d_reporting_date"
          title="Date"
          cell={CustomCell3}
          headerCell={CustomHeaderCell}
        />
        <GridColumn
          field="genAoCMTL"
          title="Areas of Concern"
          cell={CustomCell2}
          headerCell={CustomHeaderCell}
        />
        <GridColumn
          field=""
          title="Att"
          cell={CustomCell}
          headerCell={CustomHeaderCell}
        />
      </Grid>
      {visible && (
        <Dialog title={"File Downloader"} onClose={toggleDialog}>
        {dialogData.files.length > 0 ? (
          dialogData.files.map((com, idx) => {
            return (
              <p
                key={idx}
                style={{
                  margin: "25px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => goDownload(com.name)}
              >
                {com.name}
              </p>
            );
          })
        ) : (
          <p
            style={{
              margin: "25px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            첨부파일 없음
          </p>
        )}
      </Dialog>
      )}
    </div>
  );
};

export default AreaofConcern;
