import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { Dialog } from "@progress/kendo-react-dialogs";
import { ResponsivePie } from '@nivo/pie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';
import {selectedValueStateMan, selectedValueStateManPeriod, selectedChartMonth, selectedChartWeek} from '../atom/selectedValue'

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Man1 = ({ positiondata, data11, dailyreport, order, blockH }) => {
    const [BoxHeight, setBoxHeight] = useState(250);
    const { t, i18n } = useTranslation();

    const selectedValueManVendor = useRecoilValue(selectedValueStateMan);
    const selectedValueManp = useRecoilValue(selectedValueStateManPeriod);

    const chartWeek = useRecoilValue(selectedChartWeek);
    const chartMonth = useRecoilValue(selectedChartMonth);


    console.log(chartWeek)

    const ToggleButton = styled(MuiToggleButton)(() => ({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: '#4B5335',
        },
        '&.MuiToggleButton-root': {
            fontSize: '0.7rem',
            fontFamily: 'Pretendard-R',
            padding: 0,
            width: i18n.language === "ko"? 50:90,
            borderRadius: '5px 5px 5px 5px!important',
            border: '1px solid rgba(0, 0, 0, 0.12)!important'
    
        }
    }));
    

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});


    const { width } = useWindowDimensions();
    const { project_code } = useParams();

    const [data, setdata] = useState([]);

    const kr_currnet_time = moment(new Date()).tz("Asia/Seoul").subtract(5, 'day').format("MM-DD-YYYY");
    const kr_currnet_time_a = moment(new Date()).tz("Asia/Seoul").add(1, 'day').format("MM-DD-YYYY HH:mm:ss");

    const [selectedValue, setSelectedValue] = React.useState("직종");
  
    // const handleChange = React.useCallback(
    //     (e) => {
    //         setSelectedValue(e.value);
    //     },
    //     [setSelectedValue]
    // );
    const handleChange = (event, selectedValue) => {
      if (selectedValue !== null) {
        setSelectedValue(selectedValue);
      }
    };
  

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Daily Manpower Input",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {

                            "field": "d_reporting_date",
                            "value": kr_currnet_time,
                            "value2": kr_currnet_time_a,
                            "condition_type": "range"
                        },

                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
            setdata(res.data.data.data.map((v) => ({
                ...v, date: v.d_reporting_date.slice(6, 10) + '년 ' + v.d_reporting_date.slice(0, 2) + '월 ' + v.d_reporting_date.slice(3, 5) + '일'
                , job_title: v.d_dash_LIBS_JOBTITLE === null || typeof v.d_dash_LIBS_JOBTITLE === 'undefined' ? v.j_jobtitle_bpk : v.d_dash_LIBS_JOBTITLE
            })))

        };

        fetchData();
    }, [project_code, kr_currnet_time, kr_currnet_time_a]);

    useEffect(() => {
        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, order, blockH]);

    const linedata1 = _.filter(_.uniqBy(data11, 'record_no'), function (o) { return typeof o._bp_lineitems !== 'undefined'&&  o.d_reporting_date !== null && o.d_company_name !== null; }).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { d_reporting_date: v.d_reporting_date, d_company_name: v.d_company_name, d_subcon_discipline_spd:v.d_subcon_discipline_spd }))), [])
        .filter(f => f.uuu_tab_id === 'Manpower(Picker)' || f.uuu_tab_id === 'Manpower(Optional)' || f.uuu_tab_id === '투입인력');

    const linedata2 = _.filter(_.uniqBy(dailyreport, 'record_no'), function (o) { return typeof o._bp_lineitems !== 'undefined'&&  o.d_reporting_date !== null && o.d_company_name !== null; }).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { d_reporting_date: v.d_reporting_date, d_company_name: v.d_company_name, d_subcon_discipline_spd:v.d_subcon_discipline_spd  }))), [])
        .filter(f => f.uuu_tab_id === 'Manpower(Picker)' || f.uuu_tab_id === 'Manpower(Optional)' || f.uuu_tab_id === '투입인력');

    const linedata = _.sortBy([...linedata1, ...linedata2].map((v) => ({
        ...v,
        date: v.d_reporting_date.slice(6, 10) + '년 ' + v.d_reporting_date.slice(0, 2) + '월 ' + v.d_reporting_date.slice(3, 5) + '일'
    })), 'date').reverse().map((f) => ({ ...f, job_title: f.d_dash_LIBS_JOBTITLE === null || typeof f.d_dash_LIBS_JOBTITLE === 'undefined' ? f.j_jobtitle_bpk : f.d_dash_LIBS_JOBTITLE }))


    // const lastdate = _.sortBy([...linedata,...data].map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy([...linedata,...data].map((v)=>({date:v.date})),'date').reverse()[0]['date']

    // const lastdate = _.uniqBy(_.sortBy([...linedata,...data].map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy([...linedata,...data].map((v)=>({date:v.date})),'date').reverse(), "date")?.[1]?.['date']
    // const lastdate = moment(new Date()).add(-1, 'days').format('YYYY년 MM월 DD일')
    const today = moment(new Date()).format("YYYY년 MM월 DD일");

    const filteredData =selectedValueManVendor ? [...linedata, ...data].filter(v=>v.d_company_name === selectedValueManVendor.vendor)
    .map((v)=>({...v, week:moment(new Date(v.d_reporting_date.slice(0,10))).add(-1,'days').format("YY년 MM월") + " " + Math.ceil(new Date(moment(v.d_reporting_date.slice(0,10)).add(-1,'days')).getDate() / 7) + "주", month:moment(v.d_reporting_date).format("YY년 MM월")}))

    : 
    [...linedata, ...data]
    .map((v)=>({...v, week:moment(new Date(v.d_reporting_date.slice(0,10))).add(-1,'days').format("YY년 MM월") + " " + Math.ceil(new Date(moment(v.d_reporting_date.slice(0,10)).add(-1,'days')).getDate() / 7) + "주", month:moment(v.d_reporting_date).format("YY년 MM월")}))

    const lastdate = 
    _.sortBy(filteredData.map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy(filteredData.map((v)=>({date:v.date})),'date').reverse()[0]['date'] === today ? _.uniqBy(_.sortBy(filteredData.map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy(filteredData.map((v)=>({date:v.date})),'date').reverse(), "date")?.[1]?.['date'] :  _.sortBy(filteredData.map((v)=>({date:v.date})),'date').reverse()[0] && _.sortBy(filteredData.map((v)=>({date:v.date})),'date').reverse()[0]['date']

    const criteriaData = selectedValueManp === "weekly"? filteredData.filter(v=>v.week === chartWeek): 
    selectedValueManp === "monthly"?filteredData.filter(v=>v.month === chartMonth):
    _.filter(filteredData, { date: lastdate })


    const current = selectedValueManp === "weekly"? chartWeek:selectedValueManp === "monthly"?chartMonth:lastdate&&generateUpdatedDate(lastdate.slice(0,4)+"-"+lastdate.slice(6,8)+"-"+lastdate.slice(10,12))

    const tabledata =
        _(criteriaData)
            .groupBy('d_company_name')
            .map((objs, key) => ({
                'd_company_name': key,
                'count': _.sumBy(objs.filter(v => typeof v.d_day_work !== 'undefined'), 'd_day_work') + _.sumBy(objs.filter(v => typeof v.d_today_total !== 'undefined'), 'd_today_total'),
            }))
            .value()


    const chartdata =
    _( criteriaData)
        .groupBy('job_title')
            .map((objs, key) => ({
                'id': key,
                'value': _.sumBy(objs.filter(v => typeof v.d_day_work !== 'undefined'), 'd_day_work') + _.sumBy(objs.filter(v => typeof v.d_today_total !== 'undefined'), 'd_today_total'),

            }))
            .value().filter(v => v.value !== 0);




    const chartdata2 =
        _(criteriaData)
        .groupBy('d_subcon_discipline_spd')
            .map((objs, key) => ({
                'id': key,
                'value': _.sumBy(objs.filter(v => typeof v.d_day_work !== 'undefined'), 'd_day_work') + _.sumBy(objs.filter(v => typeof v.d_today_total !== 'undefined'), 'd_today_total'),

            }))
            .value().filter(v => v.value !== 0);


    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const [sort, setSort] = useState([{}]);



    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
   
                    {
                        height: '20px',
                    },
                       
                    {
                        height: "calc(100% - 20px)",
                    }
                ]}

                cols={[
                    {
                        width: '100%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

        <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
          {current}
        </div>
        <div style={{ position: "absolute", top: "60px",left: "15px",zIndex: "100",color:'black' ,fontSize:"0.7rem"}}>
          {selectedValueManVendor && selectedValueManVendor.vendor}
        </div>
        
        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
        <div style={{ position: "absolute", right: "5px", top: "0px", zIndex: 999999 , width: i18n.language ==="ko"? 100:180}}>
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                                <ToggleButton value="공종" aria-label= {t("공종")} >
                                {t("공종")}
                                </ToggleButton>
                                <ToggleButton value="직종" aria-label={t("직종")}>
                                {t("직종")}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ThemeProvider>
                    </div>
        </GridLayoutItem>

                <GridLayoutItem row={2} col={1} style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '999' }}><span style={{ fontSize: _.sumBy(tabledata, 'count').length > 3 ? '2rem' : '2.5rem' }} className='hovertext' onClick={toggleDialog}>{_.sumBy(tabledata, 'count')}</span></div>

                    <ResponsivePie
                        // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                        colors={{ scheme: 'blues' }}
                        className='pie'
                        data={selectedValue === '직종'?chartdata:chartdata2}
                        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
                        valueFormat=" >-"
                        cornerRadius={2}
                        innerRadius={0.7}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabel={d => (
                            <React.Fragment>
                                <tspan style={{ fontSize: 10 }} x="0" dy="-0.6em">{d.id}</tspan>
                                <tspan style={{ fontSize: 10 }} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(chartdata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                            </React.Fragment>
                        )}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextOffset={5}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={5}
                        arcLinkLabelsStraightLength={10}
                        arcLinkLabelsColor={{ from: 'color' }}
                        enableArcLabels={true}
                        arcLabel={function (e) { return e.value }}
                        arcLabelsRadiusOffset={0.5}
                        arcLabelsSkipAngle={0}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}

                        legends={[]}
                    />
                </GridLayoutItem>


            </GridLayout>



            {!visibleDialog && (
                <Dialog title={t("인원상세현황")} onClose={toggleDialog} width='40%' height= {tabledata.length >10? "600px":"auto"}>
                <Grid

                    data={orderBy(tabledata, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    className='mancount'
                    style={{height:"100%", overflowY:'auto', overflowX:'hidden'}}


                >

                        <GridColumn field="d_company_name" title={t("업체명")} />
                        <GridColumn field="count" title={t("출력 인원")} />

                    </Grid>
                </Dialog>
            )}
        </>)


}

export default Man1
