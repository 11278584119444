import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import _ from "lodash";
import {
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./ProjectInfo.scss";

const { REACT_APP_URI2 } = process.env;

const url = `http://43.200.223.224:5000`;

const ProjectInfo = ({ positiondata, blockH, order }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [data, setdata] = useState([]);
  const [start, setStart] = useState([]);
  const [end, setEnd] = useState([]);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let project_info_body = {
        bpname: "프로젝트 개요",
        lineitem: "yes",
      };

      const res = await axios.post(
        `${url}/getemsbprecord?path=${project_code}`,
        project_info_body
      );

      setdata(
        _.sortBy(
          res.data.data.data.filter((v) => v.status === "Active"),
          "record_no"
        ).reverse()[0]
      );

      let engineering_progress_body = {
        bpname: "Engineering Progress",
        lineitem: "no",
        record_fields: "CUT_OFF_DT;PJT_CD;",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "PJT_CD",
              value: project_code,
              condition_type: "eq",
            },
          ],
        },
      };

      const fetch_engineering_progress_body = _.sortBy(
        await axios
          .post(
            `${url}/getemsbprecord?path=SKEC0001`,
            engineering_progress_body
          )
          .then((com) => com.data.data.data),
        "CUT_OFF_DT"
      );

      setStart(
        fetch_engineering_progress_body.length > 0
          ? fetch_engineering_progress_body[0]?.CUT_OFF_DT
          : null
      );
      setEnd(
        fetch_engineering_progress_body.length > 0
          ? fetch_engineering_progress_body[
              fetch_engineering_progress_body.length - 1
            ]?.CUT_OFF_DT
          : null
      );
    };

    fetchData();
  }, [project_code]);

  const startFormatted =
    start && end
      ? `${start.slice(2, 4)}년 ${start.slice(4, 6)}월 ${start.slice(6, 8)}일`
      : "-";

  const endFormatted =
    start && end
      ? `${end.slice(2, 4)}년 ${end.slice(4, 6)}월 ${end.slice(6, 8)}일`
      : "-";

  const dataFields = [
    {
      label: "Project Name",
      value: data && data.ugenProjectName,
    },
    {
      label: "Location",
      value: data && data.address,
    },
    {
      label: "Contract Type",
      value: data && data.genContractType,
    },
    {
      label: "Client",
      value: data && data.genClient,
    },
    {
      label: "Progress",
      value:
        startFormatted === "-" || endFormatted === "-"
          ? ""
          : `${startFormatted} ~ ${endFormatted}`,
      tooltipValue: `${startFormatted} ~ ${endFormatted}`,
    },
  ];

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "white",
            color: "#707070",
            border: "1px solid #dadde9",
            fontSize: "14px",
            fontFamily: "Pretendard-R",
            // whiteSpace: "nowrap",
          },
        },
      },
    },
  });

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <List
            dense={true}
            style={{ width: "90%", margin: "0 auto", height: BoxHeight }}
          >
            {dataFields.map((field, index) => (
              <ListItem
                key={index}
                style={{
                  borderBottom: "1px solid rgb(112, 112, 112, 0.2)",
                  paddingLeft: "0",
                }}
              >
                <ListItemText
                  className="listleft"
                  style={{
                    width: "40%",
                    color: "#707070",
                    fontFamily: "Pretendard-B",
                  }}
                >
                  {field.label}
                </ListItemText>
                <ThemeProvider theme={theme}>
                  <Tooltip title={field.value} placement="right">
                    <ListItemText
                      className="listright"
                      style={{ width: "60%" }}
                    >
                      {field.value || "-"}
                    </ListItemText>
                  </Tooltip>
                </ThemeProvider>
              </ListItem>
            ))}
          </List>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ProjectInfo;
