import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  DataGridPremium, gridClasses, GridToolbar, GridToolbarExport, GridToolbarContainer, GridToolbarQuickFilter,
  useGridApiRef, GridEditSingleSelectCell, GridActionsCellItem, useGridApiContext, GRID_ROOT_GROUP_ID
} from '@mui/x-data-grid-premium';
import { useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import _ from 'lodash';
import axios from "axios";
import { Circles } from 'react-loader-spinner';
import { useParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import JSZip from 'jszip';
import MyDocumentViewerModal from '../viewer/modal';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import { logout } from '../../common/logout';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import WarningIcon from '@mui/icons-material/Warning';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import MyDocumentViewerModalDR from '../viewer/modalforDR';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { darken, lighten, styled } from '@mui/material/styles';
import { getSessionUsername } from '../../common/username';

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .tree-table-group': {
    backgroundColor: theme.palette.grey[50],
  }

}));

const ClientTable = ({ options, tableData }) => {

  const { project_code } = useParams();
  const [open, setOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documenTitle, setDocumenTitle] = useState('');
  const [docuID, setdocuID] = useState('');
  const [username, setUsername] = useState(null)
  const apiRef = useGridApiRef();
  const [rows, setRows] = React.useState(tableData.map((v, index) => ({ ...v, id: index})));
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);


  useEffect(() => {
    const fetchUsername = async () => {
      const name = await getSessionUsername();
      setUsername(name);
    };

    fetchUsername();
  }, []);



  const handleLogoutClick = async () => {
    try {

      logout({
        username: username,
        project: project_code,
        client: false
      });

    } catch (error) {
      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };



  const [selectedRow, setSelectedRow] = React.useState({});


  useEffect(() => {
    setRows(_.sortBy(tableData.map((v, index) => ({
      ...v,
      id: index,
    })), "ReceivedDate").reverse())
  }, [tableData])



  const handleDownloadDocument = async (fileId) => {
    try {
      const response = await axios.get(`http://43.200.223.224:5000/download/${fileId}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.zip'); // 여기서 다운로드할 파일 이름 지정
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the document:', error);
    }
  };



  const handleBulkDownload = async (selectedIds) => {
    const selectedRows = rows.filter(row => selectedIds.includes(row.id));
    const finalZip = new JSZip();
  
    try {
      for (const row of selectedRows) {
        console.log(row)
        const response = await axios.get(`http://43.200.223.224:5000/download/${row.file_id}`, {
          responseType: 'blob',
        });
  
        const zipFile = await JSZip.loadAsync(response.data);
        console.log(zipFile)
        zipFile.forEach((relativePath, file) => {
          finalZip.file(`${row.file_name}/${relativePath}`, file.async("blob"));
        });
      }
  
      const zipBlob = await finalZip.generateAsync({ type: 'blob' });
      const downloadUrl = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Documents.zip'); // Name of the final zip file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading or zipping the documents:', error);
      Swal.fire({
        icon: 'error',
        title: 'Download Failed',
        text: 'Failed to download the documents. Please try again.',
        confirmButtonText: 'OK',
      });
    }
  };

  const columns = [
    {
      field: 'No',
      headerName: 'TR No.', width: 150,
      renderHeader: () => (
        <strong>
          {'TR No.'} <br /> {'(Doc. No.)'}
        </strong>
      ),
    },


    {
      field: 'title',
      headerName: 'TR Title (Doc. Title)', width: 350,
      renderHeader: () => (
        <strong>
          {'TR Title'} <br /> {'(Doc. Title)'}
        </strong>
      ),
    },


    {
      field: 'ReceivedDate',
      headerName: 'Recevied Date',
      type: 'date',
      width: 150,
    },
    {
      field: 'DueDate',
      headerName: 'Due Date',
      type: 'date',
      width: 150,
    },
    {
      field: 'DecisionBPK',
      headerName: 'Review Outcome',
      editable: true,
      type: 'singleSelect',
      valueOptions: options,
      width: 150,
      renderEditCell: (params) => {
        if (params.row.hierarchy.length === 1) {
          // hierarchy.length가 1인 경우, 편집 불가능한 상태를 표시
          return <span></span>;
        } else {
          // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
          return (
            <GridEditSingleSelectCell
              {...params}
              options={options}
              value={params.value}
              onChange={(event) => {
                event.stopPropagation(); // 여기에 추가
                params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
              }}
            />
          );
        }
      },
    },

    {
      field: 'RevNo',
      headerName: 'Rev. No.',
      width: 80,
    },

    {
      field: 'stageName',
      headerName: 'Issue Purpose (Stage Name)',
      renderHeader: () => (
        <strong>
          {'Issue Purpose'} <br /> {'(Stage Name)'}
        </strong>
      ),
      width: 200,
    },
    {
      field: 'viewDocument',
      headerName: 'Comment',
      width: 130,
      renderCell: (params) => {
        if (params.row.file_id) {
          return (
            <>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Comment"
                onClick={() => handleOpenDocumentViewer(params.row.file_id)}
              />
              <GridActionsCellItem
                icon={<CloudDownloadIcon />}
                label="Download"
                onClick={() => handleDownloadDocument(params.row.file_id)}
              />
            </>
          );
        } else {
          return (
            <span></span>
          );
        }
      },
    },

    {
      field: 'SubmittedDate',
      headerName: 'Submitted Date',
      type: 'date',
      width: 150,
    },
    {
      field: 'Submittedby',
      headerName: 'Submitted by',
      width: 100,
    }, {
      field: 'stepStatus',
      headerName: 'Step Status',
      width: 120,
      renderCell: (params) => {
        // hierarchy.length가 1 이상인 경우
        if (params.row.hierarchy.length > 1) {
          // 'Submitted' 상태 처리
          if (params.row.SubmittedDate) {
            return <span>{params.row.status}</span>;
          }
          // 'Overdue' 상태 처리
          else if (dayjs().isAfter(dayjs(params.row.DueDate))) {
            return <Chip variant="outlined" sx={{ color: "red", fontSize: "smaller" }} {...getChipProps({ value: 'Overdue' })} />;
          }
          // 다른 상태 ('RED' 또는 'BLUE') 처리
          else {
            // 여기에서 params.value 대신 실제 값을 사용합니다.
            // 예시로 'RED' 또는 'BLUE'를 직접 지정합니다.
            // 실제 구현에서는 params.row의 적절한 속성을 참조해야 할 수 있습니다.
            return <span>{params.row.status}</span>;
          }
        }
        // hierarchy.length가 1 미만인 경우, 빈 문자열 또는 기본값 반환
        return '';
      }
    }
  ];


  const handleOpenDocumentViewer = async (fileId) => {
    try {
      const response = await axios.get(`http://43.200.223.224:5000/download/${fileId}`,
        {
          responseType: 'blob'
        }

      );

      // ZIP 파일 압축 해제
      const jszip = new JSZip();
      const zipContent = await jszip.loadAsync(response.data); // ZIP 파일 로드

      // 첫 번째 파일의 내용을 얻음
      const fileNames = Object.keys(zipContent.files);
      if (fileNames.length === 0) {
        throw new Error('ZIP file is empty');
      }

      const firstFileName = fileNames[0];
      const firstFile = zipContent.files[firstFileName];
      const fileData = await firstFile.async('blob');


      // 파일 이름을 상태로 설정
      setDocumenTitle(firstFileName);
      setdocuID(fileId)
      // Blob URL 생성 및 상태 설정
      setDocumentUrl(URL.createObjectURL(fileData));
      setSelectedRow(rows.find(v => v.file_id === fileId))
      setOpen(true);

    } catch (error) {
      console.error('Error fetching or unzipping the document:', error);
    }
  };


  const getTreeDataPath = (row) => row.hierarchy;

  const processRowUpdate = (newRow) => {
    console.log(newRow)
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleClickButton = async () => {
    const rowModels = apiRef.current.getRowModels();
    const rowValuesArray = Array.from(rowModels.values());

    // DecisionBPK 값이 변경된 행만 필터링
    const changedRows = rowValuesArray.filter((updatedRow) => {
      const originalRow = rows.filter(v => v.Doc_No !== undefined).find(row => row.record_no === updatedRow.record_no);
      return originalRow && originalRow.DecisionBPK !== updatedRow.DecisionBPK;
    })
      .filter(v => rowSelectionModel.some(vf => vf === v.id))

    if (changedRows.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Do you want to submit the changes for the ${changedRows.length} reviews out of the ${rowSelectionModel.length} selected?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!'
      }).then((result) => {
        if (result.isConfirmed) {

          // 사용자가 '확인'을 클릭한 경우
          const updatePromises = changedRows.map(row =>
            axios.get(`http://43.200.223.224:5000/updatesimpletr?project=${project_code}&record=${row.record_no}&DecisionBPK=${row.DecisionBPK}&username=${username}&LineAutoSeq=${row.LineAutoSeq}`)
          );

          Promise.all(updatePromises).then(response => {
            // 모든 업데이트 요청이 성공적으로 처리된 경우
            Swal.fire(
              'Submitted!',
              'Your review changes have been submitted.',
              'success'
            ).then(() => {
              // 성공 메시지 후 페이지 새로고침
              window.location.reload();
            });
          }).catch(error => {
            // 요청 중 하나라도 실패한 경우
            Swal.fire(
              'Error!',
              'An error occurred while submitting your reviews. Please try again.',
              'error'
            );
          });
        }
      });
    } else {
      // 변경된 행이 없는 경우
      Swal.fire(
        'No Changes Detected',
        'There are no changes to submit.',
        'info'
      );
    }
  };



  const initialRowsRef = React.useRef(rows);
  React.useEffect(() => {
    if (rows !== initialRowsRef.current) {
      apiRef.current.updateRows(rows);
      setRows(rows)
    }
  }, [apiRef, rows]);



  function getChipProps(params) {
    if (params.value === "Overdue") {
      return {
        icon: <WarningIcon style={{ fill: "red", fontSize: 14 }} />, // red[500]을 "red"로 변경
        label: params.value,
        style: {
          borderColor: "red" // red[500]을 "red"로 변경
        }
      };
    } else {
      return {
        // icon: <CheckCircleIcon style={{ fill: "blue" }} />, // blue[500]을 "blue"로 변경
        label: params.value,
        style: {
          borderColor: "blue" // blue[500]을 "blue"로 변경
        }
      };
    }
  }

  const toggleGroup = () => {
    const groups = apiRef.current.getRowNode(GRID_ROOT_GROUP_ID).children;

    if (groups.length > 1) {
      groups.forEach(groupId => {
        apiRef.current.setRowChildrenExpansion(
          groupId,
          !apiRef.current.getRowNode(groupId).childrenExpanded,
        );
      });

    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ fontSize: 'larger', fontWeight: 600,marginLeft:10}}>
          {project_code === "20238S" ? "Transmittals (for TideWater)" : "Transmittals"}
        </div>
  
        <div>
          <Button
            size="small"
            onClick={toggleGroup}
            style={{ marginRight: '10px' }}
          >
            Toggle row expansion
          </Button>
          <GridToolbarExport style={{ marginRight: '10px' }} />
          <Button
            startIcon={<FileDownloadIcon />}
            size="small"
            onClick={() => handleBulkDownload(rowSelectionModel)}
            style={{ marginRight: '10px' }}
          >
            Selected Row File Download
          </Button>
          <GridToolbarQuickFilter style={{ marginRight: '10px' }} />
          <Button
            onClick={handleClickButton}
            style={{ color: 'snow', fontSize: '0.7rem', fontWeight: 600, backgroundColor: 'dodgerblue' }}
          >
            Submit Review
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }
  
  const expansionState = useRef({}); // 이 객체는 각 행의 확장 상태를 저장합니다.

  useEffect(() => {
    const handleExpansionChange = (node) => {
      expansionState.current[node.id] = node.childrenExpanded || false;
    };

    const unsubscribe = apiRef.current.subscribeEvent('rowExpansionChange', handleExpansionChange);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 해제
    return () => {
      unsubscribe();
    };
  }, []);

  const isGroupExpanded = useCallback(
    (node) => expansionState.current[node.id] || false,
    []
  );

  const getRowClassName = (params) => {
    if (params.row && params.row.hierarchy.length === 1) {
      return 'tree-table-group';
    }
    return '';
  };


  return (
    <>
      <div className="navbar">
        <img src='/image/sklogo.svg' alt="Logo" className="logo" />
        <div className="button-group">


          <Button
            onClick={handleLogoutClick}
            style={{ color: 'snow', fontSize: '0.7rem', fontWeight: 600, backgroundColor: '#FF6F61' }}
          >
            Log out
          </Button>
        </div>
      </div>


      <div className="tableContainer">
        <MyDocumentViewerModal selectedRow={selectedRow} open={open} onClose={() => setOpen(false)} docuID={docuID} documenTitle={documenTitle} documentUrl={documentUrl} username={username} />
        {rows.length > 0 &&
          <Box
            sx={{
              height: "90vh",
              // marginTop: "40px",
              width: '100%',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
              },
              '& .MuiDataGrid-columnHeaders': {
                lineHeight: 'normal !important',
              },
              '& .MuiDataGrid-cell': {
                fontSize: 12,
              },
              '& .notmatched': {
                backgroundColor: '#f2f3f3',
              },
              '& .notmatched.MuiDataGrid-cell--editing': {
                backgroundColor: '#f2f3f3',
                color: '#f2f3f3'
              },
              '& .notmatched input': {
                // backgroundColor: '#F0EDE5', 
                fontSize: 0
              },
              '& .notmatched.MuiDataGrid-cell': {
                backgroundColor: '#f2f3f3',
              },
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <StyledDataGrid
              // checkboxSelection
              apiRef={apiRef}
              isGroupExpandedByDefault={isGroupExpanded}
              rows={initialRowsRef.current}
              columns={columns}
              treeData
              getTreeDataPath={getTreeDataPath}
              cellSelection
              pagination
              autoPageSize
              checkboxSelection
              disableRowSelectionOnClick
              isRowSelectable={(params) => params.row.hierarchy.length > 1}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              density="compact"
              // processRowUpdate={processRowUpdate}
              slots={{ toolbar: CustomToolbar }}
              getRowClassName={getRowClassName}

            // slotProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //   },
            // }}
            />
          </Box>
        }
      </div>
    </>
  );
};
export default ClientTable;
