import * as React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DataGridPro,GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';

export const ModuleTable = ({moduletabledata, onModuleDataHandle }) => {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);


  const [rows, setRows] = React.useState(moduletabledata.map((v,index)=>({
    ...v, 
    id:index+1,
  })));

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    const updatedSelectedRows = rows.map(row => ({
      ...row,
      selected: rowSelectionModel.includes(row.id)
    }));

    setSelectedRows(updatedSelectedRows);
  }, [rowSelectionModel, rows]);


 
  const columns = [
    {field: 'WBSDiscNM', headerName: '공종', width: 80},
    {field: 'uuu_P6ActivityId', headerName: 'Activity ID', width: 120},
    {field: 'uuu_P6ActivityName', headerName: 'Activity Name' ,width: 350},
    {field: 'PMSClassNM', headerName: 'Class Code Name', width: 80},
    {field: 'emsPlanStartDO', headerName: '계획 시작일', type: 'date', width: 120},
    {field: 'emsPlanFinishDO', headerName: '계획 종료일', type: 'date', width: 120},
]

  // Modify this function
  const onRowSelectionModelChange = (newRowSelectionModel) => {
    if (newRowSelectionModel.length > 1) {
      // Keep only the most recently selected row
      const newSelection = newRowSelectionModel.slice(-1);
      setRowSelectionModel(newSelection);
    } else {
      // Allow selection to be cleared or a single row to be selected
      setRowSelectionModel(newRowSelectionModel);
    }
  };

function CustomToolbar() {

  return (
    <GridToolbarContainer>
      <Button onClick={() => onModuleDataHandle(selectedRows)}>
        적용
      </Button>
    </GridToolbarContainer>
  );
  }
  return <div style={{height:600}}>
      <DataGridPro
        density="compact"
        pagination
        autoPageSize 
        rows={rows}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        slots={{
          toolbar: CustomToolbar,
        }}

      />
    </div>;
};