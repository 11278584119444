import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import OrganizationChart from "@dabeng/react-orgchart";
import axios from "axios";
// import html2canvas from "html2canvas";
import Node from "./Node";
import "./SkonEMSOrgChart.scss";

const { REACT_APP_URI2 } = process.env;

const url = `http://43.200.223.224:5000`;

const SkonEMSOrgChart = () => {
  const { project_code } = useParams();
  const orgChart = useRef(null);
  const [orgChartData, setOrgChartData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "조직도",
        lineitem: "yes",
        filter_condition: "status=Active",
      };

      const unifierData = await axios.post(
        `${url}/getemsbprecord?path=${project_code}`,
        body
      );

      const dataf = unifierData.data.data.data
        .filter((com) => com.status === "Active")
        .map((v) => {
          return {
            id: v.record_no,
            position: v.SKRoleClass2 !== null ? v.SKRoleClass2 : "",
            biopic: `http://it.skecoplant.com/image.aspx?sabun=${v.empEmployeeIDTB50}`,
            name: v.OrgName,
            pid: v.orgParentBPK === null ? 0 : v.orgParentBPK,
            part: v.orgPartSPD,
          };
        });

      var nodes = function (data, root) {
        var r = [],
          o = {};
        data.forEach(function (a) {
          if (o[a.id] && o[a.id].children) {
            a.children = o[a.id] && o[a.id].children;
          }
          o[a.id] = a;
          if (a.pid === root) {
            r.push(a);
          } else {
            o[a.pid] = o[a.pid] || {};
            o[a.pid].children = o[a.pid].children || [];
            o[a.pid].children.push(a);
          }
        });

        return r;
      };

      const treeData = nodes(dataf, 0);
      setOrgChartData(...treeData);
    };

    fetchData();
  }, [project_code]);

  return (
    <div style={{ position: "relative" }}>
      <div className="skonEMSOrgChart" ref={orgChart}>
        {orgChartData !== undefined && (
          <OrganizationChart
            datasource={orgChartData}
            chartClass="sekure-org-chart"
            NodeTemplate={Node}
            pan={true}
            zoom={true}
          />
        )}
      </div>
    </div>
  );
};

export default SkonEMSOrgChart;
