import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartCategoryAxisItem,
  ChartCategoryAxis,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartXAxis,
  ChartXAxisItem,
  ChartTooltip,
  ChartPlotArea,
} from "@progress/kendo-react-charts";
import { RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";
import { ListBox } from "@progress/kendo-react-listbox";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import DetailResourcePlanTable from "./DetailResourcePlanTable";

const SELECTED_FIELD = "selected";

const DetailResourcePlanChart = ({
  planStaff,
  currentStaff,
  height,
  color_list,
  bgColor,
}) => {
  const [chartDatas, setChartDatas] = useState([]);
  const [actualCurrentData, setActualCurrentData] = useState([]);
  const [listBoxDatas, setListBoxDatas] = useState({
    SKRoleClass2: [],
    SKRoleClass3: [],
  });
  const [filterState, setFilterState] = useState("SKRoleClass2");
  const [monthsBase, setMonthsBase] = useState([]);
  const [chartRange, setChartRange] = useState({ min: "", max: "" });
  const [dateRangeSlider, setDateRangeSlider] = useState({ min: "", max: "" });

  const [xPlotBands, setXPlotBands] = useState([]);

  const [detailTableToggle, setDetailTableToggle] = useState(false);

  useEffect(() => {
    if (planStaff.length > 0 && currentStaff.length > 0) {
      const uniqSKRoleClass2 = _.uniqBy(planStaff, "SKRoleClass2").map(
        (com) => ({
          name: com.SKRoleClass2,
          [SELECTED_FIELD]: false,
        })
      );

      setListBoxDatas((prev) => ({ ...prev, SKRoleClass2: uniqSKRoleClass2 }));
    }
  }, [planStaff, currentStaff]);

  const handledateRange = (e) => {
    const { start, end } = e.value;

    const customStart = new Date(moment(new Date(start)).format("YYYY-MM-DD"));
    const customEnd = new Date(moment(new Date(end)).format("YYYY-MM-DD"));

    setChartRange({ min: customStart, max: customEnd });
  };

  const handleListBoxValue = (e, target, nextTarget) => {
    const { dataItem } = e;

    const changedValue = listBoxDatas[target];

    const find_index = changedValue.findIndex(
      (com) => com.name === dataItem.name
    );

    changedValue[find_index] = {
      ...changedValue[find_index],
      [SELECTED_FIELD]: !listBoxDatas[target][find_index][SELECTED_FIELD],
    };

    listBoxDatas[target] = changedValue;

    setListBoxDatas((prev) => ({ ...prev, ...listBoxDatas }));

    if (
      listBoxDatas[target].filter((com) => com[SELECTED_FIELD] === true)
        .length > 0
    ) {
      let copy_planStaff = [...planStaff];

      const filter_arr = listBoxDatas.SKRoleClass2.filter(
        (com) => com[SELECTED_FIELD] === true
      );

      let filter_data = [];

      filter_arr.forEach((com) =>
        filter_data.push(
          ...copy_planStaff.filter((com2) => com2["SKRoleClass2"] === com.name)
        )
      );

      const changeUgenProjectNumberValue = _.uniqBy(
        filter_data,
        nextTarget
      ).map((com) => ({
        name: com.SKRoleClass3,
        [SELECTED_FIELD]: false,
      }));

      setFilterState(nextTarget);
      setListBoxDatas((prev) => ({
        ...prev,
        SKRoleClass3: changeUgenProjectNumberValue,
      }));
    } else {
      setFilterState(target);
      setListBoxDatas((prev) => ({
        ...prev,
        SKRoleClass3: [],
      }));
    }
  };

  useEffect(() => {
    if (planStaff.length > 0 && currentStaff.length > 0) {
      let copy_planStaff = [...planStaff];

      const filter_arr = listBoxDatas.SKRoleClass2.filter(
        (com) => com[SELECTED_FIELD] === true
      );

      let filter_data = [];

      if (filter_arr.length > 0) {
        filter_arr.forEach((com) =>
          filter_data.push(
            ...copy_planStaff.filter(
              (com2) => com2["SKRoleClass2"] === com.name
            )
          )
        );
      } else {
        filter_data = copy_planStaff;
      }

      const dateSorting = _.sortBy(filter_data, "months");

      const dateRangeSet = {
        min: dateSorting[0].months,
        max: dateSorting[dateSorting.length - 1].months,
      };

      setChartRange({
        min: new Date(moment(new Date()).format("YYYY-MM-01")),
        max: dateSorting[dateSorting.length - 1].months,
      });

      setDateRangeSlider({
        min: new Date(moment(dateRangeSet.min).format("YYYY-MM-01")),
        max: new Date(moment(dateRangeSet.max).format("YYYY-MM-01")),
      });

      const actual_group_data = _(currentStaff)
        .groupBy(filterState)
        .map((objs, key) => {
          return { name: key, data: objs, value: objs.length };
        })
        .value();

      setActualCurrentData(actual_group_data);

      const group_data = _(dateSorting)
        .groupBy(filterState)
        .map((objs, key) => {
          return { name: key, data: _.sortBy(objs, "months") };
        })
        .value();

      const chart_group_data = group_data.map((com) => {
        if (com.data.length > 0) {
          const detail_data = com.data.filter(
            (com2) => com2.months !== undefined
          );

          const detail_months_data_base = create_months_data(
            detail_data[0].months,
            detail_data[detail_data.length - 1].months
          ).map((com2) => {
            const value_sum = Number(
              _.sumBy(
                com.data.filter(
                  (com3) => com3.months_moment === com2.months_moment
                ),
                "value"
              ).toFixed(1)
            );

            return { months: com2.months, value: value_sum };
          });

          return { ...com, data: detail_months_data_base };
        } else {
          return { ...com };
        }
      });

      const months_data_base = create_months_data(
        dateRangeSet.min,
        dateRangeSet.max
      );

      setMonthsBase(months_data_base);

      setChartDatas(
        [...chart_group_data].map((com) => {
          const filter_data = com.data.filter(
            (com2) =>
              com2.months > new Date(moment(new Date()).format("YYYY-MM-01"))
          );

          return {
            ...com,
            peak: _.maxBy(filter_data, "value"),
          };
        })
      );

      setXPlotBands([
        {
          from: new Date(moment(new Date()).format("YYYY-MM-01")),
          to: new Date(
            moment(new Date()).add(1, "months").format("YYYY-MM-01")
          ),
          color: "#00f",
          opacity: 0.05,
        },
      ]);
    }
  }, [planStaff, currentStaff, filterState, listBoxDatas]);

  const labelContentAxis1 = (e) => e.value + "명";

  const labelContent_0 = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.index % 3 === 0
      ? moment(new Date(e.value)).format("YY년 MM월")
      : null;
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent_0,
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    const find_current_staff = (name) => {
      const find_obj = actualCurrentData.find((com) => com.name === name);
      return find_obj?.value === undefined
        ? 0 + "명"
        : find_obj?.value.toLocaleString() + "명";
    };

    return (
      <div>
        <div>
          {moment(new Date(categoryText)).format("YY년 MM월") ===
          moment(dateRangeSlider.min).format("YY년 MM월")
            ? "현재 인원"
            : moment(new Date(categoryText)).format("YY년 MM월")}
        </div>
        {points.map((point, index) => {
          if (
            moment(new Date(categoryText)).format("YY년 MM월") ===
            moment(dateRangeSlider.min).format("YY년 MM월")
          ) {
            return (
              <div key={index}>
                {point.series.name} :{" "}
                {Number(point.value.toFixed(1)).toLocaleString()}명
              </div>
            );
          } else {
            return (
              <div key={index}>
                {point.series.name} : {find_current_staff(point.series.name)} /{" "}
                {Number(point.value.toFixed(1)).toLocaleString()}명
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleTableToggle = () => {
    setDetailTableToggle((prev) => !prev);
  };

  const labelContentAxis2 = (e) => {
    const { name } = e.series;

    const find_peak_data = chartDatas.find((com) => com.name === name)?.[
      "peak"
    ]?.["months"];

    if (
      moment(e.category).format("YY년 MM월") ===
        moment(new Date()).format("YY년 MM월") ||
      moment(e.category).format("YY년 MM월") ===
        moment(find_peak_data).format("YY년 MM월")
    ) {
      return e.value;
    } else {
      return "";
    }
  };

  return (
    <DetailResourcePlanChartDiv height={height}>
      {detailTableToggle && (
        <Dialog
          title={"인력동원계획"}
          onClose={handleTableToggle}
          height={height * 0.95}
          width={"90%"}
        >
          <DetailResourcePlanTable
            planStaff={planStaff}
            currentStaff={currentStaff}
            height={height * 0.95 - 44 - 32}
          />
        </Dialog>
      )}
      <Button
        title="블록 온오프"
        iconClass="k-icon k-font-icon k-i-grid"
        onClick={handleTableToggle}
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
        }}
      />
      <GridLayout
        style={{ height }}
        rows={[
          {
            height: "25%",
          },
          {
            height: "65%",
          },
          {
            height: "10%",
          },
        ]}
        cols={[
          {
            width: "50%",
          },
          {
            width: "50%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        {Object.keys(listBoxDatas).map((com, idx, arr) => {
          return (
            <GridLayoutItem
              col={idx + 1}
              row={1}
              style={{ padding: "5px" }}
              key={idx}
            >
              <ListBoxTitle>Level.{idx + 1}</ListBoxTitle>
              <ListBox
                style={{
                  height: (height - 10) * 0.25 - 35,
                  width: "100%",
                }}
                data={listBoxDatas[com]}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) =>
                  idx === arr.length - 1
                    ? null
                    : handleListBoxValue(e, com, arr[idx + 1])
                }
              />
            </GridLayoutItem>
          );
        })}

        <GridLayoutItem col={1} colSpan={4} row={2}>
          <Chart>
            <ChartArea height={height * 0.65} margin={20} />
            <ChartPlotArea
              border={{ width: 0, color: "#ffffff", dashType: "dot" }}
              padding={2000}
            />
            <ChartLegend position="right" orientation="vertical" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                min={chartRange.min}
                max={chartRange.max}
                labels={seriesLabels_0}
                axisCrossingValue={[0, monthsBase.length]}
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
                plotBands={xPlotBands}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                name="인원"
                min={0}
                labels={{
                  content: labelContentAxis1,
                  font: "0.7rem Arial, pretendard-R",
                }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
                visible={true}
                border="none"
              />
            </ChartValueAxis>
            <ChartXAxis>
              <ChartXAxisItem plotBands={xPlotBands} />
            </ChartXAxis>
            <ChartTooltip shared={true} render={sharedTooltipRender} />

            <ChartSeries>
              {chartDatas.map((com, idx) => {
                return (
                  <ChartSeriesItem
                    key={idx}
                    color={bgColor(color_list, idx)}
                    type="line"
                    data={com.data}
                    field="value"
                    categoryField="months"
                    name={com.name}
                    autoFit={true}
                    axis="인원"
                    labels={{
                      visible: true,
                      // Note that visible defaults to false
                      content: labelContentAxis2,
                      background: "none",
                      font: "0.7rem Arial, pretendard-R",
                    }}
                  />
                );
              })}
            </ChartSeries>
          </Chart>
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          colSpan={4}
          row={3}
          style={{ padding: "0px 5px" }}
        >
          {chartRange.min !== "" &&
            chartRange.max !== "" &&
            dateRangeSlider.min !== "" &&
            dateRangeSlider.max !== "" && (
              <RangeSlider
                min={dateRangeSlider.min.getTime()}
                max={dateRangeSlider.max.getTime()}
                value={{
                  start: chartRange.min.getTime(),
                  end: chartRange.max.getTime(),
                }}
                onChange={(e) => handledateRange(e)}
                style={{
                  width: "100%",
                  fontSize: "10px",
                }}
              >
                {_.uniqBy(monthsBase, "months").map((com, idx, arr) => {
                  if (idx !== 0 && idx !== arr.length - 1 && idx % 3 === 0) {
                    return (
                      <SliderLabel
                        key={idx}
                        position={new Date(`${com.months}-01`).getTime()}
                      >
                        {moment(com.months).format("YY/MM")}
                      </SliderLabel>
                    );
                  } else {
                    return null;
                  }
                })}
              </RangeSlider>
            )}
        </GridLayoutItem>
      </GridLayout>
    </DetailResourcePlanChartDiv>
  );
};

export default DetailResourcePlanChart;

const DetailResourcePlanChartDiv = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.height}px;
`;

const ListBoxTitle = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;

const create_months_data = (start_date, end_date) => {
  const monthDiff = moment(end_date).diff(moment(start_date), "months");

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    const base_months = moment(start_date)
      .add(i, "months")
      .format("YYYY-MM-01");

    monthRange.push({
      months: new Date(base_months),
      months_moment: moment(new Date(base_months)).format("YYYY-MM"),
    });
  }

  return monthRange;
};

// const color_list = ["#DBF3FF", "#42B7F4", "#B1CCE4", "#5E8EFF"];

// const bgColor = (color, i) => {
//   if (i >= color.length) {
//     return color[i % color.length];
//   } else {
//     return color[i];
//   }
// };

// const overall_acutaul_group_data = {
//   name: "Overall",
//   data: [],
//   value: _.sumBy([...actual_group_data], "value"),
// };

// setActualCurrentData([overall_acutaul_group_data, ...actual_group_data]);

// const overall_obj = {
//   name: "Overall",
//   data: months_data_base.map((com) => {
//     const value_sum = Number(
//       _.sumBy(
//         dateSorting.filter(
//           (com2) => com2.months_moment === com.months_moment
//         ),
//         "value"
//       ).toFixed(1)
//     );
//     return { months: com.months, value: value_sum };
//   }),
// };

// setChartDatas(
//   [overall_obj, ...chart_group_data].map((com) => {
//     const filter_data = com.data.filter(
//       (com2) =>
//         com2.months > new Date(moment(new Date()).format("YYYY-MM-01"))
//     );

//     return {
//       ...com,
//       peak: _.maxBy(filter_data, "value"),
//     };
//   })
// );
