import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import axios from 'axios';
import _ from 'lodash';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const LandscapeImg = ({ positiondata, order, blockH }) => {

  const [imgsrc, setimgsrc] = useState("");
  const { project_code } = useParams();
  const { width } = useWindowDimensions();


  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "현장 사진 업로드",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": "Active",
              "condition_type": "eq"
            }
          ]
        }
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
      const res2 = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/landscape/${project_code}/`,)

      const imageList = res2.data.fileList
      const existfiles = res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined').reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no }))), [])
      .filter(v=>v.genPhotoCatSRB === '전경사진')
      .map(v => v.genPhotoTitle)

      const imgs = _.sortBy(imageList && imageList.map((v) => ({ date: v.name.split('_7_')[1], title: v.name.split('_7_')[2], filename: v.name })).filter(f => existfiles.includes(f.title)), 'date').reverse()

      const ImgList = imgs.map(v => v.filename)

      let imagesrc = [];
      for (const image of ImgList) {
        const dr = await axios.get(`http://43.200.223.224:5000/getFile?path=/${REACT_APP_PATH2}/landscape/${project_code}/${image}`, {
          responseType: 'arraybuffer', 
        })
        const dr_data = window.URL.createObjectURL(new Blob([dr.data]))
        imagesrc.push(dr_data)
      }

      setimgsrc(imagesrc)

    };

    fetchData();
  }, [project_code]);

  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);


  return (
    <>
     <div style={{width:'100%'}}>
        {imgsrc ?
          <Zoom>
            <img
              alt="전경 사진"
              src={imgsrc[0]}
              style={{
                height: BoxHeight,
                maxWidth: "100%",
                objectFit:'cover'
              }}
            /></Zoom> :
          <span>이미지 없음</span>
        }
</div>
    </>

  );
};

export default LandscapeImg;