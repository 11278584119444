import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import 'hammerjs';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import 'hammerjs';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { ResponsivePie } from '@nivo/pie';
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PTW = ({ positiondata }) => {
    const { project_code } = useParams();
    const [data, setdata] = useState([]);
    const [lastdate, setdate] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const res2 = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/safety/${project_code}_safety.json`,)

            if (project_code === '22105D' || project_code === '22106D') {

                setdata(res2.data.map((data) => {
                    return {
                        WorkType: data.d_cwp_check_list_dpk,
                        IssueDate: data.d_work_start.slice(6, 10) + '년 ' + data.d_work_start.slice(0, 2) + '월 ' + data.d_work_start.slice(3, 5) + '일',
                        record_no: data.record_no,
                        count: 1,
                        workercount:Number(data.d_number_of_person)
                    }
                }));
                setdate(_.sortBy(res2.data, 'record_no')[0]['d_work_start'])
            }

            else {
                setdata(res2.data.map((data) => {
                    return {
                        WorkType: data.asWorkType,
                        WorkType2: data.asTargetHazardNM,
                        IssueDate: data.asWorkDate.slice(6, 10) + '년 ' + data.asWorkDate.slice(0, 2) + '월 ' + data.asWorkDate.slice(3, 5) + '일',
                        record_no: data.record_no,
                        asWorkStatus: data.asWorkStatus,
                        count: 1,
                        workercount: Number(data.asWorkNo)

                    }
                }));
                setdate(_.sortBy(res2.data, 'record_no')[0]['asWorkDate'])
            }

        };
        fetchData();
    }, [project_code]);


    const filtered_sr = _.filter(data, function (o) { return o.WorkType !== 'General Work Permit'; })

    var bytype =
        _(data)
            .groupBy('WorkType')
            .map((objs, key) => ({
                'WorkType': key,
                'count': _.sumBy(objs, 'count')
            }))
            .value()

    var bytype2 =
        _(data.map((v) => ({ ...v, uniq: v.WorkType + "//" + v.WorkType2 })))
            .groupBy('uniq')
            .map((objs, key) => ({
                'WorkType': key.slice(0, key.indexOf("//")),
                'WorkType2': key.slice(key.indexOf("//") + 2),
                'count': _.sumBy(objs, 'count')
            }))
            .value()


    var piedata =
    project_code === '22105D' || project_code === '22106D' ?
        _(data)
            .groupBy('WorkType')
            .map((objs, key) => ({
                'id': key,
                'value': _.sumBy(objs, 'workercount')
            }))
            .value():

            _(data.filter(v=>v.WorkType ==='특별 유해위험'))
            .groupBy('WorkType2')
            .map((objs, key) => ({
                'id': key,
                'value': _.sumBy(objs, 'workercount')
            }))
            .value()

    const totoal_worker = project_code === '22105D' || project_code === '22106D' ? _.sumBy(data, 'workercount') :  _.sumBy(data.filter(v=>v.WorkType ==='특별 유해위험'), 'workercount') 
    

    const tabledata = project_code === '22105D' || project_code === '22106D' ? bytype : bytype2;


    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[5];
            setBoxHeight(210 + 140 * (boxHeight.rowSpan - 2));
        }
    }, [positiondata]);


    const [sort, setSort] = useState([{}]);

    const normalcell = (props) => {
        const field = props.field || "";
        const cell = props.dataItem[field]
        return (
            <td style={{ fontSize: '0.8rem', textAlign: 'left' }}>
                {cell}
            </td>
        );
    };

    const HeaderCell = (props) => {
        const { field, title } = props;

        const handleSort = (field) => {
            const findSortIndex = sort.findIndex((e) => e.field === field);

            const findSort = sort.find((e) => e.field === field);

            if (findSort === undefined) {
                if (sort[0]["field"] === undefined) {
                    sort.push({ field: field, dir: "asc" });
                    setSort([...sort]);
                }
            } else {
                if (findSort["dir"] === "asc") {
                    sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
                    setSort([...sort]);
                } else if (findSort["dir"] === "desc") {
                    sort.splice(findSortIndex, 1);
                    setSort([...sort]);
                }
            }
        };

        const hadleSortArrow = (field) => {
            const findSort = sort.find((e) => e.field === field);

            return findSort !== undefined ? findSort["dir"] : false;
        };

        const hadleSortIndex = (field) => {
            const findSortIndex = sort.findIndex((e) => e.field === field);

            return findSortIndex === -1 ? "" : findSortIndex;
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "left",
                    fontSize: "0.8rem",
                    cursor: "pointer"
                }}
                onClick={() => {
                    handleSort(field);
                }}
            >
                <p style={{ margin: "0" }}>{title} </p>
                <div
                    className={
                        hadleSortArrow(field) === "asc"
                            ? "k-icon k-font-icon k-i-sort-asc-sm"
                            : hadleSortArrow(field) === "desc"
                                ? "k-icon k-font-icon k-i-sort-desc-sm"
                                : ""
                    }
                ></div>
                <div style={{ color: "#ff6358" }}>{hadleSortIndex(field)}</div>
            </div>
        );
    };

    const [visibleDialog, setVisibleDialog] = useState(true);
    const [visibleDialog2, setVisibleDialog2] = useState(true);
    const [visibleDialog3, setVisibleDialog3] = useState(true);
    const [visibleDialogO, setVisibleDialogO] = useState(true);

    const toggleDialog1 = useCallback(() => {
        setVisibleDialog(!visibleDialog);
    },[visibleDialog]);
    const toggleDialog2 = useCallback(() => {
        setVisibleDialog2(!visibleDialog2);
    },[visibleDialog2]);

    const toggleDialog3 = useCallback(() => {
        setVisibleDialog3(!visibleDialog3);
    },[visibleDialog3]);

    const toggleDialogO = useCallback(() => {
        setVisibleDialogO(!visibleDialogO);
    },[visibleDialogO]);
    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}

                cols={[
                    {
                        width: '40%',
                    },
                    {
                        width: '60%',
                    }

                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>
                {/* <div style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '0.8rem' }}><span style={{ fontWeight: '600' }}><span style={{ color: '#939597' }}>작업건수 </span>총 <span style={{ color: 'red', fontWeight: '600' }}>{data.length}</span> 건</span></div> */}
                <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
          {lastdate && generateUpdatedDate(lastdate)}
        </div>
                <GridLayoutItem row={1} col={1}>

                {!visibleDialogO && (
                                <Dialog title={"작업상세현황"} onClose={toggleDialogO} width='40%'>
                                    <Grid

                                        data={orderBy(bytype, sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                        className='safety3'
                                        style={{ width: '100%', height: BoxHeight * 0.85 }}

                                    >
                                        <GridColumn field="WorkType" title="유형" cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="count" title="count" cell={normalcell} headerCell={HeaderCell} />

                                    </Grid>
                                </Dialog>
                            )}
                    {project_code === '22105D' || project_code === '22106D' || project_code === '22266D'|| project_code === '22339D'?

                        <div style={{ position: 'relative', height: BoxHeight * 0.9 }} >
                            <div  onClick={toggleDialogO} className='hovertext' style={{ minWidth:200,position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', textAlign: 'center'}}>
                                {t("특별위험작업")} / {t("일반위험작업")}<br></br>
                                <span style={{ fontSize: filtered_sr.length > 2 ? '1.5rem' : '2rem', fontWeight: 'bold' }} >{filtered_sr.length}</span><span>{t("개")}</span>
                                <span style={{ fontSize: filtered_sr.length > 2 ? '1.5rem' : '2rem', }}> / </span>
                                <span style={{ fontSize: data.length - filtered_sr.length > 2 ? '1.5rem' : '2rem', fontWeight: 'bold' }} >{data.length - filtered_sr.length}</span><span>{t("개")}</span>
                            </div>
                        </div>
                        :


                        <div>

                            {!visibleDialog && (
                                <Dialog title={"작업상세현황"} onClose={toggleDialog1} width='40%'>
                                    <Grid

                                        data={orderBy(bytype2.filter(v => v.WorkType.includes('특별')), sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                        className='safety2'
                                        style={{ width: '100%', height: BoxHeight * 0.85 }}

                                    >
                                        <GridColumn field="WorkType" title="유형" cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="WorkType2" title="작업" cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="count" title="count" cell={normalcell} headerCell={HeaderCell} />

                                    </Grid>
                                </Dialog>
                            )}

                            {!visibleDialog3 && (
                                <Dialog title={t("작업상세현황")} onClose={toggleDialog3} width='40%'>
                                    <Grid

                                        data={orderBy(bytype2.filter(v => v.WorkType.includes('일상')), sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                        className='safety2'
                                        style={{ width: '100%', height: BoxHeight * 0.85 }}

                                    >
                                        <GridColumn field="WorkType" title={t("유형")} cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="WorkType2" title={t("작업")} cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="count" title="count" cell={normalcell} headerCell={HeaderCell} />

                                    </Grid>
                                </Dialog>
                            )}

                            {!visibleDialog2 && (
                                <Dialog title={"작업상세현황"} onClose={toggleDialog2} width='40%'>
                                    <Grid

                                        data={orderBy(bytype2.filter(v => v.WorkType.includes('중점')), sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                        className='safety2'
                                        style={{ width: '100%', height: BoxHeight * 0.85 }}

                                    >
                                        <GridColumn field="WorkType" title="유형" cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="WorkType2" title="작업" cell={normalcell} headerCell={HeaderCell} />
                                        <GridColumn field="count" title="count" cell={normalcell} headerCell={HeaderCell} />

                                    </Grid>
                                </Dialog>
                            )}
                            <table style={{ fontSize: '0.8rem', width: '100%', fontWeight: '500', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style={{ backgroundColor: '#f8f8f8', padding: '3px' }}>
                                            <Button style={{ fontSize: '0.8rem', fontWeight: '600', backgroundColor: '#939597', color: 'white', width: '90%' }}>
                                                점검대기 &gt;
                                            </Button>
                                        </th>
                                        <th style={{ backgroundColor: '#eff5f4' }}>
                                            <Button style={{ fontSize: '0.8rem', fontWeight: '600', backgroundColor: '#006b54', color: 'white', width: '90%' }}>
                                                점검완료 &gt;
                                            </Button></th>
                                        <th style={{ backgroundColor: '#ffe8e6' }}>
                                            <Button style={{ fontSize: '0.8rem', fontWeight: '600', backgroundColor: '#FF6F61', color: 'white', width: '90%' }}>
                                                작업중지 &gt;
                                            </Button></th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderBottom: '1px solid #EDF1FF' }}>
                                    <tr style={{ height: BoxHeight * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                                        <td style={{ color: '#f31700', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem', cursor:'pointer' }} onClick={toggleDialog1}> 특별 {data.filter(item => item.WorkType === '특별 유해위험').length}</td>
                                        <td style={{ backgroundColor: '#f8f8f8' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#939597', borderColor: '#939597' }}>{data.filter(item => item.WorkType === '특별 유해위험' && item.asWorkStatus === '점검대기').length}</Badge></BadgeContainer></td>
                                        <td style={{ backgroundColor: '#eff5f4' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}>{data.filter(item => item.WorkType === '특별 유해위험' && item.asWorkStatus === '작업 중').length}</Badge></BadgeContainer></td>
                                        <td style={{ backgroundColor: '#ffe8e6' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#FF6F61', borderColor: '#FF6F61' }}>{data.filter(item => item.WorkType === '특별 유해위험' && item.asWorkStatus === '작업 중지').length}</Badge></BadgeContainer></td>
                                    </tr>
                                    <tr style={{ height: BoxHeight * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                                        <td style={{ color: '#006b54', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' , cursor:'pointer' }} onClick={toggleDialog2}>중점  {data.filter(item => item.WorkType === '중점 유해위험').length}</td>
                                        <td style={{ backgroundColor: '#f8f8f8' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#939597', borderColor: '#939597' }}>{data.filter(item => item.WorkType === '중점 유해위험' && item.asWorkStatus === '점검대기').length}</Badge></BadgeContainer></td>
                                        <td style={{ backgroundColor: '#eff5f4' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}>{data.filter(item => item.WorkType === '중점 유해위험' && item.asWorkStatus === '작업 중').length}</Badge></BadgeContainer></td>
                                        <td style={{ backgroundColor: '#ffe8e6' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#FF6F61', borderColor: '#FF6F61' }}>{data.filter(item => item.WorkType === '중점 유해위험' && item.asWorkStatus === '작업 중지').length}</Badge></BadgeContainer></td>
                                    </tr>
                                    <tr style={{ height: BoxHeight * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                                        <td style={{ color: 'gray', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' , cursor:'pointer' }} onClick={toggleDialog3}>일상  {data.filter(item => item.WorkType === '일상 유해위험').length}</td>
                                        <td style={{ backgroundColor: '#f8f8f8' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#939597', borderColor: '#939597' }}>{data.filter(item => item.WorkType === '일상 유해위험' && item.asWorkStatus === '점검대기').length}</Badge></BadgeContainer></td>
                                        <td style={{ backgroundColor: '#eff5f4' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}>{data.filter(item => item.WorkType === '일상 유해위험' && item.asWorkStatus === '작업 중').length}</Badge></BadgeContainer></td>
                                        <td style={{ backgroundColor: '#ffe8e6' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#FF6F61', borderColor: '#FF6F61' }}>{data.filter(item => item.WorkType === '일상 유해위험' && item.asWorkStatus === '작업 중지').length}</Badge></BadgeContainer></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    }
                </GridLayoutItem>

                <GridLayoutItem row={1} col={2} rowSpan={1} style={{position:'relative'}}>
 
                        
                        <>
                        <div style={{ textAlign: 'center' }}>{t("유형별 작업자 수")}</div>
                        <div style={{position:'absolute' , top:'55%', left:'50%',transform: 'translate(-50%, -55%)', fontWeight:'600'}}> {totoal_worker}</div>

                        <ResponsivePie
                            // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                            colors={{ scheme: 'blues' }}
                            className='pie'
                            data={piedata}
                            margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                            valueFormat=" >-"
                            cornerRadius={2}
                            innerRadius={0.65}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabel={function (e) { return e.id + "(" + ((e.value / _.sumBy(piedata, 'value')) * 100).toFixed(0) + "%)" }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextOffset={5}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsDiagonalLength={5}
                            arcLinkLabelsStraightLength={5}
                            arcLinkLabelsColor={{ from: 'color' }}
                            enableArcLabels={true}
                            arcLabel={function (e) { return e.value }}
                            arcLabelsRadiusOffset={0.5}
                            arcLabelsSkipAngle={0}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        3
                                    ]
                                ]
                            }}

                            legends={[]}
                        />

                    </>
                        
     
                </GridLayoutItem>

            </GridLayout>
        </>
    );

}

export default PTW