import axios from 'axios';

const {REACT_APP_URI2} = process.env;

export const logout = async ({ username, project ,client}) => {
  try {
    // 서버에 로그아웃 요청
    await axios.get(`http://43.200.223.224:5000/logout`, {
      params: {
        username,
        project
      },
      withCredentials: true
    });

    const redirectPath = client? `/${project}/client/login`:`/${project}/login`
    // const redirectPath = `/${project}/login`

    // 로그인 페이지로 리다이렉트
    window.location = redirectPath;
  } catch (error) {
    console.error("Error during logout:", error);
  }
};