import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import Grid from './main';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import PJTOverview from './pjtoverview/projectoverview';
import CProgress from './progress/CProgress/pageCprogress';
import CWPStatus from './CWP/pagecwp';
import ManEquip from './manequip/pagemanequip'
import Safety from './safety/pagesafety';
import Quality from './quality/pagequality';
import Photo from './photo/pagephoto';
import Environment from './environment/pageenvio';
import Concern from './concern/pageconcern';
import Tab from './tab';
import SafetyALL from './companychart/safety/ESGpagesafety';
import QualityALL from './companychart/quality/ESGpagequality';
import ESGEnvironment from './companychart/environment/ESGpageenvio';
import TabESG from './tabESG';
import ResourceAssign from './ems/resourceAssign';
import ResourceGantt from './ems/gantt';
import EntireALL2 from './ALL/pages2';
import SkoneEMSProgress from "./SkoneEMSProgress/SkoneEMSProgress";
import SkonEMSOrgChart from "./SkonEMSOrgChart/SkonEMSOrgChart";
import PermitTimeLine from "./PermitTimeLine/PermitTimeLine";
import SkoneEMSOverall from "./SkoneEMSOverall/SkoneEMSOverall"
import SkoneEMSManHour from "./SkoneEMSManHour/SkoneEMSManHour"
import InputTab from './ALL/input/input';
import SkoneAreaChart from "./SkoneResourceAreaChart/SkoneAreaChart"
import SkonEmsResourcePlan from './SkonEmsResourcePlan/SkonEmsResourcePlan';
import SkoneEMSActionList from "./SkoneEMSActionList/SkoneEMSActionList";
import ProjectOverviewTest from "./SkoneEMSQuality/ProjectOverview";
import CriticalProcess2 from "./SkoneEMSQuality/CriticalProcess/CriticalProcess2";
import SignIn from './login/login'
import SignUp from './login/signup'
import PrivateRoute from './privateRoute'
import ResetPassword from './login/resetpw'
import './i18n';
import TabOwner from './tabowner';
import TestViewer from './CWP/testview';
import TotalProgress from './progress/progressTotal';
import ETotalProgress from './progress/eprogressTotal';
import PTotalProgress from './progress/pprogressTotal';
import CTotalProgress from './progress/cprogressTotal';
import EDPRscon from './edpr/edprScon';
import RPPRscon from './edpr/rpprScon';
import { LicenseInfo } from '@mui/x-license-pro';
import DRscon from './edpr/registerScon';
import { RecoilRoot } from 'recoil';
import EDPRsconR from './edpr/edprSconRead';
import RPPRsconR from './edpr/rpprSconRead';
import DRsconP from './edpr/registerSconP';
import ClientData from './edpr/clientScon';
import MyDocumentViewerModalSquad from './edpr/viewer/modalSquadCheck';
import MyDocumentViewerModalSquadLast from './edpr/viewer/modalSquadCheckLast';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
  <BrowserRouter>
    <Switch>

      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/reset-password" component={ResetPassword} />

      <Route exact path="/esg" component={TabESG} />
      <Route exact path="/resource" component={ResourceAssign} />
      <Route exact path="/resource/gantt" component={ResourceGantt} />
      <Route exact path="/resource/chart" component={SkoneAreaChart} />


      <Route exact path="/esg/safety" component={SafetyALL} />
      <Route exact path="/esg/quality" component={QualityALL} />
      <Route exact path="/esg/environment" component={ESGEnvironment} />

      <Route exact path="/:project_code/client/login" component={SignIn} />
      <Route exact path="/:project_code/login" component={SignIn} />

      <Route exact path="/:project_code/edpr" component={EDPRscon} />
      <Route exact path="/:project_code/edprr" component={EDPRsconR} />
      <Route exact path="/:project_code/rpp" component={RPPRscon} />
      <Route exact path="/:project_code/rppr" component={RPPRsconR} />
      <Route exact path="/:project_code/drawingregister" component={DRscon} />
      <Route exact path="/:project_code/mrregister" component={DRsconP} />
      <PrivateRoute exact path="/:project_code/clientTR" component={ClientData} />


      <Route exact path="/:project_code/emsprogress" component={SkoneEMSProgress} />
      <Route exact path="/:project_code/emsorg" component={SkonEMSOrgChart} />
      <Route exact path="/:project_code/permit" component={PermitTimeLine} />
      <Route exact path="/:project_code/qualityp" component={ProjectOverviewTest} />
      <Route exact path="/:project_code/criticalprocess" component={CriticalProcess2} />
      <Route exact path="/:project_code/emsmanhour" component={SkoneEMSManHour} />
      <Route exact path="/ems/overall" component={SkoneEMSOverall} />
      <Route exact path="/ems/resource" component={SkonEmsResourcePlan} />
      <Route exact path="/ems/actionlist" component={SkoneEMSActionList} />
  
      <Route exact path="/input/:project_code" component={InputTab} />

      {/* <Route exact path="/cad" component={TestViewer} /> */}



      <PrivateRoute exact path="/total" component={EntireALL2} />
      

      <PrivateRoute exact path="/:project_code" component={Tab} />
      <PrivateRoute exact path="/:project_code/client" component={TabOwner} />

      <Route exact path="/:project_code/overview" component={PJTOverview} />
      <Route exact path="/:project_code/cprogress" component={CTotalProgress} />
      <Route exact path="/:project_code/progress" component={TotalProgress} />
      <Route exact path="/:project_code/eprogress" component={ETotalProgress} />
      <Route exact path="/:project_code/pprogress" component={PTotalProgress} />
      <PrivateRoute exact path="/:project_code/cwp" component={CWPStatus} />
      <PrivateRoute exact path="/:project_code/mp" component={ManEquip} />
      <PrivateRoute exact path="/:project_code/safety" component={Safety} />
      <PrivateRoute exact path="/:project_code/quality" component={Quality} />
      <PrivateRoute exact path="/:project_code/photo" component={Photo} />
      <PrivateRoute exact path="/:project_code/environment" component={Environment} />
      <PrivateRoute exact path="/:project_code/concern" component={Concern} />

      <PrivateRoute exact path="/:project_code/documentViewer" component={MyDocumentViewerModalSquad} />
      <PrivateRoute exact path="/:project_code/documentViewerLast" component={MyDocumentViewerModalSquadLast} />


    </Switch>
  </BrowserRouter>
  </RecoilRoot>
  ,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
