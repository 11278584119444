import React, { useMemo, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { orderBy } from "@progress/kendo-data-query";
import {
  Chart,
  ChartLegend,
  ChartTooltip,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";

const mediaQuery = "(min-resolution: 120dpi)";

const mediaQueryList = window.matchMedia(mediaQuery);

const ActionListTileBody = ({ height, actionListData = [] }) => {
  const [sort, setSort] = useState([{}]);

  const tile_height = useMemo(() => {
    const title_height = mediaQueryList.matches ? 30.14 : 47.84;

    return height - title_height;
    // eslint-disable-next-line
  }, [height, mediaQueryList]);

  const SharedTooltip = (props) => {
    const { categoryText, points } = props;

    const total_value = points.reduce((acc, cum) => {
      return (acc = acc + cum.value);
    }, 0);

    return (
      <div>
        <div>{categoryText}</div>
        <div>Total : {total_value}</div>
        {points.map((point, index) => (
          <div key={index}>
            {point.series.name} : {point.value}
          </div>
        ))}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <GridLayout
      gap={{
        rows: 5,
        cols: 0,
      }}
      rows={[
        {
          height: (tile_height - 5) * 0.6,
        },
        {
          height: (tile_height - 5) * 0.4,
        },
      ]}
      cols={[
        {
          width: "100%",
        },
      ]}
    >
      <GridLayoutItem row={1} col={1}>
        <Grid
          data={orderBy(actionListData, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          style={{
            height: (tile_height - 5) * 0.6,
          }}
        >
          <GridColumn
            field="ugenProjectName"
            title="Project 명"
            cell={stringCell}
          />
          <GridColumn
            field="total"
            title="Total"
            width={75}
            cell={stringCell}
          />
          <GridColumn
            field="closed"
            title="Closed"
            width={75}
            cell={stringCell}
          />
          <GridColumn field="open" title="Open" width={75} cell={stringCell} />
          <GridColumn
            field="progress"
            title="진행률"
            width={75}
            cell={percentCell}
          />
        </Grid>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={1}>
        <Chart>
          <ChartArea height={(tile_height - 5) * 0.4} />
          <ChartTooltip shared={true} render={sharedTooltipRender} />
          <ChartLegend
            position="bottom"
            orientation="horizontal"
            labels={{ font: "0.9rem Pretendard-R" }}
          />
          <ChartSeries>
            <ChartSeriesItem
              type="bar"
              stack={{ type: "100%" }}
              data={actionListData}
              field="closed"
              categoryField="ugenProjectName"
              name="Closed"
              color={"rgb(62, 111, 195)"}
            />
            <ChartSeriesItem
              type="bar"
              stack={{ type: "100%" }}
              data={actionListData}
              field="open"
              categoryField="ugenProjectName"
              name="Open"
              color={"rgb(236, 125, 49)"}
            />
          </ChartSeries>
        </Chart>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ActionListTileBody;

const stringCell = (props) => {
  const field = props.field || "";
  const Dev = props.dataItem[field];
  return <td style={{ fontSize: "0.8rem", textAlign: "center" }}>{Dev}</td>;
};

const percentCell = (props) => {
  const field = props.field || "";
  const Dev = props.dataItem[field];
  return (
    <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
      {Number(Number(Dev).toFixed(1)).toLocaleString()}%
    </td>
  );
};
