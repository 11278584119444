import * as React from "react";

import { Calendar } from "@progress/kendo-react-dateinputs";

const CustomCalendar =({value, onChange}) => {


    return (
      <Calendar
        bottomView="year"
        topView="year"
        value={value}
        onChange={onChange}
      />
    );
}


export default CustomCalendar