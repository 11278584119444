import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import "../../kendocustom.css";
import ProjectDetail from './projectdetail';
import * as mnode from '../../nodelibrary'
import PTWWorker from './ptwworker';
import ManCount from './mancount';
import SafetyMan from './safetyman';
import TotalLTIR from './totalltir';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const SafetyALL = () => {
  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },

      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },


    ],

    tablet: [

      {
        col: 1,
        colSpan: 10,
        rowSpan: 2,
        status: true,
      },
      {
        col: 11,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
      {
        col: 13,
        colSpan: 6,
        status: true,
        rowSpan: 2,
      },

      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },


    ],

    desktop: [

      {
        col: 1,
        colSpan: 10,
        rowSpan: 2,
        status: true,
      },
      {
        col: 11,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
      {
        col: 13,
        colSpan: 6,
        status: true,
        rowSpan: 2,
      },

      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },



    ]
  }


  const GRIDCONFIG_filterd = { mobile: _.filter(GRID_CONFIG['mobile'], { 'status': true }), tablet: _.filter(GRID_CONFIG['tablet'], { 'status': true }), desktop: _.filter(GRID_CONFIG['desktop'], { 'status': true }) }


  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);


  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);

  ///////////데이터 가공
  const [time, settime] = useState(moment().format("YYYY-MM-DD hh:mm"));

  const [projectlist, setrprojectlist] = useState([]);
  const [Division, setDivision] = useState([]);


  const [ltirfilelist, setltirfilelist] = useState([]);


  const getFolder = () => {
    mnode.getFileFolderList('/'+REACT_APP_PATH2+'/file/ltir/', setltirfilelist);
  }
  useEffect(getFolder, []);




  const updatetime = async () => {

    settime(moment().format("YYYY-MM-DD hh:mm"))
    const res = await axios.get(`http://43.200.223.224:5000/getproject`, )


    const pjtList = _.filter(res.data.data.data, { "shell_type": "Projects_Generic" }).map((v) => { return { projectcode: v.ugenProjectNumber, projectname: v.ugenProjectName, division: v.uuu_location.slice(v.uuu_location.lastIndexOf('/SK 에코엔지니어링/') + 12) } }).filter(item => !item.projectname.includes('TEST'))
    const divisionList = _.uniqBy(pjtList, 'division').filter(item => item.division !== 'SK 에코엔지니어링')

    setrprojectlist(pjtList)
    setDivision(divisionList)

  };


  useEffect(() => {
    updatetime();
  }, []);

  const [mandata2, setmandata2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/datafiles/newgate.json`)
      setmandata2(res.data.map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.projectcode) })).map((v) => ({ ...v, uniq: v.직종 + "//" + v.division + "//" + v.d_reporting_date.slice(0, 10) })))

    }

    fetchData();
  }, [projectlist]);



  const [equipdata, setequipdata] = useState([]);


  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/datafiles/equipdata.json`)

      setequipdata(res.data)
      // setequipdata([...equiparray1, ...equiparray2])

    }

    fetchData();
  }, []);



  const [safetyddata, setsafetyddata] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/datafiles/ptw.json`)
      setsafetyddata(res.data)
    }

    fetchData();
  }, []);


  const [ltirdata, setltirdata] = useState([]);
  const ltirlist = useMemo(() => ltirfilelist, [ltirfilelist]);


  useEffect(() => {
    const fetchData = async () => {

      const requestArr = _.map(ltirlist.fileList, function (o) { return o.name }).map(async data => {
        return await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/ltir/${data}`, )
    });

    const res = await Promise.all(requestArr)
    const reslist = res.map(v => v.data).flat(1).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { 전사ltir: v.LTIRCumulDA, date: v.LTIRBaseDate , record_no:v.record_no}))), []);



      setltirdata(reslist)
    }

    fetchData();
  }, [ltirlist, time]);



  const tiles = [{
    header: (<div style={{ position: 'relative' }}>LTIR</div>),
    body: <TotalLTIR positiondata={positiondata} ltirdata={ltirdata} projectlist={projectlist} height={height} />,
    status: true,
    resizable: false,
    reorderable: false,

  }, {
    header: (<div style={{ position: "relative" }}>안전보건 인력선임 현황</div>),
    body: <SafetyMan positiondata={positiondata} projectlist={projectlist} height={height} />,
    status: true,
    resizable: false,
    reorderable: false,
  },
  {
    header: <div style={{ position: "relative" }}><span>인력투입현황</span> <span style={{ fontSize: '0.8rem' }}> (Gate System 기준)</span></div>,
    body: <ManCount positiondata={positiondata} height={height} mandata2={mandata2} Division={Division} projectlist={projectlist} />,
    status: true,
    resizable: false,
    reorderable: false,
  },

  {
    header: (<div style={{ position: 'relative' }}>위험작업 인력투입현황<span style={{ fontSize: '0.8rem' }}> (작업허가서 기준)</span></div>),
    body: <PTWWorker positiondata={positiondata} height={height} safetyddata={safetyddata} />,
    status: true,
    resizable: false,
    reorderable: false,
  },



  {
    header: (<div style={{ position: 'relative' }}>PJT별 안전관리 현황</div>),
    body: <ProjectDetail positiondata={positiondata} safetyddata={safetyddata} mandata2={mandata2}
      // mandata={mandata} 
      ltirdata={ltirdata} height={height} projectlist={projectlist} Division={Division} equipdata={equipdata} />,
    status: true,
    resizable: false,
    reorderable: false,

  }
  ];


  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, safetyddata, mandata2, ltirdata, projectlist, Division, equipdata]);

  const tile_filtered = _.filter(tile, ['status', true]);


  return (
    <div>

      <TileLayout
        columns={18}
        rowHeight={(height - 20) / 7}
        positions={positiondata}
        gap={{
          rows: 20,
          columns: 20
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='esg'
        style={{ height: height - 20 }}

      />


    </div>
  )

    ;
};

export default SafetyALL;