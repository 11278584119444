import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { useTranslation } from "react-i18next";
import { generateUpdatedDate } from "../common/datadate";

const ImprovementStatus = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [data, setData] = useState({
    chartData: [
      { cate: "위반", air: 0, water: 0, waste: 0, noise: 0 },
      { cate: "보완", air: 0, water: 0, waste: 0, noise: 0 },
    ],
    breach: 0,
    complement: 0,
  });

  useEffect(() => {
    const boxHeight = positiondata[order];

    // setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 35);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetch_data = async () => {
      try {
        const res = await axios
          .post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, request_body)
          .then((data) =>
            data.data.data.data.filter(
              (c) =>
                c.CheckupDate && c._bp_lineitems && c._bp_lineitems.length > 0
            )
          );

        const line_items_flat = res.reduce(
          (acc, cur) => {
            const { _bp_lineitems } = cur;

            const target_line = _bp_lineitems.filter(
              (c) => c.uuu_tab_id === "일일점검 체크리스트"
            );

            acc.breach_list.push(
              ...target_line.filter((c) => c.CheckupResult === "위반")
            );

            acc.complement_list.push(
              ...target_line.filter((c) => c.CheckupResult === "보완")
            );

            return acc;
          },
          { breach_list: [], complement_list: [] }
        );

        const { breach_list, complement_list } = line_items_flat;

        setData((prev) => {
          return {
            ...prev,
            chartData: [
              {
                cate: "위반",
                air: returnZeroIfInvalid(
                  breach_list.filter((c) => c.CheckupPD === "대기").length
                ),
                water: returnZeroIfInvalid(
                  breach_list.filter((c) => c.CheckupPD === "수질").length
                ),
                waste: returnZeroIfInvalid(
                  breach_list.filter((c) => c.CheckupPD === "폐기물").length
                ),
                noise: returnZeroIfInvalid(
                  breach_list.filter((c) => c.CheckupPD === "소음진동").length
                ),
              },
              {
                cate: "보완",
                air: returnZeroIfInvalid(
                  complement_list.filter((c) => c.CheckupPD === "대기").length
                ),
                water: returnZeroIfInvalid(
                  complement_list.filter((c) => c.CheckupPD === "수질").length
                ),
                waste: returnZeroIfInvalid(
                  complement_list.filter((c) => c.CheckupPD === "폐기물").length
                ),
                noise: returnZeroIfInvalid(
                  complement_list.filter((c) => c.CheckupPD === "소음진동")
                    .length
                ),
              },
            ],
            breach: Number(
              (
                (breach_list.filter((c) => c.Statustxt === "승인").length /
                  breach_list.length) *
                100
              ).toFixed(0)
            ),
            complement: Number(
              (
                (complement_list.filter((c) => c.Statustxt === "승인").length /
                  complement_list.length) *
                100
              ).toFixed(0)
            ),
          };
        });
      } catch (err) {
        console.log(err);
        setData({
          chartData: [
            { cate: "위반", air: 0, water: 0, waste: 0, noise: 0 },
            { cate: "보완", air: 0, water: 0, waste: 0, noise: 0 },
          ],
          breach: 0,
          complement: 0,
        });
      }
    };

    fetch_data();
  }, [project_code]);

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    // content: labelContent2,
  };

  const labelContent2 = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value;

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-B",
    position: "top",
    margin: { top: -15 },
    background: "none",
    content: labelContent2,
  };

  const legendlabel = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    background: "none",
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points } = props;

    return (
      <div>
        <div>{points[0].category}</div>
        {points.map((point, index) => (
          <div key={index}>
            {point.value === 0 ? (
              <span>{point.series.name} : 0 건 </span>
            ) : (
              <span>
                {point.series.name} :{" "}
                {Number(point.value).toLocaleString() + " 건"}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <GridLayout
      style={{ height: BoxHeight }}
      rows={[
        {
          height: BoxHeight - 60,
        },
        {
          height: 20,
        },
        {
          height: 40,
        },
      ]}
      cols={[
        {
          width: 80,
        },
        {
          width: "calc((100% - 80px) / 2) ",
        },
        {
          width: "calc((100% - 80px) / 2) ",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
    >
      <GridLayoutItem
        row={1}
        col={1}
        colSpan={3}
        // className="envCardCenterStyle"
      >
        <Chart
        // pannable={{
        //   lock: "y",
        // }}
        // zoomable={{
        //   mousewheel: {
        //     lock: "y",
        //   },
        //   selection: {
        //     lock: "y",
        //   },
        // }}
        >
          <ChartArea height={BoxHeight - 60} />
          <ChartLegend position="bottom" labels={legendlabel} />
          <ChartTooltip shared={true} render={sharedTooltipRender} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={["위반", "보완"]}
              maxDivisions={10}
              startAngle={45}
              labels={seriesLabels}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{ font: "0.7rem Pretendard-R" }}
              majorGridLines={{ step: 10 }}
              minorGridLines={{ step: 10 }}
            />
          </ChartValueAxis>

          <ChartSeries>
            <ChartSeriesItem
              type="column"
              data={data.chartData}
              field="air"
              categoryField="cate"
              labels={seriesLabels_0}
              color={"#5B9BD5"}
              name="대기"
            />
            <ChartSeriesItem
              type="column"
              data={data.chartData}
              field="water"
              categoryField="cate"
              labels={seriesLabels_0}
              color={"#ED7D32"}
              name="수질"
            />
            <ChartSeriesItem
              type="column"
              data={data.chartData}
              field="waste"
              categoryField="cate"
              labels={seriesLabels_0}
              color={"#A5A5A5"}
              name="폐기물"
            />
            <ChartSeriesItem
              type="column"
              data={data.chartData}
              field="noise"
              categoryField="cate"
              labels={seriesLabels_0}
              color={"#FFBF00"}
              name="소음진동"
            />
          </ChartSeries>
        </Chart>
      </GridLayoutItem>
      <GridLayoutItem row={3} col={1} className="envCardCenterStyle">
        <div className={"envBoldTextStyle"}>조치율</div>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={2} className="envCardCenterStyle">
        <div className={"envBoldTextStyle"}>위반</div>
      </GridLayoutItem>
      <GridLayoutItem row={3} col={2} className="envCardCenterStyle">
        <div className={"envPercentStyle"}>{data.breach}%</div>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={3} className="envCardCenterStyle">
        <div className={"envBoldTextStyle"}>보완</div>
      </GridLayoutItem>
      <GridLayoutItem row={3} col={3} className="envCardCenterStyle">
        <div className={"envPercentStyle"}>{data.complement}%</div>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ImprovementStatus;

const request_body = {
  bpname: "Daily Environmental Checkup",
  lineitem: "yes",
  filter_criteria: {
    join: "AND",
    filter: [
      {
        field: "status",
        value: "Terminated",
        condition_type: "neq",
      },
      {
        field: "status",
        value: "Auto_Created",
        condition_type: "neq",
      },
    ],
  },
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};
