import React, { useEffect, useState } from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Photo from "./photo/pagephoto";
import PJTOverview from "./pjtoverview/projectoverview";
import Safety from "./safety/pagesafety";
import CProgress from "./progress/CProgress/pageCprogress";
import CWPStatus from "./CWP/pagecwp";
import ManEquip from "./manequip/pagemanequip";
import Quality from "./quality/pagequality";
import Concern from "./concern/pageconcern";
import Environment from "./environment/pageenvio";
import { useParams } from 'react-router-dom'; 
 import axios from "axios";import Cookies from 'js-cookie';
import useDocumentTitle from './useDocumentTitle';
import { useTranslation } from 'react-i18next'

import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';



const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
      color: 'black!important',
      backgroundColor: 'snow',
  },
  '&.MuiToggleButton-root': {
    color:"snow",
      fontSize: '0.7rem',
      fontFamily: 'Pretendard-R',
      padding: 0,
      width: 50,
      borderRadius: '0px 0px 0px 0px!important',
      border: '1px solid #fff!important'

  }
}));


const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
          marginRight: theme.spacing(0)
      }
  }
}));

const theme = createTheme({
  palette: {
      text: {
          primary: '#00ff00',
      },
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const TabOwner = () => {

  const { t, i18n } = useTranslation();
    const [selected, setSelected] = React.useState(0);
    const { project_code } = useParams();
    const { height, width } = useWindowDimensions();

    const [projectName, setprojectName] = useState("");
    const [selectedValue, setSelectedValue] = React.useState("ko");

    useEffect(() => {
      const fetchData = async () => {
        // const res = await axios.get(`http://43.200.223.224:5000/getproject`);
        // setprojectName(res.data.data.data.filter((com) => com.shell_number === project_code)[0]["ugenProjectName"])

        let body = {
          bpname: "ESG 대시보드 프로젝트 관리",
          lineitem: "no",
          "filter_criteria": {
            "join": "AND",
            "filter": [
              {
      
                "field": "ugenProjectNumber",
                "value": project_code,
                "condition_type": "eq"
              },
      
              {
                "field": "status",
                "value": "Active",
                "condition_type": "eq"
              }
            ]
          }
        };
        const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=SKEC0001`, body)
        setprojectName(res.data.data.data[0]["ugenProjectName"])
        setSelectedValue(res.data.data.data[0]["DashboardLanguagePD"] === "English"? "en":"ko")

        i18n.changeLanguage(res.data.data.data[0]["DashboardLanguagePD"] === "English"? "en":"ko");


      }
      fetchData();
  
    }, [project_code]);
    
    useDocumentTitle("S.CON - "+projectName+" Dashboard")
  

    const handleSelect = (e) => {
      setSelected(e.selected);
    };


    const handleChange = (event, selectedValue) => {
      if (selectedValue !== null) {
          setSelectedValue(selectedValue);
          i18n.changeLanguage(selectedValue);
      }
    };
  
    return (
        <div>
      <TabStrip className='tab1' selected={selected} onSelect={handleSelect} tabPosition='left' dir="down" style={{height:height}}>
        <TabStripTab title={t('프로젝트 개요')}>
         <PJTOverview/>
        </TabStripTab>
        {/* <TabStripTab title={t("시공 공정률")}>
          <CProgress/>
        </TabStripTab>
        <TabStripTab title={t("CWP Status")}>
          <CWPStatus/>
        </TabStripTab> */}

        <TabStripTab title={t("인원/장비 현황")}>
          <ManEquip/>
        </TabStripTab>

        {/* <TabStripTab title={t("안전")}>
            <Safety/>
        </TabStripTab> */}

        <TabStripTab title={t("품질")}>
          <Quality/>
        </TabStripTab>

        <TabStripTab title={t("환경")}>
          <Environment/>
        </TabStripTab>
        <TabStripTab title={t("현장 사진")}>
          <Photo/>
        </TabStripTab>
        <TabStripTab title={t("Area of Concern")}>
          <Concern/>
        </TabStripTab>




      </TabStrip>

      <div style={{ position: 'absolute', color: 'snow', top: '170px', zIndex: '999', width: '200px', textAlign: 'center' }}>
        <span style={{ fontSize: '1rem', fontWeight: '600' }}>{projectName}</span>


  
      </div>

      <div style={{ position: 'absolute', bottom: '35px', zIndex: '999', width: '200px', textAlign: 'center' }}>

      <div style={{ position: 'absolute',left:"50%",  transform: "translate(-50%)", zIndex: '999',textAlign: 'center'}}>
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="언어">
                                <ToggleButton value="ko" aria-label='한국어' >
                                    한국어
                                </ToggleButton>
                                <ToggleButton value="en" aria-label="english">
                                    ENG
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ThemeProvider>
                    </div>
        
      </div>


      </div>
    );
  };

export default TabOwner;
  