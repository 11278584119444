import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
    Collapse, IconButton, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TableFooter
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import moment from 'moment';
 import axios from "axios";import Cookies from 'js-cookie';
import QTimeline from "./qtimeline";
import { useMediaQuery } from 'react-responsive';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const CheckAll = ({ positiondata, projectlist, height }) => {

    const [data, setdata] = useState([]);
    const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })


    const [BoxHeight, setBoxHeight] = useState(250);
    useEffect(() => {
        const boxHeight = positiondata[0];
        setBoxHeight(height * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, height]);

    const today_year = moment().format("YYYY")

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "품질심사",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Terminated",
                            "condition_type": "neq"
                        }
                    ]
                }
            }

            const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=SKEC0001`, body)
            setdata(res.data.data.data.map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.ugenProjectName) }))
            .filter(v=> typeof v.ProjectShellPK !== 'undefined').map((v)=>({...v, projectcode: v.ugenProjectNumber, division: v.ProjectShellPK.split('/')[2]}))
            .filter(v=>v.AuditDateDO && v.AuditDateDO.slice(6,10) === today_year)
            )

        };

        fetchData();
    }, [projectlist]);

    const Division = _.uniqBy(projectlist, 'division').filter(item => item.division !== 'SK 에코엔지니어링' && item.division !== '반도체')

    // const data = data_1.filter(v=> typeof v.ProjectShellPK !== 'undefined').map((v)=>({...v, projectcode: v.ugenProjectNumber, division: v.ProjectShellPK.split('/')[2]}))


    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = useState(false);


        return (
            <React.Fragment>

                <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
                <TableCell style={{ fontSize: '1rem', border: 0, fontWeight: '600' , color:'#B0B8C2', padding:0}}>

                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                            value={true}
                            style={{color:'#E4E8ED'}}
                        >
                            {open ? <Remove /> : <Add />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ fontSize: '1rem', border: 0,  color:'#B0B8C2', fontWeight: '600', padding:'0' }} align='left'>
                        {row}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '1rem', border: 0,  color:'#B0B8C2', fontWeight: '600' , padding:'0'}}>


                    </TableCell>

                    <TableCell align="center" style={{ fontSize: '1rem', border: 0,  color:'#B0B8C2', fontWeight: '600' , padding:'0'}}>
                        {_.filter(data, function (o) { return o.division === row }).length > 0 ?
                            _.sumBy(_.filter(data, function (o) { return o.division === row }), 'OBLineCountDA').toLocaleString('ko-KR')
                            : '-'
                        }

                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '1rem', border: 0, color:'#B0B8C2', fontWeight: '600' , padding:'0'}}>

                        {_.filter(data, function (o) { return o.division === row }).length > 0 ?
                            _.sumBy(_.filter(data, function (o) { return o.division === row }), 'OCountDA').toLocaleString('ko-KR')
                            : '-'
                        }

                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '1rem', border: 0, color:'#B0B8C2', fontWeight: '600' , padding:'0'}}>
                        {_.filter(data, function (o) { return o.division === row }).length > 0 ?
                            _.sumBy(_.filter(data, function (o) { return o.division === row }), 'CLineCountDA').toLocaleString('ko-KR')
                            : '-'
                        }

                    </TableCell>

                    <TableCell align="center" style={{ fontSize: '1rem', border: 0, color:'#B0B8C2', fontWeight: '600' , padding:'0'}}>
                        {_.filter(data, function (o) { return o.division === row }).length > 0 ?
                            _.sumBy(_.filter(data, function (o) { return o.division === row }), 'CACLineCountDA').toLocaleString('ko-KR')
                            : '-'
                        }

                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '1rem', border: 0,  color:'#B0B8C2', fontWeight: '600' , padding:'0'}}>
                        {_.filter(data, function (o) { return o.division === row }).length > 0 ?
                            _.sumBy(_.filter(data, function (o) { return o.division === row }), 'ClosedItemCountDA').toLocaleString('ko-KR')
                            : '-'
                        }


                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '1rem', border: 0,  color:'#B0B8C2', fontWeight: '600', padding:'0' }}>
                        {_.filter(data, function (o) { return o.division === row }).length > 0 ?
                            isNaN(((_.sumBy(_.filter(data, function (o) { return o.division === row }), 'ClosedItemCountDA')) / (_.sumBy(_.filter(data, function (o) { return o.division === row }), 'ActionReqCountDA')) * 100))? '0%' : ((_.sumBy(_.filter(data, function (o) { return o.division === row }), 'ClosedItemCountDA')) / (_.sumBy(_.filter(data, function (o) { return o.division === row }), 'ActionReqCountDA')) * 100).toFixed(0) + '%'
                            : '-'
                        }

                    </TableCell>


                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>

                            <Table>
                                <TableBody>
                                    {_.filter(data, { 'division': row }).map((f, index) => (
                                        <TableRow key={index}>
                                            {/* <TableCell width='5%' style={{ paddingTop: '5px', paddingBottom: '5px' }}></TableCell> */}
                                            <TableCell width='13%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', paddingLeft: '10%', borderBottom: 'none' }}align="right">{f.QARPSRNo}</TableCell>
                                            <TableCell width='17%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', paddingLeft: '10%', borderBottom: 'none' }}align="left">{f.ugenProjectName}</TableCell>

                                            <TableCell width='10%' align="center" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', borderBottom: 'none'}}>

                                                {_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }).length > 0 ?
                                                    _.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'OBLineCountDA').toLocaleString('ko-KR')
                                                    : '-'
                                                }

                                            </TableCell>

                                            <TableCell width='10%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', borderBottom: 'none'}} align="center">


                                                {_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }).length > 0 ?
                                                    _.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'OCountDA').toLocaleString('ko-KR')
                                                    : '-'
                                                }

                                            </TableCell>
                                            <TableCell width='10%' align="center" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', borderBottom: 'none'}}>

                                                {_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }).length > 0 ?
                                                    _.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'CLineCountDA').toLocaleString('ko-KR')

                                                    : '-'
                                                }

                                            </TableCell>

                                            <TableCell width='10%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', borderBottom: 'none'}} align="center">

                                                {_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }).length > 0 ?
                                                    _.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'CACLineCountDA').toLocaleString('ko-KR')

                                                    : '-'
                                                }
                                            </TableCell>
                                            <TableCell width='10%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px', color:'#B0B8C2', borderBottom: 'none'}} align="center">

                                                {_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }).length > 0 ?
                                                    _.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'ClosedItemCountDA').toLocaleString('ko-KR')

                                                    : '-'
                                                }

                                            </TableCell>

                                            <TableCell width='10%' style={{ fontSize: '0.8rem', paddingTop: '5px', paddingBottom: '5px',color:'#B0B8C2' , borderBottom: 'none'}} align="center">

                                                {_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }).length > 0 ?
                                                    isNaN(((_.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'ClosedItemCountDA')) / (_.sumBy(_.filter(data, function (o) { return o.division === row }), 'ActionReqCountDA')) * 100)) ? '0%': ((_.sumBy(_.filter(data, function (o) { return o.QARPSRNo === f.QARPSRNo }), 'ClosedItemCountDA')) / (_.sumBy(_.filter(data, function (o) { return o.division === row }), 'ActionReqCountDA')) * 100).toFixed(0) + '%'
                                                    : '-'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const COLORS = [
        "#34568B",
        "#6B5B95",
        "#FF6F61",
        "#88B04B"
    ]

    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '50%',
                    },

                    {
                        height: '50%',
                    },

                ]}

                cols={[
                    {
                        width: '100%',
                    },

                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    <QTimeline height={isDPI ? (height/2)+20 :height/2}/>
                </GridLayoutItem>


                <GridLayoutItem row={2} col={1} style={{ position: 'relative' }}>
                    {/* <div style={{ maring: "0 auto", height: '25px', width: "80%" }}>
                        {Division.map((v, index) => (
                            <React.Fragment key={index}>
                                <div>
                                    <span
                                        style={{
                                            float: "left",
                                            fontSize: "1.2rem",
                                            color: COLORS[index],
                                        }}
                                        className="k-icon k-font-icon k-i-circle"
                                    ></span>
                                    <span
                                        style={{
                                            float: "left",
                                            fontSize: "0.8rem",
                                            color: COLORS[index],
                                        }}
                                    >
                                        {v.division} &nbsp;&nbsp;

                                    </span>
                                </div>
                            </React.Fragment>
                        ))}
                    </div> */}
                    <TableContainer style={{ height: BoxHeight / 2 }}>
                        <Table stickyHeader aria-label="collapsible table" size='small' className="proejctdetail" style={{ overflow: 'auto' }}>
                            <TableHead>

                                <TableRow >
                                    <TableCell width='3%' style={{ borderBottom: 'none', backgroundColor:'#464B52', color:'#E4E8ED' }} />
                                    <TableCell width='13.5%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QAR번호</TableCell>
                                    <TableCell width='13.5%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">프로젝트</TableCell>
                                    <TableCell width='10%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center" >우수사항</TableCell>
                                    <TableCell width='10%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}}align="center">관찰사항</TableCell>
                                    <TableCell width='10%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">시정</TableCell>
                                    <TableCell width='10%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">시정조치</TableCell>
                                    <TableCell width='10%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">완료건수</TableCell>
                                    <TableCell width='10%' style={{ fontSize: '1rem', paddingBottom: '0px', borderBottom: 'none', fontWeight: 'bold' , backgroundColor:'#464B52' ,color:'#F1F2F5'}} align="center">조치 공정율</TableCell>
                                </TableRow>


                            </TableHead>
                            <TableBody style={{ border: 0 }} >



                                <TableRow >
                                    <TableCell width='3%' style={{border:0, background:'#676D77 0% 0% no-repeat padding-box'}} />
                                    <TableCell width='13.5%' style={{border:0, fontSize: '1rem', paddingBottom: '6px', paddingTop: '6px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center"></TableCell>
                                    <TableCell width='13.5%' style={{border:0, fontSize: '1rem', paddingBottom: '6px', paddingTop: '6px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center">전 현장</TableCell>
                                    <TableCell width='10%' style={{border:0, fontSize: '1rem', paddingBottom: '6px', paddingTop: '6px' , fontWeight: 'bold', color:'#F49B42', background:'#676D77 0% 0% no-repeat padding-box'}} align="center" >
                                        {_.sumBy(data, 'OBLineCountDA').toLocaleString('ko-KR')}
                                    </TableCell>
                                    <TableCell width='10%' style={{border:0, fontSize: '1rem', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                        {_.sumBy(data, 'OCountDA').toLocaleString('ko-KR')}
                                    </TableCell>
                                    <TableCell width='10%' style={{border:0, fontSize: '1rem', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                        {_.sumBy(data, 'CLineCountDA').toLocaleString('ko-KR')}
                                    </TableCell>
                                    <TableCell width='10%'style={{border:0, fontSize: '1rem', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                        {_.sumBy(data, 'CACLineCountDA').toLocaleString('ko-KR')}
                                    </TableCell>
                                    <TableCell width='10%' style={{border:0, fontSize: '1rem', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                        {_.sumBy(data, 'ClosedItemCountDA').toLocaleString('ko-KR')}

                                    </TableCell>
                                    <TableCell width='10%' style={{border:0, fontSize: '1rem', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', color:'#F49B42' , background:'#676D77 0% 0% no-repeat padding-box'}} align="center">
                                        {isNaN(((_.sumBy(data, 'ClosedItemCountDA') / _.sumBy(data, 'ActionReqCountDA'))* 100)) ? '0': ((_.sumBy(data, 'ClosedItemCountDA') / _.sumBy(data, 'ActionReqCountDA'))* 100).toFixed(0)}%

                                    </TableCell>
                                </TableRow>
                                {Division.map((pi, index) => (
                                    <Row key={pi.division} row={pi.division} index={index} />
                                ))}
                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                </GridLayoutItem>


            </GridLayout>

        </>);

};

export default CheckAll;
