import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import axios from "axios";

const PDFTronDR = ({ initialDocUrl, documenTitle,  username }) => {
  const viewer = useRef(null);
  const usernameR =username?username:'defaultUser'

  // if using a class, equivalent of componentDidMount 
  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        initialDoc: initialDocUrl,
        licenseKey: 'demo:1712033365211:7f00e5e80300000000ef1ecbafeaacf47ddd5a5ba2430f4c4fa58b2d09',
        enableMeasurement: true
      },
      viewer.current,
    ).then((instance) => {
      // instance.UI.enableFeatures([instance.Feature.Measurement]);

      const { documentViewer, annotationManager, Annotations } = instance.Core;
      annotationManager.setCurrentUser(usernameR);
      documentViewer.addEventListener('documentLoaded', () => {
        // perform document operations
      });

    });
  }, []);

  return (
    <div className="App">
      <div className="header">{documenTitle}</div>
      <div className="webviewer"style={{ height: "70vh" }} ref={viewer}></div>
    </div>
  );
};

export default PDFTronDR;