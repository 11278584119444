import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import axios from "axios"; import Cookies from 'js-cookie';
import moment from "moment";

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;;

const PQIData = () => {

  const { project_code } = useParams();


  const [checkdata, setcheckdata] = useState([]);
  const [ncrdata, setncrdata] = useState([]);
  const [punchdata, setpunchdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const requestArr2 = await axios.get(
        `http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/qualitydaily/ncr/${project_code}.json`,
      );

      const reslist2 = requestArr2.data

      const requestArr3 = await axios.get(
        `http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/qualitydaily/check/${project_code}.json`,
      );
      const reslist3 = requestArr3.data;

      const requestArr4 = await axios.get(
        `http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/qualitydaily/punch/${project_code}.json`,
      );
      const reslist4 = requestArr4.data;

      setcheckdata(reslist3);

      setncrdata(reslist2);

      setpunchdata(reslist4);

    };

    fetchData();
  }, [project_code]);


  /////////////
  const ncrdatau = ncrdata
    .filter((v) => v.status !== "Terminated" && v.NCRTitle !== "Terminated"
      // && v.status === 'Closed'
    );

  const punchdatau = punchdata.filter(
    (v) => v.status !== "Terminated" && v.PunchTitle250 !== "Terminated"
  );

  var checkdatau =
    checkdata
      .filter((v) => v.status !== "Terminated" && v.InspItem250 !== "Test" && v.InspItem250 !== "Terminated" && v.status !== 'Canceled' && v.status !== 'Rejected_by_SK' && v.status !== 'Rejected_by_Biz')
      .filter(v => (v.d_actual_insp_date && moment(v.d_actual_insp_date.slice(0, 10)).diff(moment(), "days") < 0))
      .map((v) => ({
        ...v, RFIEndDate: v.RFIEndDate === null ? moment().format("MM-DD-YYYY") : v.RFIEndDate,
        d_actual_insp_date: v.d_actual_insp_date === null ? v.d_inspection_date : v.d_actual_insp_date
      }))

  const dates = _.uniq([
    ...ncrdatau
      .filter((v) => v.NCPIssueDP !== null)
      .map((v) => moment(v.NCPIssueDP).format("YY-MM-DD")),
    ...punchdatau
      .filter((v) => v.d_punch_issue_date !== null)
      .map((v) => moment(v.d_punch_issue_date).format("YY-MM-DD")),
    ...checkdatau
      .filter((v) => v.InspReqDP !== null)
      .map((v) => moment(v.InspReqDP).format("YY-MM-DD")),
  ]);

  const vendorInfo = [
    // ...ncrdatau
    //   .filter((v) => v.Subcontractor_dpk !== null)
    //   .map((v) => ({ vendor: v.Subcontractor_dpk, discipline: v.qa_discipline_pd }))
    // ,
    // ...punchdatau
    //   .filter((v) => v.Subcontractor_dpk !== null)
    //   .map((v) => ({ vendor: v.Subcontractor_dpk, discipline: v.qa_discipline_pd })),
    ...checkdatau
      .filter((v) => v.Subcontractor_dpk !== null)
      .map((v) => ({ vendor: v.Subcontractor_dpk, discipline: v.qa_discipline_pd })),
  ]

  const subcon = _.uniqBy(vendorInfo, 'vendor');


  function dateRange(startDate, endDate) {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0].slice(0, 2));
    var endYear = parseInt(end[0].slice(0, 2));
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayYear = i + "년";
        var displayMonth = month < 10 ? "0" + month + "월" : month + "월";
        dates.push([displayYear, displayMonth].join(" "));
      }
    }
    return dates;
  }

  const monthList =
    _.min(dates) &&
    dateRange(_.min(dates), _.max(dates)).map((v) => ({ month: v }));



  const VendorMonthList = [];

  subcon.forEach(vendor => {
    monthList.forEach(month => {
      const vendor_month = `${month.month}_${vendor.vendor}`;
      VendorMonthList.push({
        "month": month.month,
        "vendor": vendor.vendor,
        "vendor_month": vendor_month
      });
    });
  });



  const punchdata_point = punchdatau
    .filter((f) => f.d_punch_issue_date !== null
      // &&  f.status === 'Approved'
    )
    .map((v) => ({
      ...v,
      month:
        v.d_punch_issue_date.slice(8, 10) +
        "년 " +
        v.d_punch_issue_date.slice(0, 2) +
        "월",
      issuedate: v.d_punch_issue_date,
      weeknumber: moment(v.d_punch_issue_date.slice(0, 10)).format("YYYYWW"),
      Type: 'Punch',
      group: v.Subcontractor_dpk + '//' + v.d_punch_issue_date.slice(8, 10) + "년 " + v.d_punch_issue_date.slice(0, 2) + "월",
    }));


    console.log(punchdata_point)

  const ncrdata_point = ncrdatau
    .filter((f) => f.NCPIssueDP !== null)
    .filter((v) => v.NCPTypeSPD === "NCR")
    .map((v) => ({
      ...v,
      month:
        v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      issuedate: v.NCPIssueDP,
      weeknumber: moment(v.NCPIssueDP.slice(0, 10)).format("YYYYWW"),
      Type: 'NCR',
      group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",

    }))


    
// 주어진 month에 대한 모든 weeknumber를 생성하는 함수
function getAllWeekNumbersForMonth(date) {
  let startOfMonth = moment(date, "MM-DD-YYYY").startOf('month');
  let endOfMonth = moment(date, "MM-DD-YYYY").endOf('month');

  let weekNumbers = [];
  while (startOfMonth <= endOfMonth) {
    weekNumbers.push(startOfMonth.format("YYYYWW"));
    startOfMonth.add(1, 'weeks');
  }

  return weekNumbers;
}


const updatedNcrDataPoints = [];

ncrdata_point.forEach(current => {
  const monthWeekNumbers = getAllWeekNumbersForMonth(current.issuedate);
  monthWeekNumbers.forEach(weekNumber => {
    // 현재 주 번호에 대한 데이터 생성, 중복을 허용
    const newItem = weekNumber === current.weeknumber ? {...current} : {
      ...current,
      NCRTitle: null,
      NCPGrade: null,
      uuu_record_last_update_date: null,
      Mig_Due_Date: null,
      due_date: null,
      d_ncr_result_srb: null,
      record_no: null,
      NCPActionCompDP: null,
      NCPActualCostDA: null,
      status: null,
      weeknumber: weekNumber,
      // 나머지 필드들은 여기에 null로 설정
    };
    updatedNcrDataPoints.push(newItem);
  });
})

const updatedncr = _.uniqBy(updatedNcrDataPoints.map((v)=>({...v, uniq:v.record_no+v.weeknumber})), "uniq")


  const cardata_point = ncrdatau
    .filter((f) => f.NCPIssueDP !== null)
    .filter((v) => v.NCPTypeSPD === "CAR")
    .map((v) => ({
      ...v,
      month:
        v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      issuedate: v.NCPIssueDP,
      Type: 'CAR',

    }))



const updatedPunchDataPoints = [];

punchdata_point.forEach(current => {
  const monthWeekNumbers = getAllWeekNumbersForMonth(current.d_punch_issue_date);
  console.log(monthWeekNumbers)
  monthWeekNumbers.forEach(weekNumber => {
    // 현재 주 번호에 대한 데이터 생성, 중복을 허용
    const newItem = weekNumber === current.weeknumber ? {...current} : {
      ...current,
      record_no: null,
      status: null,
      weeknumber: weekNumber,
      // 나머지 필드들은 여기에 null로 설정
    };

    console.log(newItem)
    updatedPunchDataPoints.push(newItem);
  });
})




const updatedpunch = _.uniqBy(updatedPunchDataPoints.map((v)=>({...v, uniq:v.record_no+v.weeknumber})), "uniq")


  const issue_point = [...updatedncr, ...updatedpunch];


  var issuerate_vendor = _(ncrdata_point)
    .groupBy("group")
    .map((objs, key) => ({
      month: key.split('//')[1],
      vendor: key.split('//')[0],
      issuepoint: 100 - ((objs.filter(v => v.NCPGrade === 'A').length * 50 + objs.filter(v => v.NCPGrade === 'B').length * 30 + objs.filter(v => v.NCPGrade === 'C').length * 20 + objs.filter(v => v.NCPGrade === '-').length * 10))
    }))
    .value();


  const issuepoint_month_full_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({ ...k, ...issuerate_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor) }))
      .map((v) => ({
        ...v,
        issuepoint: typeof v.issuepoint === "undefined" ? 100 : (v.issuepoint < 0 ? 0 : v.issuepoint),
        issuepoint_pqiF: typeof v.issuepoint === "undefined" ? "N/A" : "",
      }));

  var issuerate_vendor2 = _(punchdata_point)
    .groupBy("group")
    .map((objs, key) => ({
      month: key.split('//')[1],
      vendor: key.split('//')[0],
      issuepoint2: objs.length >= 50 ? 50 : 100 - objs.length
    }))
    .value();


  const issuepoint2_month_full_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({ ...k, ...issuerate_vendor2.find((sp) => sp.month === k.month && sp.vendor === k.vendor) }))
      .map((v) => ({
        ...v,
        issuepoint2: typeof v.issuepoint2 === "undefined" ? 100 : (v.issuepoint2 < 0 ? 0 : v.issuepoint2),
        issuepoint2_pqiF:typeof v.issuepoint2 === "undefined" ? "N/A" :"",
      }));


  //   issuepoint_month_full_v.forEach((item) => {
  //   item[item.vendor+'_issuepoint'] = item.issuepoint;
  // });




  var issuerate = _(issue_point)
    .groupBy("month")
    .map((objs, key) => ({
      month: key,
      issuepoint:
        _.sumBy(
          _(objs)
            .groupBy("weeknumber")
            .map((subobjs, subkey) => ({
              weeknumber: subkey,

              issuepoint_week:
                subobjs.filter((v) => v.weeknumber === subkey && v.record_no !== null).length === 0
                  ? 0
                  : subobjs.filter((v) => v.weeknumber === subkey&& v.record_no !== null).length === 1
                    ? 20
                    : subobjs.filter((v) => v.weeknumber === subkey&& v.record_no !== null).length === 2
                      ? 40
                      : subobjs.filter((v) => v.weeknumber === subkey&& v.record_no !== null).length === 3
                        ? 60
                        : subobjs.filter((v) => v.weeknumber === subkey&& v.record_no !== null).length === 4
                          ? 80
                          : subobjs.filter((v) => v.weeknumber === subkey&& v.record_no !== null).length >= 5
                            ? 100
                            : 0,
            }))
            .value(),
          "issuepoint_week"
        ) / _.uniqBy(objs, "weeknumber").length,
      issuepoint_detail:
        _(objs)
          .groupBy("weeknumber")
          .map((subobjs, subkey) => ({
            weeknumber: subkey,
            weekdate: moment().year(subkey.slice(0, 4)).week((subkey.slice(4, 6))).startOf('week').add(1, 'days').format('YY년 MM월 DD일') + "~"
              + moment().year(subkey.slice(0, 4)).week((subkey.slice(4, 6))).endOf('week').add(1, 'days').format('YY년 MM월 DD일'),
            issue_count: subobjs.filter((v) => v.weeknumber === subkey&&v.record_no !== null).length,
            issue_count_ncr: subobjs.filter((v) => v.weeknumber === subkey && v.Type === 'NCR'&&v.record_no !== null).length,
            issue_count_punch: subobjs.filter((v) => v.weeknumber === subkey && v.Type === 'Punch'&& v.record_no !== null).length,
            issuepoint_week:
              subobjs.filter((v) => v.weeknumber === subkey&&v.record_no !== null).length === 0
                ? 0
                : subobjs.filter((v) => v.weeknumber === subkey&&v.record_no !== null).length === 1
                  ? 20
                  : subobjs.filter((v) => v.weeknumber === subkey&&v.record_no !== null).length === 2
                    ? 40
                    : subobjs.filter((v) => v.weeknumber === subkey&&v.record_no !== null).length === 3
                      ? 60
                      : subobjs.filter((v) => v.weeknumber === subkey&&v.record_no !== null).length === 4
                        ? 80
                        : 100,
          }))
          .value(),

    }))
    .value();



  // 현재 날짜 기반으로 현재 주차와 연도 구하기
  const currentYear = moment().year();
  const currentWeek = moment().week();
  const currentWeekString = `${currentYear}${currentWeek < 10 ? '0' : ''}${currentWeek}`;

  let currentWeekIssuePoint = null;
  for (const monthData of issuerate) {
    const weekData = monthData.issuepoint_detail.find(w => w.weeknumber === currentWeekString);
    if (weekData) {
      currentWeekIssuePoint = weekData.issuepoint_week;
      break;
    }
  }

  const issuepoint = issuerate.map((v) => ({
    ...v,
    issuepoint: isNaN(v.issuepoint) ? 0 : v.issuepoint,
  }));




  const issuepoint_month_full_t =
    monthList &&
    monthList
      .map((k) => ({ ...k, ...issuepoint.find((sp) => sp.month === k.month) }))
      .map((v) => ({
        ...v,
        issuepoint: typeof v.issuepoint === "undefined" ? 0 : v.issuepoint,
      }));

  //PAR점수
  var par_by_month_2 = _(
    ncrdatau
      .filter((f) => f.NCPIssueDP !== null)
      .map((v) => ({
        ...v,
        month:
          v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      }))
  )
    .groupBy("month")
    .map((objs, key) => ({
      month: key,
      parpoint:
        ncrdatau
          .filter(v => objs.some(vf => vf.record_no === v.record_no))
          .filter((v) => v.NCPTypeSPD === "PAR").length * 1 >= 5
          ? 5
          :
          ncrdatau
            .filter(v => objs.some(vf => vf.record_no === v.record_no))
            .filter((v) => v.NCPTypeSPD === "PAR").length * 1,
    }))
    .value();



  const par_month_full_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...par_by_month_2.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        parpoint: typeof v.parpoint === "undefined" ? 0 : v.parpoint,
      }));

  //CAR 점수
  var car_by_month_t = _(
    ncrdatau
      .filter((f) => f.NCPIssueDP !== null)
      .map((v) => ({
        ...v,
        month:
          v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      }))
  )
    .groupBy("month")
    .map((objs, key) => ({
      month: key,
      carpoint:
        ncrdatau
          .filter(v => objs.some(vf => vf.record_no === v.record_no))
          .filter((v) => v.NCPTypeSPD === "CAR").length >= 5
          ? 5
          :
          ncrdatau
            .filter(v => objs.some(vf => vf.record_no === v.record_no))
            .filter((v) => v.NCPTypeSPD === "CAR").length,
    }))
    .value();

  const car_month_full_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...car_by_month_t.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        carpoint: typeof v.carpoint === "undefined" ? 0 : v.carpoint,
      }));


  var car_by_month_vendor = _(
    ncrdatau
      .filter((f) => f.NCPIssueDP !== null)
      .map((v) => ({
        ...v,
        month:
          v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",

      }))
  )
    .groupBy("group")
    .map((objs, key) => ({
      month: key.split("//")[1],
      vendor: key.split("//")[0],
      carpoint:
        ncrdatau
          .filter(v => objs.some(vf => vf.record_no === v.record_no))
          .filter((v) => v.NCPTypeSPD === "CAR").length > 0
          ? 0
          :
          100
    }))
    .value();


  const car_month_full_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...car_by_month_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        carpoint: typeof v.carpoint === "undefined" ? 100 : v.carpoint,
        carpoint_pqiF: typeof v.carpoint === "undefined" ? "N/A" : "",
      }));



  //long pending ncr


  const ncr_pendig_point = ncrdatau.filter(f =>
    f.NCPIssueDP !== null &&
    f.NCPTypeSPD === "NCR" &&
    f.Mig_Due_Date !== null
  ).map(v => {
    if (v.NCPActionCompDP !== null) {
      // NCPActionCompDP가 null이 아닌 경우의 로직
      return {
        ...v,
        month: v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        diffdays: moment(v.NCPActionCompDP.slice(0, 10)).diff(moment(v.Mig_Due_Date.slice(0, 10)), "days"),
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      };
    } else {
      // NCPActionCompDP가 null인 경우의 로직
      // 여기에 원하는 로직을 작성하세요.
      return {
        ...v,
        month: v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        diffdays: moment().diff(moment(v.Mig_Due_Date.slice(0, 10)), "days"),
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      };
    }
  }).map(v => {
    // 위의 매핑 함수에서 반환된 객체를 기반으로 추가적인 데이터 변환을 수행할 수 있습니다.
    return {
      ...v,
      ncr_pending_point: v.diffdays <= 0 ? 100 : v.diffdays >= 1 && v.diffdays < 7 ? 80 : v.diffdays >= 7 && v.diffdays < 14 ? 70 : 0
    };
  });


  const ncr2_by_month_clean_t =
    _(ncr_pendig_point)
      .groupBy('group')
      .map((objs, key) => ({
        month: key.split('//')[1],
        group: key,
        ncrpoint2: _.sumBy(objs, 'ncr_pending_point') / objs.length,

      }))
      .value();



  const ncr_pendig_point_v = ncrdatau.filter(f =>
    f.NCPIssueDP !== null &&
    f.NCPTypeSPD === "NCR" &&
    f.Mig_Due_Date !== null
  ).map(v => {
    if (v.NCPActionCompDP !== null) {
      // NCPActionCompDP가 null이 아닌 경우의 로직
      return {
        ...v,
        month: v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        diffdays: moment(v.NCPActionCompDP.slice(0, 10)).diff(moment(v.Mig_Due_Date.slice(0, 10)), "days"),
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      };
    } else {
      // NCPActionCompDP가 null인 경우의 로직
      // 여기에 원하는 로직을 작성하세요.
      return {
        ...v,
        month: v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        diffdays: moment().diff(moment(v.Mig_Due_Date.slice(0, 10)), "days"),
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      };
    }
  }).map(v => {
    // 위의 매핑 함수에서 반환된 객체를 기반으로 추가적인 데이터 변환을 수행할 수 있습니다.
    return {
      ...v,
      ncr_pending_point: v.diffdays <= 0 ? 100 : v.diffdays >= 1 && v.diffdays < 7 ? 80 : v.diffdays >= 7 && v.diffdays < 14 ? 70 : 50
    };
  });

  const car_pendig_point_v = ncrdatau.filter(f =>
    f.NCPIssueDP !== null &&
    f.NCPTypeSPD === "CAR" &&
    f.Mig_Due_Date !== null
  ).map(v => {
    if (v.NCPActionCompDP !== null) {
      // NCPActionCompDP가 null이 아닌 경우의 로직
      return {
        ...v,
        month: v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        diffdays: moment(v.NCPActionCompDP.slice(0, 10)).diff(moment(v.Mig_Due_Date.slice(0, 10)), "days"),
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      };
    } else {
      // NCPActionCompDP가 null인 경우의 로직
      // 여기에 원하는 로직을 작성하세요.
      return {
        ...v,
        month: v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
        diffdays: moment().diff(moment(v.Mig_Due_Date.slice(0, 10)), "days"),
        group: v.Subcontractor_dpk + '//' + v.NCPIssueDP.slice(8, 10) + "년 " + v.NCPIssueDP.slice(0, 2) + "월",
      };
    }
  }).map(v => {
    // 위의 매핑 함수에서 반환된 객체를 기반으로 추가적인 데이터 변환을 수행할 수 있습니다.
    return {
      ...v,
      car_pending_point: v.diffdays <= 0 ? 100 : v.diffdays >= 1 && v.diffdays < 7 ? 80 : v.diffdays >= 7 && v.diffdays < 14 ? 70 : 50
    };
  });

  const ncr2_by_month_clean_v =
    _(ncr_pendig_point_v)
      .groupBy('group')
      .map((objs, key) => ({
        month: key.split('//')[1],
        group: key,
        vendor: key.split('//')[0],
        ncrpoint2: _.sumBy(objs, 'ncr_pending_point') / objs.length,

      }))
      .value();

  const car2_by_month_clean_v =
    _(car_pendig_point_v)
      .groupBy('group')
      .map((objs, key) => ({
        month: key.split('//')[1],
        group: key,
        vendor: key.split('//')[0],
        carpoint2: _.sumBy(objs, 'car_pending_point') / objs.length,

      }))
      .value();




  const ncr2_month_full_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...ncr2_by_month_clean_t.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        ncrpoint2:
          typeof v.ncrpoint2 === "undefined" || isNaN(v.ncrpoint2)
            ? "N/A"
            : v.ncrpoint2,
      }));

  const ncr2_month_full_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...ncr2_by_month_clean_v.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        ncrpoint2:
          typeof v.ncrpoint2 === "undefined" || isNaN(v.ncrpoint2)
            ? "N/A"
            : v.ncrpoint2,
            ncrpoint2_pqiF:typeof v.ncrpoint2 === "undefined" ?"N/A":""
      }));

  const car2_month_full_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...car2_by_month_clean_v.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        carpoint2:
          typeof v.carpoint2 === "undefined" || isNaN(v.carpoint2)
            ? "N/A"
            : v.carpoint2,
            carpoint2_pqiF: typeof v.carpoint2 === "undefined"? "N/A":""
        
      }));

  // ncr2_month_full_v.forEach((item) => {
  //         item[item.vendor+'_ncrpoint2'] = item.ncrpoint2;
  //       });



  const checkpoint = checkdatau.filter(v => v.d_inspection_date !== null && v.InspReqDP !== null).map((l) => ({
    month: l.d_inspection_date.slice(8, 10) + "년 " + l.d_inspection_date.slice(0, 2) + "월",
    InspectionWFType: l.InspectionWFType,
    record_no: l.record_no,
    InspItem250: l.InspItem250,
    status: l.status,
    RFIEndDate: l.RFIEndDate,
    d_inspection_date: l.d_inspection_date,
    InspReqDP: l.InspReqDP,
    d_actual_insp_date: l.d_actual_insp_date,
    Subcontractor_dpk: l.Subcontractor_dpk,
    group: l.Subcontractor_dpk + '//' + l.d_inspection_date.slice(8, 10) + "년 " + l.d_inspection_date.slice(0, 2) + "월",

    point1:
      moment(l.d_inspection_date.slice(0, 10)).diff(moment(l.InspReqDP.slice(0, 10)), "days") >= 1 ? 100 :
        moment(l.d_inspection_date.slice(0, 10)).diff(moment(l.InspReqDP.slice(0, 10)), "days") === 0 ? 70 :
          moment(l.d_inspection_date.slice(0, 10)).diff(moment(l.InspReqDP.slice(0, 10)), "days") < 1 ? 50 : 0,

    point2: l.RFIEndDate &&
      moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") <= 1 ? 100 :
      l.RFIEndDate &&
        moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") > 1 &&
        l.RFIEndDate &&
        moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") < 3 ? 80 :
        l.RFIEndDate &&
          moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") >= 3 &&
          l.RFIEndDate &&
          moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") < 5 ? 70 :
          l.RFIEndDate &&
            moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") >= 5 &&
            l.RFIEndDate &&
            moment(l.RFIEndDate.slice(0, 10)).diff(moment(l.d_actual_insp_date.slice(0, 10)), "days") < 7 ? 50 : 0,

    point3: l.status === 'Rejected_by_Owner' ? -5 : 0,
    point4: l.status === 'Rejected_by_Owner' ? 95 : 100,
    point_v: l.status === 'Rejected_by_Owner' ? 0 : 100,

  }))

  const g_checkpoint_m = _(checkpoint.filter(v => v.InspectionWFType === "Material Inspection"))
    .groupBy('month')
    .map((objs, key) => ({
      month: key,
      mipoint1: _.sumBy(objs, 'point1') / objs.length,
      mipoint3: _.sumBy(objs, 'point2') / objs.length,
      mipoint2: 100 + _.sumBy(objs, 'point3'),
    }))
    .value();


  const g_checkpoint_c = _(checkpoint.filter(v => v.InspectionWFType === "Construction Inspection"))
    .groupBy('month')
    .map((objs, key) => ({
      month: key,
      cipoint1: _.sumBy(objs, 'point1') / objs.length,
      cipoint3: _.sumBy(objs, 'point2') / objs.length,
      cipoint2: 100 + _.sumBy(objs, 'point3'),

    }))
    .value();


  const g_checkpoint_m_vendor = _(checkpoint.filter(v => v.InspectionWFType === "Material Inspection"))
    .groupBy('group')
    .map((objs, key) => ({
      month: key.split('//')[1],
      vendor: key.split('//')[0],
      na_m: objs.length === 0 ? true : false,
      mipoint1: _.sumBy(objs, 'point1') / objs.length,
      mipoint3: _.sumBy(objs, 'point2') / objs.length,
      mipoint2: _.sumBy(objs, 'point_v') / objs.length,
      // mipoint2: 100 + _.sumBy(objs, 'point3'),
    }))
    .value();



  const g_checkpoint_c_vendor = _(checkpoint.filter(v => v.InspectionWFType === "Construction Inspection"))
    .groupBy('group')
    .map((objs, key) => ({
      month: key.split('//')[1],
      vendor: key.split('//')[0],
      cipoint1: _.sumBy(objs, 'point1') / objs.length,
      cipoint3: _.sumBy(objs, 'point2') / objs.length,
      // cipoint2: 100 + _.sumBy(objs, 'point3'),
      cipoint2: _.sumBy(objs, 'point_v') / objs.length,
      na_c: objs.length === 0 ? true : false,

    }))
    .value();



  //시공 인스펙션 신청 점수

  const ci_month_full1_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_c.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        cipoint1:
          typeof v.cipoint1 === "undefined" || isNaN(v.cipoint1)
            ? 100
            : v.cipoint1,
      }));

  const ci_month_full1_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_c_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        cipoint1:
          typeof v.cipoint1 === "undefined" || isNaN(v.cipoint1)
            ? 100
            : v.cipoint1,

            cipoint1_pqiF:typeof v.cipoint1 === "undefined" ?"N/A":""
      }));

  // ci_month_full1_v.forEach((item) => {
  //     item[item.vendor+'_cipoint1'] = item.cipoint1;
  //   });


  //시공 인스펙션 실패율 점수

  const ci_month_full2_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_c.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        cipoint2:
          typeof v.cipoint2 === "undefined" || isNaN(v.cipoint2)
            ? 100
            : v.cipoint2,
      }));

  const ci_month_full2_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_c.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        cipoint2:
          typeof v.cipoint2 === "undefined" || isNaN(v.cipoint2)
            ? 100
            : v.cipoint2,
            cipoint2_pqiF:typeof v.cipoint2 === "undefined" ?"N/A":""
      }));

  // ci_month_full2_v.forEach((item) => {
  //   item[item.vendor+'_cipoint2'] = item.cipoint2;
  // });


  //시공 인스펙션 완료 점수


  const ci_month_full3_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_c.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        cipoint3:
          typeof v.cipoint3 === "undefined" || isNaN(v.cipoint3)
            ? 100
            : v.cipoint3,
      }));


  const ci_month_full3_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_c_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        cipoint3:
          typeof v.cipoint3 === "undefined" || isNaN(v.cipoint3)
            ? 100
            : v.cipoint3,
            cipoint3_pqiF:typeof v.cipoint3 === "undefined" ? "N/A":""
      }));

  // ci_month_full3_v.forEach((item) => {
  //   item[item.vendor+'_cipoint3'] = item.cipoint3;
  // });




  //자재 인스펙션 신청 점수

  const mi_month_full1_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_m.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        mipoint1:
          typeof v.mipoint1 === "undefined" || isNaN(v.mipoint1)
            ? 100
            : v.mipoint1,
      }));

  const mi_month_full1_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_m_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        mipoint1:
          typeof v.mipoint1 === "undefined" || isNaN(v.mipoint1)
            ? 100
            : v.mipoint1,
            mipoint1_pqiF: typeof v.mipoint1 === "undefined" ?"N/A":""
      }));

  // mi_month_full1_v.forEach((item) => {
  //     item[item.vendor+'_mipoint1'] = item.mipoint1;
  //   });


  //자재 인스펙션 실패율 점수


  const mi_month_full2_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_m.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        mipoint2:
          typeof v.mipoint2 === "undefined" || isNaN(v.mipoint2)
            ? 100
            : v.mipoint2,
      }));


  const mi_month_full2_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_m_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        mipoint2:
          typeof v.mipoint2 === "undefined" || isNaN(v.mipoint2)
            ? 100
            : v.mipoint2,
            mipoint2_pqiF: typeof v.mipoint2 === "undefined" ?"N/A":""
      }));

  // mi_month_full2_v.forEach((item) => {
  //   item[item.vendor+'_mipoint2'] = item.mipoint2;
  // });


  //자재 인스펙션 완료 점수

  const mi_month_full3_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_m.find((sp) => sp.month === k.month),
      }))
      .map((v) => ({
        ...v,
        mipoint3:
          typeof v.mipoint3 === "undefined" || isNaN(v.mipoint3)
            ? 100
            : v.mipoint3,
      }));

  const mi_month_full3_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...g_checkpoint_m_vendor.find((sp) => sp.month === k.month && sp.vendor === k.vendor),
      }))
      .map((v) => ({
        ...v,
        mipoint3:
          typeof v.mipoint3 === "undefined" || isNaN(v.mipoint3)
            ? 100
            : v.mipoint3,
            mipoint3_pqiF:typeof v.mipoint3 === "undefined"?"N/A":""
      }));

  // mi_month_full3_v.forEach((item) => {
  //   item[item.vendor+'_mipoint3'] = item.mipoint3;
  // });


  const month_pqi_t =
    monthList &&
    monthList
      .map((k) => ({
        ...k,
        ...par_month_full_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...issuepoint_month_full_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...car_month_full_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...ncr2_month_full_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...ci_month_full1_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...ci_month_full2_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...ci_month_full3_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...mi_month_full1_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...mi_month_full2_t.find((sp) => sp.month === k.month),
      }))
      .map((k) => ({
        ...k,
        ...mi_month_full3_t.find((sp) => sp.month === k.month),
      }))



  const month_pqi_v =
    VendorMonthList &&
    VendorMonthList
      .map((k) => ({
        ...k,
        ...issuepoint_month_full_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...issuepoint2_month_full_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...car_month_full_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...ncr2_month_full_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...car2_month_full_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...ci_month_full1_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...ci_month_full2_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...ci_month_full3_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...mi_month_full1_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...mi_month_full2_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))
      .map((k) => ({
        ...k,
        ...mi_month_full3_v.find((sp) => sp.vendor_month === k.vendor_month),
      }))



  const pqi =
    _.sortBy(
      month_pqi_t &&
      month_pqi_t.map((v) => ({
        month: v.month,
        PQI: isNaN(v.ncrpoint2) ? (
          v.mipoint1 * 0.1 +
          v.mipoint2 * 0.15 +
          v.mipoint3 * 0.1 +
          v.cipoint1 * 0.1 +
          v.cipoint2 * 0.15 +
          v.cipoint3 * 0.1 +
          v.issuepoint * 0.15) * 100 / 85 +
          v.carpoint +
          v.parpoint :
          (
            v.mipoint1 * 0.1 +
            v.mipoint2 * 0.15 +
            v.mipoint3 * 0.1 +
            v.cipoint1 * 0.1 +
            v.cipoint2 * 0.15 +
            v.cipoint3 * 0.1 +
            v.issuepoint * 0.15 +
            v.ncrpoint2 * 0.15 +
            v.carpoint +
            v.parpoint)

        ,

        "자재검사 신청": v.mipoint1 * 0.1,
        "자재검사 실패율": v.mipoint2 * 0.15,
        "자재검사 완료": v.mipoint3 * 0.1,
        "시공검사 신청": v.cipoint1 * 0.1,
        "시공검사 실패율": v.cipoint2 * 0.15,
        "시공검사 완료": v.cipoint3 * 0.1,
        "NCR/Punch 발행건수": v.issuepoint * 0.15,
        "NCR 지연": isNaN(v.ncrpoint2) ? "N/A" : v.ncrpoint2 * 0.15,
        "CAR 발행건수": v.carpoint,
        "PAR 발행건수": v.parpoint,

      })).map((v) => ({ ...v, PQI: v.PQI >= 100 ? 100 : v.PQI })),
      "month"
    );


    const allNA = (v) => {
      // 'pqiF'를 포함하는 모든 키의 값이 "N/A"인지 체크
      return Object.keys(v).filter(key => key.includes('pqiF')).every(key => v[key] === "N/A");
    };
    
    const pqi_v = _.sortBy(
      month_pqi_v &&
      month_pqi_v.map((v) => {
        const isAllNA = allNA(v);  // 모든 'pqiF' 키들이 "N/A"인지 확인
        return {
          month: v.month,
          vendor: v.vendor,
          vendor_month: v.vendor_month,
          PQI: isAllNA ? "N/A" : v.na_c && v.na_m ? 0 : (
            isNaN(v.ncrpoint2) && isNaN(v.carpoint2) ?
              (v.mipoint1 * 0.1 +
                v.mipoint2 * 0.1 +
                v.mipoint3 * 0.1 +
                v.cipoint1 * 0.1 +
                v.cipoint2 * 0.1 +
                v.cipoint3 * 0.1 +
                v.issuepoint * 0.1 +
                v.carpoint * 0.1) * 100 / 80 :
              !isNaN(v.ncrpoint2) && isNaN(v.carpoint2) ?
                (v.mipoint1 * 0.1 +
                  v.mipoint2 * 0.1 +
                  v.mipoint3 * 0.1 +
                  v.cipoint1 * 0.1 +
                  v.cipoint2 * 0.1 +
                  v.cipoint3 * 0.1 +
                  v.issuepoint * 0.1 + v.ncrpoint2 * 0.1 +
                  v.carpoint * 0.1) * 100 / 90 :
                isNaN(v.ncrpoint2) && !isNaN(v.carpoint2) ?
                  (v.mipoint1 * 0.1 +
                    v.mipoint2 * 0.1 +
                    v.mipoint3 * 0.1 +
                    v.cipoint1 * 0.1 +
                    v.cipoint2 * 0.1 +
                    v.cipoint3 * 0.1 +
                    v.issuepoint * 0.1 + v.carpoint2 * 0.1 +
                    v.carpoint * 0.1) * 100 / 90 :
                  (v.mipoint1 * 0.1 +
                    v.mipoint2 * 0.1 +
                    v.mipoint3 * 0.1 +
                    v.cipoint1 * 0.1 +
                    v.cipoint2 * 0.1 +
                    v.cipoint3 * 0.1 +
                    v.issuepoint * 0.1 + v.carpoint2 * 0.1 + v.ncrpoint2 * 0.1 +
                    v.carpoint * 0.1)),
    
          "자재검사 신청": isAllNA || v.na_c && v.na_m ? "N/A" : v.mipoint1 * 0.1,
          "자재검사 실패율": isAllNA || v.na_c && v.na_m ? "N/A" : v.mipoint2 * 0.1,
          "자재검사 완료": isAllNA || v.na_c && v.na_m ? "N/A" : v.mipoint3 * 0.1,
          "시공검사 신청": isAllNA || v.na_c && v.na_m ? "N/A" : v.cipoint1 * 0.1,
          "시공검사 실패율": isAllNA || v.na_c && v.na_m ? "N/A" : v.cipoint2 * 0.1,
          "시공검사 완료": isAllNA || v.na_c && v.na_m ? "N/A" : v.cipoint3 * 0.1,
          "NCR 발행건수": isAllNA || v.na_c && v.na_m ? "N/A" : v.issuepoint * 0.1,
          "NCR 지연": isAllNA || v.na_c && v.na_m ? "N/A" : (isNaN(v.ncrpoint2) ? "N/A" : v.ncrpoint2 * 0.1),
          "CAR 발행건수": isAllNA || v.na_c && v.na_m ? "N/A" : v.carpoint * 0.1,
          "CAR 지연": isAllNA || v.na_c && v.na_m ? "N/A" : (isNaN(v.carpoint2) ? "N/A" : v.ncrpoint2 * 0.1),
        };
      }),
      "month"
    );
    

  pqi_v.forEach((item) => {
    item[item.vendor + '_자재검사 신청'] = item['자재검사 신청'];
    item[item.vendor + '_자재검사 실패율'] = item['자재검사 실패율'];
    item[item.vendor + '_자재검사 완료'] = item['자재검사 완료'];
    item[item.vendor + '시공검사 신청'] = item['시공검사 신청'];
    item[item.vendor + '시공검사 실패율'] = item['시공검사 실패율'];
    item[item.vendor + '시공검사 완료'] = item['시공검사 완료'];
    item[item.vendor + '_NCR 발행건수'] = item['NCR 발행건수'];
    item[item.vendor + '_NCR 지연'] = item['NCR 지연'];
    item[item.vendor + '_CAR 발행건수'] = item['CAR 발행건수'];
    item[item.vendor + '_CAR 지연'] = item['CAR 지연'];
    // item[item.vendor + 'Punch 발행건수'] = item['Punch 발행건수'];
    item[item.vendor + '_PQI'] = item['PQI'];
  });


  const groupedByVendor = _.groupBy(pqi_v.filter(v => v.PQI !== 0 && v.PQI !== "N/A"), "vendor");

  const averagePqiByVendor = _.map(groupedByVendor, (vendorGroup) => {
    const totalPqi = _.sumBy(vendorGroup, "PQI");
    const averagePqi = totalPqi / vendorGroup.length;
    return {
      vendor: vendorGroup[0].vendor,
      averagePqi: averagePqi,
    };
  });

  const pqi_v_with_average = pqi_v
    .map((k) => ({ ...k, ...averagePqiByVendor.find((sp) => sp.vendor === k.vendor) }))

  return (
    {
      pqi: pqi,
      pqi_v: pqi_v,
      pqi_v_with_average: pqi_v_with_average,
      checkpoint: checkpoint,
      ncr_pendig_point: ncr_pendig_point,
      issuerate: issuerate,
      ncr_pendig_point_v: ncr_pendig_point_v,
      car_pendig_point_v: car_pendig_point_v,
      ncrdata_point: ncrdata_point,
      cardata_point: cardata_point,
      punchdata_point: punchdata_point,
      ncrdatau: ncrdata,
      checkdatauo: checkdatau,
      punchdatau: punchdatau,
      currentWeekIssuePoint: currentWeekIssuePoint,
      ncr_pendig_point_v: ncr_pendig_point_v

    }
  );


}

export default PQIData

