import React, { useEffect, useState } from 'react';
import {
    GridLayout,
} from "@progress/kendo-react-layout";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Adjust = () => {
    const { width } = useWindowDimensions();
    return (
        <>
            <GridLayout style={{ height: 1}}
                rows={[
                    // {  height: '10%',},
                    {
                        height: '100%',
                    },
      
                ]}

                cols={[
                    {
                        width:width,
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}></GridLayout>
                </>
    )

}

export default Adjust
