import React from "react";
import { ResponsivePie } from "@nivo/pie";

const LessonLearnPieChart = ({ data }) => {
  const customColors = ["#4472C4", "#ED7D31"];
  const totalSum = data.reduce((sum, d) => sum + d.value, 0);
  const getPercentage = (value) => {
    const percentage = (value / totalSum) * 100;
    return percentage.toFixed(1);
  };

  return (
    <ResponsivePie
      data={data}
      startAngle={90}
      endAngle={-270}
      margin={{ top: 5, right: 0, bottom: 0, left: 0 }}
      innerRadius={0.7}
      padAngle={0}
      activeOuterRadiusOffset={1}
      colors={customColors}
      borderWidth={0}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      // enableArcLinkLabels={false}
      arcLinkLabel={(e) => e.id + " (" + e.value + ")"}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsStraightLength={0}
      arcLinkLabelsDiagonalLength={0}
      arcLinkLabelsOffset={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabel={(e) => e.value}
      arcLabelsRadiusOffset={0.5}
      arcLabelsSkipAngle={4}
      arcLabelsTextColor="#fff"
      legends={[
        {
          anchor: "right",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 50,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
      tooltip={({ datum: { id, value } }) => (
        <div
          style={{
            padding: 5,
            borderRadius: "5px",
            textAlign: "center",
            backgroundColor: "white",
            color: "#707070",
            border: "1px solid #dadde9",
            fontSize: "14px",
            fontFamily: "Pretendard-R",
          }}
        >
          <span>
            {id} : {value} 건
          </span>
          <br />
          <span>{getPercentage(value)} %</span>
        </div>
      )}
      theme={{
        tooltip: {
          container: {
            background: "#333",
          },
        },
      }}
    />
  );
};

export default LessonLearnPieChart;

// const data = [
//   {
//     id: "보류",
//     label: "보류",
//     value: 283,
//     color: "#4472C4",
//   },
//   {
//     id: "승인",
//     label: "승인",
//     value: 42,
//     color: "#ED7D31",
//   },
//   //   {
//   //     id: "make",
//   //     label: "make",
//   //     value: 229,
//   //     color: "hsl(87, 70%, 50%)",
//   //   },
//   //   {
//   //     id: "java",
//   //     label: "java",
//   //     value: 333,
//   //     color: "hsl(243, 70%, 50%)",
//   //   },
//   //   {
//   //     id: "javascript",
//   //     label: "javascript",
//   //     value: 340,
//   //     color: "hsl(209, 70%, 50%)",
//   //   },
// ];
