import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import axios from 'axios';
import { ResponsivePie } from '@nivo/pie';
import { RadialGauge } from "@progress/kendo-react-gauges";
import LTIR from '../charts/ltir';
import PTW1 from './ptw1';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const SafetyAct2 = ({  positiondata, order, blockH  }) => {

  // const [companyval, setcompanyval] = useState(0);
  // const [actulval, setactulval] = useState(0);
  // const [targetval, settargetval] = useState(0);
  // const [date, setdate] = useState();

 

  const { project_code } = useParams();

  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);

  const [data, setdata] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
        "bpname": "안전불합리",
        "lineitem": "no",
        "record_fields": "accident_type_pd;safety_type_pd;Subcontractor_dpk",
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
      setdata(res.data.data.data)


    };

    fetchData();
  }, [project_code]);

  const chartdata1 = Object.entries(_.countBy(data, 'Subcontractor_dpk')).map(([k, v]) => ({ id: k, value: v }))


  const labelContent = (props) => {
    let sumvalue = data.length
    let formatedNumber = Number(props.dataItem.count / sumvalue).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return `${props.dataItem.type}:${props.dataItem.count} (${formatedNumber})`;
  };


  return (

    <GridLayout style={{ height: BoxHeight }}
      rows={[
        {
          height: '100%',
        },

      ]}

      cols={[
        {
          width: '100%',
        },



      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}>

      <GridLayoutItem row={1} col={1} rowSpan={1} style={{ textAlign: 'center', fontSize: '1rem' , position:'relative'}}>
      <div style={{position:'absolute' , top:'55%', left:'50%',transform: 'translate(-50%, -55%)', fontWeight:'600'}}> {_.sumBy(chartdata1,'value')}</div>

        <ResponsivePie
          className='pie'
          // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
          colors={{ scheme: 'blues' }}
          data={chartdata1}
          margin={{ top: 20, right: 70, bottom: 10, left: 70 }}
          valueFormat=" >-"
          innerRadius={0.65}
          cornerRadius={2}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.2
              ]
            ]
          }}
          arcLinkLabel={function (e) { return e.id + "(" + ((e.value / data.length) * 100).toFixed(0) + "%)" }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextOffset={5}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsDiagonalLength={5}
          arcLinkLabelsStraightLength={5}
          arcLinkLabelsColor={{ from: 'color' }}
          enableArcLabels={true}
          arcLabel={function (e) { return e.value }}
          arcLabelsRadiusOffset={0.5}
          arcLabelsSkipAngle={0}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                2
              ]
            ]
          }}

          legends={[]}
        />

      </GridLayoutItem>



    </GridLayout>
  )


}

export default SafetyAct2

