import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Card,
    CardTitle,
    CardBody,
} from "@progress/kendo-react-layout";
 import axios from "axios";import Cookies from 'js-cookie';
import Geocode from "react-geocode";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';

var countrycodedict = {
    "ad": "Andorra",
    "ae": "United Arab Emirates",
    "af": "Afghanistan",
    "ag": "Antigua and Barbuda",
    "ai": "Anguilla",
    "al": "Albania",
    "am": "Armenia",
    "ao": "Angola",
    "aq": "Antarctica",
    "ar": "Argentina",
    "as": "American Samoa",
    "at": "Austria",
    "au": "Australia",
    "aw": "Aruba",
    "ax": "Åland Islands",
    "az": "Azerbaijan",
    "ba": "Bosnia and Herzegovina",
    "bb": "Barbados",
    "bd": "Bangladesh",
    "be": "Belgium",
    "bf": "Burkina Faso",
    "bg": "Bulgaria",
    "bh": "Bahrain",
    "bi": "Burundi",
    "bj": "Benin",
    "bl": "Saint Barthélemy",
    "bm": "Bermuda",
    "bn": "Brunei",
    "bo": "Bolivia",
    "bq": "Caribbean Netherlands",
    "br": "Brazil",
    "bs": "Bahamas",
    "bt": "Bhutan",
    "bv": "Bouvet Island",
    "bw": "Botswana",
    "by": "Belarus",
    "bz": "Belize",
    "ca": "Canada",
    "cc": "Cocos (Keeling) Islands",
    "cd": "DR Congo",
    "cf": "Central African Republic",
    "cg": "Republic of the Congo",
    "ch": "Switzerland",
    "ci": "Côte d'Ivoire (Ivory Coast)",
    "ck": "Cook Islands",
    "cl": "Chile",
    "cm": "Cameroon",
    "cn": "China",
    "co": "Colombia",
    "cr": "Costa Rica",
    "cu": "Cuba",
    "cv": "Cape Verde",
    "cw": "Curaçao",
    "cx": "Christmas Island",
    "cy": "Cyprus",
    "cz": "Czechia",
    "de": "Germany",
    "dj": "Djibouti",
    "dk": "Denmark",
    "dm": "Dominica",
    "do": "Dominican Republic",
    "dz": "Algeria",
    "ec": "Ecuador",
    "ee": "Estonia",
    "eg": "Egypt",
    "eh": "Western Sahara",
    "er": "Eritrea",
    "es": "Spain",
    "et": "Ethiopia",
    "eu": "European Union",
    "fi": "Finland",
    "fj": "Fiji",
    "fk": "Falkland Islands",
    "fm": "Micronesia",
    "fo": "Faroe Islands",
    "fr": "France",
    "ga": "Gabon",
    "gb": "United Kingdom",
    "gb-eng": "England",
    "gb-nir": "Northern Ireland",
    "gb-sct": "Scotland",
    "gb-wls": "Wales",
    "gd": "Grenada",
    "ge": "Georgia",
    "gf": "French Guiana",
    "gg": "Guernsey",
    "gh": "Ghana",
    "gi": "Gibraltar",
    "gl": "Greenland",
    "gm": "Gambia",
    "gn": "Guinea",
    "gp": "Guadeloupe",
    "gq": "Equatorial Guinea",
    "gr": "Greece",
    "gs": "South Georgia",
    "gt": "Guatemala",
    "gu": "Guam",
    "gw": "Guinea-Bissau",
    "gy": "Guyana",
    "hk": "Hong Kong",
    "hm": "Heard Island and McDonald Islands",
    "hn": "Honduras",
    "hr": "Croatia",
    "ht": "Haiti",
    "hu": "Hungary",
    "id": "Indonesia",
    "ie": "Ireland",
    "il": "Israel",
    "im": "Isle of Man",
    "in": "India",
    "io": "British Indian Ocean Territory",
    "iq": "Iraq",
    "ir": "Iran",
    "is": "Iceland",
    "it": "Italy",
    "je": "Jersey",
    "jm": "Jamaica",
    "jo": "Jordan",
    "jp": "Japan",
    "ke": "Kenya",
    "kg": "Kyrgyzstan",
    "kh": "Cambodia",
    "ki": "Kiribati",
    "km": "Comoros",
    "kn": "Saint Kitts and Nevis",
    "kp": "North Korea",
    "kr": "South Korea",
    "kw": "Kuwait",
    "ky": "Cayman Islands",
    "kz": "Kazakhstan",
    "la": "Laos",
    "lb": "Lebanon",
    "lc": "Saint Lucia",
    "li": "Liechtenstein",
    "lk": "Sri Lanka",
    "lr": "Liberia",
    "ls": "Lesotho",
    "lt": "Lithuania",
    "lu": "Luxembourg",
    "lv": "Latvia",
    "ly": "Libya",
    "ma": "Morocco",
    "mc": "Monaco",
    "md": "Moldova",
    "me": "Montenegro",
    "mf": "Saint Martin",
    "mg": "Madagascar",
    "mh": "Marshall Islands",
    "mk": "North Macedonia",
    "ml": "Mali",
    "mm": "Myanmar",
    "mn": "Mongolia",
    "mo": "Macau",
    "mp": "Northern Mariana Islands",
    "mq": "Martinique",
    "mr": "Mauritania",
    "ms": "Montserrat",
    "mt": "Malta",
    "mu": "Mauritius",
    "mv": "Maldives",
    "mw": "Malawi",
    "mx": "Mexico",
    "my": "Malaysia",
    "mz": "Mozambique",
    "na": "Namibia",
    "nc": "New Caledonia",
    "ne": "Niger",
    "nf": "Norfolk Island",
    "ng": "Nigeria",
    "ni": "Nicaragua",
    "nl": "Netherlands",
    "no": "Norway",
    "np": "Nepal",
    "nr": "Nauru",
    "nu": "Niue",
    "nz": "New Zealand",
    "om": "Oman",
    "pa": "Panama",
    "pe": "Peru",
    "pf": "French Polynesia",
    "pg": "Papua New Guinea",
    "ph": "Philippines",
    "pk": "Pakistan",
    "pl": "Poland",
    "pm": "Saint Pierre and Miquelon",
    "pn": "Pitcairn Islands",
    "pr": "Puerto Rico",
    "ps": "Palestine",
    "pt": "Portugal",
    "pw": "Palau",
    "py": "Paraguay",
    "qa": "Qatar",
    "re": "Réunion",
    "ro": "Romania",
    "rs": "Serbia",
    "ru": "Russia",
    "rw": "Rwanda",
    "sa": "Saudi Arabia",
    "sb": "Solomon Islands",
    "sc": "Seychelles",
    "sd": "Sudan",
    "se": "Sweden",
    "sg": "Singapore",
    "sh": "Saint Helena, Ascension and Tristan da Cunha",
    "si": "Slovenia",
    "sj": "Svalbard and Jan Mayen",
    "sk": "Slovakia",
    "sl": "Sierra Leone",
    "sm": "San Marino",
    "sn": "Senegal",
    "so": "Somalia",
    "sr": "Suriname",
    "ss": "South Sudan",
    "st": "São Tomé and Príncipe",
    "sv": "El Salvador",
    "sx": "Sint Maarten",
    "sy": "Syria",
    "sz": "Eswatini (Swaziland)",
    "tc": "Turks and Caicos Islands",
    "td": "Chad",
    "tf": "French Southern and Antarctic Lands",
    "tg": "Togo",
    "th": "Thailand",
    "tj": "Tajikistan",
    "tk": "Tokelau",
    "tl": "Timor-Leste",
    "tm": "Turkmenistan",
    "tn": "Tunisia",
    "to": "Tonga",
    "tr": "Turkey",
    "tt": "Trinidad and Tobago",
    "tv": "Tuvalu",
    "tw": "Taiwan",
    "tz": "Tanzania",
    "ua": "Ukraine",
    "ug": "Uganda",
    "um": "United States Minor Outlying Islands",
    "un": "United Nations",
    "us": "United States",
    "us-ak": "Alaska",
    "us-al": "Alabama",
    "us-ar": "Arkansas",
    "us-az": "Arizona",
    "us-ca": "California",
    "us-co": "Colorado",
    "us-ct": "Connecticut",
    "us-de": "Delaware",
    "us-fl": "Florida",
    "us-ga": "Georgia",
    "us-hi": "Hawaii",
    "us-ia": "Iowa",
    "us-id": "Idaho",
    "us-il": "Illinois",
    "us-in": "Indiana",
    "us-ks": "Kansas",
    "us-ky": "Kentucky",
    "us-la": "Louisiana",
    "us-ma": "Massachusetts",
    "us-md": "Maryland",
    "us-me": "Maine",
    "us-mi": "Michigan",
    "us-mn": "Minnesota",
    "us-mo": "Missouri",
    "us-ms": "Mississippi",
    "us-mt": "Montana",
    "us-nc": "North Carolina",
    "us-nd": "North Dakota",
    "us-ne": "Nebraska",
    "us-nh": "New Hampshire",
    "us-nj": "New Jersey",
    "us-nm": "New Mexico",
    "us-nv": "Nevada",
    "us-ny": "New York",
    "us-oh": "Ohio",
    "us-ok": "Oklahoma",
    "us-or": "Oregon",
    "us-pa": "Pennsylvania",
    "us-ri": "Rhode Island",
    "us-sc": "South Carolina",
    "us-sd": "South Dakota",
    "us-tn": "Tennessee",
    "us-tx": "Texas",
    "us-ut": "Utah",
    "us-va": "Virginia",
    "us-vt": "Vermont",
    "us-wa": "Washington",
    "us-wi": "Wisconsin",
    "us-wv": "West Virginia",
    "us-wy": "Wyoming",
    "uy": "Uruguay",
    "uz": "Uzbekistan",
    "va": "Vatican City (Holy See)",
    "vc": "Saint Vincent and the Grenadines",
    "ve": "Venezuela",
    "vg": "British Virgin Islands",
    "vi": "United States Virgin Islands",
    "vn": "Vietnam",
    "vu": "Vanuatu",
    "wf": "Wallis and Futuna",
    "ws": "Samoa",
    "xk": "Kosovo",
    "ye": "Yemen",
    "yt": "Mayotte",
    "za": "South Africa",
    "zm": "Zambia",
    "zw": "Zimbabwe"
}
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
  }
  
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
  
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}
am4core.addLicense("CH363108325");
am4core.addLicense("MP363108325");

am4core.useTheme(am4themes_animated);



const CHART_ID = 'map_chart';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PJTLoc = ({ filter ,BoxHeight}) => {
    const { height, width } = useWindowDimensions();

    const [pjtlist, setpjtlist] = useState([]);
    const [pjtlist2, setpjtlist2] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "ESG 대시보드 프로젝트 관리",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                        {
                            "field": "SKONeUSEPD",
                            "value": "제외",
                            "condition_type": "neq"
                        },
                    ]
                }
            }

            const res = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body)
            

            const latlnglist = res.data.data.data.filter(v => v.uuu_latitude !== null && v.uuu_longitude !== null && typeof v.uuu_latitude !== 'undefined' && !v.uuu_shell_location.includes('반도체') ).map((v) => ({ project: v.ugenProjectName, lat: String(v.uuu_latitude), lng: String(v.uuu_longitude), uuu_location:v.uuu_shell_location, SKONeUSEPD:v.SKONeUSEPD }))

            let newarray = [];

            Geocode.setApiKey("AIzaSyBP-17SYLdi3X2v-qh8k-cK8obmONubMUU");
            Geocode.setLanguage("en");


            for (let i of latlnglist) {

                const response = await Geocode.fromLatLng(i.lat, i.lng)

                const country = response.results.reverse()[0].formatted_address
                const newobj = [Object.assign(i, { country: country })]
                newarray.push(...newobj)
            }

            setpjtlist(newarray)

        }
        fetchData();

    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const latlng_f = [];
            for (let i of pjtlist) {
                const response = await Geocode.fromAddress(i.country)
                const newloc = response.results[0].geometry.location;
                const newobj = [Object.assign(i, { newloc: newloc })]
                latlng_f.push(...newobj)
            }
            setpjtlist2(latlng_f)
        }
        fetchData();

    }, [pjtlist]);

    const countrycount = _.uniqBy(pjtlist.filter(v=>v.uuu_location.includes(filter)), 'country').length


    var pjtlist3 =
        _(_.sortBy( pjtlist2.filter(v=>v.uuu_location.includes(filter)), "project"))
            .groupBy('country')
            .map((objs, key) => ({
                'country': key,
                'pjt': 
                objs.length > 10 ? 
               _.filter(objs, { country: key }).slice(0,10).map(v => v.project).join('\n') + `\n...외 ${objs.length -10}건` : 
             _.filter(objs, { country: key }).map(v => v.project).join('\n'),
                'latlng': _.filter(objs, { country: key })[0]['newloc'],
                'nodap': objs.length > 10 ? _.filter(objs, { country: key }).slice(10).map(v => v.project).join('\n') :''
            }))
            .value()
            .map((v) => ({ ...v, latitude: v.latlng['lat'], longitude: v.latlng['lng'], icon: "https://flagcdn.com/" + getKeyByValue(countrycodedict, v.country) + ".svg", length:v.country ==='Poland'?40:20 }))


    useEffect(() => {

        // Create map instance
        var chart = am4core.create(CHART_ID, am4maps.MapChart);
        // chart.geodata = am4geodata_continentsLow;
        chart.geodata = am4geodata_worldLow;
        chart.projection = new am4maps.projections.Miller();
        chart.y = am4core.percent(-2);
        chart.x = am4core.percent(-8);

        // Colors
        var color1 = am4core.color("#236D73");

        chart.homeGeoPoint = {
            latitude: 50,
            longitude: 0
        }
        chart.homeZoomLevel = 0.8;
        chart.minZoomLevel = 0.8;

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        // polygonSeries.exclude = ["antarctica"];
        polygonSeries.useGeodata = true;

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = am4core.color("#236D73").lighten(0.5);

        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#236D73");


        // Add shadow
        // var shadow = polygonSeries.filters.push(new am4core.DropShadowFilter());
        // shadow.color = am4core.color("#60666b");
        // shadow.blur = 0;

        // Pins
        var imageSeries = chart.series.push(new am4maps.MapImageSeries());
        var imageTemplate = imageSeries.mapImages.template;
        imageTemplate.propertyFields.longitude = "longitude";
        imageTemplate.propertyFields.latitude = "latitude";
        imageTemplate.nonScaling = true;

        // Creating a pin bullet
        var pin = imageTemplate.createChild(am4plugins_bullets.PinBullet);

        // Configuring pin appearance
        pin.background.fill = '#F5DF4D';
        pin.background.pointerBaseWidth = 1;
        pin.background.pointerLength = 20;
        pin.background.propertyFields.pointerLength = "length";
        pin.circle.fill = pin.background.fill;
        pin.label = new am4core.Label();
        pin.label.text = "";
        pin.label.fill = color1.alternate;
        pin.background.radius = 10;
        pin.image = new am4core.Image();
        pin.image.propertyFields.href = "icon";
        pin.image.scale = 1;

        imageSeries.heatRules.push({
            "target": pin.background,
            "property": "pointerLength",
            "min": 20,
            "max": 40,
            "dataField": "length"
           });

        var label = pin.createChild(am4core.Label);
        label.text = "{pjt}";
        label.fontWeight = "bold";
        label.propertyFields.dy = "length";
        label.verticalCenter = "middle";
        label.fill = '#363945';
        label.fontSize = '11px';
        label.tooltipText = "{nodap}";
        // label.tooltip.fontSize ='10px';
        label.adapter.add("dy", function (dy) {
            return (10 + dy) * -1;
        });

        label.adapter.add("dx", function (dx) {
            return (15 + dx);
        });


        // Creating a "heat rule" to modify "radius" of the bullet based
        // on value in data
        // imageSeries.heatRules.push({
        //     "target": pin.background,
        //     "property": "radius",
        //     "min": 5,
        //     "max": 8,
        //     "dataField": 5
        // });

        // imageSeries.heatRules.push({
        //     "target": label,
        //     "property": "dx",
        //     "min": 30,
        //     "max": 40,
        //     "dataField": "value"
        // });

        // imageSeries.heatRules.push({
        //     "target": label,
        //     "property": "paddingBottom",
        //     "min": 0,
        //     "max": 10,
        //     "dataField": "value"
        // });

        // Pin data
        imageSeries.data = pjtlist3;

        return () => {
            chart && chart.dispose();
        };


    }, [pjtlist3]);

    return (<>
                <Card style={{ height: BoxHeight-62, margin: '12px' }}>
                    <CardTitle style={{ margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>Project Location</CardTitle>
                    <CardBody style={{ margin: '0 auto', padding: '0' }}>
                        <div style={{ textAlign: 'center', zIndex: '999999999' }}><span style={{ fontSize: '1.4rem', fontWeight: '600' }}>{countrycount} </span>Countries  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ fontSize: '1.4rem', fontWeight: '600' }}>{pjtlist.filter(v=>v.uuu_location.includes(filter)).length}</span> Projects</div>
                        <div
                            id={CHART_ID}
                            style={{ width: (width-300)*3/10, height: BoxHeight-122, margin: '0 auto',zIndex: '99' }}
                        ></div>
                    </CardBody>
                </Card>
    </>)
}

export default PJTLoc
