import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import useBreakpoint from "use-breakpoint";
import _ from "lodash";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from "moment";
import html2canvas from "html2canvas";
import Check from "./check";
import Punch from "./punch";
import Ncr from "./ncr";
import PQI from "./pqi";
import MonthPQI from "./monthpqi";
import QIssue from "./issue";
import "../kendocustom.css";
import "@progress/kendo-theme-default/dist/all.css";
import ProjectNDE from "./projectNDE";
import SubconNDE from "./subconNDE";
import { useTranslation } from 'react-i18next';
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import { DashboardLayout } from "../projectLayout";


const { REACT_APP_URI2 } = process.env;


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const Quality = () => {
  const { project_code } = useParams();
  const { t, i18n } = useTranslation();

  const exportAsImage = async () => {
    const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, project_code + "_품질.png");
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const [grid, setgrid] = useState({
    mobile: [
      { col: 1, colSpan: 4, rowSpan: 2, status: true, },
      { col: 1, colSpan: 6, status: true, rowSpan: 4, },
      { col: 1, colSpan: 8, status: true, rowSpan: 2, },

      { col: 1, colSpan: 4, status: true, rowSpan: 2, },
      { col: 1, colSpan: 8, rowSpan: 2, status: true, },

      { col: 1, colSpan: 10, status: true, rowSpan: 2, },
      { col: 1, colSpan: 8, rowSpan: 2, status: true, },

      { col: 1, colSpan: 18, status: true, rowSpan: 3, },

    ],

    tablet: [
      { col: 1, colSpan: 4, rowSpan: 2, status: true, },
      { col: 5, colSpan: 6, status: true, rowSpan: 4, },
      { col: 11, colSpan: 8, status: true, rowSpan: 2, },

      { col: 1, colSpan: 4, status: true, rowSpan: 2, },
      { col: 11, colSpan: 8, rowSpan: 2, status: true, },

      { col: 1, colSpan: 10, status: true, rowSpan: 2, },
      { col: 11, colSpan: 8, rowSpan: 2, status: true, },

      { col: 1, colSpan: 18, status: true, rowSpan: 3, },


    ],

    desktop: [
      { col: 1, colSpan: 4, rowSpan: 2, status: true, },
      { col: 5, colSpan: 6, status: true, rowSpan: 4, },
      { col: 11, colSpan: 8, status: true, rowSpan: 2, },

      { col: 1, colSpan: 4, status: true, rowSpan: 2, },
      { col: 11, colSpan: 8, rowSpan: 2, status: true, },

      { col: 1, colSpan: 10, status: true, rowSpan: 2, },
      { col: 11, colSpan: 8, rowSpan: 2, status: true, },

      { col: 1, colSpan: 18, status: true, rowSpan: 3, },

    ],
  });
  const [status_grid, setstatusgrid] = useState([
    { status: true },
    { status: true },
    { status: true },
    { status: true },
    { status: true },
    { status: true },
    { status: true },
    { status: true },
    { status: true },
  ]);
  const [lineitem, setlineitem] = useState([]);
  const [columns, setcolumns] = useState(18);
  const [blockH, setblockH] = useState(140);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  const [ndedata, setndedata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "Welder Registration",
        "record_fields": "d_subcontractor_bpk;d_name;WelderID",
        lineitem_fields: "NDEDate;NDEType;TotalFilmQuantities;RejectFilmQuantities",
        lineitem: "yes",
        filter_condition: "status=Active",
      };
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body);
      setndedata(res.data.data.data.filter(v => v._bp_lineitems !== undefined))
    };
    fetchData();
  }, [project_code]);


  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "대시보드 설정",
        lineitem: "yes",
        filter_condition: "status=Active",
      };
      const res = await axios.post(
        `http://43.200.223.224:5000/getbprecord?path=${project_code}`,
        body,
      );
      if (
        res.data.data.data.filter(
          (v) =>
            typeof v._bp_lineitems !== "undefined" &&
            v.Dashboard_Name === "품질"
        ).length > 0
      ) {
        const sort = _.sortBy(
          res.data.data.data
            .filter(
              (v) =>
                typeof v._bp_lineitems !== "undefined" &&
                v.Dashboard_Name === "품질"
            )[0]
          ["_bp_lineitems"].filter((v) => v.Status_CB === "Yes"),
          "Card_Order"
        );
        const GRIDCONFIG = sort.map((v) => ({
          colSpan: v.Card_Width,
          rowSpan: v.Card_Vertical_Width,
          status: true,
          col: v.StartingPosition,
        }));
        const GRID_CONFIG = {
          mobile: GRIDCONFIG.map((v) => ({
            colSpan: 16,
            rowSpan: v.rowSpan,
            status: v.status,
            col: 1,
          })),
          tablet: GRIDCONFIG,
          desktop: GRIDCONFIG,
        };

        setlineitem(
          res.data.data.data.filter(
            (v) =>
              typeof v._bp_lineitems !== "undefined" &&
              v.Dashboard_Name === "품질"
          )[0]["_bp_lineitems"]
        );

        setstatusgrid(
          _.sortBy(
            res.data.data.data
              .filter(
                (v) =>
                  typeof v._bp_lineitems !== "undefined" &&
                  v.Dashboard_Name === "품질"
              )[0]
            ["_bp_lineitems"].map((v) => ({
              ...v,
              status: v.Status_CB === "Yes" ? true : false,
            })),
            "Card_Order"
          )
        );

        setblockH(
          res.data.data.data.filter(
            (v) =>
              typeof v._bp_lineitems !== "undefined" &&
              v.Dashboard_Name === "품질"
          )[0]["default_vertical_height"]
        );
        setcolumns(
          res.data.data.data.filter(
            (v) =>
              typeof v._bp_lineitems !== "undefined" &&
              v.Dashboard_Name === "품질"
          )[0]["columns_count"]
        );
        setgrid(GRID_CONFIG);
      }
    };
    fetchData();
  }, [project_code]);

  const [positiondata, setPositionData] = useState(grid["desktop"]);

  useEffect(() => {
    setPositionData(grid[breakpoint]);
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(true);

  const togglefix = () => {
    setfixblock(!fixblock);
  };




  //////////////

  const [tile1, settile1] = useState(0);
  const [tile2, settile2] = useState(1);
  const [tile3, settile3] = useState(2);
  const [tile4, settile4] = useState(3);
  const [tile5, settile5] = useState(4);
  const [tile6, settile6] = useState(5);
  const [tile7, settile7] = useState(5);
  const [tile8, settile8] = useState(5);
  const [tile9, settile9] = useState(5);

  useEffect(() => {
    settile1(
      _.get(lineitem.filter((v) => v.Card_Name === "PQI")[0], "Card_Order") - 1
    );
    settile2(
      _.get(
        lineitem.filter((v) => v.Card_Name === "품질 Issue 사항")[0],
        "Card_Order"
      ) - 1
    );
    settile3(
      _.get(
        lineitem.filter((v) => v.Card_Name === "Punch  현황")[0],
        "Card_Order"
      ) - 1
    );
    settile4(
      _.get(
        lineitem.filter((v) => v.Card_Name === "월별 PQI")[0],
        "Card_Order"
      ) - 1
    );
    settile5(
      _.get(
        lineitem.filter((v) => v.Card_Name === "프로젝트 용접불량률")[0],
        "Card_Order"
      ) - 1
    );
    settile6(
      _.get(
        lineitem.filter((v) => v.Card_Name === "검사현황")[0],
        "Card_Order"
      ) - 1
    );
    settile7(
      _.get(
        lineitem.filter((v) => v.Card_Name === "용접불량률")[0],
        "Card_Order"
      ) - 1
    );
    settile8(
      _.get(
        lineitem.filter((v) => v.Card_Name === "업체 용접불량률")[0],
        "Card_Order"
      ) - 1
    );
    settile9(
      _.get(
        lineitem.filter((v) => v.Card_Name === "NCR/CAR/PAR")[0],
        "Card_Order"
      ) - 1
    );
  }, [lineitem]);

  const tiles = [
    {
      header: <div style={{ position: "relative" }}>PQI</div>,
      body: (
        <PQI
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile1) ? 0 : tile1}
        />
      ),
      status: true,
      key: "PQI",
      resizable: fixblock,
      reorderable: fixblock,
    },
    {
      header: <div style={{ position: "relative" }}>{t("월별 PQI")}</div>,
      body: (
        <MonthPQI
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile2) ? 1 : tile2}
        />
      ),
      status: true,
      key: "월별 PQI",
      resizable: fixblock,
      reorderable: fixblock,
    },


    {
      header: (
        <div>
          <div style={{ position: "relative" }}>{t("Punch 현황")}</div>
        </div>
      ),
      body: (
        <Punch
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile3) ? 2 : tile3}
        />
      ),
      status: true,
      key: "Punch 현황",
      resizable: fixblock,
      reorderable: fixblock,
    },



    {
      header: <div style={{ position: "relative" }}>{t("프로젝트 용접불량률")}</div>,
      body: (
        <ProjectNDE
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile4) ? 3 : tile4}
          data={ndedata}

        />
      ),
      status: true,
      key: "",
      resizable: fixblock,
      reorderable: fixblock,
    },


    {
      header: <div style={{ position: "relative" }}>{t("검사현황")}</div>,
      body: (
        <Check
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile5) ? 4 : tile5}
        />
      ),
      status: true,
      key: "검사현황",
      resizable: fixblock,
      reorderable: fixblock,
    },



    {
      header: <div style={{ position: "relative" }}>{t("업체별 용접불량률")}</div>,
      body: (
        <SubconNDE
          positiondata={positiondata}
          data={ndedata}
          blockH={blockH}
          order={isNaN(tile6) ? 5 : tile6}
        />
      ),
      status: true,
      key: "",
      resizable: fixblock,
      reorderable: fixblock,
    },

    {
      header: <div style={{ position: "relative" }}>NCR/CAR/PAR</div>,
      body: (
        <Ncr
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile6) ? 5 : tile6}
        />
      ),
      status: true,
      key: "NCR/CAR/PAR",
      resizable: fixblock,
      reorderable: fixblock,
    },

    {
      header: <div style={{ position: "relative" }}>{t("품질 Issue 사항")}</div>,
      body: (
        <QIssue
          positiondata={positiondata}
          blockH={blockH}
          order={isNaN(tile2) ? 1 : tile2}
        />
      ),
      status: true,
      key: "품질 Issue 사항",
      resizable: fixblock,
      reorderable: fixblock,
    },
  ];

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(
      tiles.map((v, index) => ({
        ...v,
        status: _.get(status_grid[index], "status"),
      }))
    );
  }, [
    positiondata,
    status_grid,
    tile1,
    tile2,
    tile3,
    tile4,
    tile5,
    tile6,
    tile7,
    tile8,
    tile9,
    fixblock,
    ndedata, 
    i18n.language
  ]);

  const tile_filtered = _.filter(tile, ["status", true]);

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  const location = useLocation();
  const usernameFromState = location.state?.username;
  const [isLoading, setIsLoading] = useState(false);
  
  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`http://43.200.223.224:5000/getcheckowner?project=${project_code}`);
      setIsLoading(false);
   
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

  const isNotIframe = window.self === window.top;

  return (
    <>
    {isNotIframe? (
    <DashboardLayout>
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>
      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />
    </div>
    </DashboardLayout>)
    :
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>
      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />
    </div>
}
</>
  );
};

export default Quality;
