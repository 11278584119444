import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import axios from 'axios';
import _ from 'lodash';
import moment from "moment";
import useBreakpoint from "use-breakpoint";
import html2canvas from "html2canvas";
import Progress1 from './chart1';
import CWP from './cwpstatus';
import CWPV from './cwpstatusvendor';
import '@progress/kendo-theme-default/dist/all.css';
import { useTranslation } from 'react-i18next';
import { logout } from '../../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import { DashboardLayout } from '../../projectLayout';
import PartMilestone from '../common/milestone';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const CProgress = () => {
  const { t, i18n } = useTranslation();



  const { project_code } = useParams();
  const exportAsImage = async () => {
    const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, project_code+'_시공 공정율.png');
    };
  
  const downloadImage = (blob, fileName) => {
      const fakeLink = window.document.createElement("a");
      fakeLink.style = "display:none;";
      fakeLink.download = fileName;
      
      fakeLink.href = blob;
      
      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);
      
      fakeLink.remove();
      };
  const [grid, setgrid] = useState({
    mobile: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
    ],

    tablet: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
    ],

    desktop: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        rowSpan: 5,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 3,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 3,
      },
    ]
  }
  );
  const [status_grid, setstatusgrid] = useState([{status:true}, {status:true}, {status:true},  {status:true}, ]);
  const [lineitem, setlineitem] = useState([]);
  const [columns, setcolumns] = useState(12);
  const [blockH, setblockH] = useState(100);
  const [progressSource, setprogressSource]=useState('작업일보 기반')
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
        "bpname": "대시보드 설정",
        "lineitem": "yes",
        "filter_condition": "status=Active",
      }
      const res = await axios.post(`http://43.200.223.224:5000/getemsbprecord?path=${project_code}`, body, 
      )

      
      if (res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '시공 공정률').length > 0){
      const sort = _.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '시공 공정률')[0]['_bp_lineitems'].filter(v => v.Status_CB === 'Yes'), 'Card_Order')
      const GRIDCONFIG = sort.map((v) => ({ colSpan: v.Card_Width, rowSpan: v.Card_Vertical_Width, status: true, col: v.StartingPosition }))
      const GRID_CONFIG = { mobile: GRIDCONFIG.map((v) => ({ colSpan: 16, rowSpan: v.rowSpan, status: v.status, col: 1 })), tablet: GRIDCONFIG, desktop: GRIDCONFIG };

      setlineitem(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '시공 공정률')[0]['_bp_lineitems'])

      setstatusgrid(_.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '시공 공정률')[0]['_bp_lineitems'].map((v) => ({ ...v, status: v.Status_CB === 'Yes' ? true : false })), 'Card_Order'))

      setblockH(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '시공 공정률')[0]['default_vertical_height'])
      setcolumns(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '시공 공정률')[0]['columns_count'])
      setgrid(
        GRID_CONFIG
      )

      setprogressSource(res.data.data.data.filter(v => v.Dashboard_Name === '시공 공정률')[0]['ProgressSourceType'])
  }
}

    fetchData();
  }, [project_code]);

  const [positiondata, setPositionData] = useState(grid['desktop']);
  const [data, setdata] = useState([]);
  const [pdata, setpdata] = useState([]);


  const yesterday = moment(new Date()).format("YYYY-MM-DD");
  const yesterday7 = moment(new Date()).subtract(7, "day").format("YYYY-MM-DD");

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/activityprogress/${project_code}/${yesterday}_act.json`
        , 
        );

      let body =
      {
          "bpname": "ESG 대시보드 프로젝트 관리",
          "lineitem": "no",
          "filter_criteria": {
              "join": "AND",
              "filter": [
                  {
                      "field": "status",
                      "value": "Active",
                      "condition_type": "eq"
                  },
                  {
      
                    "field": "ugenProjectNumber",
                    "value": project_code,
                    "condition_type": "eq"
                  },
              ]
          }
      }

      const resVersion = await axios.post(`http://43.200.223.224:5000/getemsbprecord?path=SKEC0001`, body
      )
      const version = resVersion.data.data.data.filter(v => v.ugenProjectNumber === project_code)[0]['DRversion']


      const newdata =progressSource ==='작업일보 기반'? res.data : res.data.map((v)=>({...v,
        d_evm_actual_start:typeof v.ManualActualStart !== 'undefined'?v.ManualActualStart:v.d_evm_actual_start,  
        d_evm_actual_finish:typeof v.ManualActualFinish !== 'undefined'?v.ManualActualFinish:v.d_evm_actual_finish,  
        d_actual_progress:typeof v.ManualActualProgress !== 'undefined'?v.ManualActualProgress:v.d_actual_progress,  
      }))

      const newdata2 = version === "OLD" ? newdata:newdata.map((v)=>({...v,d_weight_value:v.d_assigned_wv }))

      setdata(
        _.filter(newdata2, function (elem) {
          return elem.d_activity_status !== null && elem.Lv_4_Name !== null && elem.status !== 'Inactive' && elem.status !=='Terminated';
        })
      );

      const res2 = await axios.get(
        `http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/activityprogress/${project_code}/${yesterday7}_act.json`

      );

      const newdatap =progressSource ==='작업일보 기반'? res2.data : res2.data.map((v)=>({...v,
        d_evm_actual_start:typeof v.ManualActualStart !== 'undefined'?v.ManualActualStart:v.d_evm_actual_start,  
        d_evm_actual_finish:typeof v.ManualActualFinish !== 'undefined'?v.ManualActualFinish:v.d_evm_actual_finish,  
        d_actual_progress:typeof v.ManualActualProgress !== 'undefined'?v.ManualActualProgress:v.d_actual_progress,  
      }))

      const newdatap2 =version === "OLD" ? newdatap: newdatap.map((v)=>({...v,d_weight_value:v.d_assigned_wv }))


      setpdata(
        _.filter(newdatap2, function (elem) {
          return elem.d_activity_status !== null && elem.Lv_4_Name !== null;
        })
      );
    };

    fetchData();
  }, [project_code, progressSource,yesterday7, yesterday]);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(false);

  const togglefix = () => {
    setfixblock(!fixblock);
  };

  const today = moment(new Date()).format("YYYY-MM-DD");



const [tile1, settile1] = useState(0)
const [tile2, settile2] = useState(1)
const [tile3, settile3] = useState(2)
const [tile4, settile4] = useState(3)

useEffect(() =>{
  settile2(_.get(lineitem.filter(v=>v.Card_Name ==='Construction Progress')[0], 'Card_Order')-1)
  settile3(_.get(lineitem.filter(v=>v.Card_Name ==='Progress Detail (by Disc.)')[0], 'Card_Order')-1)
  settile4(_.get(lineitem.filter(v=>v.Card_Name ==='Progress Detail (by Biz.P)')[0], 'Card_Order')-1)
  settile1(_.get(lineitem.filter(v=>v.Card_Name ==='Milestone')[0], 'Card_Order')-1)
}, [lineitem])


  const tiles = [
    {      
      header: (<div style={{ position: 'relative' }}>Milestone</div>),
      body: <PartMilestone part ={"Construction"} />,
      status: true,
      resizable: fixblock,
      reorderable: fixblock,
    },
    {
      header: (<div style={{ position: 'relative' }}>Construction Progress</div>),
      body: <Progress1 positiondata={positiondata} blockH={blockH} order={isNaN(tile2)? 1:tile2} data={data} progressSource={progressSource}/>,
      status: true,
      key: 'Construction Progress',
      resizable: fixblock,
      reorderable: fixblock,

    }, {
      header: (<div style={{ position: "relative" }}>Progress Detail (by Disc.)</div>),
      body: <CWP positiondata={positiondata} blockH={blockH} order={isNaN(tile3)? 2:tile3} pdata={pdata} data={data} />,
      // body: '',
      status: true,
      key: 'Progress Detail(by Disc.)',
      resizable: fixblock,
      reorderable: fixblock,
    },

    {
      header: (<div><div style={{ position: "relative" }}>Progress Detail (by Biz.P)</div></div>),
      body: <CWPV positiondata={positiondata} blockH={blockH} order={isNaN(tile4)? 3:tile4} pdata={pdata} data={data}/>,
      // body: '',
      status: true,
      key: 'Progress Detail(by Disc.)',
      resizable: fixblock,
      reorderable: fixblock,
    },


  ];



  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles.map((v, index)=>({...v, status:_.get(status_grid[index], 'status')})));
  }, [positiondata, status_grid, tile1, tile2, tile3,fixblock,data, pdata, progressSource]);

  const tile_filtered = _.filter(tile, ['status', true]);

  const handleReposition = e => {
    setPositionData(e.value);
    // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
  };

  const location = useLocation();
  const usernameFromState = location.state?.username;
  const [isLoading, setIsLoading] = useState(false);
  
  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`http://43.200.223.224:5000/getcheckowner?project=${project_code}`);
      setIsLoading(false);
      
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

  return (
    <>
    <DashboardLayout>
    <div>
      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
        <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
        <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>


      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />


    </div>
    </DashboardLayout>
    </>
  )

    ;
};

export default CProgress;