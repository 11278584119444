import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from "moment";
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useParams } from 'react-router-dom';
import SCurveChartSimple from '../common/SCurveChartSimple';
import CProgressChart from './constructionSimple';

const ProgrssAll = ({emsData, progressSource, emsWeightValue, cprogressData, progressOnOff}) => {

      const emsData_e = emsData.filter(v=>(v.PROG_TYPE ==="E" || v.PROG_TYPE ==="R") && v.DISC_NM === "OVERALL")
      const emsData_p = emsData.filter(v=>v.PROG_TYPE ==="P" && v.DISC_NM === "OVERALL")

      
    return (
        <>   
        {progressOnOff.T === "Off"?
        <GridLayout style={{ height: 820 }}
        rows={[
            {
                height: 260,
            },

            {
                height: 260,
            },
        ]}

        cols={[
            {
                width: '100%',
            },


        ]}
        gap={{
            rows: 8,
            cols: 0,
        }}>
        <GridLayoutItem col={1} row={1}>
          <SCurveChartSimple emsData={emsData_e} emsWeightValue={emsWeightValue}  part={"Engineering"} height={260}/>
        </GridLayoutItem>
        <GridLayoutItem col={1} row={2}>
          <CProgressChart progressSource={progressSource} cprogressData={cprogressData}/>
        </GridLayoutItem>


</GridLayout>
        :   
        <GridLayout style={{ height: 820 }}
                rows={[
                    {
                        height: 260,
                    },
                    {
                        height: 260,
                    },
                    {
                        height: 260,
                    },
                ]}

                cols={[
                    {
                        width: '100%',
                    },


                ]}
                gap={{
                    rows: 8,
                    cols: 0,
                }}>
                <GridLayoutItem col={1} row={1}>
                  <SCurveChartSimple emsData={emsData_e} emsWeightValue={emsWeightValue}  part={"Engineering"} height={260}/>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2}>
                  <SCurveChartSimple emsData={emsData_p} emsWeightValue={emsWeightValue}  part={"Procurement"} height={260}/>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={3}>
                  <CProgressChart progressSource={progressSource} cprogressData={cprogressData}/>
                </GridLayoutItem>


        </GridLayout>}
        </>
    )

}

export default ProgrssAll;

