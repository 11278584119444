import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { generateUpdatedDate } from '../common/datadate';
import { useTranslation } from 'react-i18next';
import PQI from '../quality/pqi';
import PQIData from '../pqi/pqidata';

const {REACT_APP_URI2} = process.env;

const QSummary = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  
  const data = PQIData();
  const ncrdatau = data.ncrdatau;
  const checkdatauo = data.checkdatauo;
  const punchdatau = data.punchdatau;


  useEffect(() => {
    const boxHeight =positiondata&& positiondata[order-1];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);


const { t } = useTranslation();

  const [criteria, setcriteria] =useState(3);
  const [projectweldingrate, setprojectweldingrate] =useState(0);

  useEffect(() => {
    const fetchData = async () => {
        let body = {
            bpname: "Project Setting",
            "record_fields": "DefectRateCalculate;d_name;DefectRateStandard",
            lineitem: "no",
            filter_condition: "status=Active",
          };
          const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body);

          const calcriteria = res.data.data.data[0]['DefectRateCalculate']

          setcriteria(res.data.data.data[0]['DefectRateStandard'])

          let body2 = {
            bpname: "Welding Defect Rate Management",
            "record_fields": calcriteria === "Joints"? "RejectJoint;TotalJoint;RejectJoint02;TotalJoint02;" :"TotalFilm02;RejectFilm02;TotalFilm;RejectFilm;",
            lineitem: "no",
            filter_condition: "status=Approved",
          };
          const res2 = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body2,);

          setprojectweldingrate( calcriteria === "Joints"?
            ((_.sumBy(res2.data.data.data, "RejectJoint") +_.sumBy(res2.data.data.data, "RejectJoint02") )/(_.sumBy(res2.data.data.data, "TotalJoint") +_.sumBy(res2.data.data.data, "TotalJoint02")) )*100:
            ((_.sumBy(res2.data.data.data, "RejectFilm")+_.sumBy(res2.data.data.data, "RejectFilm02") ) /(_.sumBy(res2.data.data.data, "TotalFilm02")+_.sumBy(res2.data.data.data, "TotalFilm"))) *100
            )

    };
    fetchData();
  }, [project_code]);


  return (
    <>


      <GridLayout style={{ height: BoxHeight }}
        rows={[
          {
            height: '30%',
          },
          {
            height: '20%',
          }, {
            height: '20%',
          },
          {
            height: '30%',
          }
        ]}

        cols={[
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          },
          {
            width: '8.3%',
          }
        ]}
        gap={{
          rows: 0,
          cols: 1,
        }}>
          <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
        {generateUpdatedDate(moment().add(-1,"days"))}
        </div>
        <GridLayoutItem row={1} rowSpan={4} col={1} colSpan={6} style={{ position: 'relative' }}>
          <PQI positiondata={positiondata} order={order} blockH={blockH}/>

        </GridLayoutItem>


        <GridLayoutItem row={1} rowSpan={2} col={7} colSpan={3} style={{ textAlign: 'center' }}>
          <Card style={{ height: '100%', position: 'relative' }}>
            <CardBody style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', fontSize: '0.8rem' }}>
              <CardTitle style={{ fontSize: '0.8rem', fontFamily: 'pretendard-R' }}>{t("NCR 조치/전체")}</CardTitle>
              <CardTitle style={{ fontFamily: 'pretendard-B' }}>{ncrdatau.filter(v=>v.status !=='Terminated').filter(v => v.status === 'Closed' && v.NCPTypeSPD === 'NCR').length} / {ncrdatau.filter(v=>v.status !=='Terminated').filter(v => v.NCPTypeSPD === 'NCR').length}</CardTitle>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem row={1} rowSpan={2} col={10} colSpan={3} style={{ textAlign: 'center' }}>
          <Card style={{ height: '100%', position: 'relative' }}>
            <CardBody style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', fontSize: '0.8rem' }}>
              <CardTitle style={{ fontSize: '0.8rem', fontFamily: 'pretendard-R' }}>{t("불합격수/총검사자수")}</CardTitle>
              <CardTitle style={{ fontFamily: 'pretendard-B' }}>{checkdatauo.filter(v=>v.status !=='Terminated').filter(v => v.d_test_result_spd === '불합격').length} / {checkdatauo.filter(v=>v.status !=='Terminated').filter(v=>v.d_test_result_spd !== null).length}</CardTitle>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem row={3} rowSpan={2} col={7} colSpan={3} style={{ textAlign: 'center' }}>
          <Card style={{ height: '100%', position: 'relative' }}>
            <CardBody style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', fontSize: '0.8rem' }}>
              <CardTitle style={{ fontSize: '0.8rem', fontFamily: 'pretendard-R' }}>{t("용접불량율")}</CardTitle>
              <CardTitle style={{ fontFamily: 'pretendard-B' }}> { isNaN(projectweldingrate) ?"N/A": projectweldingrate && projectweldingrate.toFixed(1) +"% /"+criteria+"%"} </CardTitle>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem row={3} rowSpan={2} col={10} colSpan={3} style={{ textAlign: 'center' }}>
          <Card style={{ height: '100%', position: 'relative' }}>
            <CardBody style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', fontSize: '0.8rem' }}>
              <CardTitle style={{ fontSize: '0.8rem', fontFamily: 'pretendard-R' }}>{t("Punch조치/전체")}</CardTitle>
              <CardTitle style={{ fontFamily: 'pretendard-B' }}>{punchdatau && punchdatau.filter(v => v.status === 'Approved').length} / {punchdatau && punchdatau.filter(v=>v.status !=='Terminated').length}</CardTitle>
            </CardBody>
          </Card>
        </GridLayoutItem>


      </GridLayout>
    </>
  );
}

export default QSummary
