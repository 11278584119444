import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import html2canvas from "html2canvas";
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import axios from 'axios';
import SafetyAct from './safetyactivity';
import SProgress from './progress'
import Milestone from './milestone';
import Intro from './projectintro';
import QSummary from './qualitys';
import Man1P from './man1';
import Equip1P from './equip1';
import SafetyAct1 from '../safety/safetyactivity';
import '@progress/kendo-theme-default/dist/all.css';
import "../kendocustom.css";
import { useTranslation } from 'react-i18next'
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import { DashboardLayout } from '../projectLayout';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PJTOverview = () => {
  const { t, i18n } = useTranslation();
 
  const { project_code } = useParams();


  const exportAsImage = async () => {
    const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, project_code+'_프로젝트 개요.png');
    };
  
  const downloadImage = (blob, fileName) => {
      const fakeLink = window.document.createElement("a");
      fakeLink.style = "display:none;";
      fakeLink.download = fileName;
      
      fakeLink.href = blob;
      
      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);
      
      fakeLink.remove();
      };
  
  const [grid, setgrid] = useState({
    mobile:
      [

        {
          col: 1,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },

        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },


        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },


        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        }



      ]
    ,

    tablet:
      [

        {
          col: 1,
          colSpan: 4,
          rowSpan: 6,
          status: true,
        },
        {
          col: 5,
          colSpan: 12,
          status: true,
          rowSpan: 2,
        },



        {
          col: 5,
          colSpan: 4,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 4,
          status: true,
          rowSpan: 2,
        },
        {
          col: 13,
          colSpan: 4,
          status: true,
          rowSpan: 2,
        },
        {
          col: 5,
          colSpan: 3,
          status: true,
          rowSpan: 2,
        },
        {
          col: 8,
          colSpan: 3,
          status: true,
          rowSpan: 2,
        },

        {
          col: 11,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        }

      ]
    ,

    desktop:

      [

        {
          col: 1,
          colSpan: 4,
          rowSpan: 6,
          status: true,
        },
        {
          col: 5,
          colSpan: 12,
          status: true,
          rowSpan: 2,
        },



        {
          col: 5,
          colSpan: 4,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 4,
          status: true,
          rowSpan: 2,
        },
        {
          col: 13,
          colSpan: 4,
          status: true,
          rowSpan: 2,
        },
        {
          col: 5,
          colSpan: 6,
          status: true,
          rowSpan: 2,
        },
        // {
        //   col: 8,
        //   colSpan: 3,
        //   status: true,
        //   rowSpan: 2,
        // },

        {
          col: 11,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        }


      ]
  });
  const [status_grid, setstatusgrid] = useState([{ status: true }, { status: true }, { status: true },{ status: true }, { status: true }, { status: true }, { status: true }, { status: true }]);
  const [lineitem, setlineitem] = useState([]);
  const [columns, setcolumns] = useState(16);
  const [blockH, setblockH] = useState(140);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  const iframe_boolean = window.self === window.top;

  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
        "bpname": "대시보드 설정",
        "lineitem": "yes",
        "filter_condition": "status=Active",
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
      if (res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '프로젝트 개요').length > 0) {
        const sort = _.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '프로젝트 개요')[0]['_bp_lineitems'].filter(v => v.Status_CB === 'Yes'), 'Card_Order')
        const GRIDCONFIG = sort.map((v) => ({ colSpan: v.Card_Width, rowSpan: v.Card_Vertical_Width, status: true, col: v.StartingPosition }))
        const GRID_CONFIG = { mobile: GRIDCONFIG.map((v) => ({ colSpan: 16, rowSpan: v.rowSpan, status: v.status, col: 1 })), tablet: GRIDCONFIG, desktop: GRIDCONFIG };

        setlineitem(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '프로젝트 개요')[0]['_bp_lineitems'])

        setstatusgrid(_.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '프로젝트 개요')[0]['_bp_lineitems'].map((v) => ({ ...v, status: v.Status_CB === 'Yes' ? true : false })), 'Card_Order'))

        setblockH(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '프로젝트 개요')[0]['default_vertical_height'])
        setcolumns(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '프로젝트 개요')[0]['columns_count'])
        setgrid(
          GRID_CONFIG
        )
      }
    }
    fetchData();
  }, [project_code]);

  const [positiondata, setPositionData] = useState(grid['desktop']);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(true);

  const togglefix = () => {
    setfixblock(!fixblock);
  };


  const [data1, setdata1] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [dailyreport, setdailyreport] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/drhistory/${project_code}/`,)
      const files = res.data.fileList.map((v) => (v.name))
      setfilelist(files)
    }

    fetchData();
  }, [project_code]);


  const drfilelist = useMemo(() => filelist, [filelist]);

  useEffect(() => {
    const fetchData = async () => {


      let manarray = [];

      for (const drfile of drfilelist) {
        const dr = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/drhistory/${project_code}/${drfile}`,)
        const dr_data = dr.data
        manarray.push(...dr_data)
      }

      setdailyreport(manarray)

    };

    fetchData();
  }, [project_code, drfilelist]);


  useEffect(() => {
    const fetchData = async () => {
      const res2 = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/dailyreport/${project_code}_dr.json`,)
      setdata1(res2.data)

    };
    fetchData();
  }, [project_code]);


  const [data11, setdata11] = useState(data1);


  useEffect(() => {
    setdata11(data1)
  }, [data1])

  const [tile1, settile1] = useState(0)
  const [tile2, settile2] = useState(1)
  const [tile3, settile3] = useState(2)
  const [tile4, settile4] = useState(3)
  const [tile5, settile5] = useState(4)
  const [tile6, settile6] = useState(5)
  const [tile7, settile7] = useState(6)
  const [tile8, settile8] = useState(7)

  useEffect(() => {
    settile1(_.get(lineitem.filter(v => v.Card_Name === 'Project 개요')[0], 'Card_Order') - 1)
    settile2(_.get(lineitem.filter(v => v.Card_Name === 'Project Milestone')[0], 'Card_Order') - 1)
    settile3(_.get(lineitem.filter(v => v.Card_Name === 'EPC Progress')[0], 'Card_Order') - 1)
    settile4(_.get(lineitem.filter(v => v.Card_Name === '안전활동')[0], 'Card_Order') - 1)
    settile5(_.get(lineitem.filter(v => v.Card_Name === '안전불합리')[0], 'Card_Order') - 1)
    settile6(_.get(lineitem.filter(v => v.Card_Name === '인원')[0], 'Card_Order') - 1)
    settile7(_.get(lineitem.filter(v => v.Card_Name === '장비')[0], 'Card_Order') - 1)
    settile8(_.get(lineitem.filter(v => v.Card_Name === '품질활동')[0], 'Card_Order') - 1)
  }, [lineitem])

  const tiles = [{
    header: (<div style={{ position: 'relative' }}> {t('Project 개요')}</div>),
    body: <Intro positiondata={positiondata} blockH={blockH} order={0}/>,
    status: true,
    key: 'PJT 개요',
    resizable: fixblock,
    reorderable: fixblock,

  }, {
    header: (<div style={{ position: "relative" }}>Project Milestone</div>),
    body: (<Milestone positiondata={positiondata} blockH={blockH} order={1} />),
    // body:'',
    status: true,
    key: 'PJT Milestone',
    resizable: fixblock,
    reorderable: fixblock,
    className: 'milestone'
  },

  {
    header: (<div style={{ position: "relative" }}>EPC Progress</div>),
    body: (<SProgress positiondata={positiondata} blockH={blockH} order={2} />),
    status: true,
    key: 'EPC Progress',
    resizable: fixblock,
    reorderable: fixblock,
  },


  {
    header: (<div style={{ position: "relative" }}>{t("인원")}</div>),
    body: <Man1P positiondata={positiondata} data11={data11} dailyreport={dailyreport} blockH={blockH} order={3}/>,
    status: true,
    key: '인원',
    resizable: fixblock,
    reorderable: fixblock,
  },
  {
    header: (<div style={{ position: "relative" }}>{t("장비")}</div>),
    body: <Equip1P positiondata={positiondata} data11={data11} dailyreport={dailyreport} blockH={blockH} order={4} />,
    status: true,
    key: '장비',
    resizable: fixblock,
    reorderable: fixblock,
  },
  {
    header: (<div style={{ position: 'relative' }}>{t("안전활동")}</div>),
    body: (<SafetyAct positiondata={positiondata} blockH={blockH} order={5} />),
    status: true,
    key: '안전활동',
    resizable: fixblock,
    reorderable: fixblock,

  },
  // {
  //   header: (<div style={{ position: 'relative' }}>{t("안전불합리")}</div>),
  //   body: (<SafetyAct1 positiondata={positiondata} blockH={blockH} order={isNaN(tile5)? 4:tile5} />),
  //   status: true,
  //   key: '안전활동',
  //   resizable: fixblock,
  //   reorderable: fixblock,

  // },

  {
    header: (<div style={{ position: 'relative' }}>{t("품질활동")}</div>),
    body: <QSummary positiondata={positiondata} blockH={blockH} order={6} />,
    status: true,
    key: '품질활동',
    resizable: fixblock,
    reorderable: fixblock,

  }
  ];

  const handleReposition = e => {

    setPositionData(e.value);
    // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
  };


  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles.map((v, index) => ({ ...v, status: _.get(status_grid[index], 'status') })));
  }, [positiondata, data11, dailyreport, status_grid, tile1, tile2, tile3, tile4, tile5, tile6, tile7,tile8, fixblock, i18n.language]);

  const tile_filtered = _.filter(tile, ['status', true]);

  const location = useLocation();
  const usernameFromState = location.state?.username;
  const [isLoading, setIsLoading] = useState(false);
  
  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`http://43.200.223.224:5000/getcheckowner?project=${project_code}`);
      setIsLoading(false);
   
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

  const isNotIframe = window.self === window.top;


  return (
    <>
    {isNotIframe? (
    <DashboardLayout>

      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        onReposition={handleReposition}
        autoFlow="row dense" 
        style={{marginTop:20}}
      />

    </DashboardLayout>)
   :
   <div>
      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
      <ButtonGroup>
        <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
        <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
        <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
      </ButtonGroup>
    </div>

    <TileLayout
      columns={columns}
      rowHeight={blockH}
      positions={positiondata}
      gap={{
        rows: 10,
        columns: 10
      }}
      items={tile_filtered}
      onReposition={handleReposition}
      autoFlow="row dense" 
      style={{marginTop:20}}
    />
    </div>
  } 
    </>
  ) ;
};

export default PJTOverview;