import React from "react";

const EmissionRateComponent = ({
  subTitleBoolean,
  year,
  total,
  project,
  type,
}) => {
  return (
    <div className="envEmissionRateBox">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="envBoldTextStyle">Emission Rate ({year}년)</div>
        {subTitleBoolean && (
          <div className="envNormalTextStyle">(Scope1, 2)</div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="envBoldTextStyle">전사대비</div>
          <div className="envPercentStyle">{total}%</div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {type === "waste" ? (
            <>
              <div className="envBoldTextStyle">전년대비</div>
              <div className="envPercentStyle">{project}%</div>
            </>
          ) : Number(year) > 24 ? (
            <>
              <div className="envBoldTextStyle">전년대비</div>
              <div className="envPercentStyle">{project}%</div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default EmissionRateComponent;
