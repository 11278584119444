import * as React from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";

const BarChart100 = ({ height, data }) => {
  const SharedTooltip = (props) => {
    const { category, points } = props;
    return (
      <div>
        <div>{category}</div>
        {points.map((point, i) => (
          <div key={i}>
            {point.series.name} : {point.value} 건
          </div>
        ))}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <Chart style={{ height: height, margin: "auto" }}>
      <ChartTooltip
        shared={true}
        render={sharedTooltipRender}
        background="white"
      />
      <ChartSeries>
        <ChartSeriesItem
          type="bar"
          stack={{ type: "100%" }}
          labels={(point) => ({
            visible: point.value !== 0,
            position: "center",
            background: "transparent",
            color: "#fff",
          })}
          data={data.map((item) => item.open)}
          name="보류"
          color="#4472C4"
        />
        <ChartSeriesItem
          type="bar"
          data={data.map((item) => item.closed)}
          name="승인"
          labels={(point) => ({
            visible: point.value !== 0,
            position: "center",
            background: "transparent",
            color: "#fff",
          })}
          color="#ED7D31"
        />
      </ChartSeries>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={data.map((item) => item.disc)}
          majorGridLines={{ visible: false }}
        />
      </ChartCategoryAxis>
    </Chart>
  );
};

export default BarChart100;
