import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';

const PDFTronSquad = ({ initialDocUrl, documenTitle, docuID, username , onInstanceReady}) => {
  const viewer = useRef(null);
  const connection = useRef(null);
  const serializer = useRef(new XMLSerializer());

  console.log(docuID)

  const [instanceCreated,setInstanceCreated] = useState(false)

  // 어노테이션 변경을 서버로 전송하는 함수
  const sendAnnotationChange = (annotation, action, annotationId) => {
    const annotationString = serializer.current.serializeToString(annotation);
    if (docuID) {
      connection.current.send(JSON.stringify({
        documentId: docuID,
        annotationId: annotationId,
        xfdfString: convertToXfdf(annotationString, action)
      }));
    } else {
      console.error('docuID is null');
    }
  };
  
  // XFDF 문자열을 변환하는 함수
  const convertToXfdf = (changedAnnotation, action) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    xfdfString += `<${action}>${changedAnnotation}</${action}><add /><modify /><delete />`.replace(`<${action}></${action}>`, '');
    xfdfString += `</xfdf>`;
    return xfdfString;
  };
  const handleAnnotationChange = (xfdfString) => {
    const parser = new DOMParser();
    const commandData = parser.parseFromString(xfdfString, 'text/xml');
  
    ['add', 'modify', 'delete'].forEach(action => {
      const annotsCollection = commandData.getElementsByTagName(action);
      if (annotsCollection.length > 0) {
        const annots = annotsCollection[0];
        console.log(annots);
        if (annots) {
          annots.childNodes.forEach(child => {
            let annotationId;
            if (action === 'delete') {
              // For delete action
              annotationId = child.textContent;
            } else {
              // For add or modify action
              annotationId = child.getAttribute('name');
            }
    
            // Send annotation change
            sendAnnotationChange(child, action, annotationId);
          });
        }
      }
    });
    
    
  };
  
  // WebSocket 메시지 처리 로직
  const processWebSocketMessage = async (annotationManager, data) => {
    try {
      // data가 문자열인지 확인하고, 문자열이면 JSON으로 파싱
      const annotation = typeof data === 'string' ? JSON.parse(data) : data;
  
      // 인자로 받은 annotationManager를 사용하여 어노테이션 커맨드를 임포트하고, 어노테이션을 그립니다.
      const annotations = await annotationManager.importAnnotationCommand(annotation.xfdfString);
      await annotationManager.drawAnnotationsFromList(annotations);
    } catch (error) {
      console.error('Error processing WebSocket message:', error);
    }
  };
  useEffect(() => {
    if (!instanceCreated && initialDocUrl) {
      initializeWebViewer(docuID);
    } else if (instanceCreated && viewer.current && viewer.current.UI) {
      viewer.current.UI.loadDocument(initialDocUrl);
      loadXfdfStrings(docuID).then(xfdfStrings => {
        const annotationManager = viewer.current.Core.documentViewer.getAnnotationManager();
        xfdfStrings.forEach(xfdf => {
          annotationManager.importAnnotationCommand(xfdf);
        });
      });
    }
  }, [initialDocUrl, instanceCreated, docuID]);

  useEffect(() => {
    if (instanceCreated && docuID && viewer.current) {
      const annotationManager = viewer.current.Core.documentViewer.getAnnotationManager();
      annotationManager.setCurrentUser(username || 'defaultUser');
      annotationManager.addEventListener('annotationChanged', async (e) => {
        if (e.imported || !connection.current || connection.current.readyState !== WebSocket.OPEN) {
          return;
        }
        const xfdfString = await annotationManager.exportAnnotationCommand();
        handleAnnotationChange(xfdfString);
      });
    }
  }, [docuID, instanceCreated]);

  

  const initializeWebViewer = (docuID) => {
    const url = `wss://dashboard.skecoengineering.com:8080`;
    connection.current = new WebSocket(url);

    connection.current.onerror = (event) => {
      for (const key in event) {
        console.log(`${key}: ${event[key]}`);
      }
    };

    WebViewer({
      path: '/webviewer/lib',
      initialDoc: initialDocUrl,
      licenseKey: process.env.REACT_APP_PDF_KEY,
      documentXFDFRetriever: async () => {
        const rows = await loadXfdfStrings(docuID);
        return JSON.parse(rows).map(row => row.xfdfString);
      },
    }, viewer.current).then(instance => {
      viewer.current = instance;
      setInstanceCreated(true);
      if (onInstanceReady) {
        onInstanceReady(instance);
      }
    }).catch(error => {
      console.error('Failed to initialize WebViewer:', error);
    });
  };

  const loadXfdfStrings = (documentId) => {
    return new Promise((resolve, reject) => {
      fetch(`https://dashboard.skecoengineering.comhttp://43.200.223.224:5000/server/annotationHandlerproxy.js?documentId=${documentId}`, {
        method: 'GET',
      }).then((res) => {
        if (res.status < 400) {
          res.text().then(xfdfStrings => {
            resolve(xfdfStrings);
          });
        } else{
          reject(res);
        }
      });
    });
  };



  return (
    <div className="App">
      <div className="webviewer" style={{ height: "100vh" }} ref={viewer}></div>
    </div>
  );
};

export default PDFTronSquad;