import React, { useState, useEffect, useRef } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartCategoryAxisItem,
  ChartCategoryAxis,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  exportVisual,
  ChartTitle,
} from "@progress/kendo-react-charts";
import moment from "moment";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import "hammerjs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import _ from "lodash";

const before_month = moment(new Date())
  .subtract(1, "month")
  .format("YYYY-MM-01");

// const today_month = moment(new Date()).format("YYYY-MM-01");

const EMSManHourSCurveChart = ({
  positiondata,
  blockH,
  order,
  originalData,
  originalDataPlan,
  comboBoxValues,
}) => {
  const [chartData, setChartData] = useState([]);
  const [chartPercent, setChartPercent] = useState({
    plan: 0,
    act: 0,
    dev: 0,
    prod_p: 0,
  });
  const [boxValue, setBoxValue] = useState("OVERALL");
  const [zoomableyn, setzoomableyn] = useState(false);

  const chart = useRef(null);

  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const filterData = originalData.filter((com) =>
      boxValue !== "OVERALL" ? com.Disc_txt.toUpperCase() === boxValue : com
    );

    const filterDataPlan = originalDataPlan.filter((com) =>
      boxValue !== "OVERALL" ? com.Disc_txt.toUpperCase() === boxValue : com
    );

    const acutal_date_arr = filterData.map(
      (com) => new Date(com.emsWorkingDate_date)
    );

    const plan_date_arr = filterDataPlan.map((com) => new Date(com.months));

    const total_date_arr = [...acutal_date_arr, ...plan_date_arr];

    if (total_date_arr.length > 0) {
      const minMonth = _.min(total_date_arr);
      const maxMonth = _.max(total_date_arr);

      // const baseDataArr = [];
      const baseDataArr = createDateRange(
        new Date(minMonth),
        new Date(maxMonth)
      );

      const chartData_base = baseDataArr.map((com) => ({
        months: moment(new Date(com.months)).format("YYYY-MM-DD"),
        actual: Number(
          _.sumBy(
            filterData.filter(
              (com2) => com2.emsWorkingDate_month === com.months
            ),
            "emsTMSActualHour"
          )
        ),
        plan: Number(
          _.sumBy(
            filterDataPlan.filter((com2) => com2.months === com.months),
            "days"
          )
        ),
      }));

      chartData_base.reduce((acc, cum) => {
        if (new Date(before_month) >= new Date(cum.months)) {
          return (cum["actual_cum"] = acc + cum.actual);
        } else {
          return cum;
        }
      }, 0);

      chartData_base.reduce((acc, cum) => {
        return (cum["plan_cum"] = acc + cum.plan);
      }, 0);

      setChartData(
        chartData_base.map((com) => ({
          ...com,
          months: new Date(com.months),
        }))
      );

      setChartPercent((prev) => {
        const total_man_hour = _.sumBy(filterDataPlan, "days");

        const lastest_plan = chartData_base.find(
          (com) => moment(com.months).format("YYYY-MM-01") === before_month
        )?.["plan_cum"];

        const lastest_act = chartData_base.find(
          (com) => moment(com.months).format("YYYY-MM-01") === before_month
        )?.["actual_cum"];

        let lastest_plan_result = 0;
        let lastest_act_result = 0;

        if (lastest_plan) {
          lastest_plan_result = lastest_plan;
        } else {
          const max_months = _.maxBy(chartData_base, "months");
          lastest_plan_result = max_months ? max_months["plan_cum"] : 0;
        }

        if (lastest_act) {
          lastest_act_result = lastest_act;
        } else {
          const max_months = _.maxBy(chartData_base, "months");
          lastest_act_result = max_months ? max_months["actual_cum"] : 0;
        }

        const result_obj = {
          ...prev,
          plan: Number(
            ((lastest_plan_result / total_man_hour) * 100).toFixed(2)
          ),
          act: Number(((lastest_act_result / total_man_hour) * 100).toFixed(2)),
          dev:
            Number(((lastest_act_result / total_man_hour) * 100).toFixed(2)) -
            Number(((lastest_plan_result / total_man_hour) * 100).toFixed(2)),
          prod_p: Number(
            Number(lastest_plan_result / lastest_act_result).toFixed(2)
          ),
        };

        return {
          ...result_obj,
          plan: fixed_number(result_obj.plan),
          act: fixed_number(result_obj.act),
          dev: fixed_number(result_obj.dev),
          prod_p: fixed_number(result_obj.prod_p),
        };
      });
    } else {
      setChartData([]);
    }
  }, [originalData, boxValue, originalDataPlan]);

  const handleDiscComboBox = (e) => {
    if (e.value === null) {
      setBoxValue("OVERALL");
    } else {
      setBoxValue(e.value);
    }
  };

  const toggleZoom = () => {
    setzoomableyn(!zoomableyn);
  };

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chart.current);

    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) =>
        saveAs(dataURI, "EMSSCurvechart.png")
      );
    }
  };

  const labelContent_0 = (e) => {
    return e.value === null || typeof e.value === "undefined"
      ? ""
      : moment(new Date(e.value)).format("YY년 MM월");
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent_0,
  };

  const labelContentAxis1 = (e) => e.value.toLocaleString();

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    const plan_data =
      points.find((com) => com.series.name === "월간 계획")?.["value"] ?? 0;
    const act_data =
      points.find((com) => com.series.name === "월간 실적")?.["value"] ?? 0;

    const cum_plan_data =
      points.find((com) => com.series.name === "누적 계획")?.["value"] ?? 0;
    const cum_act_data =
      points.find((com) => com.series.name === "누적 실적")?.["value"] ?? 0;

    return (
      <div>
        <div>{moment(new Date(categoryText)).format("YY년 MM월")}</div>
        <div>
          월간 Prod. : {fixed_number(Number(plan_data / act_data).toFixed(2))}
        </div>
        <div>
          누적 Prod. :{" "}
          {fixed_number(Number(cum_plan_data / cum_act_data).toFixed(2))}
        </div>
        {points.map((point, index) => {
          return (
            <div key={index}>
              {point.series.name} :{" "}
              {Number(point.value.toFixed(2)).toLocaleString()}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <div className="eMSSCurveChart">
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "70%",
          },

          {
            height: "15%",
          },
          {
            height: "15%",
          },
        ]}
        cols={[
          {
            width: "18%",
          },
          {
            width: "82%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <GridLayoutItem col={1} row={1}>
          <Card style={{ width: "100%", height: BoxHeight * 0.65 }}>
            <CardBody style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%, -40%)",
                }}
              >
                <CardTitle
                  style={{
                    display: "flex",
                    textAlign: "center",
                    color: "#363945",
                  }}
                >
                  <div>Productivity.</div>
                </CardTitle>

                <CardTitle
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  <span
                    style={{
                      color: handleEvalGrade("prod_p", chartPercent.prod_p),
                    }}
                  >
                    {chartPercent.prod_p}
                  </span>
                </CardTitle>
                <br />
                <CardTitle
                  style={{
                    display: "flex",
                    textAlign: "center",
                    color: "#363945",
                  }}
                >
                  <div>Dev.</div>
                </CardTitle>

                <CardTitle
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  <span
                    style={{
                      color: chartPercent.dev >= 0 ? "#9E1030" : "#00539C",
                    }}
                  >
                    {chartPercent.dev.toFixed(2)}%
                  </span>
                </CardTitle>
              </div>
              <Tooltip anchorElement="target" position="top">
                <div
                  style={{ position: "absolute", left: "5px", bottom: "5px" }}
                  title="현재일까지 일할 계산된 계획값입니다."
                >
                  <CardSubtitle
                    style={{
                      color: "#939597",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Plan
                  </CardSubtitle>

                  <CardSubtitle
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {chartPercent.plan.toFixed(2)}%
                  </CardSubtitle>
                </div>
              </Tooltip>

              <div
                style={{ position: "absolute", right: "5px", bottom: "5px" }}
              >
                <CardSubtitle
                  style={{
                    textAlign: "center",
                    color: "#939597",
                    fontSize: "1rem",
                  }}
                >
                  Actual
                </CardSubtitle>
                <CardSubtitle
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {chartPercent.act.toFixed(2)}%
                </CardSubtitle>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>

        <GridLayoutItem col={1} row={2}>
          <div>
            <div style={{ marginLeft: "5px" }}>공종</div>
            <ComboBox
              style={{
                width: "100%",
                marginRight: "5px",
              }}
              data={comboBoxValues}
              value={boxValue}
              onChange={handleDiscComboBox}
              className="disc"
            />
          </div>
        </GridLayoutItem>

        <GridLayoutItem col={2} row={1} rowSpan={3}>
          <div style={{ position: "absolute", top: "10px", right: "40px" }}>
            <ButtonGroup>
              <Button
                title="확대/이동 켜기"
                iconClass="k-icon k-font-icon k-i-zoom-in"
                themeColor={zoomableyn ? "primary" : null}
                fillMode="flat"
                onClick={toggleZoom}
              ></Button>

              <Button
                title="이미지로 저장"
                iconClass="k-icon k-font-icon k-i-image-export"
                fillMode="flat"
                onClick={onImageExportClick}
              ></Button>
            </ButtonGroup>
          </div>

          <div
            style={{
              position: "absolute",
              right: "85px",
              top: "70px",
              zIndex: 100003,
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              right: "5px",
              top: "60px",
              zIndex: 100003,
            }}
          ></div>
          <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
            <ChartTitle
              text={`Manhour S-Curve (${boxValue})`}
              font="1.2rem pretendard-R"
            />
            <ChartArea height={BoxHeight * 1.1} margin={20} />
            {/* <ChartPlotArea
              border={{ width: 0, color: "#ffffff", dashType: "dot" }}
              padding={2000}
            /> */}
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                // min={chartRange.min}
                // max={chartRange.max}
                labels={seriesLabels_0}
                axisCrossingValue={[0, chartData.length]}
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                name="월간"
                labels={{
                  content: labelContentAxis1,
                  font: "0.7rem Arial, pretendard-R",
                }}
                visible={true}
                line={{ visible: false }}
                majorTicks={{ step: 100 }}
                minorTicks={{ step: 100 }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
              />
              <ChartValueAxisItem
                name="누적"
                labels={{
                  content: labelContentAxis1,
                  font: "0.7rem Arial, pretendard-R",
                }}
                visible={true}
                line={{ visible: false }}
                majorTicks={{ step: 100 }}
                minorTicks={{ step: 100 }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
              />
            </ChartValueAxis>
            <ChartTooltip shared={true} render={sharedTooltipRender} />

            <ChartSeries>
              <ChartSeriesItem
                type="column"
                data={chartData}
                field="plan"
                color="#DBF3FF"
                categoryField="months"
                name="월간 계획"
                autoFit={true}
                axis="월간"
              />

              <ChartSeriesItem
                color="#42B7F4"
                type="column"
                data={chartData}
                field="actual"
                categoryField="months"
                name="월간 실적"
                autoFit={true}
                axis="월간"
              />

              <ChartSeriesItem
                color="#B1CCE4"
                type="line"
                data={chartData}
                field="plan_cum"
                categoryField="months"
                name="누적 계획"
                autoFit={true}
                axis="누적"
              />
              <ChartSeriesItem
                color="#5E8EFF"
                type="line"
                data={chartData}
                field="actual_cum"
                categoryField="months"
                name="누적 실적"
                autoFit={true}
                axis="누적"
              />
            </ChartSeries>
          </Chart>
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default EMSManHourSCurveChart;

const createDateRange = (startDate, endDate) => {
  const end_date = endDate;
  const end_date_month = end_date.getMonth();

  end_date.setMonth(end_date_month + 1);
  end_date.setDate(0);

  const monthDiff = moment(end_date).diff(
    moment(startDate).format("YYYY-MM-01"),
    "months"
  );

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    monthRange.push({
      months: moment(startDate).add(i, "months").format("YYYY-MM-01"),
    });
  }

  return monthRange;
};

const handleEvalGrade = (type, Dev) => {
  if (type === "progress_d") {
    if (Dev > 5) {
      return colors1.qualityBlue;
    } else if (Dev > 0 && Dev <= 5) {
      return colors1.qualityGreen;
    } else if (Dev > -5 && Dev <= 0) {
      return colors1.qualityOrange;
    } else if (Dev <= -5) {
      return colors1.qualityRed;
    }
  } else if (type === "prod_p") {
    if (Dev > 1.21) {
      return colors1.qualityBlue;
    } else if (Dev > 0.95 && Dev <= 1.21) {
      return colors1.qualityGreen;
    } else if (Dev > 0.81 && Dev <= 0.95) {
      return colors1.qualityOrange;
    } else if (Dev <= 0.81) {
      return colors1.qualityRed;
    }
  } else if (type === "qua") {
    if (Dev >= 90) {
      return colors1.qualityBlue;
    } else if (Dev >= 80 && Dev < 90) {
      return colors1.qualityGreen;
    } else if (Dev >= 60 && Dev < 80) {
      return colors1.qualityOrange;
    } else if (Dev < 60) {
      return colors1.qualityRed;
    }
  }
};

const colors1 = {
  qualityBlue: "rgba(0, 176, 240, 0.4)",
  qualityGreen: "rgba(112, 173, 71, 0.4)",
  qualityOrange: "rgba(255, 192, 0, 0.4)",
  qualityRed: "rgba(255, 0, 0, 0.4)",
};

const fixed_number = (num) => {
  return Number(num) === Infinity || isNaN(num) ? 0 : Number(num);
};
