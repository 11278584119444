import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from "axios"; 
import { Circles } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { FormTable } from './table/edprtable';
import { RPPFormTable } from './table/rpptable';


const RPPRscon = () => {
  const { project_code } = useParams();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [actData, setActData] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [stageData2, setStageData2] = useState([]);
  const [documentStatus, setDocumentStatus] = useState({LOCK_YN:null,FINAL_YN:null });

  useEffect(() => {
    const fetchData = async () => {
      if (project_code) {
        const res = await axios.get(`http://43.200.223.224:5000/getDrawingData?path=${project_code}&code=P`);
        const res2 = await axios.get(`http://43.200.223.224:5000/getPMSData?path=${project_code}&code=P`);
        const res3 = await axios.get(`http://43.200.223.224:5000/getActData?path=${project_code}&code=P`);
        if (res.data.success && res2.data.success && res3.data.success) {

          const drawinData = res.data.data.data
            .map((v) => ({
              ...v,
              _bp_lineitems: v._bp_lineitems.map((vf) => ({
                ...vf,
                EPPMPlanDate: vf.EPPMPlanDate && new Date(vf.EPPMPlanDate.slice(0, 10)),
                EPPMForecastDate: vf.EPPMForecastDate && new Date(vf.EPPMForecastDate.slice(0, 10)),
                EPPMActualDate: vf.EPPMActualDate && new Date(vf.EPPMActualDate.slice(0, 10)),
              }))

            }));
          const PMS = _.uniqBy(_.sortBy(res2.data.data.data, "EPRColumnOrder"), "PMSStandardStageCD");
          const PMSOriginal = res2.data.data.data;


          const PMS_Stages = PMS.map(v => v.PMSStageNM);
          setStageData(PMS_Stages)
          setStageData2(PMSOriginal)
          setDocumentStatus({LOCK_YN:res2.data.data.data[0].LOCK_YN, FINAL_YN:res2.data.data.data[0].FINAL_YN,})

          const activityData = res3.data.data.data.map((v, index) => ({
            ...v, id: index + 1,
            emsPlanStartDO: v.emsPlanStartDO && new Date(v.emsPlanStartDO.slice(0, 10)),
            emsPlanFinishDO: v.emsPlanFinishDO && new Date(v.emsPlanFinishDO.slice(0, 10))
          }))
            .map((v) => { delete v.record_no; delete v.uuu_creation_date; delete v.uuu_record_last_update_date; return v; })

          setActData(activityData)
          // drawinData의 각 객체에 PMS_Stages의 값들을 키로 추가하고 해당 키의 값은 null로 설정
          const newDrawingData = drawinData.map(drawingItem => {
            const newDrawingItem = { ...drawingItem };

            PMS_Stages.forEach(stage => {
              newDrawingItem[stage] = null;
            });
            return newDrawingItem;
          });

          const updatedDrawingData = newDrawingData.map(drawingItem => {
            // 복사본 생성
            const updatedDrawingItem = { ...drawingItem };

            // _bp_lineitems가 배열인지 확인 (undefined인 경우도 처리)
            if (Array.isArray(drawingItem._bp_lineitems)) {
              PMS_Stages.forEach(stage => {
                // 각 스테이지에 해당하는 객체를 찾아서 할당
                const stageItem = drawingItem._bp_lineitems.find(item => item.PMSStageNM === stage);
                updatedDrawingItem[stage] = stageItem || null;
              });
            } else {
              // _bp_lineitems가 배열이 아니거나 undefined인 경우, 모든 스테이지를 null로 설정
              PMS_Stages.forEach(stage => {
                updatedDrawingItem[stage] = null;
              });
            }

            return updatedDrawingItem;
          });

          const flattenDrawingData = updatedDrawingData.map(drawingItem => {
            const flattenedItem = {};

            Object.entries(drawingItem).forEach(([key, value]) => {
              if (value && typeof value === 'object' && !Array.isArray(value)) {
                // 오브젝트인 경우, 각 속성을 문자열로 변환하여 새 키로 추가
                Object.entries(value).forEach(([innerKey, innerValue]) => {
                  flattenedItem[`${key}//${innerKey}`] = innerValue;
                });
              } else {
                // 오브젝트가 아닌 경우, 기존의 키와 값을 그대로 사용
                flattenedItem[key] = value;
              }
            });

            return flattenedItem;
          });


          const lastData = flattenDrawingData.map((v) => {
            delete v._bp_lineitems;
            return v;
          })
            .map((v) => ({ ...v, ...activityData.find(vf => vf.uuu_P6ActivityId === v.EPPMActivityBPK) }))


          setTableData(lastData)
          setLoading(false);
        }
      }
    }
    fetchData()
  }, [project_code])


  console.log(tableData)

  if (loading) {
    return <div className="loadingContainer">
      <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
    </div>;;
  }



  return (
    <>
                  <RPPFormTable projectCode={project_code} columnData2={stageData2} columnData={stageData} moduleData={actData} tabeldata={tableData} newkey={'record_no'} documentStatus={documentStatus}/>
    </>
  );
};
export default RPPRscon;
