import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
    Card,
    CardTitle,
    CardBody,

} from "@progress/kendo-react-layout";
import moment from 'moment';
import './bootstrap.min.css';
import { ResponsivePie } from '@nivo/pie';
import { ListView } from '@progress/kendo-react-listview';
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../../common/datadate';


const LTIR = ({ filter, BoxHeight ,projeclist}) => {

    const { t, i18n } = useTranslation();

    // const [ltirpoint, setltirpoint] = useState([]);
    // const [ltir, setltir] = useState([]);
    const [data2, setdata] = useState([]);
    const [lastdata, setlastdata] = useState([]);


    const data = data2.filter(v => v.SKEcoDivisionSPD.includes(filter))

    const ltirlist = lastdata && lastdata._bp_lineitems&& lastdata._bp_lineitems.map(o => Object.assign(o, { 전사LITR: lastdata.LTIRCumulDA, 타겟LTIR: lastdata.TargetLTIR })).filter(v => v.SKEcoDivisionSPD.replace('/','').includes(filter))
    .filter(v=>projeclist.some(vf=>vf.projectcode === v.ugenProjectNumber))
    .map(v => ({ ...v, ...projeclist.find(sp => sp.projectcode === v.ugenProjectNumber) }))


    var ltir_group =
    _(ltirlist)
        .groupBy(filter === '' ? 'SKEcoDivisionSPD' : 'projectname')
        .map((objs, key) => ({
            'group': key,
            'ltir': (_.sumBy(objs, 'LTICumulShRatioDA') / _.sumBy(objs, 'CumulTotalMH')) * 200000,
        }))
        .value();

    const ltir = 
    filter === '' ?  ltir_group.filter(v=>typeof v.group !== 'undefined' && v.group !== null) : ltir_group.filter(v=>typeof v.group !== 'undefined' && v.group !== null&& v.group !== '본사' ) 

    const ltirpoint= ((_.sumBy(ltirlist, 'LTICumulShRatioDA') / _.sumBy(ltirlist, 'CumulTotalMH')) * 200000).toFixed(3)

    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "LTI 리스트",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "YearText",
                            "value": moment(new Date()).format("YYYY년"),
                            "condition_type": "eq"
                        },
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                    ]
                }
            }
            const res = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body)
            setdata(res.data.data.data)

            let body2 =
            {
                "bpname": "LTIR 현황",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "YearText",
                            "value": moment(new Date()).format("YYYY년"),
                            "condition_type": "eq"
                        },
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                        // {
                        //     "field": "MonthText",
                        //     "value": moment(new Date()).format("MM월"),
                        //     "condition_type": "eq"
                        // },
                    ]
                }
            }
            const res2 = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body2)
            const last_data = _.sortBy(res2.data.data.data, 'record_no').reverse()[0]


            setlastdata(last_data)



            // setltir(
            //     filter === '' ?  ltir_group.filter(v=>typeof v.group !== 'undefined' && v.group !== null) : ltir_group.filter(v=>typeof v.group !== 'undefined' && v.group !== null&& v.group !== '본사' ) 
            // )

            // setltirpoint(((_.sumBy(ltirlist, 'LTICumulShRatioDA') / _.sumBy(ltirlist, 'CumulTotalMH')) * 200000).toFixed(3))
        }
        fetchData();
    }, []);

    const lang ={
        "전도": "Electrocution",
        "충돌": "Collision",
        "비래": "Sprain",
        "협착": "Constriction",
        "낙하": "Fall",
        "무리한 동작": "Overexertion",
        "끼임": "Entanglement",
        "기타": "Others"
    }

    var chartdata =
        _(data)
            .groupBy('LTICategorySPD')
            .map((objs, key) => ({
                'id': i18n.language ==="ko"? key: (lang[key] || key),
                'value': _.filter(objs, { 'LTICategorySPD': key }).length,
            }))
            .value();


    const MyItemRender = props => {
        let item = props.dataItem;
        return <div className='row p-2 border-bottom align-middle' style={{
            margin: 0, borderBottom: '1px solid lightgray', padding: '5px 0 5px 0'
        }}>

            <div className='col-8'>
                <span style={{
                    fontSize: 12,
                    color: '#454545', margin: 0
                }} >{item.group ==="수소/친환경"? "H2/Recycling":item.group}</span>

            </div>
            <div className='col-4'>
                <span style={{ fontSize: 12 }}>{item.ltir.toFixed(3)}</span>
            </div>
        </div>;
    };

    return (
        <>
            <Card style={{ height: (BoxHeight - 70) / 2, margin: '12px', fontSize: '12px' }}>
                <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
                    <span style={{ float: 'left' }}>LTIR</span>
                    <span style={{ position: 'absolute', fontSize:"0.8rem" , fontWeight: '500', right: '12px' }}>{generateUpdatedDate(new Date())}</span>
                </CardTitle>
                <CardBody style={{ margin: '12px' }}>
                    <div className="row">
                        <div className='col-6' style={{ height: ((BoxHeight - 70) / 2) - 35, marginBottom: '2px' }}>
                            <div style={{ height: (((BoxHeight - 70) / 2) - 50) * 0.2, textAlign: 'center', fontSize: '2rem' }}>{ltirpoint && ltirpoint}<span style={{ fontSize: '1rem' }}> LTIR</span> </div>
                            {/* <div style={{ height: (((BoxHeight - 70) / 2) - 50) * 0.8, position: 'relative' }}> */}
                                <ListView data={ltir} item={MyItemRender} style={{ marginTop: '10px', marginBottom: '10px', overflow: 'auto', height: (((BoxHeight - 70) / 2) - 35) * 0.7, width: "100%", textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                            {/* </div> */}
                        </div>
                        <div className='col-6' style={{ height: ((BoxHeight - 70) / 2) - 35 }}>

                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '999' }}><span style={{ fontSize: _.sumBy(chartdata, 'value').length > 3 ? '0.9rem' : '1.2rem' }}>{_.sumBy(chartdata, 'value')}<span style={{fontSize:"smaller"}}>{t("건")}</span></span></div>
                            <div style={{ position: 'absolute', top: '6%', left: '50%', transform: 'translateX(-50%)', fontSize: '0.8rem', fontWeight: '500', minWidth:200, textAlign:"center" }}>{t("유형별 LTI (연단위 누적)")}</div>
                            <ResponsivePie
                                // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                                colors={{ scheme: 'nivo' }}
                                className='pie'
                                data={chartdata}
                                padAngle={0}
                                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                                valueFormat=" >-"
                                cornerRadius={0}
                                innerRadius={0.7}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                arcLinkLabel={d => (
                                    <React.Fragment>
                                        <tspan style={{ fontSize: 10 }} x="0" dy="-0.6em">{d.id}</tspan>
                                        <tspan style={{ fontSize: 10 }} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(chartdata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                                    </React.Fragment>
                                )}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextOffset={3}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsDiagonalLength={3}
                                arcLinkLabelsStraightLength={5}
                                arcLinkLabelsColor={{ from: 'color' }}
                                enableArcLabels={true}
                                arcLabel={function (e) { return e.value }}
                                arcLabelsRadiusOffset={0.5}
                                arcLabelsSkipAngle={0}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            2
                                        ]
                                    ]
                                }}

                                legends={[]}
                            />
                        </div>
                    </div>
 
                </CardBody>
            </Card>


        </>
    );
}

export default LTIR;