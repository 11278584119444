import React, { useState, useEffect } from "react";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Path } from "@progress/kendo-drawing";
import _ from "lodash";
import moment from "moment";
import { useMediaQuery } from 'react-responsive';

const BySectorDetail = ({ positiondata, order, blockH,EnvDataWaste,EnvDataAll,projectlist  }) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

      const linedata = EnvDataAll.reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ueffectyearPD: v.ueffectyearPD, umuMonthPD: v.umuMonthPD }))), [])
      .filter(v => v.ueffectyearPD === moment().format('YYYY'))
      .map(k => ({ ...k, ...projectlist.find(sp => sp.ugenProjectNumber === k.ugenProjectNumber) }))

      var Sum_by_division =
        _(linedata)
          .groupBy('bySector')
          .map((objs, key) => ({
            'Sector': key,
            '용수': _.sumBy(objs.filter(v => v.Envclass1 === '용수'), 'Envamount'),
            "에너지": _.sumBy(objs.filter(v => v.Envclass1 === "에너지"), 'Envamount'),
            "온실가스": _.sumBy(objs.filter(v => v.Envclass1 === "온실가스"), 'Envamount'),
          }))
          .value();

          const unifierDataFilter = EnvDataWaste
          .filter((com) => com.ESG_TRTM_WAYS !== null)
          .filter((com) => Number(com.ESG_GIVE_QUNT) !== 0)
          .filter((com) => com.ESG_WSTE_CATE !== null)
          .filter(
            (com) =>
              com.ESG_GIVE_DATE.slice(0, 4) ===
              String(moment(new Date()).format("YYYY"))
          )
          .map((com) => {
            return {
              ...com,
              give_qunt_unit_chage: changeCount(com),
            };
          })
        .map(k => ({ ...k, ...projectlist.find(sp => sp.ugenProjectNumber === k.project_projectnumber) }))

  
        var wasteSum_by_pjt =
          _(unifierDataFilter)
            .groupBy('bySector')
            .map((objs, key) => ({
              'Sector': key,
              '폐기물': _.sumBy(objs, 'give_qunt_unit_chage'),
            }))
            .value();

        const finalData = _.uniqBy(projectlist.map((v)=>({Sector:v.bySector})),'Sector')
        .map(k => ({ ...k, ...wasteSum_by_pjt.find(sp => sp.Sector === k.Sector) }))
        .map(k => ({ ...k, ...Sum_by_division.find(sp => sp.Sector === k.Sector) }))
        .map((v)=>({
          Sector:v.Sector,
          '에너지':typeof v.에너지 !== 'undefined'?v.에너지:0,
          '온실가스':typeof v.온실가스 !== 'undefined'?v.온실가스:0,
          '용수':typeof v.용수 !== 'undefined'?v.용수:0,
          '폐기물':typeof v.폐기물 !== 'undefined'?v.폐기물:0,
          data:[typeof v.에너지 !== 'undefined'?v.에너지:0, typeof v.온실가스 !== 'undefined'?v.온실가스:0,typeof v.용수 !== 'undefined'?v.용수:0,typeof v.폐기물 !== 'undefined'?v.폐기물:0]
        }))


  const category = ['에너지', '온실가스', '용수','폐기물']
  const seriesLabels = {
    visible: true,
    color:'#F1F2F5',
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
  };

  const handleColumnChart0 = (e) => {
    var origin = e.rect.origin;
    var bottomRight = e.rect.bottomRight();

    var offset = (bottomRight.x - origin.x) / 4;
    var path = new Path({
      fill: {
        color: e.options.color,
      },
      stroke: {
        color: "#939393",
        width: 0.5,
      },
    })
      .moveTo(origin.x + offset, bottomRight.y)
      .lineTo(bottomRight.x - offset, bottomRight.y)
      .lineTo(bottomRight.x - offset, origin.y)
      .lineTo(origin.x + offset, origin.y)
      .close();

    return path;
  };

  const handleColumnChart = (e) => {
    var origin = e.rect.origin;
    var bottomRight = e.rect.bottomRight();

    var offset = (bottomRight.x - origin.x) / 1;
    var path = new Path({
      fill: {
        color: e.options.color,
      },
      stroke: {
        color: "#939393",
        width: 1.5,
      },
    })
      .moveTo(origin.x + offset, bottomRight.y)
      .lineTo(bottomRight.x - offset, bottomRight.y)
      .lineTo(bottomRight.x - offset, origin.y)
      .lineTo(origin.x + offset, origin.y)
      .close();

    return path;
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, index) => (
          <div key={index}>
            {point.value === 0 ? (
              <span>
                {point.series.name} :0{" "}
                {categoryText === "용수"
                  ? "m3"
                  : categoryText === "온실가스"
                  ? "tCO2e"
                  : categoryText === "폐기물"
                  ? "톤"
                  : categoryText === "에너지"
                  ? "GJ"
                  : ""}
              </span>
            )  : (
              <span>
                {point.series.name} :{" "}
                {Number.parseFloat(Number(point.value)).toLocaleString()}{" "}
                {categoryText === "용수"
                  ? "m3"
                  : categoryText === "온실가스"
                  ? "tCO2e"
                  : categoryText === "폐기물"
                  ? "톤"
                  : categoryText === "에너지"
                  ? "GJ"
                  : ""}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  // const chartLegend = [
  //   {
  //     title: `${moment(new Date()).format("YY")} 가능규모`,
  //     color: "#939393",
  //     type: "box",
  //   },
  //   {
  //     title: `${moment(new Date()).format("YY")} 누적 소비규모`,
  //     color: "#4BACC6",
  //     type: "column",
  //   },

  // ];

  const customChartValueAxisItemContent = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toLocaleString();
  };

  const customChartValueAxisItemLabel = {
    visible: false,

    font: "0.7rem pretendard-R",

    content: customChartValueAxisItemContent,
  };

  return (
    <div className="bySectorDetail">
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "33.3%",
          },
          {
            width: "33.3%",
          },
          {
            width: "33.3%",
          },
        
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >


        {finalData.map((com, index) => {
          return (
            <GridLayoutItem key={index} row={1} col={index + 1}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "pretendard-B",
                  fontSize:'1rem',
                  margin:'0px',
                  color:'#F1F2F5'
                }}
              >
                {com.Sector} 
              </p>
              <Chart gradient="glass" transitions={false}>
                <ChartArea height={isDPI ? BoxHeight+20 :BoxHeight-40} margin={{top:20}}  background='#464B52'/>
                <ChartTooltip shared={true} render={sharedTooltipRender} />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    startAngle={45}
                    labels={seriesLabels}
                    name="categoryAxis"
                    majorGridLines={{step:100}}
                    minorGridLines={{step:100}}
                    color='#8E959F'
                    categories={category}
                  />
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    labels={customChartValueAxisItemLabel}
                    visible={false}
                    majorGridLines={{step:100}}
                    minorGridLines={{step:100}}
                    color='#8E959F'

                  />
                  <ChartValueAxisItem name="valueAxis" visible={true}   
                  majorTicks={{step:10000000000}}        
                  majorGridLines={{step:100000000}}      
                  color='#8E959F'
                  labels={{visible:false}}
                    />
                </ChartValueAxis>
                <ChartSeriesDefaults type="column" spacing={-1} />
                <ChartSeries>
         
                  <ChartSeriesItem
                    type="column"
                    data={com.data}
                    color="#4BACC6"
                    visual={(e) => handleColumnChart0(e)}
                    name={`${moment(new Date()).format("YY")} 누적 소비규모`}
                    visibleInLegend={false}
                    border={{width:0}}
                    gap={0}
                    labels={{visible:true, content:(e) => {
                      return e.value === 0 || e.value === null || typeof e.value === "undefined"
                        ? ""
                        : Number(e.value.toFixed(0)).toLocaleString();
                    }, background:'none', color:'#F1F2F5'
                  
                  }}
                  />

               
                </ChartSeries>
              </Chart>
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default BySectorDetail;

const changeCount = (baseArr) => {
  if (baseArr.ESG_GIVE_QUNT_UNIT === "Ton") {
    return Number(baseArr.ESG_GIVE_QUNT);
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "kg") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000;
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "g") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000000;
  }
};
