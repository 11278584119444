import React, { useEffect } from "react";
import PropTypes from "prop-types";

const propTypes = {
  nodeData: PropTypes.object.isRequired,
};

const Node = ({ nodeData }) => {
  const handleNodeData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let childNodes = document.getElementById(nodeData.id).parentElement
      .childNodes;
    if (childNodes[1].className.includes("hidden")) {
      childNodes[0].className = "oc-node";
      childNodes[1].className = "";
    } else {
      childNodes[0].className = "oc-node isChildrenCollapsed";
      childNodes[1].className = "hidden";
    }
  };

  useEffect(() => {
    if (nodeData.position.includes("EM")) {
      let childNodes = document.getElementById(nodeData.id).parentElement
        .childNodes;

      childNodes[0].className = "oc-node isChildrenCollapsed";
      // childNodes[1].className = "hidden";
    }
  }, [nodeData.id, nodeData.position]);

  return (
    <div className="org-node-container">
      <div className="org-position">
        <div style={{ width: "100%" }}>{nodeData.position}</div>
      </div>
      <div className="org-person">
        {nodeData.biopic === null ? (
          <div className="biopicIMG">
            <img src="/img/orgChart/baseIMG.jpg" alt="user_img" />
          </div>
        ) : (
          <div className="biopicIMG">
            <img src={nodeData.biopic} alt="user_img" />
          </div>
        )}

        <div className="org-name">{nodeData.name}</div>
        {nodeData.children && nodeData.children.length > 0 ? (
          <div
            className="org-node-children"
            onClick={(e) => {
              handleNodeData(e);
            }}
          >
            {nodeData.children.length}명 팀원
          </div>
        ) : (
          <div
            className="org-node-children"
            style={{ color: "white", cursor: "default" }}
          >
            0명 팀원
          </div>
        )}
      </div>
    </div>
  );
};

Node.propTypes = propTypes;

export default Node;
