import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
  Card,
  CardTitle,

  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import {
  Chart, ChartLegend,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../../common/datadate';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#4B5335',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.7rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 75,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));

const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const ManPower = ({ filter, projeclist, BoxHeight }) => {
  const [mandata2, setMandata2] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/temp/man`);
  
      // After file list is set, fetch the other data and set mandata2.
      const res1 = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/datafiles/drmandata.json`);
      const res2 = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/datafiles/manmanualdata.json`);
  
      const newarray = [];
      for (let i of res.data.fileList) {
        const res = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/man/${i.name}`);
        const data_with_pjtinfo = res.data.map((v) => ({ ...v, d_reporting_date: moment(v.d_reporting_date).format('MM-DD-YYYY') }))
          .map(v => ({ ...v, ...projeclist.find(sp => sp.ugenProjectNumber === v.projectcode) }));
        newarray.push(...data_with_pjtinfo);
      }
  
      setMandata2(
        [...res1.data, ...res2.data, ...newarray].map(v => ({ ...v, ...projeclist.find(sp => sp.ugenProjectNumber === v.projectcode) }))
          .filter(v => typeof v.division !== 'undefined' && typeof v.ugenProjectName !== 'undefined')
          .map((v) => ({
            ...v,
            uniq: v.직종 + "//" + v.division + "//" + v.d_reporting_date.slice(0, 10),
            uniq2: v.직종 + "//" + v.projectname + "//" + v.d_reporting_date.slice(0, 10)
          }))
      );
    };
    fetchData();
  }, [projeclist]); 

  const today = moment().format("YYYY-MM-DD");
  const today2 = moment().add(-1, 'days').format("MM-DD-YYYY");
  const weekfirst = moment(today).startOf('week').format("YYYY-MM-DD");
  const monthfirst = moment(today).startOf('month').format("YYYY-MM-DD");

  var getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };


  var daylistofweek = getDaysArray(new Date(weekfirst), new Date(today)).map(v => moment(v).format("MM-DD-YYYY"));
  var daylistofmonth = getDaysArray(new Date(monthfirst), new Date(today)).map(v => moment(v).format("MM-DD-YYYY"));

  const total_man_day = _.sumBy(mandata2.filter(v => v.division&& v.division.includes(filter) && v.d_reporting_date === today2), '인원')
  const total_man_week = _.sumBy(mandata2.filter(v =>v.division && v.division.includes(filter) && daylistofweek.includes(v.d_reporting_date)), '인원')
  const total_man_month = _.sumBy(mandata2.filter(v =>v.division && v.division.includes(filter) && daylistofmonth.includes(v.d_reporting_date)), '인원')


  const [chartxType, setchartxType] = useState([]);
  const [chartColData, setChartColData] = useState([]);
  const [chartmin, setChartmin] = useState({});
  const [chartmax, setChartmax] = useState({});
  const [baseunit, setbaseunit] = useState('days');
  const [selectedValue, setSelectedValue] = React.useState("daily");

  // console.log(typeof chartColData)
  // console.log(typeof chartLineData)
  // const handleChange = React.useCallback(
  //     (e) => {
  //         setSelectedValue(e.value);
  //     },
  //     [setSelectedValue]
  // );
  const handleChange = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
    }
  };


  useEffect(() => {

    const chartdata = _(mandata2.filter(v => v.division&&v.division.includes(filter)))
      .groupBy(filter === '' ? "uniq" : 'uniq2')
      .map((objs, key) => ({
        date: new Date(key.split("//")[2]),
        xtype: key.split("//")[1],
        count: _.sumBy(objs, "인원"),
      }))
      .value();

    const man_result = _.sortBy(
      chartdata.map(function (obj) {
        var index = obj.xtype;
        obj[index] = obj.count;
        return obj;
      }),
      "date"
    );


    const linechartdata = _(chartdata)
      .groupBy("date")
      .map((objs, key) => ({
        date: key,
        count: _.sumBy(objs, "count"),
      }))
      .value();

    const xtype_list = _.uniqBy(chartdata, 'xtype');

    const max =
      man_result[man_result.length - 1] &&
      moment(man_result[man_result.length - 1]["date"]).add(1, 'days');

    const min =
      man_result[man_result.length - 1] &&
      new Date(
        moment(new Date(man_result[man_result.length - 1]["date"]))
          .subtract(63, "days")
          .format("YYYY-MM-DD")
      );

    const min2 =
      man_result[man_result.length - 1] &&
      new Date(
        moment(new Date(man_result[man_result.length - 1]["date"]))
          .subtract(10, "days")
          .format("YYYY-MM-DD")
      );


      setchartxType([
        ...xtype_list,
        {
          data: linechartdata,
        },
      ]);
    setChartColData(man_result);
    setChartmin(selectedValue === 'daily' ? min2 : min);
    setChartmax(max);

    setbaseunit(selectedValue === 'daily' ? 'days' : selectedValue === 'weekly' ? 'weeks' : selectedValue === 'monthly' ? 'months' : '')

  }, [mandata2, selectedValue, filter, i18n.language]);


  const COLORS = [
    "#FFA500",
    "#56C6A9",
    "#6F9FD8",
    "#E8A798",
    "#D9CE52",
    "#D19C97",
    "#ECDB54",
    '#D2C29D',
    '#F0EAD6',
    '#D69C2F',
    '#FFD662',
    '#E8A798',
    '#95DEE3',
    '#ECDB54',
    '#EC9787'
  ];

  const labelContent2 = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toLocaleString();

  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 2,
    font: "normal 0.6rem Arial, sans-serif",
    position: "center",
    background: "none",
    content: labelContent2,
  };

  const labelContent = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toLocaleString();

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 2,
    font: "normal 0.6rem Arial, sans-serif",
    position: "top",
    background: "none",
    content: labelContent,
  };

  // const labelContent_0 = (e) => {
  //   return e.value === 0 || e.value === null || typeof e.value === "undefined"
  //     ? ""
  //     : selectedValue ==='monthly'
  //     ? moment(new Date(e.value)).format("YY년 MM월")
  //     : 
  //     selectedValue ==='weekly'?
  //     moment(new Date(e.value)).format("YY년 MM월") +
  //       " " +
  //       Math.ceil(new Date(e.value).getDate() / 7) +
  //       "주":moment(new Date(e.value).format("YY년 MM월 DD일"))
  // };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    //   content: labelContent_0,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        {selectedValue === 'monthly' ? (
          <div>{moment(points[0].category).format(i18n.language ==="ko"? "YY년 MM월":"MMM YYYY")}</div>
        ) :
          selectedValue === 'weekly' ?
            (
              <div>
                {moment(points[0].category).format(i18n.language ==="ko"? "YY년 MM월":"MMM YYYY") +
                  " " +
                  Math.ceil(points[0].category.getDate() / 7) +
                  (i18n.language ==="ko"? "주":"week")}
              </div>
            )
            : (<div>{moment(points[0].category).format(i18n.language ==="ko"? "YY년 MM월":"MMM DD YYYY")}</div>)
        }
        {points.map(function (point, index) {
          return point.series.field === "count" ? (
            <div key={index}>
             {t("합계")}: {point.value.toLocaleString()}
            </div>
          ) : (
            <div key={index}>
              {point.series.name} : {point.value.toLocaleString()}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <>
      <Card style={{ height: (BoxHeight - 80) / 2, margin: '12px', fontSize: '12px' }}>
        <CardTitle style={{ margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
          <span style={{ float: 'left' }}>{t("인원 투입현황")} 
          <span style={{ fontSize: 'smaller' }}> ({t("작업일보 기준")})</span></span>
          <span style={{ position: 'absolute', fontWeight: '500', fontSize:"0.8rem" , right: '12px' }}>{generateUpdatedDate(new Date())}</span></CardTitle>
        <GridLayout style={{ width: '100%', float: 'left', height: ((BoxHeight - 62) / 2) - 40 }} rows={[
          {
            height: '20%',
          },

          {
            height: '80%',
          },


        ]}
          cols={[
            {
              width: '100%',
            },
          ]}
          gap={{
            rows: 0,
            cols: 0,
          }}>
          <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
            <div style={{ marginLeft: '12px', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
              {selectedValue === 'daily' ?
                <div><span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{total_man_day.toLocaleString()}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> {t("명 카운트")} </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> ({t("어제")}) </span></div> :
                selectedValue === 'weekly' ?
                  <div><span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{total_man_week.toLocaleString()}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> {t("명 카운트")} </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> ({t("이번 주")}) </span></div> :
                  selectedValue === 'monthly' ?
                    <div><span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{total_man_month.toLocaleString()}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> {t("명 카운트")} </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> ({t("이번 달")}) </span></div> : ''
              }
            </div>
            <div style={{ position: 'absolute', top: '50%', right: '12px', transform: 'translateY(-50%)' }} className='manRB'>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                  <ToggleButton value="daily" aria-label='daily' >
                    daily
                  </ToggleButton>
                  <ToggleButton value="weekly" aria-label="weekly">
                    weekly
                  </ToggleButton>
                  <ToggleButton value="monthly" aria-label="monthly">
                    monthly
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
              {/* 
                            <RadioButton
                                name="group1"
                                value="daily"
                                checked={selectedValue === "daily"}
                                label="Daily"
                                onChange={handleChange}
                            />
                           &nbsp;&nbsp;
                            <RadioButton
                                name="group1"
                                value="weekly"
                                checked={selectedValue === "weekly"}
                                label="Weekly"
                                onChange={handleChange}
                            />
                            &nbsp;&nbsp;
                            <RadioButton
                                name="group1"
                                value="monthly"
                                checked={selectedValue === "monthly"}
                                label="Monthly"
                                onChange={handleChange}
                            /> */}
            </div>
          </GridLayoutItem>
          <GridLayoutItem row={2} col={1} style={{ position: 'relative' }}>
            <Chart
            // transitions={false}
              pannable={{
                lock: "y",
              }}
              zoomable={{
                mousewheel: {
                  lock: "y",
                },
                selection: {
                  lock: "y",
                },
              }}
            >
              <ChartArea height={((((BoxHeight - 62) / 2)) * 0.8 - 20)} margin={{ bottom: 5, top: 20 }} />
              <ChartLegend position="bottom" orientation="horizontal" />
              <ChartTooltip shared={true} render={sharedTooltipRender} />
              <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    min={chartmin}
                    max={chartmax}
                    baseUnit={baseunit}
                    labels={seriesLabels_0}
                    majorGridLines={{ step: 1000000 }}
                  // baseUnitStep='auto' 
                  // maxDateGroups={9} 
                  />
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem labels={{ visible: false }} majorGridLines={{ step: 1000000 }} visible={false} line={{ visible: false }}></ChartValueAxisItem>
                </ChartValueAxis>
              <ChartSeries>
              {
                  chartxType.map((v, index) => {
                    if (index !== chartxType.length - 1) {
                      return (
                        <ChartSeriesItem
                          key={index}
                          aggregate="sum"
                          type="column"
                          stack={true}
                          data={[...chartColData]}
                          field={v.xtype}
                          categoryField="date"
                          labels={seriesLabels2}
                          color={COLORS[index]}
                          border={{ width: "0" }}
                          name={ i18n.language!== "ko"&& v.xtype ==="수소친환경"?"H2/Recycling":v.xtype}
                        />
                      );
                    } else {
                      return (
                        <ChartSeriesItem
                          key={index}
                          type="line"
                          aggregate="sum"
                          data={v.data}
                          field="count"
                          categoryField="date"
                          labels={seriesLabels}
                          color="lightgrey"
                          name="total"
                        />
                      );
                    }
                  })}


              </ChartSeries>
            </Chart>
          </GridLayoutItem>

        </GridLayout>

      </Card>
    </>
  );
};

export default ManPower;