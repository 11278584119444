import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { TileLayout } from "@progress/kendo-react-layout";
import axios from 'axios';
import _ from 'lodash';
import useBreakpoint from "use-breakpoint";
import html2canvas from "html2canvas";
import LandscapeImg from './land';
import HistroyImg from './histroy';
import WeeklyImg from './weekly2';
import "../kendocustom.css";
import '@progress/kendo-theme-default/dist/all.css';
import { useTranslation } from 'react-i18next';
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import { DashboardLayout } from '../projectLayout';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const {REACT_APP_URI2} = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Photo = () => {
  const { t, i18n } = useTranslation();
 
  const { project_code } = useParams();
  const exportAsImage = async () => {
    const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, project_code+'_현장 사진.png');
    };
  
  const { height, width } = useWindowDimensions();
  
  const downloadImage = (blob, fileName) => {
      const fakeLink = window.document.createElement("a");
      fakeLink.style = "display:none;";
      fakeLink.download = fileName;
      
      fakeLink.href = blob;
      
      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);
      
      fakeLink.remove();
      };
  
  const [grid, setgrid] = useState(  {
    mobile: [
      {
        col: 1,
        colSpan: 6,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 3,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      },
    
     
     
    ],

    tablet:  [
      {
        col: 1,
        colSpan: 6,
        rowSpan: 3,
        status: true,
      },
      {
        col: 7,
        colSpan: 12,
        status: true,
        rowSpan: 3,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      },
    
     
         
    ],

    desktop: [
      {
        col: 1,
        colSpan: 6,
        rowSpan: 4,
        status: true,
      },
      {
        col: 7,
        colSpan: 12,
        status: true,
        rowSpan: 4,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 2,
      },
    
     
    ]
  });
  const [status_grid, setstatusgrid] = useState([{status:true}, {status:true}, {status:true} ]);
  const [lineitem, setlineitem] = useState([]);
  const [columns, setcolumns] = useState(18);
  const [blockH, setblockH] = useState(140);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  
  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
          "bpname": "대시보드 설정",
          "lineitem": "yes",
          "filter_condition" : "status=Active",
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
      if (res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '현장 사진').length > 0){
      const sort =  _.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '현장 사진')[0]['_bp_lineitems'].filter(v=>v.Status_CB ==='Yes'), 'Card_Order')
      const GRIDCONFIG =  sort.map((v)=>({colSpan:v.Card_Width, rowSpan:v.Card_Vertical_Width, status:true , col:v.StartingPosition}))
      const GRID_CONFIG =  {mobile: GRIDCONFIG.map((v)=> ({colSpan:16, rowSpan:v.rowSpan, status:v.status, col:1})), tablet:GRIDCONFIG , desktop:GRIDCONFIG};

      setlineitem(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '현장 사진')[0]['_bp_lineitems'])

      setstatusgrid(_.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '현장 사진')[0]['_bp_lineitems'].map((v)=>({...v, status:v.Status_CB ==='Yes'?true:false})), 'Card_Order'))

      setblockH(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '현장 사진')[0]['default_vertical_height'])
      setcolumns(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '현장 사진')[0]['columns_count'])
      setgrid(
        GRID_CONFIG
      )
    }
  }
    fetchData();
  }, [project_code]);

  const [positiondata, setPositionData] = useState(grid['desktop']);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(true);

  const togglefix = () => {
    setfixblock(!fixblock);
  };
  
const [tile1, settile1] = useState(0)
const [tile2, settile2] = useState(1)
const [tile3, settile3] = useState(2)


useEffect(() =>{
  settile1(_.get(lineitem.filter(v=>v.Card_Name ==='전경사진')[0], 'Card_Order')-1)
  settile2(_.get(lineitem.filter(v=>v.Card_Name ==='주요공정 Weekly')[0], 'Card_Order')-1)
  settile3(_.get(lineitem.filter(v=>v.Card_Name ==='Photo History')[0], 'Card_Order')-1)

}, [lineitem])

  const tiles = [{
    header: (<div style={{ position: 'relative' }}>{t("전경사진")}</div>),
    body:<LandscapeImg positiondata={positiondata} blockH={blockH} order={isNaN(tile1)? 0:tile1}/>,
    status: true,
    key:'전경사진',
    resizable: fixblock,
    reorderable: fixblock,

  }, {
    header: (<div style={{ position: "relative" }}>{t("주요공정 Weekly")}</div> ),
    body:<WeeklyImg  positiondata={positiondata} blockH={blockH}  order={isNaN(tile2)? 1:tile2}/>,
    status: true,
    key:'주요공정 Weekly',
    resizable: fixblock,
    reorderable: fixblock,
  }, 
  
  {
    header: (<div><div style={{ position: "relative" }}>Photo History</div></div> ),
    body: <HistroyImg  positiondata={positiondata} blockH={blockH}  order={isNaN(tile3)? 2:tile3}/>,
    status: true,
    key:'Photo History',
    resizable: fixblock,
    reorderable: fixblock,
  }

  ];



  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles.map((v, index)=>({...v, status:_.get(status_grid[index], 'status')})));
  }, [positiondata, status_grid, tile1, tile2, tile3,fixblock,i18n.language]);

  const tile_filtered = _.filter(tile, ['status', true]);

  const handleReposition = e => {
    setPositionData(e.value);
    // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
  };

  const location = useLocation();
  const usernameFromState = location.state?.username;
  const [isLoading, setIsLoading] = useState(false);
  
  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`http://43.200.223.224:5000/getcheckowner?project=${project_code}`);
      setIsLoading(false);
   
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

  const isNotIframe = window.self === window.top;

  return (
    <>
    {isNotIframe? (
      <DashboardLayout>
      <div>
      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
          <ButtonGroup>
            <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
            <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
            <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
          </ButtonGroup>
        </div>
          
        <TileLayout
          columns={columns}
          rowHeight={blockH}
          positions={positiondata}
          gap={{
            rows: 10,
            columns: 10
          }}
          items={tile_filtered}
          autoFlow="row dense"
          onReposition={handleReposition}
          style={{marginTop:20}}
        />
  
      
      </div>
      </DashboardLayout>
    )
  :
    <div>
        <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
            <ButtonGroup>
              <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
              <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
              <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
            </ButtonGroup>
          </div>
            
          <TileLayout
            columns={columns}
            rowHeight={blockH}
            positions={positiondata}
            gap={{
              rows: 10,
              columns: 10
            }}
            items={tile_filtered}
            autoFlow="row dense"
            onReposition={handleReposition}
            style={{marginTop:20}}
          />
    
        
        </div>

    }

    </>
  ) ;
};

export default Photo;