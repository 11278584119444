import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
const OverrallTableLegend = () => {
  const normalCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        <div
          style={{
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Dev}
        </div>
      </td>
    );
  };

  // const normalCell2 = (props) => {
  //   const field = props.field || "";
  //   const Dev = props.dataItem[field];

  //   return (
  //     <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
  //       {Dev.includes("<br />") ? (
  //         Dev.split("<br />").map((com, idx) => {
  //           return (
  //             <p key={idx} style={{ marginBottom: 0 }}>
  //               {com}
  //             </p>
  //           );
  //         })
  //       ) : (
  //         <p style={{ marginBottom: 0 }}>{Dev}</p>
  //       )}
  //     </td>
  //   );
  // };

  const colorCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return (
      <td>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60px",
              height: "20px",
              borderRadius: "0.4rem",
              backgroundColor: Dev,
            }}
          ></div>
        </div>
      </td>
    );
  };

  const normalHeaderCell = (props) => {
    const { title } = props;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          font: "normal normal normal 0.8rem Pretendard",
        }}
      >
        <p style={{ margin: "0" }}>{title} </p>
      </div>
    );
  };
  return (
    <Grid data={legend_code} style={{ padding: 0 }} scrollable={false}>
      <GridColumn
        field="color"
        title="Color"
        cell={colorCell}
        headerCell={normalHeaderCell}
        width={"100px"}
      />
      <GridColumn
        field="progress"
        title="Progress"
        cell={normalCell}
        headerCell={normalHeaderCell}
      />
      <GridColumn
        field="productivity"
        title="Productivity"
        cell={normalCell}
        headerCell={normalHeaderCell}
      />
      {/* <GridColumn title="Resource Plan" headerCell={normalHeaderCell}>
        <GridColumn
          field="resourcePlan_percent"
          title="Resource Plan(%)"
          cell={normalCell2}
          headerCell={normalHeaderCell}
        />
        <GridColumn
          field="resourcePlan_gap"
          title="Resource Plan(GAP)"
          cell={normalCell2}
          headerCell={normalHeaderCell}
        />
      </GridColumn>
      <GridColumn
        field="quality"
        title="Quality"
        cell={normalCell}
        headerCell={normalHeaderCell}
      /> */}
      <GridColumn
        field="evaluation"
        title="Evaluation"
        cell={normalCell}
        headerCell={normalHeaderCell}
      />
    </Grid>
  );
};

export default OverrallTableLegend;

const legend_code = [
  {
    color: "rgba(0, 176, 240, 0.4)",
    progress: "> +5%",
    productivity: "> 1.21",
    resourcePlan_percent: "-10% ~ 10%",
    resourcePlan_gap: "-2명 ~ 2명",
    quality: "S(≥90)",
    evaluation: "Excellent",
  },
  {
    color: "rgba(112, 173, 71, 0.4)",
    progress: "0% ~ +5%",
    productivity: "0.95 ~ 1.20",
    resourcePlan_percent: "-20% ~ -10% <br /> 10% ~ 20%",
    resourcePlan_gap: "-5명 ~ -3명 <br /> 3명 ~ 5명",
    quality: "A(80≤P<90)",
    evaluation: "Good",
  },
  {
    color: "rgba(255, 192, 0, 0.4)",
    progress: "-5% ~ 0%",
    productivity: "0.81 ~ 0.95",
    resourcePlan_percent: "-20% ~ -40% <br /> 20% ~ 40%",
    resourcePlan_gap: "-5명 ~ -10명 <br /> 5명 ~ 10명",
    quality: "B(60≤P<80)",
    evaluation: "Moderate",
  },
  {
    color: "rgba(255, 0, 0, 0.4)",
    progress: "< -5%",
    productivity: "< 0.80",
    resourcePlan_percent: "< -40% <br /> > 40%",
    resourcePlan_gap: "< -10명 <br /> > 10명",
    quality: "C(<60)",
    evaluation: "Bad",
  },
];
