import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import axios from "axios";
import { TileLayout } from "@progress/kendo-react-layout";
import ActionListTileBody from "./ActionListTileBody/ActionListTileBody";

const { REACT_APP_URI2 } = process.env;

const url = `http://43.200.223.224:5000`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const iframe_boolean = window.self === window.top;

const SkoneEMSActionList = () => {
  const { height } = useWindowDimensions();

  // const [sampleData, setSampleData] = useState([]);
  const [gasPowerData, setGasPowerData] = useState([]);
  const [hydroData, setHydroData] = useState([]);
  const [industrialData, setIndustrialData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let engineering_progress_ratio_body = {
        bpname: "Engineering Progress Ratio",
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "status",
              value: "Active",
              condition_type: "eq",
            },
          ],
        },
      };

      const fetch_engineering_progress_ratio_body = await axios
        .post(
          `${url}/getemsbprecord?path=SKEC0001`,
          engineering_progress_ratio_body
        )
        .then((data) => data.data.data.data);

      const action_list_project_list_filter_arr =
        fetch_engineering_progress_ratio_body.map((com) => {
          return com.ugenProjectNumber;
        });

      const project_action_list_body = {
        bpname: "Action List",
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "status",
              value: "Terminated",
              condition_type: "neq",
            },
          ],
        },
      };

      const fetch_project_action_list = await axios
        .post(`${url}/getemsbprecord?path=SKEC0001`, project_action_list_body)
        .then((data) =>
          data.data.data.data.reduce((acc, cum) => {
            const { ugenProjectNumber } = cum;

            if (
              action_list_project_list_filter_arr.includes(ugenProjectNumber)
            ) {
              acc.push(cum);
            }

            return acc;
          }, [])
        );

      console.log(fetch_project_action_list);

      const sector_group_action_list = _(fetch_project_action_list)
        .groupBy("EMSSKDivPD")
        .map((objs, key) => {
          const project_group_data = _(objs)
            .groupBy("ugenProjectNumber")
            .map((objs2, key2) => {
              const find_project_name =
                fetch_engineering_progress_ratio_body.find(
                  (com) => com.ugenProjectNumber === key2
                );

              const action_list_closed_list = objs2.filter(
                (com) => com.status === "Closed"
              );

              const action_list_open_list = objs2.filter(
                (com) => com.status !== "Closed"
              );

              return {
                ugenProjectName: find_project_name?.ugenProjectName,
                total: objs2.length,
                closed: action_list_closed_list.length,
                open: action_list_open_list.length,
                progress: Number(
                  (
                    returnZeroIfInvalid(
                      action_list_closed_list.length / objs2.length
                    ) * 100
                  ).toFixed(2)
                ),
                objs2,
                key2,
              };
            })
            .value();

          return { key, arr: project_group_data };
        })
        .value();

      const find_gas_power_data = sector_group_action_list.find(
        (com) => com.key === "Gas & Power"
      );

      const find_hydro_data = sector_group_action_list.find(
        (com) => com.key === "수소친환경"
      );

      const find_industrial_data = sector_group_action_list.find(
        (com) => com.key === "Industrial"
      );

      setGasPowerData(find_gas_power_data?.arr ?? []);
      setHydroData(find_hydro_data?.arr ?? []);
      setIndustrialData(find_industrial_data?.arr ?? []);

      // const fetch_engineering_progress_ratio_body_map =
      //   fetch_engineering_progress_ratio_body.map((com) => {
      //     const closed = sameple_number();
      //     const open = sameple_number();
      //     const total = closed + open;

      //     return {
      //       ugenProjectName: com.ugenProjectName
      //         .replaceAll("Project", "")
      //         .replaceAll("PJT", ""),
      //       total,
      //       closed,
      //       open,
      //       progress: Number(
      //         (returnZeroIfInvalid(closed / total) * 100).toFixed(2)
      //       ),
      //     };
      //   });

      // setSampleData(fetch_engineering_progress_ratio_body_map);
    };
    fetchData();
  }, []);

  const tile_height = useMemo(() => {
    return iframe_boolean ? height - 20 : height - 210 - 20;
  }, [height]);

  const tilesData = useMemo(
    () => [
      {
        header: <div>Gas & Power</div>,
        body: (
          <ActionListTileBody
            height={tile_height - 12}
            actionListData={gasPowerData}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: <div>수소친환경</div>,
        body: (
          <ActionListTileBody
            height={tile_height - 12}
            actionListData={hydroData}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: <div>Industrial</div>,
        body: (
          <ActionListTileBody
            height={tile_height - 12}
            actionListData={industrialData}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
    ],
    [tile_height, gasPowerData, hydroData, industrialData]
  );

  return (
    <div>
      <TileLayout
        columns={12}
        rowHeight={tile_height / 12}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tilesData}
        autoFlow="row dense"
        style={{
          height: tile_height,
          padding: 0,
          margin: 10,
        }}
      />
    </div>
  );
};

export default SkoneEMSActionList;

const positiondata = [
  {
    col: 1,
    colSpan: 4,
    rowSpan: 12,
    status: true,
  },
  {
    col: 5,
    colSpan: 4,
    rowSpan: 12,
    status: true,
  },
  {
    col: 9,
    colSpan: 4,
    rowSpan: 12,
    status: true,
  },
];

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

// const sameple_number = () => {
//   return Number((Math.random() * 100).toFixed(0));
// };

const arr = [
  [
    {
      field: "ugenProjectNumber",
      value: "22270D",
      condition_type: "eq",
    },
  ],
];
