import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import _ from "lodash";
import moment from "moment";
import { useMediaQuery } from 'react-responsive';

const ByProjectDetail = ({ positiondata, order, blockH, EnvDataAll, EnvDataWaste, EnvDataFine,projectlist }) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  const [tableData, setTableData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {

      const unifierDataFilter = EnvDataWaste
        .filter((com) => com.ESG_TRTM_WAYS !== null)
        .filter((com) => Number(com.ESG_GIVE_QUNT) !== 0)
        .filter((com) => com.ESG_WSTE_CATE !== null)
        .filter(
          (com) =>
            com.ESG_GIVE_DATE.slice(0, 4) ===
            String(moment(new Date()).format("YYYY"))
        )
        .map((com) => {
          return {
            ...com,
            give_qunt_unit_chage: changeCount(com),
          };
        });

      var wasteSum_by_pjt =
        _(unifierDataFilter)
          .groupBy('project_projectnumber')
          .map((objs, key) => ({
            'projectnumber': key,
            '폐기물': _.sumBy(objs, 'give_qunt_unit_chage'),
          }))
          .value();
        


      const linedata = EnvDataAll.reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ueffectyearPD: v.ueffectyearPD, umuMonthPD: v.umuMonthPD }))), [])
        .filter(v => v.ueffectyearPD === moment().format('YYYY'))

      var Sum_by_pjt =
        _(linedata)
          .groupBy('ugenProjectNumber')
          .map((objs, key) => ({
            'projectnumber': key,
            '용수': _.sumBy(objs.filter(v => v.Envclass1 === '용수'), 'Envamount'),
            "에너지": _.sumBy(objs.filter(v => v.Envclass1 === "에너지"), 'Envamount'),
            "온실가스": _.sumBy(objs.filter(v => v.Envclass1 === "온실가스"), 'Envamount'),
          }))
          .value();


      var fine_by_pjt =
        _(EnvDataFine)
          .groupBy('project_projectnumber')
          .map((objs, key) => ({
            'projectnumber': key,
            '벌금': _.sumBy(objs, 'EnvPenAmountDA'),
            '벌금횟수': objs.length,
          }))
          .value();

      const data_total = projectlist
      .map(k => ({ ...k, ...Sum_by_pjt.find(sp => sp.projectnumber === k.ugenProjectNumber) }))
      .map(k => ({ ...k, ...wasteSum_by_pjt.find(sp => sp.projectnumber === k.ugenProjectNumber) }))
      .map(k => ({ ...k, ...fine_by_pjt.find(sp => sp.projectnumber === k.ugenProjectNumber) }))
      .map((v)=>({
        ugenProjectName:v.ugenProjectName,
        Sector:v.bySector,
        '에너지':typeof v.에너지 !== 'undefined'?v.에너지:0,
        '온실가스':typeof v.온실가스 !== 'undefined'?v.온실가스:0,
        '용수':typeof v.용수 !== 'undefined'?v.용수:0,
        '폐기물':typeof v.폐기물 !== 'undefined'?v.폐기물:0,
        '벌금':typeof v.벌금 !== 'undefined'?v.벌금:0,
        '벌금횟수':typeof v.벌금횟수 !== 'undefined'?v.벌금횟수:0,
      }))
      .map((v)=>({...v, sum:v.에너지+v.온실가스+v.용수+v.벌금+v.벌금횟수+v.폐기물}))
      .filter(v=>v.sum !== 0)

      setTableData(_.sortBy(_.sortBy(data_total, "ugenProjectName"), "Sector"));

    };
    fetchData();
  }, [EnvDataAll, EnvDataWaste, EnvDataFine,projectlist]);


  const cellRender = (cell, props) => {
    const { field, dataItem } = props;

    if (field === "Sector") {
      const filterSectorRow = tableData.filter(
        (com) => com.Sector === dataItem[props.field]
      );
      if (
        dataItem["ugenProjectName"] === filterSectorRow[0]["ugenProjectName"] 
      ) {
        return (
          <td
            rowSpan={filterSectorRow.length}
            style={{
              fontWeight: "bold",
              wordBreak: "break-word",
            }}
          >
            {props.dataItem[props.field]}
          </td>
        );
      } else {
        return null;
      }
    } else if (field === "ugenProjectName" || field === "벌금") {
      const filterSectorRow = tableData.filter(
        (com) => com.ugenProjectName === dataItem["ugenProjectName"]
      );

    
        return (
          <td
            rowSpan={filterSectorRow.length}
            style={{
              fontSize: "0.8rem",
            }}
          >
            {props.dataItem[props.field]}
          </td>
        );
      
    } else {
      return cell;
    }
  };

  const footerShell = (props) => {
    const field = props.field || "";

    if (field === "ugenProjectName") {
      return (
        <td
          colSpan={props.colSpan}
          style={{ textAlign: "center" }}
        >
          <div>계</div>
        </td>
      );
    }  else {
      const tableFilter1 = tableData

      return (
        <td
          colSpan={props.colSpan}
          style={{ textAlign: "right" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "25px",
              justifyContent: "space-around",textAlign: "center" 
            }}
          >
            <div>{field === "벌금"?  _.sumBy(tableFilter1, field)&& (Number(_.sumBy(tableFilter1, field).toFixed(0))/10000).toLocaleString() :_.sumBy(tableFilter1, field)&& Number(_.sumBy(tableFilter1, field).toFixed(0)).toLocaleString()}</div>
          </div>
        </td>
      );
    }
  };

  const HeaderCell = (props) => {
    const { title } = props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        {title}
        <br />
        {title === "용수"
          ? "(m3)"
          : title === "온실가스"
            ? "(tCO2e)"
            : title === "폐기물"
              ? "(톤)"
              : title === "에너지"
                ? "(GJ)"
                : title === "벌점/과태료"
                && "(만원)"}
      </div>
    );
  };

  const normalCellRender = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td
        style={{
          textAlign:"center" ,
        }}
      >
        {value === 0 ? '' : field === '벌금' ? value / 10000 : value && Number(value.toFixed(0)).toLocaleString()}
      </td>
    );
  };

  return (
    <div>
      <Grid
        style={{ height: isDPI? BoxHeight+20 :BoxHeight - 16 }}
        data={tableData}
        cellRender={cellRender}
        className="esgProjectTable"
      >
        <GridColumn field="Sector" title="부문" headerCell={HeaderCell} />
        <GridColumn
          field="ugenProjectName"
          title="PJT"
          footerCell={footerShell}
          headerCell={HeaderCell}
        />

        <GridColumn title={"발생량"} headerCell={HeaderCell}>
          <GridColumn
            field="에너지"
            title="에너지"
            footerCell={footerShell}
            headerCell={HeaderCell}
            cell={normalCellRender}
          />
          <GridColumn
            field="온실가스"
            title="온실가스"
            footerCell={footerShell}
            headerCell={HeaderCell}
            cell={normalCellRender}
          />
          <GridColumn
            field="용수"
            title="용수"
            footerCell={footerShell}
            headerCell={HeaderCell}
            cell={normalCellRender}
          />
          <GridColumn
            field="폐기물"
            title="폐기물"
            footerCell={footerShell}
            headerCell={HeaderCell}
            cell={normalCellRender}
          />
        </GridColumn>
        <GridColumn title={"환경 벌금/과태료"} headerCell={HeaderCell} >

          <GridColumn field="벌금횟수" title="발생건수" headerCell={HeaderCell} cell={normalCellRender}  footerCell={footerShell}/>

          <GridColumn
            field="벌금"
            title="벌점/과태료"
            headerCell={HeaderCell}
            cell={normalCellRender}
            footerCell={footerShell}
          />
        </GridColumn>
      </Grid>
    </div>
  );
};

export default ByProjectDetail;

const changeCount = (baseArr) => {
  if (baseArr.ESG_GIVE_QUNT_UNIT === "Ton") {
    return Number(baseArr.ESG_GIVE_QUNT);
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "kg") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000;
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "g") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000000;
  }
};
