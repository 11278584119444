import React, { useEffect, useState ,useMemo} from 'react';
import krMessages from "./kr.json";
import _ from 'lodash';
import {
    Card,
    CardTitle,
    CardBody,
    CardSubtitle,
} from "@progress/kendo-react-layout";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from 'moment';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import CustomCalendar from './customcalendar';
import { useTranslation } from 'react-i18next';
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const KeyEvents = ({ filter, BoxHeight, projeclist }) => {

  const [filelist, setfilelist] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
       const res = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/temp/key`)
        setfilelist(res.data.fileList)
    };
    fetchData();
}, []);

const filelist2 = useMemo(() => filelist, [filelist]);
    const { t, i18n } = useTranslation();

    const [data, setdata] = useState([]);
    useEffect(() => {
        const fetchData = async () => {

          const newarray2 = [];
          for (let i of filelist2) {
            const res = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/key/${i.name}`)
              const data_with_pjtinfo = res.data.map((v) => ({ ...v, plan_date: moment(v.plan_date.slice(0, 10)).format('MM-DD-YYYY'),  genActualDate: v.genActualDate === 'Invalid date'? null:moment(v.genActualDate.slice(0, 10)).format('MM-DD-YYYY'), }))
              .map(v => ({ ...v, ...projeclist.find(sp => sp.ugenProjectNumber === v.projectcode) }))
              newarray2.push(...data_with_pjtinfo)
    
          }

            const newarray = [];
            for (let i of projeclist) {
                let body =
                {
                    "bpname": "Project Milestone",
                    "lineitem": "no",
                    "filter_criteria": {
                        "join": "AND",
                        "filter": [

                            {
                                "field": "status",
                                "value": "Active",
                                "condition_type": "eq"
                            },
                        ]
                    }
                }
                const res = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=${i.ugenProjectNumber}`, body)
                const data_with_pjtinfo = res.data.data.data.map((v) => ({ ...v, division:i.division, projectname:i.projectname, projectcode:i.projectcode ,plan_date: v.plan_date.slice(0, 10) }))
                newarray.push(...data_with_pjtinfo)

            }

            setdata([...newarray, ...newarray2])


        };

        fetchData();
    }, [projeclist, filelist2]);

    
    const [sort, setSort] = useState([{ field: "plan_date", dir: "asc"}]);

    const normalcell = (props) => {
        const field = props.field || "";
        const cell = props.dataItem[field];

        const actual = props.dataItem["genActualDate"] === null ? moment().format("MM-DD-YYYY") : moment(props.dataItem["genActualDate"]) 
        const variance = moment(props.dataItem["plan_date"]).diff(actual, 'days')
 
        return  <td title={cell} style={{ fontSize: "0.8rem", textAlign: "left" , whiteSpace:'nowrap',color:variance<0?'#FA7A35': variance>0 && props.dataItem["genActualDate"] !== null?'#006B54' :'#363945'}}><Tooltip anchorElement="target" position="top" style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}><span title={cell}>{cell}</span></Tooltip> </td>;
      };

      const normalcell2 = (props) => {
        const field = props.field || "";
        const cell = props.dataItem[field];

        const actual = props.dataItem["genActualDate"] === null ? moment().format("MM-DD-YYYY") : moment(props.dataItem["genActualDate"]) 
        const variance = moment(props.dataItem["plan_date"]).diff(actual, 'days')

        return <td style={{ fontSize: "0.8rem", textAlign: "left" , color:variance<0?'#FA7A35': variance>0 && props.dataItem["genActualDate"] !== null?'#006B54' :'#363945' }}>{cell && cell.slice(0,5)}</td>;
      };


      const defaultvalue = new Date(Date.now())
      const [value, setValue] = useState(defaultvalue);
  
      const handleChange = (event) => {
          setValue(event.target.value);
      };
  
      const max = new Date();

      const from_date = moment(value).startOf('month');
      const to_date = moment(value).endOf('month');
  
      var getDaysArray = function (start, end) {
          for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
              arr.push(new Date(dt));
          }
          return arr;
      };
  
      const total_data = data

      var daylist = getDaysArray(new Date(from_date), new Date(to_date)).map(v => moment(v).format("MM-DD-YYYY"));
      const data_filter = _.filter(total_data.filter(v=>v.division&&v.division.includes(filter)), function (o) { return daylist.includes(o.plan_date) })
     

    return (
        <>

            <Card style={{ height: BoxHeight-62, margin: '12px' }}>
                <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
                    <span style={{ float: 'left' }}>Key Events</span>
                    <span style={{width:'125px', position:'absolute', right:'12px'}} >
                        <LocalizationProvider language={i18n.language ==="ko"?"ko-KR":"en"}>
                            <IntlProvider locale={i18n.language ==="ko"?"ko-KR":"en"}>
                              <DatePicker format={i18n.language ==="ko"?"yyyy년 MM월":"MMM yyyy"} calendar={CustomCalendar} max={max} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} />
                            </IntlProvider>
                        </LocalizationProvider>
                    </span>
                </CardTitle>
                <CardSubtitle style={{ position:'absolute', top:'40px', left:'12px'}}>
                    <span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{data_filter.length}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>Events</span>
                </CardSubtitle>
                <CardBody style={{ position:'absolute',top:'80px',height: BoxHeight - 178, margin:'12px'  }}>
                    <Grid
                        data={orderBy(data_filter, sort)}
                        sort={sort}
                        onSortChange={(e) => {
                          setSort(e.sort);
                        }}
                        sortable={{ mode: "multiple", allowUnsort: true }}
                       
                        className="keyevent"
                        style={{maxHeight:BoxHeight - 168}}
                    >
                        <GridColumn
                            field="plan_date"
                            title={t("계획일")}
                            cell={normalcell2}
                        />

                        <GridColumn
                            field="genActualDate"
                            title={t("실적일")}
                            cell={normalcell2}
                        />
                        <GridColumn
                            field="projectname"
                            title={t("프로젝트")}
                            cell={normalcell}
                        />

                        <GridColumn
                            field="genMilestoneDesc"
                            title={t("마일스톤")}
                            cell={normalcell}
                        />
                    </Grid>

                </CardBody>
            </Card>
        </>
    );

}
export default KeyEvents
