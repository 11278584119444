import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import { Button } from "@progress/kendo-react-buttons";
import { useParams } from "react-router-dom";
import { Milestones } from "react-milestones-vis";

const { REACT_APP_URI2 } = process.env;

const url = `http://43.200.223.224:5000`;

const CriticalProcess = ({ positiondata, blockH, order }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [data, setData] = useState([]);

  const handleButtonClick = () => {
    window.location.assign(`/${project_code}/criticalprocess`);
  };
  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "Quality Critical Process",
        lineitem: "yes",
        filter_condition: "status=Active",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "QCPDisciplinePD",
              value: "EMT",
              condition_type: "eq",
            },
          ],
        },
      };

      const unifierData = await axios
        .post(`${url}/getemsbprecord?path=${project_code}`, body)
        .then((c) => {
          return c.data.data.data;
        });

      const total_lineitems = _.sortBy(
        unifierData
          .filter((c) => c._bp_lineitems)
          .map((c) => c._bp_lineitems)
          .flat(),
        "emsPlanStartDO"
      ).map((c) => {
        const plan_date =
          c.emsPlanFinishDO.slice(6, 10) +
          "-" +
          c.emsPlanFinishDO.slice(0, 2) +
          "-" +
          c.emsPlanFinishDO.slice(3, 5);

        const act_date = c.emsActualFinishDO
          ? c.emsActualFinishDO.slice(6, 10) +
            "-" +
            c.emsActualFinishDO.slice(0, 2) +
            "-" +
            c.emsActualFinishDO.slice(3, 5)
          : null;

        return {
          title: act_date
            ? `[완료] ${c.QCPActivityNM} (Plan : ${plan_date})`
            : c.QCPActivityNM,

          date: act_date ? act_date : plan_date,

          titleStyle: {
            color: !act_date
              ? "black"
              : new Date(act_date) <= new Date(plan_date)
              ? "#008080"
              : "#A2242F",
          },
        };
      });
      setData(total_lineitems);
    };
    fetchData();
  }, [project_code]);
  
  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "10%",
          },
          {
            height: "90%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <ButtonDiv>
            <CustomButton
              title="블록 온오프"
              iconClass="k-icon k-font-icon k-i-grid"
              onClick={handleButtonClick}
            ></CustomButton>
          </ButtonDiv>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1}>
          <MileStonDiv>
            <Milestones
              aggregateBy="day"
              data={data}
              mapping={{
                text: "title",
                timestamp: "date",
              }}
              parseTime="%Y-%m-%d"
              distribution="top-bottom"
              optimize={true}
              autoResize={true}
            />
          </MileStonDiv>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default CriticalProcess;

const ButtonDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
`;

const CustomButton = styled(Button)`
  background-color: #7f84ab;
  border: none;
  box-shadow: none;
  color: white;
`;

const MileStonDiv = styled.div`
  margin-top: 60px;
`;
