import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import 'hammerjs';
import 'hammerjs';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { useTranslation } from 'react-i18next';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PTW1 = ({ positiondata, sendDataToParent }) => {
    const { project_code } = useParams();
    const [data, setdata] = useState([]);
    const [lastdate, setdate] = useState('');
    const { t } = useTranslation();


    useEffect(() => {
        const fetchData = async () => {
            const res2 = await axios.get(`http://43.200.223.224:5000/getJSON?path=/${REACT_APP_PATH2}/file/safety/${project_code}_safety.json`,)

            if (project_code === '22105D' || project_code === '22106D') {

                setdata(res2.data.map((data) => {
                    return {
                        WorkType: data.d_cwp_check_list_dpk,
                        IssueDate: data.d_work_start.slice(6, 10) + '년 ' + data.d_work_start.slice(0, 2) + '월 ' + data.d_work_start.slice(3, 5) + '일',
                        record_no: data.record_no,
                        count: 1
                    }
                }));
                setdate(_.sortBy(res2.data, 'record_no')[0]['d_work_start'])
            }

            else {
                setdata(res2.data.map((data) => {
                    return {
                        WorkType: data.asWorkType,
                        WorkType2: data.asTargetHazardNM,
                        IssueDate: data.asWorkDate.slice(6, 10) + '년 ' + data.asWorkDate.slice(0, 2) + '월 ' + data.asWorkDate.slice(3, 5) + '일',
                        record_no: data.record_no,
                        asWorkStatus: data.asWorkStatus,
                        count: 1
                    }
                }));
                setdate(_.sortBy(res2.data, 'record_no')[0]['asWorkDate'])
                
            }

        };
        fetchData();
    }, [project_code]);

    const filtered_sr = _.filter(data, function (o) { return o.WorkType !== 'General Work Permit'; })

    useEffect(() => {
    sendDataToParent(lastdate)
    },[lastdate])

    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(210 + 140 * (boxHeight.rowSpan - 3));
        }
    }, [positiondata]);

    

    return (
        <>
            <GridLayout style={{ height: BoxHeight*0.8 }}
                rows={[
                    {
                        height: '90%',
                    }, {
                        height: '10%',
                    },
                ]}

                cols={[
                    {
                        width: '100%',
                    }
                   
                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>
                {/* <div style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '0.8rem' }}><span style={{ fontWeight: '600' }}><span style={{ color: '#939597' }}>작업건수 </span>총 <span style={{ color: 'red', fontWeight: '600' }}>{data.length}</span> 건</span></div> */}

                <GridLayoutItem row={1} col={1} rowSpan={2} style={{position:'relative'}}>
                        {project_code === '22105D' || project_code === '22106D' || project_code === '22266D'|| project_code === '22339D'?

                        <div style={{position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)', textAlign:'center', minWidth:'200px'}}>
                            <div>
                                {t("특별위험작업")} / {t("일반위험작업")}<br></br>
                                <span style={{ fontSize: filtered_sr.length > 2 ?'1.5rem': '2rem', fontWeight: 'bold' }} >{filtered_sr.length}</span><span>{t("개")}</span>
                                <span style={{ fontSize: filtered_sr.length > 2 ?'1.5rem': '2rem', }}> / </span>
                                <span style={{ fontSize: data.length - filtered_sr.length > 2? '1.5rem':'2rem', fontWeight: 'bold' }} >{data.length - filtered_sr.length}</span><span>{t("개")}</span>
                            </div> 
                        </div>
                            :
                            <div>
                                <table style={{ fontSize: '0.8rem', width: '100%', fontWeight: '500', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style={{ backgroundColor: '#f8f8f8', padding: '3px' }}>
                                                <Button style={{ fontSize: '0.6rem', fontWeight: '600', backgroundColor: '#939597', color: 'white', width: '90%' }}>
                                                    점검대기 &gt;
                                                </Button>
                                            </th>
                                            <th style={{ backgroundColor: '#eff5f4' }}>
                                                <Button style={{ fontSize: '0.6rem', fontWeight: '600', backgroundColor: '#006b54', color: 'white', width: '90%' }}>
                                                    점검완료 &gt;
                                                </Button></th>
                                            <th style={{ backgroundColor: '#ffe8e6' }}>
                                                <Button style={{ fontSize: '0.6rem', fontWeight: '600', backgroundColor: '#FF6F61', color: 'white', width: '90%' }}>
                                                    작업중지 &gt;
                                                </Button></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderBottom: '1px solid #EDF1FF' }}>
                                        <tr style={{ height: BoxHeight * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                                            <td style={{ color: '#f31700', paddingLeft: '3px', paddingRight: '3px' , fontSize:'0.7rem'}}> 특별 {data.filter(item => item.WorkType === '특별 유해위험').length}</td>
                                            <td style={{ backgroundColor: '#f8f8f8' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#939597', borderColor: '#939597' }}>{data.filter(item => item.WorkType === '특별 유해위험' && item.asWorkStatus === '점검대기').length}</Badge></BadgeContainer></td>
                                            <td style={{ backgroundColor: '#eff5f4' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}>{data.filter(item => item.WorkType === '특별 유해위험' && item.asWorkStatus === '작업 중').length}</Badge></BadgeContainer></td>
                                            <td style={{ backgroundColor: '#ffe8e6' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#FF6F61', borderColor: '#FF6F61' }}>{data.filter(item => item.WorkType === '특별 유해위험' && item.asWorkStatus === '작업 중지').length}</Badge></BadgeContainer></td>
                                        </tr>
                                        <tr style={{ height: BoxHeight * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                                            <td style={{ color: '#006b54', paddingLeft: '3px', paddingRight: '3px', fontSize:'0.7rem' }}>중점  {data.filter(item => item.WorkType === '중점 유해위험').length}</td>
                                            <td style={{ backgroundColor: '#f8f8f8' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#939597', borderColor: '#939597' }}>{data.filter(item => item.WorkType === '중점 유해위험' && item.asWorkStatus === '점검대기').length}</Badge></BadgeContainer></td>
                                            <td style={{ backgroundColor: '#eff5f4' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}>{data.filter(item => item.WorkType === '중점 유해위험' && item.asWorkStatus === '작업 중').length}</Badge></BadgeContainer></td>
                                            <td style={{ backgroundColor: '#ffe8e6' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#FF6F61', borderColor: '#FF6F61' }}>{data.filter(item => item.WorkType === '중점 유해위험' && item.asWorkStatus === '작업 중지').length}</Badge></BadgeContainer></td>
                                        </tr>
                                        <tr style={{ height: BoxHeight * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                                            <td style={{ color: 'gray', paddingLeft: '3px', paddingRight: '3px' , fontSize:'0.7rem'}}>일상  {data.filter(item => item.WorkType === '일상 유해위험').length}</td>
                                            <td style={{ backgroundColor: '#f8f8f8' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#939597', borderColor: '#939597' }}>{data.filter(item => item.WorkType === '일상 유해위험' && item.asWorkStatus === '점검대기').length}</Badge></BadgeContainer></td>
                                            <td style={{ backgroundColor: '#eff5f4' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}>{data.filter(item => item.WorkType === '일상 유해위험' && item.asWorkStatus === '작업 중').length}</Badge></BadgeContainer></td>
                                            <td style={{ backgroundColor: '#ffe8e6' }}><BadgeContainer style={{ display: 'normal' }}><Badge shape="dot" style={{ fontSize: '0.6rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#FF6F61', borderColor: '#FF6F61' }}>{data.filter(item => item.WorkType === '일상 유해위험' && item.asWorkStatus === '작업 중지').length}</Badge></BadgeContainer></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        
                        }
                </GridLayoutItem>
                {/* <GridLayoutItem row={2} col={1} style={{ textAlign: 'center' }}>
                    {lastdate ? lastdate.slice(6, 10) + '년 ' + lastdate.slice(0, 2) + '월 ' + lastdate.slice(3, 5) + '일' : '데이터없음'}
                </GridLayoutItem> */}
              
            </GridLayout>
        </>
    );

}

export default PTW1