import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import axios from 'axios';
import moment from 'moment-timezone';
import { generateUpdatedDate } from '../common/datadate';
import { useTranslation } from 'react-i18next';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const SafetyIssue = ({ positiondata, order, blockH }) => {
  const { t, i18n } = useTranslation();


    const { project_code } = useParams();

    const [BoxHeight, setBoxHeight] = useState(250);
  
  
    useEffect(() => {
  
      const boxHeight = positiondata[order];
      setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
  }, [positiondata, order, blockH]);
  
  
    const [data, setdata] = useState([]);
  
  
    useEffect(() => {
      const fetchData = async () => {
  
        let body =
        {
          "bpname": "안전 이슈사항",
          "lineitem": "no",
        }
        const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
        setdata(_.sortBy(res.data.data.data, 'record_no').reverse()[0])
  
  
      };
  
      fetchData();
    }, [project_code]);


  

    return (

        <GridLayout style={{ height: BoxHeight }}
          rows={[
            {
              height: '90%',
            },            {
                height: '10%',
              },
      
    
          ]}
    
          cols={[
            {
              width: '100%',
            }

    
    
          ]}
          gap={{
            rows: 0,
            cols: 0,
          }}>
<div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>

    {data && data.genSubmitDO && generateUpdatedDate(moment(new Date(data.genSubmitDO.slice(0,10))))}
    </div>
          <GridLayoutItem row={1} col={1} rowSpan={2} style={{ fontSize: '1rem' , overflow:'auto' }}>
            <div dangerouslySetInnerHTML={{ __html:data && data.genIssueDescRT && data.genIssueDescRT.replaceAll('<br/>&nbsp;','') }} className='ment'></div>

    
          </GridLayoutItem>

    
        
    
        </GridLayout>
      )
    
    

}

export default SafetyIssue
