import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets'
import moment, { months } from "moment";
import axios from 'axios';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


am4core.useTheme(am4themes_animated);
const CHART_ID = 'Etimeline_chart';
am4core.addLicense("CH363108325");
am4core.addLicense("TL363291176");


const ETimeline = () => {

  const { height, width } = useWindowDimensions();
  const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

  const [data2, setdata] = useState([ ]);

  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "연간 환경심사 계획서",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            // {

            //   "field": "d_reporting_date",
            //   "value": kr_currnet_time,
            //   "value2": kr_currnet_time_a,
            //   "condition_type": "range"
            // },

            {
              "field": "status",
              "value": "Terminated",
              "condition_type": "neq"
            },
            {              
            "field": "AAPYearPD",
            "value": moment().format('YYYY년'),
            "condition_type": "eq"

            }
          ]
        }
      }

      const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=SKEC0001`, body )

      const lines = res.data.data.data
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no }))), [])
        .map((v) => ({
          category: "", start: moment(`1 ${v.AAPMonthPD} ${v.AAPYearPD.slice(0, 4)}`, ['DDMMMMY', 'MMMMDDY']).startOf('month').format('YYYY-MM-DD hh:mm'), end: moment(`1 ${v.AAPMonthPD} ${v.AAPYearPD.slice(0, 4)}`, ['DDMMMMY', 'MMMMDDY']).add(1, 'months').startOf('month').format('YYYY-MM-DD hh:mm')
          , phase: v.EnvAuditTargetSPD, qstatus: v.AAPAuditStatusSPD, division: v.QAPDivisionSPD, project: v.AAPAuditOrg, yearmonth: v.AAPMonthPD + v.AAPYearPD
        }))

      const countDict = lines.reduce((acc, curr) => {
        const { yearmonth } = curr;
        if (acc[yearmonth]) ++acc[yearmonth];
        else acc[yearmonth] = 1;
        return acc;
      }, {});

      const result = lines.map((obj) => {
        obj["count"] = countDict[obj.yearmonth];
        return obj;
      });

      const line2 = _.sortBy(result, 'yearmonth').map((v, index) => ({ ...v, flag: v.count === 1 ? 0.5 : ((index % v.count) / v.count) + 0.01 }))


      setdata(
        line2

      )

    };

    fetchData();
  }, []);


  useEffect(() => {


    var chart = am4core.create(CHART_ID, am4plugins_timeline.SerpentineChart);
    isDPI ? chart.curveContainer.padding(70, 70, -10, 20) :chart.curveContainer.padding(70, 70, 30, 20);


    chart.levelCount = 2;
    chart.yAxisRadius = am4core.percent(20);
    chart.yAxisInnerRadius = am4core.percent(2);
    chart.maskBullets = false;

    var colorSet = new am4core.ColorSet();

    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.dateFormatter.dateFormat = "MMM";

    chart.data = data2.map((v, index) => ({
      ...v, color: colorSet.getIndex(index),
      color2: v.qstatus === '심사 계획됨' ? '#F0EDE5' : v.qstatus === '심사 수행됨' ? '#9BB7D4' : v.qstatus === '심사관찰사항 조치완료 확인/심사완료됨' ? 'lightblue' : v.qstatus === '지원 계획됨' ? '#A0DAA9' : v.qstatus === '지원 수행됨' ? '#56C6A9' : 'snow'
    }));


    chart.fontSize = 10;
    chart.tooltipContainer.fontSize = 10;

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.innerRadius = 10;
    categoryAxis.renderer.radius = 30;


    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.periodChangeDateFormats.setKey("month", "mm[bold]yyyy");
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.title.fontWeight = 'bold';
    dateAxis.title.fontSize = '15px';
    dateAxis.baseInterval = { count: 1, timeUnit: "month" };
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.line.strokeOpacity = 1;
    dateAxis.renderer.line.stroke = am4core.color("snow");
    dateAxis.tooltip.background.fillOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 5;
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    dateAxis.tooltip.label.paddingTop = 7;
    dateAxis.endLocation = 0;
    dateAxis.startLocation = -0.5;
    dateAxis.fontSize = '13px';
    dateAxis.fontWeight = 'bold';
    // dateAxis.dateFormatter.dateFormat = "MMM";

    // chart.dateFormatter.dateFormat = "MM";

    dateAxis.min = new Date(Number(moment().format("YYYY")), 0, 1, 23, 55).getTime();
    dateAxis.max = new Date(Number(moment().format("YYYY")), 11, 31, 0, 0).getTime();

    var labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.6;
    labelTemplate.background.fill = am4core.color("#464B52");
    labelTemplate.background.fillOpacity = 1;
    labelTemplate.fill = am4core.color("#F1F2F5");
    labelTemplate.padding(7, 7, 7, 7);


    var series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
    series.columns.template.height = am4core.percent(30);

    series.dataFields.openDateX = "start";
    series.dataFields.dateX = "end";
    series.dataFields.categoryY = "category";
    series.baseAxis = categoryAxis;
    series.columns.template.propertyFields.fill = "color"; // get color from data
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.fillOpacity = 0.6;
    
    chart.events.on("ready", function () {
      dateAxis.zoomToDates(
        new Date(moment().format('YYYY-01-01')),
        new Date(moment().format('YYYY-12-31'))
      );
    });

    var bullet = series.bullets.push(new am4plugins_bullets.FlagBullet());

    bullet.label.text = "{division}\n[bold]{project}\n{phase}";
    bullet.label.textAlign = "middle";

    bullet.poleHeight = 20;
    bullet.pole.propertyFields.stroke = "color2";



    bullet.background.waveLength = 0;
    // bullet.background.fillOpacity = 0.5;
    bullet.background.propertyFields.fill = "color2";
    bullet.background.propertyFields.stroke = "color";


    bullet.tooltipText = "{status}";
    bullet.pole.stroke = "color2";
    bullet.pole.length = 55;

    bullet.contentWidth = 200;


    series.tooltip.pointerOrientation = "up";

    bullet.adapter.add("locationX", (locationX, target) => {
      if (!target.dataItem) {
        return locationX;
      }
      var values = target.dataItem._dataContext;
      return values.flag
    });

    // bullet.adapter.add("poleHeight", (value, target) => {
    //         if (target.dataItem) {
    //             var position = dateAxis.valueToPosition(target.dataItem.openDateX.getTime());
    //             var angle =  dateAxis.renderer.positionToAngle(position)

    //             var length = angle >= 300 ?-20:20

    //             console.log(angle)
    //             return length;
    //         }
    //         return value;
    //     });


    var hs = bullet.states.create("hover")
    hs.properties.scale = 1.3;
    hs.properties.opacity = 1;

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.align = "center"
    chart.scrollbarX.width = isDPI ? am4core.percent(60):am4core.percent(75);
    // chart.scrollbarX.parent = chart.curveContainer;
    // chart.scrollbarX.height = 150;
    // chart.scrollbarX.orientation = "vertical";
    // chart.scrollbarX.x = 128;
    // chart.scrollbarX.y = -100;
    // chart.scrollbarX.isMeasured = false;
    chart.scrollbarX.opacity = 0.5;
    chart.scrollbarX.startGrip.background.fill = am4core.color("#B0B8C2");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#B0B8C2");
    chart.scrollbarX.startGrip.icon.stroke = am4core.color("snow");
    chart.scrollbarX.endGrip.icon.stroke = am4core.color("snow");

    var cursor = new am4plugins_timeline.CurveCursor();
    cursor.cursor = cursor;
    cursor.xAxis = dateAxis;
    cursor.yAxis = categoryAxis;
    cursor.lineY.disabled = true;
    cursor.lineX.disabled = true;
    cursor.lineX.strokeDasharray = "1,4";
    cursor.lineX.strokeOpacity = 1;

    dateAxis.renderer.tooltipLocation2 = 0;
    categoryAxis.cursorTooltipEnabled = false;

    chart.zoomOutButton.disabled = true;

    return () => {
      chart && chart.dispose();
    };


  }, [data2, height]);


  return (
    <>
      <div
        id={CHART_ID}
        style={{ width: "95%", height: (height / 4)+20 , zIndex: '999' , margin:'0 auto' }}
      ></div>

      <table style={{ position: 'absolute', top: '60px', left: '10px', listStyle: 'none', borderSpacing:'0px' , lineHeight:'0px'}}>
        <tbody>
          <tr>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#F0EDE5',
                }}
                className="k-icon k-font-icon k-i-stop"
              ></span>
            </td>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#F0EDE5',
                }}
              >
                심사 계획됨
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#9BB7D4',
                }}
                className="k-icon k-font-icon k-i-stop"
              ></span>
            </td>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#9BB7D4',
                }}
              >
                심사 수행됨
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: 'lightblue',
                }}
                className="k-icon k-font-icon k-i-stop"
              ></span>
            </td>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: 'lightblue',
                }}
              >
                심사관찰사항 조치완료 확인/심사완료됨
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#A0DAA9',
                }}
                className="k-icon k-font-icon k-i-stop"
              ></span>
            </td>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#A0DAA9',
                }}
              >
                지원 계획됨
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#56C6A9',
                }}
                className="k-icon k-font-icon k-i-stop"
              ></span>
            </td>
            <td>
              <span
                style={{
                  // float: "left",
                  fontSize: "0.7rem",
                  color: '#56C6A9',
                }}
              >
                지원 수행됨
              </span>
            </td>
          </tr>

        </tbody>

      </table>
    </>
  );

}

export default ETimeline;
