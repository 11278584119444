import React from 'react';
import ForgeViewer from './view'; // 경로는 ForgeViewer 컴포넌트의 실제 위치에 따라 변경될 수 있습니다.

function TestViewer() {
  return (
      <ForgeViewer documentId="dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dW5pZmllci9BcHRvLjE0MDQyMDIuZHdn" />
  );
}

export default TestViewer;
