import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import _ from "lodash";
 import axios from "axios";import Cookies from 'js-cookie';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useTranslation } from 'react-i18next';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const CWPV = ({ positiondata , order, blockH ,data, pdata}) => {

  const { height, width } = useWindowDimensions();

  const yesterday = moment(new Date()).format("YYYY-MM-DD");
  const yesterday7 = moment(new Date()).subtract(7, "day").format("YYYY-MM-DD");
  const { t, i18n } = useTranslation();


  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    
}, [positiondata, order, blockH]);

  const lastdate = _.max(
    data.map(
      (v) =>
        v.uuu_record_last_update_date.slice(6, 10) +
        v.uuu_record_last_update_date.slice(0, 2) +
        v.uuu_record_last_update_date.slice(3, 5)
    )
  );

  // console.log(_.countBy(data, 'd_activity_status'))

  const total_wv = _.sumBy(data, "d_weight_value");


  var wv_l4 = _(
   _.sortBy(data.map((v)=>({...v, companyName:typeof v.d_dr_act_bpk ==='undefined' || v.d_dr_act_bpk === null ? 'N/A':v.d_dr_act_bpk.includes('SR') ? v.d_dr_act_bpk.split("SR")[0]:v.d_dr_act_bpk})), "companyName").map((v) => ({
      ...v,
      actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      planwv: (v.d_weight_value * v.d_plan_progress) / 100,
    }))
  )
    .groupBy("companyName")
    .map((objs, key) => ({
      공종: key,
      WV: (_.sumBy(objs, "d_weight_value") / total_wv) * 100,
      PV: isNaN(
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
          100
      )
        ? 0
        : (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
          100,
      EV: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100,
      Dev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
          (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
            100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
          (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
            100,
    }))
    .value();


  var wv_l4p = _(
   _.sortBy(pdata.map((v)=>({...v, companyName:typeof v.d_dr_act_bpk ==='undefined' || v.d_dr_act_bpk === null ? 'N/A':v.d_dr_act_bpk.includes('SR') ? v.d_dr_act_bpk.split("SR")[0]:v.d_dr_act_bpk})),"companyName" ).map((v) => ({
      ...v,
      actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      planwv: (v.d_weight_value * v.d_plan_progress) / 100,

    }))
  )
    .groupBy("companyName")
    .map((objs, key) => ({
      공종: key,
      pPV: isNaN(
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
          100
      )
        ? 0
        : (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
          100,
      pEV: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100,
      pDev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
          (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
            100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
          (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
            100,
    }))
    .value();

  const tabledata = wv_l4.map((v) => ({
    ...v,
    ...wv_l4p.find((sp) => sp.공종 === v.공종),
  }));

  var progressby = 
  _(_.sortBy(data.map((v)=>({...v, companyName:typeof v.d_dr_act_bpk ==='undefined' || v.d_dr_act_bpk === null ? 'N/A':v.d_dr_act_bpk.includes('SR') ? v.d_dr_act_bpk.split("SR")[0]:v.d_dr_act_bpk})), "companyName")
  .map((v) => ({
    ...v,
    actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
    planwv: (v.d_weight_value * v.d_plan_progress) / 100,

  }))
  )
  .groupBy("companyName")
    .map((objs, key) => ({
      companyName: key,
      Plan:
       isNaN((_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100)?  "N/A":((_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100),
      Actual:
        isNaN((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100)? "N/A":((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100),
      Dev:
        isNaN((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value") -
          _.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100) ? "N/A":((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value") -
          _.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100),
    }))
    .value();


  const colmunNo = Math.round(progressby.length / 2);


  const chartwidth =
    (width / colmunNo / 2) * 0.95 > 200 ? 200 : (width / colmunNo / 2) * 0.95;

  const columnList = [];

  for (var i = 1; i <= colmunNo * 2; i++) {
    columnList.push({ width: `${100 / colmunNo/2 }%` });
  }


  const hidden = {
    visible: false,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        <div>{t("계획")}: {  typeof points[0].value.target === 'number'  ? points[0].value.target.toFixed(2)+"%":'N/A'}</div>
        <div>{t("실적")}: { typeof points[0].value.current === 'number'  ? points[0].value.current.toFixed(2)+"%":'N/A'}</div>
        <div>
        {t("차이")}: {typeof points[0].value.target === 'number' && typeof points[0].value.current === 'number' ?(points[0].value.current - points[0].value.target).toFixed(2)+'%':'N/A'}
        </div>
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const [sort, setSort] = useState([{}]);

  const PercentCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        {typeof Dev === "undefined" ? "" : Dev.toFixed(2)}%
      </td>
    );
  };

  const SimpleSumCell = (props) => {
    const field = props.field || "";
    const total = tabledata.reduce((acc, current) => acc + current[field], 0);
    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        {total.toFixed(2)}%
      </td>
    );
  };

  const RateSumCell = (props) => {
    const field = props.field || "";
    const total = tabledata.reduce(
      (acc, current) => acc + (current[field] * current["WV"]) / 100,
      0
    );
    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        {total.toFixed(2)}%
      </td>
    );
  };

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];
    return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>{cell}</td>;
  };

  const HeaderCell = (props) => {
    const { field, title } = props;

    const handleSort = (field) => {
      const findSortIndex = sort.findIndex((e) => e.field === field);

      const findSort = sort.find((e) => e.field === field);

      if (findSort === undefined) {
        if (sort[0]["field"] === undefined) {
          sort.push({ field: field, dir: "asc" });
          setSort([...sort]);
        }
      } else {
        if (findSort["dir"] === "asc") {
          sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
          setSort([...sort]);
        } else if (findSort["dir"] === "desc") {
          sort.splice(findSortIndex, 1);
          setSort([...sort]);
        }
      }
    };

    const hadleSortArrow = (field) => {
      const findSort = sort.find((e) => e.field === field);

      return findSort !== undefined ? findSort["dir"] : false;
    };

    const hadleSortIndex = (field) => {
      const findSortIndex = sort.findIndex((e) => e.field === field);

      return findSortIndex === -1 ? "" : findSortIndex;
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          font: "normal normal normal 0.8rem Pretendard",

          cursor: !title.includes("까지") ? "pointer" : "default",
        }}
        onClick={() => {
          handleSort(field);
        }}
      >
        <p style={{ margin: "0" }} ref={findParentHeaderNode}>
          {title}{" "}
        </p>
        <div
          className={
            hadleSortArrow(field) === "asc"
              ? "k-icon k-font-icon k-i-sort-asc-sm"
              : hadleSortArrow(field) === "desc"
              ? "k-icon k-font-icon k-i-sort-desc-sm"
              : ""
          }
        ></div>
        {!title.includes("까지") && (
          <div style={{ color: "#ff6358" }}>{hadleSortIndex(field)}</div>
        )}
      </div>
    );
    // return (
    //     <span style={{ fontSize: '0.8rem', textAlign: 'center', margin: '0 auto' }}>
    //         {props.title}
    //     </span>

    // );
  };

  const [visibleDialog, setVisibleDialog] = useState(true);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const arcCenterRenderer = (value, color) => {
    return (
      <h3
        style={{
          color: color,
          display: "block",
        }}
      >
        {value.toFixed(2)}%
      </h3>
    );
  };

  const findParentNode = useRef();

  useEffect(() => {
    if (!visibleDialog) {
      const addClassName = " progressCWPTableBody";
      const parentNode = findParentNode.current.parentElement;
      parentNode.className = parentNode.className + addClassName;
    }
  }, [visibleDialog]);

  const findParentHeaderNode = useRef();

  useEffect(() => {
    if (!visibleDialog) {
      const parentNode =
        findParentHeaderNode.current.parentNode.parentNode.parentNode
          .previousSibling;

      const secondNode = parentNode.childNodes[1];
      const thirdNode = parentNode.childNodes[2];

      secondNode.style.border = 0;
      thirdNode.style.border = 0;
    }
  }, [visibleDialog]);


  useEffect(() => {
    const applyStyles = () => {
      const textElements = document.querySelectorAll('.bullet');
  
      textElements.forEach(bullet => {
        let gElements = bullet.querySelectorAll('g');
        let secondLastG = gElements[gElements.length - 2];
        let textElementsInSecondLastG = secondLastG.querySelectorAll('text');
  
        let firstTextInSecondLastG = textElementsInSecondLastG[0];
        firstTextInSecondLastG.style.fontWeight = 'bold';
  
        let secondTextInSecondLastG = textElementsInSecondLastG[1];
        if (Number(secondTextInSecondLastG.innerHTML.split('%')[0])<0) {
          secondTextInSecondLastG.style.fill = '#CD212A';
        }
        if (Number(secondTextInSecondLastG.innerHTML.split('%')[0])>0) {
          secondTextInSecondLastG.style.fill = '#00A170';
        }
   
  
      });
    };
  
    const timeoutId = setTimeout(applyStyles, 3000);
  
    return () => clearTimeout(timeoutId);
  }, []);


  const location = useLocation();
  const path = location.pathname && location.pathname.split("/")[2]
  
  return (
    <>
      <GridLayout
        style={{ height: BoxHeight, width: path === 'cprogess'? (width-64) :width-264}}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            zIndex: "100",
          }}
        >
          <Button
            title={t("테이블로 보기")}
            iconClass="k-icon k-font-icon k-i-grid"
            fillMode="flat"
            onClick={toggleDialog}
          ></Button>
        </div>

        {progressby.map((v, index) => (
          <GridLayoutItem
            key={index}
            col={
              index + 1 > columnList.length * 0.5
                ? index * 2 + 1 - columnList.length
                : index * 2 + 1
            }
            row={index + 1 > columnList.length * 0.5 ? 5 : 3}
            colSpan={2}
            rowSpan={2}
            style={{ textAlign: "center" }}
          >
            <Chart
              style={{
                height: BoxHeight * 0.52,
              }}
              className="bullet"
            >
              <ChartTooltip shared={true} render={sharedTooltipRender} />

              <ChartTitle
  text={`${v.companyName} \n   ${(v.Actual && v.Plan && typeof v.Actual === 'number' && typeof v.Plan === 'number' ? (v.Actual - v.Plan).toFixed(2)+'%' : 'N/A')} \n (${t("계획")}:${v.Plan && typeof v.Plan === 'number' ? v.Plan.toFixed(2)+'%' : 'N/A'} ${t("실적")}:${v.Actual && typeof v.Actual === 'number' ? v.Actual.toFixed(2)+'%' : 'N/A'})`}
  font="0.7rem pretendard-B"
/>
              <ChartSeries>
                <ChartSeriesItem
                  type="bullet"
                  color={v.Actual - v.Plan < 0 ? "#CD212A" : "#00A170"}
                  data={[v.Actual, v.Plan]}
                />
              </ChartSeries>
              <ChartValueAxis>
                <ChartValueAxisItem
                  majorGridLines={hidden}
                  minorTicks={hidden}
                  min={0}
                  max={100}
                  labels={{ font: "0.7rem Arial, KoPub" }}
                />
              </ChartValueAxis>
            </Chart>

            {/* <div style={{fontSize:'0.8rem', fontWeight:'600'}}>{v.d_company_name}</div><div  style={{fontSize:'0.8rem', fontWeight:'600'}}>(계획:{v.Plan.toFixed(2)}% 실적:{v.Actual.toFixed(2)}%)</div> 
                        <div style={{position:'relative', top:'70px' ,fontSize:'1.2rem', fontWeight:'600', color:'#34568B'}}>{v.Dev.toFixed(2)}%</div>
                        <div style={{ position:'relative', top:'-60px', left:chartwidth/2}}>
                        <ArcGauge color='#0072B5' value={v.Actual} style={{width:chartwidth, overflow:'visible'}} scale={{ rangeLineCap:'butt', rangeSize:15}}  />
                        <ArcGauge color='#CD212A' value={v.Plan} style={{width:chartwidth,opacity:'0.2', position:'relative', left:chartwidth*-1 ,overflow:'visible'}} scale={{ rangeLineCap:'butt', rangeSize:15}}  />
                        </div> */}
          </GridLayoutItem>
        ))}

        {!visibleDialog && (
          <Dialog
            title={t("업체별 공정률 상세")}
            onClose={toggleDialog}
            width={"60%"}
          >
            <div ref={findParentNode}>
              <Grid
                data={orderBy(tabledata, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                className="progressCWP"
              >
                <GridColumn
                  field="공종"
                  title={t("업체명")}
                  cell={normalcell}
                  headerCell={HeaderCell}
                />
                <GridColumn
                  title= {i18n.language === "ko"? `${yesterday7} 까지`:`till ${yesterday7}`}
                  headerCell={HeaderCell}
                >
                  <GridColumn
                    field="WV"
                    title={t("비중")}
                    cell={PercentCell}
                    footerCell={SimpleSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="pPV"
                    title={t("계획")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="pEV"
                    title={t("실적")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="pDev"
                    title={t("차이")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                </GridColumn>
                <GridColumn title={i18n.language === "ko"? `${yesterday} 까지`:`till ${yesterday}`} headerCell={HeaderCell}>
                  <GridColumn
                    field="PV"
                    title={t("계획")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="EV"
                    title={t("실적")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="Dev"
                    title={t("차이")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                </GridColumn>
              </Grid>
            </div>
          </Dialog>
        )}
      </GridLayout>
    </>
  );
};

export default CWPV;
