import { atom } from 'recoil';

// 선택된 값을 저장할 atom을 생성합니다.
export const selectedValueStateMan = atom({
  key: 'selectedValueStateMan', // 고유한 키
  default: '', // 초기값
});


// 선택된 값을 저장할 atom을 생성합니다.
export const selectedValueStateEquip = atom({
    key: 'selectedValueStateEquip', // 고유한 키
    default: '', // 초기값
  });
  
// 선택된 값을 저장할 atom을 생성합니다.
export const selectedValueStateEquipType = atom({
  key: 'selectedValueStateEquipType', // 고유한 키
  default: '', // 초기값
});

// 선택된 값을 저장할 atom을 생성합니다.
export const selectedValueStateManType = atom({
  key: 'selectedValueStateManType', // 고유한 키
  default: '', // 초기값
});


// 선택된 값을 저장할 atom을 생성합니다.
export const selectedValueStateManPeriod = atom({
  key: 'selectedValueStateManPeriod', // 고유한 키
  default: '', // 초기값
});


// 선택된 값을 저장할 atom을 생성합니다.
export const selectedValueStateEquipPeriod = atom({
  key: 'selectedValueStateEquipPeriod', // 고유한 키
  default: 'daily', // 초기값
});



// 선택된 값을 저장할 atom을 생성합니다.
export const selectedChartData= atom({
  key: 'selectedChartData', // 고유한 키
  default: [], // 초기값
});



// 선택된 값을 저장할 atom을 생성합니다.
export const selectedChartWeek= atom({
  key: 'selectedChartWeek', // 고유한 키
  default: "", // 초기값
});

// 선택된 값을 저장할 atom을 생성합니다.
export const selectedChartMonth= atom({
  key: 'selectedChartMonth', // 고유한 키
  default: "", // 초기값
});

// 선택된 값을 저장할 atom을 생성합니다.
export const selectedChartWeek2= atom({
  key: 'selectedChartWeek2', // 고유한 키
  default: "", // 초기값
});

// 선택된 값을 저장할 atom을 생성합니다.
export const selectedChartMonth2= atom({
  key: 'selectedChartMonth2', // 고유한 키
  default: "", // 초기값
});

