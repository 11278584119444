import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
 import axios from "axios";import Cookies from 'js-cookie';
import _ from 'lodash';
import { guid } from "@progress/kendo-react-common";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import {
  loadMessages,
} from "@progress/kendo-react-intl";
import moment from 'moment';
import krMessages from "./kr.json";
import { Field, Form } from '@progress/kendo-react-form';
import {
  FormInput,
  FormDatePicker,
} from "./table/form-components";
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const FORM_DATA_INDEX = "formDataIndex";
const DATA_ITEM_KEY = "ID";

const requiredValidator = value => value ? '' : '필수값입니다';

const FormSubmitContext = React.createContext(() => undefined);
const GridEditContext = React.createContext({});
const GridInlineFormRow = (props) => {
  const { onRowAction, editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  const onSubmit = React.useCallback(
    (e) => {
      onRowAction({
        rowIndex: editIndex,
        operation: "save",
        dataItem: { ...e, 
          plan_date: moment(e.plan_date).format('YYYY-MM-DD'),   genActualDate: e.genActualDate === undefined ? null : moment(e.genActualDate).format('YYYY-MM-DD'),
        },
      });
    },
    [onRowAction, editIndex]
  );
  if (isInEdit) {
    return (
      <Form
        key={JSON.stringify(props.dataItem)}
          // initialValues={{ ...props.dataItem }}
        initialValues={{ ...props.dataItem, plan_date: new Date(), genActualDate: null}}

        onSubmit={onSubmit}
        render={(formRenderProps) => {
          return (
            <FormSubmitContext.Provider value={formRenderProps.onSubmit}>
              {props.children}
            </FormSubmitContext.Provider>
          );
        }}
      />
    );
  }
  return props.children;
};

const DateCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit ? (
        <Field
          component={FormDatePicker}
          name={`${props.field}`}
        // validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field] === "Invalid date" ? '실적 없음':
        moment(props.dataItem[props.field || ""]).format('YYYY년 MM월 DD일')

      )}
    </td>
  );
};



const InputCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit && props.field !== 'projectcode' ? (
        <Field
          component={FormInput}
          name={`${props.field}`}
          required={true}
        validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};


const CommandCell = (props) => {
  const onSubmit = React.useContext(FormSubmitContext);
  const { onRowAction, setEditIndex, editIndex } =
    React.useContext(GridEditContext);
  const rowIndex = props.dataItem[FORM_DATA_INDEX];
  const rowID = props.dataItem['ID'];
  const isInEdit = rowIndex === editIndex;
  const isNewItem = !props.dataItem[DATA_ITEM_KEY];
  const onRemoveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onRowAction({
        rowIndex,
        rowID,
        operation: "remove",
      });
    },
    [rowIndex, onRowAction]
  );
  const onSaveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(e);
    },
    [onSubmit]
  );
  const onEditClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(rowIndex);
    },
    [rowIndex, setEditIndex]
  );
  const onCancelClick = React.useCallback(
    (e) => {
      console.log(e)
      e.preventDefault();
      setEditIndex(undefined);
    },
    [setEditIndex]
  );
  return isInEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={onSaveClick}
      >
        {isNewItem ? "업데이트" : "업데이트"}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={isNewItem ? onRemoveClick : onCancelClick}
      >
        {isNewItem ? "취소" : "취소"}
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
        onClick={onEditClick}
      >
        수정
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
        onClick={onRemoveClick}
      >
        삭제
      </button>
    </td>
  );
};


const rowRender = (row, props) => {
  return <GridInlineFormRow dataItem={props.dataItem}>{row}</GridInlineFormRow>;
};


const KeyT = ({pjtlist, selected}) => {

  const { project_code } = useParams();
  const [statusdata, setstatusdata] = useState([]);
  const [state, setState] = useState(true)

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/key/${project_code}.json`)
      setstatusdata(res.data.map((dataItem, idx) => ({
        ...dataItem,
        [FORM_DATA_INDEX]: idx,
      })))

    }
    fetchData();

}, [project_code, state,selected]);

  const handleSubmit = () => {

    axios.post(`http://43.200.223.224:5000/writeJSON?path=/${REACT_APP_PATH2}/file/temp/key/${project_code}.json`, statusdata)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
    alert('서버에 저장되었습니다');
    setState(!state)
  }


  const [editIndex, setEditIndex] = React.useState(undefined);

  const onRowAction = React.useCallback(
    (options) => {
      const newDataState = [...statusdata];

      switch (options.operation) {
        case "remove":
          let index2 = statusdata.findIndex((record) => record.ID === options.rowID);
          newDataState.splice(index2, 1);
          break;
        case "save":
          let index = statusdata.findIndex(
            (product) => product.ID === options.dataItem.ID
          );
          newDataState[index] = options.dataItem;
          setEditIndex(undefined);
          break;
        case "add":
          newDataState.unshift({
            [FORM_DATA_INDEX]: options.rowIndex,
            [DATA_ITEM_KEY]: guid(),
            ['projectcode']: project_code,
            // ['genActualDate']: null,
            // ['plan_date']: new Date(),
            // ['genMilestoneDesc']: '',

          });
          setEditIndex(options.rowIndex);
          break;
        default:
      }
      setstatusdata(newDataState);
    },
    [statusdata]
  );

  const onAddClick = React.useCallback(() => {
    onRowAction({
      rowIndex: statusdata.length,
      operation: "add",
    });
  }, [onRowAction, statusdata]);



  const [pjtinfo, setpjtinfo] = useState([])

  const fetchData = async () => {
    let body =
    {
        "bpname": "ESG 대시보드 프로젝트 관리",
        "lineitem": "no",
        "filter_criteria": {
            "join": "AND",
            "filter": [
                {
                    "field": "status",
                    "value": "Active",
                    "condition_type": "eq"
                },

                {
                  "field": "ugenProjectNumber",
                  "value": project_code,
                  "condition_type": "eq"
              }
            ]
        }
    }


    const res = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body)

    setpjtinfo(res.data.data.data)

}
useEffect(() => {
    fetchData();
}, [project_code]);




  return (
    <>

      <div style={{ margin: 30, textAlign: 'center' }}>
        {_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['SKONeUSEPD'] === '운용' ? 'SKONe에 값이 입력되어있습니다' :
          <>


              <GridEditContext.Provider
                value={{
                  onRowAction,
                  editIndex,
                  setEditIndex,
                }}
              >
                <Grid
                  className='import inputComponent'
                  style={{
                    height: "700px", marginTop: 16
                  }}
                  data={statusdata} dataItemKey={DATA_ITEM_KEY} rowRender={rowRender}>
                  <GridToolbar>


                    {pjtinfo[0] && pjtinfo[0]['ugenProjectName']}({pjtinfo[0] && pjtinfo[0]['ugenProjectNumber']})
                    <button
                      title="Add new"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={onAddClick}
                      style={{ position: 'absolute', right: '100px' }}

                    >
                      행추가
                    </button>
                    <button
                      title="save"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={handleSubmit}
                      style={{ position: 'absolute', right: '15px' }}
                    >
                      입력완료
                    </button>


                  </GridToolbar>

                  {/* <Column field="projectcode" title="프로젝트"
                    cell={InputCell}  editable={false}
                  /> */}

                  <Column field="genMilestoneDesc" title="마일스톤 이름"
                    cell={InputCell}
                  />

                  <Column field="plan_date" title="계획일"
                    cell={DateCell}
                  />
                  
                  <Column field="genActualDate" title="실적일"
                    cell={DateCell}
                  />

                  <Column cell={CommandCell} width="200px" />

                </Grid>
              </GridEditContext.Provider>

          </>
        }
      </div>


    </>


  );

}
export default KeyT;
