import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import "../CProgress/CProgress.css";
import { useTranslation } from 'react-i18next';

const EWP = ({ data , pdata}) => {


    const { t, i18n } = useTranslation();
    const yesterday7 = moment(pdata && pdata[0] && pdata[0].CUT_OFF_DT).format("YYYY-MM-DD");
    const yesterday = moment(data &&  pdata[0] && data[0].CUT_OFF_DT).format("YYYY-MM-DD");

    var progressby = _(_.sortBy(data, "DISC_NM"))
        .groupBy("DISC_NM")
        .map((objs, key) => ({
          Lv_4_Name: key,
          Plan:_.maxBy(objs, "PLAN_PROG").PLAN_PROG,
          Actual:_.maxBy(objs, "ACTUAL_PROG").ACTUAL_PROG,
          Dev:_.maxBy(objs, "ACTUAL_PROG").ACTUAL_PROG-_.maxBy(objs, "PLAN_PROG").PLAN_PROG ,
        }))
        .value().filter(v=>v.Lv_4_Name !=="OVERALL");
    

        var wv_l4 = _(
            _.sortBy(data, "DISC_NM")
          )
            .groupBy("DISC_NM")
            .map((objs, key) => ({
              공종: key,
              PV:_.maxBy(objs, "PLAN_PROG").PLAN_PROG,
              EV:_.maxBy(objs, "ACTUAL_PROG").ACTUAL_PROG,
              Dev:_.maxBy(objs, "ACTUAL_PROG").ACTUAL_PROG-_.maxBy(objs, "PLAN_PROG").PLAN_PROG ,
            }))
            .value();
        
        
          var wv_l4p = _(
            _.sortBy(pdata, "DISC_NM")
          )
            .groupBy("DISC_NM")
            .map((objs, key) => ({
              공종: key,
              pPV:_.maxBy(objs, "PLAN_PROG").PLAN_PROG,
              pEV:_.maxBy(objs, "ACTUAL_PROG").ACTUAL_PROG,
              pDev:_.maxBy(objs, "ACTUAL_PROG").ACTUAL_PROG-_.maxBy(objs, "PLAN_PROG").PLAN_PROG ,
            }))
            .value();

        
    
        const tabledata2 = wv_l4.map((v) => ({
            ...v,
            ...wv_l4p.find((sp) => sp.공종 === v.공종),
          }));
        

          // "OVERALL" 객체를 찾습니다.
        const overall = tabledata2.find(item => item["공종"] === "OVERALL");

        // "OVERALL"이 아닌 다른 모든 객체를 필터링합니다.
        const rest = tabledata2.filter(item => item["공종"] !== "OVERALL");

        // "OVERALL" 객체를 맨 앞에 두고, 나머지 객체들을 그 뒤에 추가합니다.
        const tabledata = [overall, ...rest];

    
      const colmunNo = Math.round(progressby.length / 2);
    
      const columnList = [];
    
      for (var i = 1; i <= colmunNo * 2; i++) {
        columnList.push({ width: `${100 / colmunNo / 2}%` });
      }
    
      // const columnList = progressby.map((v, index)=> ({width:`${(100/colmunNo)/2}%`}))
    
      const hidden = {
        visible: false,
      };
    
      const SharedTooltip = (props) => {
        const { points } = props;
        return (
          <div>
            <div>{t("계획")}: {  typeof points[0].value.target === 'number'  ? points[0].value.target.toFixed(2)+"%":'N/A'}</div>
            <div>{t("실적")}: { typeof points[0].value.current === 'number'  ? points[0].value.current.toFixed(2)+"%":'N/A'}</div>
            <div>
            {t("차이")}: {typeof points[0].value.target === 'number' && typeof points[0].value.current === 'number' ?(points[0].value.current - points[0].value.target).toFixed(2)+'%':'N/A'}
            </div>
          </div>
        );
      };
    
      const sharedTooltipRender = (context) => <SharedTooltip {...context} />;
    
      const [sort, setSort] = useState([{}]);
    
      const PercentCell = (props) => {
        const field = props.field || "";
        const Dev = props.dataItem[field];
        return (
          <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
            {typeof Dev === "undefined" ? "" : Dev.toFixed(2)}%
          </td>
        );
      };

      const normalcell = (props) => {
        const field = props.field || "";
        const cell = props.dataItem[field];



        return <td style={{ fontSize: "0.8rem", textAlign: "left"}}>{cell}</td>;
      };
    
      const HeaderCell = (props) => {
        const { field, title } = props;
    
        const handleSort = (field) => {
          const findSortIndex = sort.findIndex((e) => e.field === field);
    
          const findSort = sort.find((e) => e.field === field);
    
          if (findSort === undefined) {
            if (sort[0]["field"] === undefined) {
              sort.push({ field: field, dir: "asc" });
              setSort([...sort]);
            }
          } else {
            if (findSort["dir"] === "asc") {
              sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
              setSort([...sort]);
            } else if (findSort["dir"] === "desc") {
              sort.splice(findSortIndex, 1);
              setSort([...sort]);
            }
          }
        };
    
        const hadleSortArrow = (field) => {
          const findSort = sort.find((e) => e.field === field);
    
          return findSort !== undefined ? findSort["dir"] : false;
        };
    
        const hadleSortIndex = (field) => {
          const findSortIndex = sort.findIndex((e) => e.field === field);
    
          return findSortIndex === -1 ? "" : findSortIndex;
        };
    
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              font: "normal normal normal 0.8rem Pretendard",
    
              cursor: !(title.includes("까지")||title.includes("till") )? "pointer" : "default",
            }}
            onClick={() => {
              handleSort(field);
            }}
          >
            <p style={{ margin: "0" }} ref={findParentHeaderNode}>
              {title}{" "}
            </p>
            <div
              className={
                hadleSortArrow(field) === "asc"
                  ? "k-icon k-font-icon k-i-sort-asc-sm"
                  : hadleSortArrow(field) === "desc"
                    ? "k-icon k-font-icon k-i-sort-desc-sm"
                    : ""
              }
            ></div>
            {!(title.includes("까지")||title.includes("till") ) && (
              <div style={{ color: "#ff6358" }}>{hadleSortIndex(field)}</div>
            )}
          </div>
        );
      };
    
      const [visibleDialog, setVisibleDialog] = useState(true);
    
      const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
      };
    
      const labelContent = (props) => {
        const { points } = props;
    
        return (
          <div>
            {points.map((point, index) => (
              <span>{point.value}</span>
            ))}
          </div>
        );
      };
    
      const findParentNode = useRef();
    
      useEffect(() => {
        if (!visibleDialog) {
          const addClassName = " progressCWPTableBody";
          const parentNode = findParentNode.current.parentElement;
          parentNode.className = parentNode.className + addClassName;
        }
      }, [visibleDialog]);
    
      const findParentHeaderNode = useRef();
    
      useEffect(() => {
        if (!visibleDialog) {
          const parentNode =
            findParentHeaderNode.current.parentNode.parentNode.parentNode
              .previousSibling;
    
          const secondNode = parentNode.childNodes[1];
          const thirdNode = parentNode.childNodes[2];
    
          secondNode.style.border = 0;
          thirdNode.style.border = 0;
        }
      }, [visibleDialog]);



      useEffect(() => {
        const applyStyles = () => {
          const textElements = document.querySelectorAll('.bullet');
      
          textElements.forEach(bullet => {
            let gElements = bullet.querySelectorAll('g');
            let secondLastG = gElements[gElements.length - 2];
            let textElementsInSecondLastG = secondLastG.querySelectorAll('text');
      
            let firstTextInSecondLastG = textElementsInSecondLastG[0];
            firstTextInSecondLastG.style.fontWeight = 'bold';
      
            let secondTextInSecondLastG = textElementsInSecondLastG[1];
            if (Number(secondTextInSecondLastG.innerHTML.split('%')[0])<0) {
              secondTextInSecondLastG.style.fill = '#CD212A';
            }
            if (Number(secondTextInSecondLastG.innerHTML.split('%')[0])>0) {
              secondTextInSecondLastG.style.fill = '#00A170';
            }
       
      
          });
        };
      
        const timeoutId = setTimeout(applyStyles, 3000);
      
        return () => clearTimeout(timeoutId);
      }, []);
    

    return (
        <>
          <GridLayout
            style={{ height: 240 }}
            rows={[
              {
                height: "0%",
              },
              {
                height: "0%",
              },
              {
                height: "15%",
              },
              {
                height: "35%",
              },
              {
                height: "15%",
              },
              {
                height: "35%",
              },
            ]}
            cols={columnList}
            gap={{
              rows: 0,
              cols: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "20px",
                zIndex: "100",
              }}
            >
              <Button
                title={t("테이블로 보기")}
                iconClass="k-icon k-font-icon k-i-grid"
                fillMode="flat"
                onClick={toggleDialog}
              ></Button>
            </div>
    
            {progressby.map((v, index) => (
              <GridLayoutItem
                key={index}
                col={
                  index + 1 > columnList.length * 0.5
                    ? index * 2 + 1 - columnList.length
                    : index * 2 + 1
                }
                row={index + 1 > columnList.length * 0.5 ? 5 : 3}
                colSpan={2}
                rowSpan={2}
                style={{ textAlign: "center" }}
              >
                <Chart
                  style={{
                    height: 120,
                  }}
                  className="bullet"
                >
                  <ChartTooltip shared={true} render={sharedTooltipRender} />
    
                  <ChartTitle
                    text={`${v.Lv_4_Name} \n ${(v.Actual && v.Plan && typeof v.Actual === 'number' && typeof v.Plan === 'number' ? (v.Actual - v.Plan).toFixed(2)+'%' : 'N/A')}\n (${t("계획")}:${v.Plan && typeof v.Plan === 'number' ? v.Plan.toFixed(2)+'%'  : 'N/A'} ${t("실적")}:${v.Actual && typeof v.Actual === 'number' ? v.Actual.toFixed(2)+'%'  : 'N/A'})`}
                    font="0.7rem pretendard-B bold"
                  />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="bullet"
                      color={v.Actual - v.Plan < 0 ? "#CD212A" : "#00A170"}
                      data={[v.Actual, v.Plan]}
                      overlay={{ gradient: "glass" }}
                    />
                  </ChartSeries>
                  <ChartValueAxis>
                    <ChartValueAxisItem
                      majorGridLines={hidden}
                      minorTicks={hidden}
                      min={0}
                      max={100}
                      labels={{ font: "0.7rem pretendard-R" }}
                    />
                  </ChartValueAxis>
                </Chart>
              </GridLayoutItem>
            ))}
    
            {!visibleDialog && (
              <Dialog
                title={t("공종별 공정률 상세")}
                onClose={toggleDialog}
                width={"60%"}
              >
                <div ref={findParentNode}>
                  <Grid
                    data={orderBy(tabledata, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    className="progressCWP"
                  >
                    <GridColumn
                      field="공종"
                      title={t("공종")}
                      cell={normalcell}
                      headerCell={HeaderCell}
                    />
                    <GridColumn
                      title= {i18n.language === "ko"? `${yesterday7} 까지`:`till ${yesterday7}`}
                      headerCell={HeaderCell}
                      className="testHeader"
                    >
                      <GridColumn
                        field="pPV"
                        title={t("계획")}
                        cell={PercentCell}
                        headerCell={HeaderCell}
                      />
                      <GridColumn
                        field="pEV"
                        title={t("실적")}
                        cell={PercentCell}
                        headerCell={HeaderCell}
                      />
                      <GridColumn
                        field="pDev"
                        title={t("차이")}
                        cell={PercentCell}
                        headerCell={HeaderCell}
                      />
                    </GridColumn>
                    <GridColumn title={i18n.language === "ko"? `${yesterday} 까지`:`till ${yesterday}`}
                    headerCell={HeaderCell}>
                      <GridColumn
                        field="PV"
                        title={t("계획")}
                        cell={PercentCell}
                        headerCell={HeaderCell}
                      />
                      <GridColumn
                        field="EV"
                        title={t("실적")}
                        cell={PercentCell}
                        headerCell={HeaderCell}
                      />
                      <GridColumn
                        field="Dev"
                        title={t("차이")}
                        cell={PercentCell}
                        headerCell={HeaderCell}
                      />
                    </GridColumn>
                  </Grid>
                </div>
              </Dialog>
            )}
          </GridLayout>
        </>
      );
    };
    
    export default EWP;
    