import { Milestones } from 'react-milestones-vis';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import _ from "lodash";


const EPCMilestone = () => {

    const { project_code } = useParams();
    const [combinedArray, setCombinedArray] = useState([]);
    const [selectedValue, setSelectedValue] = useState('Overall');

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Project Milestone",
                "lineitem": "no",
                "filter_condition": "status=Active",
            }
            const res = await axios.post(`http://43.200.223.224:5000/getemsbprecord?path=${project_code}`, body)

            const sourcedata = res.data.data.data

            const data = [...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
                .map((v) => ({
                    date: moment(v.plan_date).format('YYYY-MM-DD'),
                    title: v.genMilestoneDesc

                    ,

                    titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
                }))
                ,
            ...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
                .map((v) => ({
                    date: moment(v.plan_date).format('YYYY-MM-DD'),
                    title: v.genActualDate !== null ?
                        '(Actual: ' + moment(v.genActualDate).format('YYYY-MM-DD') + ')' : ""
                    ,

                    textStyle: {
                        // 'font-style': 'italic',
                        'font-size': '9px'

                    },
                    titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
                }))
            ]


            setCombinedArray(data)

        };

        fetchData();
    }, [project_code]);



    const handleClick = (value) => {
        setSelectedValue(value);
    };

    const getButtonStyle = (value) => ({
        backgroundColor: selectedValue === value ? '#34568B' : 'transparent',
        color: selectedValue === value ? 'white' : 'black',
    });

    return (
        <>
            <GridLayout style={{ height: 270 }}
                rows={[
                    {
                        height: 240,
                    },

                    {
                        height: 30,
                    },
                ]}

                cols={[
                    {
                        width: '100%',
                    },


                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <GridLayoutItem col={1} row={1} style={{ position: "relative" }}>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        transform: 'translateY(-50%)',
                        maxHeight: '100%',
                        margin: 'auto',
                        // 나머지 필요한 스타일 추가
                    }}>

                        <Milestones
                            aggregateBy="day"
                            data={combinedArray}
                            mapping={{
                                text: 'title',
                                timestamp: 'date'
                            }}
                            optimize
                            parseTime="%Y-%m-%d"
                            distribution='top-bottom'
                            autoResize={true}
                        />
                    </div>

                </GridLayoutItem>
                <GridLayoutItem style={{ display: "grid", justifyItems: 'center', width: '100%' }}>
                    <div style={{ display: "flex", justifyContent: 'center', position: 'absolute', bottom: 10, left: "50%", transform: "translate(-50%)" }}>
                        <div style={{ fontWeight: 600 }}><span className="k-icon k-font-icon k-i-minus-sm" style={{ color: "black", fontSize: "32px" }}></span> Overall</div><div> &nbsp; &nbsp; &nbsp;</div><div style={{ color: "#34568B", fontWeight: 600 }}>
                        </div>
                    </div>
                </GridLayoutItem>
            </GridLayout>
        </>
    );

};

export default EPCMilestone
