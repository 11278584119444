import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import "./SafetyCustom.css";
import { generateUpdatedDate } from '../common/datadate';
import { useTranslation } from 'react-i18next';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const SafetyManpower = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const { t, i18n } = useTranslation();

  const [BoxHeight, setBoxHeight] = useState(250);
  const [tableData, setTableData] = useState([
    { id: 1, name: "안전관리자", plan: 0, actual: 0, request: null },
    { id: 2, name: "보건관리자", plan: 0, actual: 0, request: null },
  ]);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "안전보건 인력 선임현황",
        lineitem: "no",
        filter_condition: "status=Active",
      };

      const res = await axios.post(
        `http://43.200.223.224:5000/getbprecord?path=${project_code}`,
        body,
      );

      const unifierData = res.data.data.data;

      try {
        if (unifierData.length > 0) {
          const unifierDataTable = _.sortBy(
            unifierData.map((com) => {
              return {
                ...com,
                uuu_record_last_update_date: new Date(
                  com.uuu_record_last_update_date
                ),
              };
            }),
            "uuu_record_last_update_date"
          ).reverse()[0];

          const dataTable = [
            {
              id: 1,
              name: "안전관리자",
              actual:
                unifierDataTable.SafetyActualDA === undefined
                  ? 0
                  : unifierDataTable.SafetyActualDA,
              plan:
                unifierDataTable.SafetyLegalDA === undefined
                  ? 0
                  : unifierDataTable.SafetyLegalDA,
              request: unifierDataTable.HSEDashReqRT,
            },
            {
              id: 2,
              name: "보건관리자",
              actual:
                unifierDataTable.HealthActualDA === undefined
                  ? 0
                  : unifierDataTable.HealthActualDA,
              plan:
                unifierDataTable.HealthLegalDA === undefined
                  ? 0
                  : unifierDataTable.HealthLegalDA,
              request: unifierDataTable.HSEDashReqRT,
            },
          ];

          setTableData(dataTable);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [project_code]);

  return (
    <GridLayout
      style={{ height: BoxHeight }}
      rows={[
        {
          height: "100%",
        },
      ]}
      cols={[
        {
          width: "49%",
        },
        {
          width: "49%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 10,
      }}
    >
      <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>

{generateUpdatedDate(new Date())}
</div>
      <GridLayoutItem col={1} row={1}>
        <Card style={{ width: "100%", height: BoxHeight }}>
          <CardBody
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "20%",
              margin: "0",
              fontFamily: "pretendard-B",
            }}
          >
            <CardTitle
              style={{
                textAlign: "center",
                height: "20%",
                margin: "0",
                fontFamily: "pretendard-B",
                color: "#707070",
                fontSize: i18n.language === "ko"? "1.5rem":"1rem",
                fontWeight: "bold",
              }}
            >
              {t("안전관리자 현황")}
            </CardTitle>
            <CardTitle
              style={{
                fontSize: i18n.language === "ko"? "1.5rem":"1rem",
                color: "#707070",
                fontWeight: "bold",
              }}
            >
              <span>
                {t("선임")}: {tableData[0].actual}{t("명")} / {t("법적")}: {tableData[0].plan}{t("명")}
              </span>
            </CardTitle>
          </CardBody>
        </Card>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={1}>
        <Card style={{ width: "100%", height: BoxHeight }}>
          <CardBody
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "20%",
              margin: "0",
              fontFamily: "pretendard-B",
            }}
          >
            <CardTitle
              style={{
                textAlign: "center",
                height: "20%",
                margin: "0",
                fontFamily: "pretendard-B",
                color: "#707070",
                fontSize: i18n.language === "ko"? "1.5rem":"1rem",
                fontWeight: "bold",
              }}
            >
              {t("보건관리자 현황")}
            </CardTitle>
            <CardTitle
              style={{
                fontSize: i18n.language === "ko"? "1.5rem":"1rem",
                color: "#707070",
                fontWeight: "bold",
              }}
            >
              {t("선임")}: {tableData[1].actual}{t("명")} / {t("법적")}: {tableData[1].plan}{t("명")}
            </CardTitle>
          </CardBody>
        </Card>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default SafetyManpower;



