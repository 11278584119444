import React, { useState, useEffect } from 'react';
import PDFTron from './viewer';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
// import InfoBox from './infoBox';
import PDFTronDR from './viewerforDR';

const MyDocumentViewerModalDR = ({ open, onClose, documentUrl, documenTitle, docuID, username }) => {


  return (
<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
  <Box display="flex" >
    <Box flex={1} mr={1}>
      <PDFTronDR initialDocUrl={documentUrl} documenTitle={documenTitle} docuID={docuID} username={username} />
    </Box>
    {/* <InfoBox selectedRow={selectedRow} documenTitle={documenTitle} username={username}  pdftronInstance={pdftronInstance}/> */}
  </Box>
</Dialog>

  );
};
export default MyDocumentViewerModalDR;
