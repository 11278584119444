import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";

 import axios from "axios";import Cookies from 'js-cookie';
import _ from 'lodash';

import StatusT from './statustable';
import KeyT from './keytable';
import ManT from './mantable';
import PhotoTable from './phototable';
import "./inputCustomCss.scss";


const {REACT_APP_URI2} = process.env;

const InputTab = () => {

    const [success, setSuccess] = React.useState(false);
    const [state, setState] = React.useState(true);
    const [lat, setlat] = React.useState('');
    const [lon, setlon] = React.useState('');

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    const { project_code } = useParams();
    const [pjtlist, setpjtlist] = useState([]);

    const fetchData = async () => {
        let body =
        {
            "bpname": "ESG 대시보드 프로젝트 관리",
            "lineitem": "no",
            "filter_criteria": {
                "join": "AND",
                "filter": [
                    {
                        "field": "status",
                        "value": "Active",
                        "condition_type": "eq"
                    }
                ]
            }
        }


        const res = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body)

        setpjtlist(res.data.data.data)
        setlat(res.data.data.data.find(v=>v.ugenProjectNumber ===project_code )['uuu_latitude'])
        setlon(res.data.data.data.find(v=>v.ugenProjectNumber ===project_code )['uuu_longitude'])

    }
    
    useEffect(() => {
        fetchData();
    }, [success, state, project_code]);


    const handleSubmit = event => {
        event.preventDefault();
        let lat = event.target[0].value
        let lon = event.target[1].value

        const record_no = pjtlist.find(v => v.ugenProjectNumber === project_code)['record_no']

        let body =
        {

            "options": {
                "bpname": "ESG 대시보드 프로젝트 관리",
            },
            "data": [
                {
                    "record_no": record_no,
                    "uuu_latitude": lat,
                    "uuu_longitude": lon
                }
            ]
        }

        axios.post(`http://43.200.223.224:5000/putrecords?path=SKEC0001`, body)

        setSuccess(!success);
        setState(!state);
        setTimeout(() => {
            fetchData();
          }, 3000);

    };

    const onAddClick = React.useCallback(() => {
        setState(!state)
      }, [state]);

    return (
        <>
            <TabStrip selected={selected} onSelect={handleSelect} className='input'>
                <TabStripTab title="프로젝트 여부">
                    <div style={{ margin: 30, textAlign: 'center' }}>
                        {pjtlist && pjtlist.filter(v => v.ugenProjectNumber === project_code).length > 0 ?
                            _.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['uuu_latitude'] !== null && state?
<>
                            <span>
                                이 프로젝트의 이름은 {_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['ugenProjectName']}이며 
                        위도:{lat && lat}  경도:{lon && lon}에 따라 대시보드에 위치가 표현됩니다.

   </span>
                                < button
   title="위경도 수정"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={onAddClick}
                        style={{ position: 'absolute', right: '100px' }}

 >
                        수정
                    </button>
                    </>
                    :



                    <>
                        <div style={{ height: 600 }}>
                            <span>
                                이 프로젝트의 이름은 {_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['ugenProjectName']}이며
                                대시보드에 위치를 표현하기 위해서는 위경도를 입력하세요.
                            </span>
                            <br />
                            <div className="col-xs-12 col-sm-6 offset-sm-3 example-col">
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <Input name="latitude" type="number" min={0} step='any' style={{
                                            width: "100%"
                                        }} label="위도" minLength={2} required={true}
                                        // value={_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['uuu_latitude'] === null ? '':_.find(pjtlist, { 'ugenProjectNumber': project_code })['uuu_latitude'] }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Input name="longitude" type="number" style={{
                                            width: '100%'
                                        }} label="경도" required={true} minLength={2} min={0} step='any'
                                        // value={_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['uuu_longitude'] === null ? '': _.find(pjtlist, { 'ugenProjectNumber': project_code })['uuu_longitude'] }
                                        />
                                    </div>

                                    <br />
                                    <input type="submit" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" value="제출" />
                                </form>
                            </div>
                            {success && <div className="alert alert-success" style={{
                                position: 'absolute'
                            }}>
                                입력되었습니다.
                            </div>}
                        </div>
                    </>
                    :
                    '해당 프로젝트는 유니파이어에 프로젝트 등록이 안되어있습니다. 프로젝트부터 등록하시기 바랍니다.'
                        }  </div>
            </TabStripTab>
            <TabStripTab title="프로그레스">
                <StatusT pjtlist={pjtlist} selected={selected} />

            </TabStripTab>
            <TabStripTab title="키 이벤트">
                <KeyT pjtlist={pjtlist} selected={selected} />
            </TabStripTab>
            <TabStripTab title="인원">
                <ManT pjtlist={pjtlist} selected={selected} />
            </TabStripTab>
            <TabStripTab title="현장 주간 사진">
                <PhotoTable pjtlist={pjtlist} selected={selected} />
            </TabStripTab>
        </TabStrip>
        </>


    );

}
export default InputTab;
