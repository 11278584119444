import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn,  } from "@progress/kendo-react-grid";

import { orderBy } from "@progress/kendo-data-query";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';


const SubconNDE = ({ positiondata, order, blockH, data }) => {
    const [BoxHeight, setBoxHeight] = useState(250);
    const { t } = useTranslation();

    const lineItems = data.reduce((c, v) => {
        const newItem = v._bp_lineitems.filter(f => f.NDEType !== null).map(o => Object.assign(o, {
            "WelderID": v.WelderID,
            "WelderName": v.d_name,
            "CompanyName": v.d_subcontractor_bpk,
            "rejectJoint": o.TotalFilmQuantities > 0 && o.RejectFilmQuantities > 0 ? 1 : 0,
            "Joint": o.TotalFilmQuantities > 0 ? 1 : 0,
            "key": v.d_subcontractor_bpk + "//" + o.NDEType,
        }));

        return c.concat(newItem);
    }, []);




    let lastCompanyName = null;

    const lineItemsWithNewKey = _.sortBy(lineItems, "key").map(item => {
        const newItem = { ...item, "duplicate": lastCompanyName === item.CompanyName ? false : true };
        lastCompanyName = item.CompanyName;
        return newItem;
    });


    function isDateInThisWeek(dateStr) {
        const date = moment(dateStr, 'MM-DD-YYYY');
        const now = moment();

        // 주의 시작일과 종료일 계산
        const startOfWeek = now.clone().startOf('week');
        const endOfWeek = now.clone().endOf('week');

        // 날짜가 현재 주에 있는지 확인
        return date.isBetween(startOfWeek, endOfWeek, null, '[]');
    }


    var groupbytype =
        lineItems &&
        _(lineItemsWithNewKey)
            .groupBy('key')
            .map((objs, key) => ({
                'type': key.split("//")[1],
                'company': key.split("//")[0],
                "duplicate": objs[0]["duplicate"],
                'PassJoint': _.sumBy(objs, "Joint") - _.sumBy(objs, "rejectJoint"),
                'NonPassJoint': _.sumBy(objs, "rejectJoint"),
                'PassFlim': _.sumBy(objs, "TotalFilmQuantities") - _.sumBy(objs, "RejectFilmQuantities"),
                'NonPassFilm': _.sumBy(objs, "RejectFilmQuantities"),
                'PassJointRate': (_.sumBy(objs, "rejectJoint") / _.sumBy(objs, "Joint")) * 100,
                'PassFilmRate': (_.sumBy(objs, "RejectFilmQuantities") / _.sumBy(objs, "TotalFilmQuantities")) * 100,

                'weekPassJoint': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "Joint") - _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "rejectJoint"),
                'weekNonPassJoint': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "rejectJoint"),
                'weekPassFlim': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "TotalFilmQuantities") - _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "RejectFilmQuantities"),
                'weekNonPassFilm': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "RejectFilmQuantities"),
                'weekPassJointRate': (_.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "rejectJoint") / _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "Joint")) * 100,
                'weekPassFilmRate': (_.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "RejectFilmQuantities") / _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "TotalFilmQuantities")) * 100,

            }))
            .value()


    useEffect(() => {
        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, order, blockH]);

    const [visibleDialog, setVisibleDialog] = useState(true);
    const [companyfilter, setcompanyfilter] = useState("");


    const toggleDialog2 = () => {
        setVisibleDialog(!visibleDialog);

    };

    const tabledata =
        _(lineItems.map((v) => ({ ...v, key: v.WelderID + "//" + v.NDEType })).filter(v => v.CompanyName === companyfilter))
            .groupBy('key')
            .map((objs, key) => ({
                'WelderID': key.split("//")[0],
                'type': key.split("//")[1],
                'WelderName': objs[0]["WelderName"],
                'PassJoint': _.sumBy(objs, "Joint") - _.sumBy(objs, "rejectJoint"),
                'NonPassJoint': _.sumBy(objs, "rejectJoint"),
                "totalJoint": _.sumBy(objs, "Joint"),
                'PassFlim': _.sumBy(objs, "TotalFilmQuantities") - _.sumBy(objs, "RejectFilmQuantities"),
                'NonPassFilm': _.sumBy(objs, "RejectFilmQuantities"),
                'PassJointRate': (_.sumBy(objs, "rejectJoint") / _.sumBy(objs, "Joint")) * 100,
                'PassFilmRate': (_.sumBy(objs, "RejectFilmQuantities") / _.sumBy(objs, "TotalFilmQuantities")) * 100,

                "weektotalJoint": _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "Joint"),
                'weekPassJoint': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "Joint") - _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "rejectJoint"),
                'weekNonPassJoint': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "rejectJoint"),
                'weekPassFlim': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "TotalFilmQuantities") - _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "RejectFilmQuantities"),
                'weekNonPassFilm': _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "RejectFilmQuantities"),
                'weekPassJointRate': (_.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "rejectJoint") / _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "Joint")) * 100,
                'weekPassFilmRate': (_.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "RejectFilmQuantities") / _.sumBy(objs.filter(v => isDateInThisWeek(v.NDEDate.slice(0, 10))), "TotalFilmQuantities")) * 100,

            }))
            .value()


    const [sort, setSort] = useState([{}]);



    const gridcell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];


        return (
            <td
                style={{
                    font: "normal normal normal 0.8rem Pretendard",
                    textAlign: "right", 
                    wordWrap:'break-all',
                    whiteSpace: 'normal',
                }}
            >
                {isNaN(value) && (field !== "type" && field !== "company"&& field !== "WelderID"&& field !== "WelderName") ? "-" : field.includes("Rate") ? value && value.toFixed(2) + "%" : value}
            </td>
        );
    };


    const mergecell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];

        const index = props.dataItem["duplicate"];
        const count = groupbytype.filter(v => v.company === value).length;

        const toggleDialog = (val) => {
            setVisibleDialog(!visibleDialog);
            setcompanyfilter(val)
        };

        return (
            <td
                rowSpan={index ? count : 0}
                style={{
                    font: "normal normal normal 0.8rem Pretendard",
                    textAlign: "right",
                    display: index ? "table-cell" : 'none',
                    cursor: 'pointer',
                    borderTop: index ? "1px solid rgba(0, 0, 0, 0.08)" : "0px solid rgba(0, 0, 0, 0.08)",
                    wordWrap:'break-all',
                    whiteSpace: 'normal',

                }}
                className='nohover'
            >
                <span onClick={() => toggleDialog(value)} className='hovertext'>{value}</span>
            </td>
        );
    };

    return (
        <>
        <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
{tabledata && generateUpdatedDate(new Date())}
</div>

            {!visibleDialog && (
                <Dialog
                    title={`${companyfilter} ${t("용접사별 용접불량률")}`}
                    onClose={toggleDialog2}
                    width={"85%"}
                    height= {tabledata.length >10? "600px":"auto"}
                // height={"80%"}
                >
                    <Grid
                        data={orderBy(tabledata, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}

                        style={{height:"100%", overflowY:'auto', overflowX:'hidden'}}
                        className='centertable'
                    >
                        <GridColumn field="type" title={t("비파괴 종류")} cell={gridcell} />
                        <GridColumn field="WelderName" title={t("용접사 이름")} cell={gridcell} />
                        <GridColumn field="WelderID" title={t("용접사 ID")} cell={gridcell} />
                        <GridColumn title={t("금주현황")} >
                            <GridColumn title="" >
                                <GridColumn title="Total Welded" >
                                    <GridColumn field="weektotalJoint" title="Joints" cell={gridcell} />

                                </GridColumn>
                            </GridColumn>
                            <GridColumn title={t("총 비파괴 시험현황")} >
                                <GridColumn title={t("합격")} >
                                    <GridColumn field="weekPassJoint" title="Joints" cell={gridcell} />
                                    <GridColumn field="weekPassFlim" title="Film" cell={gridcell} />
                                </GridColumn>
                                <GridColumn title={t("불합격")} >
                                    <GridColumn field="weekNonPassJoint" title="Joints" cell={gridcell} />
                                    <GridColumn field="weekNonPassFilm" title="Film" cell={gridcell} />
                                </GridColumn>

                            </GridColumn>
                          
                        </GridColumn>

                        <GridColumn title={t("누적현황")} >
                        <GridColumn title="" >
                                <GridColumn title="Total Welded" >
                                    <GridColumn field="totalJoint" title="Joints" cell={gridcell} />

                                </GridColumn>
                            </GridColumn>
                            <GridColumn title={t("총 비파괴 시험현황")} >
                                <GridColumn title={t("합격")} >
                                    <GridColumn field="PassJoint" title="Joints" cell={gridcell} />
                                    <GridColumn field="PassFlim" title="Film" cell={gridcell} />
                                </GridColumn>
                                <GridColumn title={t("불합격")} >
                                    <GridColumn field="NonPassJoint" title="Joints" cell={gridcell} />
                                    <GridColumn field="NonPassFilm" title="Film" cell={gridcell} />
                                </GridColumn>

                            </GridColumn>
                            <GridColumn title="" >
                            <GridColumn title={t("불량률")} >
                                <GridColumn field="PassJointRate" title="Joints" cell={gridcell} />
                                <GridColumn field="PassFilmRate" title="Film " cell={gridcell} />
                            </GridColumn>
                            </GridColumn>
                        </GridColumn>

                    </Grid>

                </Dialog>
            )}

            <Grid
                data={orderBy(groupbytype, sort)}
                sortable={true}
                sort={sort}
                // onSortChange={(e) => {
                //     setSort(e.sort);
                // }}
                style={{ overflowY: 'auto', height: BoxHeight }}
                className='centertable2'
            >

                <GridColumn field="company" title={t("업체명")} cell={mergecell} />
                <GridColumn field="type" title={t("비파괴 종류")} cell={gridcell} />
                <GridColumn title={t("금주현황")} >
                    <GridColumn title={t("총 비파괴 시험현황")} >
                        <GridColumn title={t("합격")} >
                            <GridColumn field="weekPassJoint" title="Joints" cell={gridcell} />
                            <GridColumn field="weekPassFlim" title="Film" cell={gridcell} />
                        </GridColumn>
                        <GridColumn title={t("불합격")} >
                            <GridColumn field="weekNonPassJoint" title="Joints" cell={gridcell} />
                            <GridColumn field="weekNonPassFilm" title="Film" cell={gridcell} />
                        </GridColumn>

                    </GridColumn>
                    <GridColumn title="" >
                        <GridColumn title={t("불량률")}>
                            <GridColumn field="weekPassJointRate" title="Joints" cell={gridcell} />
                            <GridColumn field="weekPassFilmRate" title="Film " cell={gridcell} />
                        </GridColumn>
                    </GridColumn>
                </GridColumn>

                <GridColumn title={t("누적현황")} >
                    <GridColumn title={t("총 비파괴 시험현황")} >
                        <GridColumn title={t("합격")} >
                            <GridColumn field="PassJoint" title="Joints" cell={gridcell} />
                            <GridColumn field="PassFlim" title="Film" cell={gridcell} />
                        </GridColumn>
                        <GridColumn title={t("불합격")} >
                            <GridColumn field="NonPassJoint" title="Joints" cell={gridcell} />
                            <GridColumn field="NonPassFilm" title="Film" cell={gridcell} />
                        </GridColumn>

                    </GridColumn>
                    <GridColumn title="" >
                        <GridColumn title={t("불량률")} >

                            <GridColumn field="PassJointRate" title="Joints" cell={gridcell} />
                            <GridColumn field="PassFilmRate" title="Film " cell={gridcell} />
                        </GridColumn>
                    </GridColumn>
                </GridColumn>

            </Grid>




        </>

    );

}

export default SubconNDE