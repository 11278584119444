import React, { useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
 import axios from "axios";import Cookies from 'js-cookie';
import _ from 'lodash';
import { guid } from "@progress/kendo-react-common";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { read, utils, } from 'xlsx';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  loadMessages,
} from "@progress/kendo-react-intl";
import moment from 'moment';
import krMessages from "./kr.json";
import { Field, Form } from '@progress/kendo-react-form';
import {
  FormNumericTextBox,
  FormInput,
  FormTextArea,
  FormDatePicker,
  FormComboBox
} from "./table/form-components";
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const FORM_DATA_INDEX = "formDataIndex";
const DATA_ITEM_KEY = "ID";

const requiredValidator = value => value ? '' : '필수값입니다';

const FormSubmitContext = React.createContext(() => undefined);
const GridEditContext = React.createContext({});
const GridInlineFormRow = (props) => {
  const { onRowAction, editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  const onSubmit = React.useCallback(
    (e) => {
      onRowAction({
        rowIndex: editIndex,
        operation: "save",
        dataItem: { ...e, Epc_reportdate: moment(e.Epc_reportdate).format('YYYY-MM-DD') },
      });
    },
    [onRowAction, editIndex]
  );
  if (isInEdit) {
    return (
      <Form
        key={JSON.stringify(props.dataItem)}
        initialValues={{ ...props.dataItem, Epc_reportdate: new Date(props.dataItem.Epc_reportdate) }}
        onSubmit={onSubmit}
        render={(formRenderProps) => {
          return (
            <FormSubmitContext.Provider value={formRenderProps.onSubmit}>
              {props.children}
            </FormSubmitContext.Provider>
          );
        }}
      />
    );
  }
  return props.children;
};

const DateCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit ? (
        <Field
          component={FormDatePicker}
          name={`${props.field}`}
        // validator={requiredValidator}
        />
      ) : (
        moment(props.dataItem[props.field || ""]).format('YYYY년 MM월 DD일')
      )}
    </td>
  );
};

const CategoryCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit ? (
        <Field
          component={FormComboBox}
          name={`${props.field}`}
          data={['Overall']}
        // validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};
const TextCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit && props.field !== 'projectcode' ? (
        <Field
          component={FormTextArea}
          name={`${props.field}`}
        // validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};



const NumberCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit ? (
        <Field
          component={FormNumericTextBox}
          name={`${props.field}`}
          format="n2" 
        validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};

const CommandCell = (props) => {
  const onSubmit = React.useContext(FormSubmitContext);
  const { onRowAction, setEditIndex, editIndex } =
    React.useContext(GridEditContext);
  const rowIndex = props.dataItem[FORM_DATA_INDEX];
  const rowID = props.dataItem['ID'];
  const isInEdit = rowIndex === editIndex;
  const isNewItem = !props.dataItem[DATA_ITEM_KEY];
  const onRemoveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onRowAction({
        rowIndex,
        rowID,
        operation: "remove",
      });
    },
    [rowIndex, onRowAction]
  );
  const onSaveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(e);
    },
    [onSubmit]
  );
  const onEditClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(rowIndex);
    },
    [rowIndex, setEditIndex]
  );
  const onCancelClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(undefined);
    },
    [setEditIndex]
  );
  return isInEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={onSaveClick}
      >
        {isNewItem ? "추가" : "업데이트"}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={isNewItem ? onRemoveClick : onCancelClick}
      >
        {isNewItem ? "취소" : "취소"}
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
        onClick={onEditClick}
      >
        수정
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
        onClick={onRemoveClick}
      >
        삭제
      </button>
    </td>
  );
};


const rowRender = (row, props) => {
  return <GridInlineFormRow dataItem={props.dataItem}>{row}</GridInlineFormRow>;
};


const StatusT = ({pjtlist, selected}) => {

  const { project_code } = useParams();
  const [statusdata, setstatusdata] = useState([]);
  const [state, setState] = useState(true)

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`http://43.200.223.224:5000/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/status/${project_code}.json`)
      setstatusdata(res.data.map((dataItem, idx) => ({
        ...dataItem,
        [FORM_DATA_INDEX]: idx,
      })))

    }
    fetchData();

  }, [project_code, state, selected]);

  const handleSubmit = () => {

    axios.post(`http://43.200.223.224:5000/writeJSON?path=/${REACT_APP_PATH2}/file/temp/status/${project_code}.json`, statusdata)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
    alert('서버에 저장되었습니다');
    setState(!state)
  }


  const [editIndex, setEditIndex] = React.useState(undefined);

  const onRowAction = React.useCallback(
    (options) => {
      console.log(options)
      const newDataState = [...statusdata];

      switch (options.operation) {
        case "remove":
          let index2 = statusdata.findIndex((record) => record.ID === options.rowID);
          newDataState.splice(index2, 1);
          break;
        case "save":
          let index = statusdata.findIndex(
            (product) => product.ID === options.dataItem.ID
          );
          newDataState[index] = options.dataItem;
          setEditIndex(undefined);
          break;
        case "add":
          newDataState.unshift({
            [FORM_DATA_INDEX]: options.rowIndex,
            [DATA_ITEM_KEY]: guid(),
            ['projectcode']:project_code,
            ['epc_Actual']: 0,
            ['epc_plan']: 0,
            ['epc_category_pd']: 'Overall',
            ['Epc_reportdate']: moment(new Date()).format('YYYY-MM-DD'),

          });
          setEditIndex(options.rowIndex);
          break;
        default:
      }
      setstatusdata(newDataState);
    },
    [statusdata]
  );

  const onAddClick = React.useCallback(() => {
    onRowAction({
      rowIndex: statusdata.length,
      operation: "add",
    });
  }, [onRowAction, statusdata]);


  const initialSort = [
    {
      field: "Epc_reportdate",
      dir: "asc",
    },
  ];


  const [files, setFiles] = React.useState([]);


  const onAdd = (event) => {

    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    setFiles(file['name']);

    const reader = new FileReader();
    reader.onloadend = (ev) => {
      if (!ev?.target?.result) {
        return;
      }

      const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
      const sheets = wb.SheetNames;

      const header = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })[0]

      if (sheets.length) {
        if (header.length === ["기준일", "Phase", "계획 프로그레스", "실적 프로그레스"].length && header.every(function (value, index) { return value ===  ["기준일", "Phase", "계획 프로그레스", "실적 프로그레스"][index] })) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          const rows_c = rows.map((v) => ({ ...v, ID: guid() })).map((v) => ({ projectcode:project_code,ID:v.ID,Epc_reportdate:v['기준일'],epc_category_pd:v['Phase'], epc_plan:v['계획 프로그레스'],epc_Actual:v['실적 프로그레스'], formDataIndex: v.ID }))
          setstatusdata([...statusdata, ...rows_c])
          // setState2(!state)
          alert('엑셀을 테이블로 로드했습니다. 취소를 위해서는 페이지를 새로고침하고 \n내용을 편집하려면 편집, 삭제 버튼 클릭 후 편집하면 되며 \n최종적으로 `입력완료`을 클릭하면 저장됩니다.')

        }

        else {
          alert('임포트한 엑셀 헤더를 확인하세요. 엑셀 템플릿 다운로드에 있는 헤더를 사용해야합니다.')
        }

      }

    };
    reader.readAsArrayBuffer(file);

  };



  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };


  const [pjtinfo, setpjtinfo] = useState([])

  const fetchData = async () => {
    let body =
    {
        "bpname": "ESG 대시보드 프로젝트 관리",
        "lineitem": "no",
        "filter_criteria": {
            "join": "AND",
            "filter": [
                {
                    "field": "status",
                    "value": "Active",
                    "condition_type": "eq"
                },

                {
                  "field": "ugenProjectNumber",
                  "value": project_code,
                  "condition_type": "eq"
              }
            ]
        }
    }


    const res = await axios.post(`http://43.200.223.224:5000/getbprecordtotal?path=SKEC0001`, body)

    setpjtinfo(res.data.data.data)

}
useEffect(() => {
    fetchData();
}, [project_code]);

  return (
    <>

      <div style={{ margin: 30, textAlign: 'center' }}>
        {_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['SKONeUSEPD'] === '운용' ? 'SKONe에 값이 입력되어있습니다' :
          <>



            <ExcelExport data={_.sortBy(statusdata, 'ID')} ref={_export}>

              <GridEditContext.Provider
                value={{
                  onRowAction,
                  editIndex,
                  setEditIndex,
                }}
              >
                <Grid
                  className='import inputComponent'
                  style={{
                    height: "700px", marginTop: 16
                  }}
                  data={statusdata} dataItemKey={DATA_ITEM_KEY} rowRender={rowRender}>
                  <GridToolbar>
                    {pjtinfo[0] && pjtinfo[0]['ugenProjectName']}({pjtinfo[0] && pjtinfo[0]['ugenProjectNumber']})

                    <button
                      title="Add new"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={onAddClick}
                      style={{ position: 'absolute', right: '100px' }}

                    >
                      행추가
                    </button>
                    <button
                      title="save"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={handleSubmit}
                      style={{ position: 'absolute', right: '15px' }}
                    >
                      입력완료
                    </button>

                  </GridToolbar>

                  {/* <Column
                    field="projectcode"
                    title="프로젝트"
                    cell={InputCell}
                  /> */}

                  <Column field="Epc_reportdate" title="기준일"
                    // editor="date"
                    cell={DateCell}
                  // format="{0:d}"
                  />
                  <Column
                    field="epc_category_pd"
                    title="Phase"
                    cell={CategoryCell}
                  />
                  <Column
                    field="epc_plan"
                    title="계획 프로그레스"
                    width="150px"
                    cell={NumberCell}
                  />
                  <Column field="epc_Actual" title="실적 프로그레스" width="150px" cell={NumberCell} />
                  <Column cell={CommandCell} width="200px" />
                  {/* <Column field="ID" title="Id" width="80px" editable={false} /> */}

                </Grid>
              </GridEditContext.Provider>

            </ExcelExport>
          </>
        }
      </div>


    </>


  );

}
export default StatusT;
