import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import html2canvas from "html2canvas";
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import axios from 'axios';
import Man1 from './man1';
import Man2 from './man2';
import Equip1 from './equip1';
import Equip2 from './equip2';
import Adjust from './adjust';
import '@progress/kendo-theme-default/dist/all.css';
import "../kendocustom.css";
import { useTranslation } from 'react-i18next';
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import { DashboardLayout } from '../projectLayout';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const ManEquip = () => {
  const { t, i18n } = useTranslation();

  const { project_code } = useParams();
  const exportAsImage = async () => {
    const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, project_code+'_인원장비현황.png');
    };
  
  const downloadImage = (blob, fileName) => {
      const fakeLink = window.document.createElement("a");
      fakeLink.style = "display:none;";
      fakeLink.download = fileName;
      
      fakeLink.href = blob;
      
      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);
      
      fakeLink.remove();
      };
  
  const [grid, setgrid] = useState({
    mobile: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 1,
      },
      
    ],

    tablet: [
      {
        col: 1,
        colSpan: 4,
        rowSpan: 3,
        status: true,
      },
      {
        col: 5,
        colSpan: 8,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 4,
        status: true,
        rowSpan: 3,
      },
      {
        col: 5,
        colSpan: 8,
        status: true,
        rowSpan: 3,
      },

      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 1,
      },

    ],

    desktop: [
      {
        col: 1,
        colSpan: 4,
        rowSpan: 3,
        status: true,
      },
      {
        col: 5,
        colSpan: 8,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 4,
        status: true,
        rowSpan: 3,
      },
      {
        col: 5,
        colSpan: 8,
        status: true,
        rowSpan: 3,
      },

      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 0,
      },

    ]
  });
  const [status_grid, setstatusgrid] = useState([{ status: true }, { status: true }, { status: true }, { status: true }]);
  const [lineitem, setlineitem] = useState([]);
  const [columns, setcolumns] = useState(12);
  const [blockH, setblockH] = useState(140);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')


  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
        "bpname": "대시보드 설정",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
              {
                  "field": "status",
                  "value": "Active",
                  "condition_type": "eq"
              },
              {
  
                "field": "ugenProjectNumber",
                "value": project_code,
                "condition_type": "eq"
              },
          ]
      }
      }
      const res = await axios.post(`http://43.200.223.224:5000/getbpemsrecord?path=${project_code}`, body)
      if (res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '인원/장비 현황').length > 0) {
        const sort = _.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '인원/장비 현황')[0]['_bp_lineitems'].filter(v => v.Status_CB === 'Yes'), 'Card_Order')
        const GRIDCONFIG = sort.map((v) => ({ colSpan: v.Card_Width, rowSpan: v.Card_Vertical_Width, status: true, col: v.StartingPosition }))
        const GRID_CONFIG = { mobile: GRIDCONFIG.map((v) => ({ colSpan: 16, rowSpan: v.rowSpan, status: v.status, col: 1 })), tablet: GRIDCONFIG, desktop: GRIDCONFIG };

        setlineitem(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '인원/장비 현황')[0]['_bp_lineitems'])

        setstatusgrid(_.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '인원/장비 현황')[0]['_bp_lineitems'].map((v) => ({ ...v, status: v.Status_CB === 'Yes' ? true : false })), 'Card_Order'))

        setblockH(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '인원/장비 현황')[0]['default_vertical_height'])
        setcolumns(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === '인원/장비 현황')[0]['columns_count'])
        setgrid(
          GRID_CONFIG
        )
      }
    }
    fetchData();
  }, [project_code]);

  const [positiondata, setPositionData] = useState(grid['desktop']);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(true);

  const togglefix = () => {
    setfixblock(!fixblock);
  };

  const [data1, setdata1] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [filelist2, setfilelist2] = useState([]);
  const [filelist3, setfilelist3] = useState([]);

  const [dailyreport, setdailyreport] = useState([]);
  const [mandata, setmandata] = useState([]);
  const [equipdata, setequipdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/drhistory/${project_code}/`,)
      const res2 = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/man/`,)
      const res3 = await axios.get(`http://43.200.223.224:5000/getFileFolderList?path=/${REACT_APP_PATH2}/file/equip/`,)
      const files = res.data.fileList.map((v) => (v.name)).sort().reverse().slice(0, 60);
      const files2 = res2.data.fileList.filter(v=>v.name.includes(project_code)).map((v) => (v.name)).sort().reverse().slice(0, 60);
      const files3 = res3.data.fileList.filter(v=>v.name.includes(project_code)).map((v) => (v.name)).sort().reverse().slice(0, 60);
      setfilelist(files)
      setfilelist2(files2)
      setfilelist3(files3)
    }

    fetchData();
  }, [project_code]);


  const drfilelist = useMemo(() => filelist, [filelist]);
  const manfilelist = useMemo(() => filelist2, [filelist2]);
  const equipfilelist = useMemo(() => filelist3, [filelist3]);

  useEffect(() => {
    const fetchData = async () => {

      
      const requestArr = drfilelist.map(async data => {
        return axios.get(`http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/drhistory/${project_code}/${data}`,)
      });

      const res = await Promise.all(requestArr)
      const reslist = res.map(v=>v.data).flat(1)


      setdailyreport(_.uniqBy(reslist,'record_no'))

      const requestArr2 = manfilelist.filter(v => v.split('_')[0].includes(project_code)).map(async data => {
        return axios.get(`http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/man/${data}`,)
      });

      const res2 = await Promise.all(requestArr2)
      const reslist2 = res2.map(v=>v.data).flat(1)

      setmandata(_.uniqBy(reslist2,'record_no'))

      const requestArr3 = equipfilelist.filter(v => v.includes(project_code)).filter(v => v.split('_')[0].includes(project_code)).map(async data => {
        return axios.get(`http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/equip/${data}`,)
      });

      const res3 = await Promise.all(requestArr3)
      const reslist3 = res3.map(v=>v.data).flat(1)

      setequipdata(_.uniqBy(reslist3,'record_no'))

    };

    fetchData();
  }, [project_code, drfilelist, manfilelist, equipfilelist]);

  useEffect(() => {
    const fetchData = async () => {
      const res2 = await axios.get(`http://43.200.223.224:5000/getemsJSON?path=/${REACT_APP_PATH2}/file/dailyreport/${project_code}_dr.json`,)

      setdata1(_.uniqBy(res2.data,'record_no'))

    };
    fetchData();
  }, [project_code]);


  const [data11, setdata11] = useState(data1);


  useEffect(() => {
    setdata11(data1)
  }, [data1])


  const [tile1, settile1] = useState(0)
  const [tile2, settile2] = useState(1)
  const [tile3, settile3] = useState(2)
  const [tile4, settile4] = useState(3)


  useEffect(() => {
    settile1(_.get(lineitem.filter(v => v.Card_Name === '인원현황')[0], 'Card_Order') - 1)
    settile2(_.get(lineitem.filter(v => v.Card_Name === '주별/월별 인원현황')[0], 'Card_Order') - 1)
    settile3(_.get(lineitem.filter(v => v.Card_Name === '장비현황')[0], 'Card_Order') - 1)
    settile4(_.get(lineitem.filter(v => v.Card_Name === '주별/월별 장비현황')[0], 'Card_Order') - 1)

  }, [lineitem])


  const tiles = [

    {
      header: (<div style={{ position: 'relative' }}>{t("인원현황")}</div>),
      body: <Man1 positiondata={positiondata} data11={data11} dailyreport={dailyreport} blockH={blockH} order={isNaN(tile1)? 0:tile1} />,
      status: true,
      key: '인원현황',
      resizable: fixblock,
      reorderable: fixblock,

    },

    {
      header: (<div style={{ position: 'relative' }}>{t("기간별 인원현황")}</div>),
      body: <Man2 positiondata={positiondata} 
      data11={data11} 
      dailyreport={dailyreport} 
      mandata={mandata} blockH={blockH} order={isNaN(tile2)? 1:tile2} />,
      status: true,
      key: '월별 업체 인원 현황',
      resizable: fixblock,
      reorderable: fixblock,

    },



    {
      header: (<div style={{ position: "relative" }}>{t("장비현황")}</div>),
      body: <Equip1 positiondata={positiondata} data11={data11} dailyreport={dailyreport} blockH={blockH} order={isNaN(tile3)? 2:tile3} />,
      status: true,
      key: '장비현황',
      resizable: fixblock,
      reorderable: fixblock,
    },
    {
      header: (<div style={{ position: 'relative' }}>{t("기간별 장비현황")}</div>),
      body: <Equip2 positiondata={positiondata} data11={data11} dailyreport={dailyreport} equipdata={equipdata} blockH={blockH}order={isNaN(tile4)? 3:tile4} />,
      status: true,
      key: '월별 업체 장비 현황',
      resizable: fixblock,
      reorderable: fixblock,

    },
    {
      item:<Adjust/>,
      status: true,
      key: '조정',
      resizable: fixblock,
      reorderable: fixblock,

    },



  ];



  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, data11, dailyreport, mandata, equipdata, status_grid, tile1, tile2, tile3, tile4, fixblock,i18n.language]);

  const tile_filtered = _.filter(tile, ['status', true]);

  const handleReposition = e => {
    setPositionData(e.value);
    // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
  };

  const location = useLocation();
  const usernameFromState = location.state?.username;
  const [isLoading, setIsLoading] = useState(false);
  
  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`http://43.200.223.224:5000/getcheckowner?project=${project_code}`);
      setIsLoading(false);
   
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  };


  const isNotIframe = window.self === window.top;

  return (
    <>
    {isNotIframe? (
    <DashboardLayout>
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />
    </div>
    </DashboardLayout>)
    :
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />
    </div>
}
</>
  );
};

export default ManEquip;