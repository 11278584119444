import React, { useEffect, useState } from 'react';
import ManPower from './common/manpower';
import Photos from './common/photo2';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Contructioninfo = ({ positiondata, filter ,projeclist}) => {

    const [BoxHeight, setBoxHeight] = useState(250);
    const { height } = useWindowDimensions();


    useEffect(() => {
        const boxHeight = positiondata[1];
        setBoxHeight(((height-30)/6) * (boxHeight && boxHeight.rowSpan));
    }, [positiondata,height]);
    
    return (<>
  
        <ManPower filter={filter} BoxHeight={BoxHeight} projeclist={projeclist}/>
        <Photos  filter={filter} BoxHeight={BoxHeight} projeclist={projeclist}/>


</>)
}

export default Contructioninfo
