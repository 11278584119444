import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import { Button } from "@progress/kendo-react-buttons";
import Timeline from "react-timelines";
import "react-timelines/lib/css/style.css";
import "./QualityCriticalProcess.scss";
import moment from "moment";
import DialogComponent from "./DialogComponent";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { unifier_date_format_transducer } from "../functions/functions";

const { REACT_APP_URI2 } = process.env;

const url =
  process.env.NODE_ENV === "development" ? `${REACT_APP_URI2}http://43.200.223.224:5000` : "http://43.200.223.224:5000";

const now = new Date();

const MIN_ZOOM = 1;
const MAX_ZOOM = 20;

const CriticalProcess2 = () => {
  const { project_code } = useParams();

  const [timeBar, setTimeBar] = useState([]);
  const [option, setOption] = useState({
    open: false,
    zoom: 1,
    tracks: [],
  });
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [toggleDialog, setToggleDiaglog] = useState(false);
  const [dialogContents, setDialogContents] = useState({});
  const [projectStartEnd, setProjectStartEnd] = useState({
    projectName: "",
    start: "",
    end: "",
  });

  // const [trackItems, setTrackItems] = useState([]);

  const handleButtonClick = () => {
    window.location.assign(`/${project_code}/qualityp`);
  };

  const clickElement = (element) => {
    handleDialog();
    setDialogContents(element);
  };

  const handleToggleOpen = useCallback(() => {
    setOption((prev) => {
      return { ...prev, open: !prev.open };
    });
  }, []);

  const handleZoomIn = useCallback(() => {
    setOption((prev) => {
      return { ...prev, zoom: Math.min(prev.zoom + 1, MAX_ZOOM) };
    });
  }, []);

  const handleZoomOut = useCallback(() => {
    setOption((prev) => {
      return { ...prev, zoom: Math.max(prev.zoom - 1, MIN_ZOOM) };
    });
  }, []);

  const handleDialog = useCallback(() => {
    setToggleDiaglog((prev) => !prev);
  }, []);

  const [isOpen, setIsOpen] = useState(true);

  const handleToggleTrackOpen = (track) => {
    setOption((prevOption) => {
      const updatedTracks = prevOption.tracks.map((t) => {
        if (t.id === track.id) {
          return { ...t, isOpen: !t.isOpen };
        }
        return t;
      });
      return { ...prevOption, tracks: updatedTracks };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "Quality Critical Process",
        lineitem: "yes",
        filter_condition: "status=Active",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "QCPMajorActivityNM",
              value: "Milestone",
              condition_type: "neq",
            },
          ],
        },
      };

      const unifierData = await axios.post(
        `${url}/getemsbprecord?path=${project_code}`,
        body
      );
      // const data = _.sortBy(
      //   unifierData.data.data.data,
      //   "uuu_record_last_update_date"
      // );

      const data = unifierData.data.data.data;

      // QCPDisciplinePD를 기준으로 데이터 묶기
      const groupedByDiscipline = _.groupBy(
        _.sortBy(data, "QCPDisciplinePD"),
        "QCPDisciplinePD"
      );

      // 각 discipline 그룹마다 QCPMajorActivityNM을 모아서 배열로 만들기
      const result = _.map(groupedByDiscipline, (activities, discipline) => {
        // QCPMajorActivityNM을 기준으로 데이터 묶기
        const groupedByMajorActivity = _.groupBy(
          _.sortBy(activities, "QCPOrderNo"),
          "QCPMajorActivityNM"
        );

        // 각 major activity 그룹마다 _bp_lineitems를 그대로 포함시켜주기
        const majorActivityData = _.map(
          groupedByMajorActivity,
          (majorActivities, majorActivity) => {
            const lineitems = _.flatMap(
              majorActivities,
              (item) => item._bp_lineitems
            );
            return {
              majorActivity,
              lineitems,
            };
          }
        );

        return {
          discipline,
          majorActivityData,
        };
      });

      const createTracks =
        result &&
        result.map((c, idx1) => {
          return {
            id: `track-${idx1 + 1}`,
            title: c.discipline,
            isOpen: true,
            elements: [],
            tracks:
              c.majorActivityData &&
              c.majorActivityData.map((o, idx2) => {
                const lineitems =
                  o.lineitems && o.lineitems.filter((item) => item);

                return {
                  id: `t-${idx1 + 1}-${idx2 + 1}`,
                  title: o.majorActivity,
                  elements: lineitems.map((z, idx3) => {
                    const start_end_date = {
                      start: unifier_date_format_transducer(z.emsPlanStartDO),
                      end: unifier_date_format_transducer(z.emsPlanFinishDO),
                    };

                    if (z.emsActualStartDO && z.emsActualFinishDO) {
                      start_end_date.start = unifier_date_format_transducer(
                        z.emsActualStartDO
                      );
                      start_end_date.end = unifier_date_format_transducer(
                        z.emsActualFinishDO
                      );
                    }

                    start_end_date.end.setHours(23);

                    return {
                      id: `t-${idx1 + 1}-${idx2 + 1}-${idx3 + 1}`,
                      title: z.QCPActivityNM,
                      start: start_end_date.start,
                      end: start_end_date.end,
                      style: {
                        backgroundColor: "transparent",
                        color: "#000000",
                        borderRadius: "4px",
                        textTransform: "capitalize",
                        textAlign: "center",
                        boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
                      },
                      position: idx3 % 2 === 0 ? "up" : "down",
                      plan_start: z.emsPlanStartDO,
                      plan_end: z.emsPlanFinishDO,
                      actual_start: z.emsActualStartDO,
                      actual_end: z.emsActualFinishDO,
                    };
                  }),
                };
              }),
          };
        });

      setOption({
        open: false,
        zoom: 1,
        tracks: createTracks,
      });

      const trackItems_arr = [];

      createTracks.forEach((com) =>
        com.tracks.forEach((com2) =>
          com2.elements.forEach((com3) => trackItems_arr.push(com3))
        )
      );

      // setTrackItems(trackItems_arr);

      const startEndDateArr = [];

      const data_flat = data
        .filter((com) => com._bp_lineitems && com._bp_lineitems.length > 0)
        .map((com) => com._bp_lineitems)
        .flat()
        .map((com) => {
          const {
            emsPlanStartDO,
            emsPlanFinishDO,
            emsActualStartDO,
            emsActualFinishDO,
          } = com;
          return [
            emsPlanStartDO,
            emsPlanFinishDO,
            emsActualStartDO,
            emsActualFinishDO,
          ];
        })
        .flat()
        .filter((com) => com)
        .map((com) => unifier_date_format_transducer(com));

      data.forEach((item) => {
        if (item._bp_lineitems && item._bp_lineitems.length > 0) {
          item._bp_lineitems.forEach((com) => {
            if (
              com.emsPlanStartDO !== null &&
              com.emsPlanStartDO !== undefined
            ) {
              startEndDateArr.push(new Date(com.emsPlanStartDO.slice(0, 10)));
            }
            if (
              com.emsPlanFinishDO !== null &&
              com.emsPlanFinishDO !== undefined
            ) {
              startEndDateArr.push(new Date(com.emsPlanFinishDO.slice(0, 10)));
            }
          });
        }
      });

      const startEndDateSorting = startEndDateArr.sort(
        (a, b) => new Date(a) - new Date(b)
      );

      const startDate = startEndDateSorting[0];
      const endDate = startEndDateSorting[startEndDateSorting.length - 1];

      setStart(settingStartDate(_.min(data_flat)));

      setEnd(settingEndDate(_.max(data_flat)));

      if (data.length > 0) {
        const projectName = data[0].project_projectname;

        setProjectStartEnd((prev) => {
          return { ...prev, projectName, start: startDate, end: endDate };
        });
      }
    };
    fetchData();
  }, [project_code]);

  useEffect(() => {
    const MONTH_NAMES = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const QUARTERS_PER_YEAR = 4;
    const MONTHS_PER_QUARTER = 3;
    const NUM_OF_YEARS = end.getFullYear() - start.getFullYear() + 1;
    const MONTHS_PER_YEAR = 12;
    const START_YEAR = start.getFullYear();

    const addMonthsToYear = (year, monthsToAdd) => {
      let y = year;
      let m = monthsToAdd;
      while (m >= MONTHS_PER_YEAR) {
        m -= MONTHS_PER_YEAR;
        y += 1;
      }
      return { year: y, month: m + 1 };
    };

    const addMonthsToYearAsDate = (year, monthsToAdd) => {
      const r = addMonthsToYear(year, monthsToAdd);
      return new Date(`${r.year}-${r.month}`);
    };

    const buildQuarterCells = () => {
      const v = [];
      for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        const quarter = (i % 4) + 1;
        const startMonth = i * MONTHS_PER_QUARTER;
        const s = addMonthsToYear(START_YEAR, startMonth);
        const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER);
        v.push({
          id: `${s.year}-q${quarter}`,
          title: `Q${quarter} ${s.year}`,
          start: new Date(`${s.year}-${s.month}-01`),
          end: new Date(`${e.year}-${e.month}-01`),
        });
      }
      return v;
    };

    const buildMonthCells = () => {
      const v = [];
      for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        const startMonth = i;
        const start = addMonthsToYearAsDate(START_YEAR, startMonth);
        const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1);
        v.push({
          id: `m${startMonth}`,
          title: MONTH_NAMES[i % 12],
          start,
          end,
        });
      }
      return v;
    };

    const buildTimebar1 = () => [
      {
        id: "quarters",
        title: "Quarters",
        cells: buildQuarterCells(),
        style: {},
      },
      {
        id: "months",
        title: "Months",
        cells: buildMonthCells(),
        useAsGrid: true,
        style: {},
      },
    ];

    setTimeBar(buildTimebar1());
  }, [end, start]);

  useEffect(() => {
    const timeLine = document.querySelector(".rt-tracks");

    if (timeLine !== null) {
      const tracksNodes = timeLine.childNodes;
      tracksNodes.forEach((com, idx) => {
        if (com.firstChild.nextSibling !== null) {
          const targetChildNodes = com.firstChild.nextSibling.childNodes;
          targetChildNodes.forEach((com2, idx2) => {
            com2.firstChild.childNodes.forEach((com3, idx3) => {
              com3.innerHTML = "";
              const targetTrack =
                option.tracks[idx].tracks[idx2].elements[idx3];
              const divs = document.createElement("div");
              divs.style.className = "rt-element__content milestone";
              divs.style.width = "100%";
              divs.style.position = "absolute";
              // divs.style.left = "-14px";
              divs.style.top = "10px";
              const spans = document.createElement("span");
              spans.className = "rt-element__title2";
              spans.innerHTML = moment(new Date(targetTrack.start)).format(
                "MM-DD-YYYY"
              );
              const icons = document.createElement("span");

              targetTrack.actual_end
                ? targetTrack.actual_end > targetTrack.plan_end
                  ? (icons.className = "rt-element qualityActBehind")
                  : (icons.className = "rt-element qualityActAhead")
                : (icons.className = "rt-element quality");

              if (targetTrack.position === "up") {
                spans.className = "rt-element__content milestone";
                spans.style.position = "absolute";
                spans.style.top = "10px";
                spans.style.fontSize = "0.9rem";
                spans.style.width = "200px";
                // spans.style.left = "-50px";
                com3.appendChild(spans);
                divs.appendChild(icons);
                com3.prepend(divs);
              } else {
                spans.className = "rt-element__content milestone";
                spans.style.position = "absolute";
                spans.style.top = "45px";
                spans.style.fontSize = "0.9rem";
                spans.style.width = "200px";
                // spans.style.left = "-50px";

                com3.appendChild(spans);
                divs.appendChild(icons);
                com3.prepend(divs);
              }
            });
          });
        }
      });
    }
  }, [option.tracks, option.zoom]);

  useEffect(() => {
    const timeLine = document.querySelector(".rt-tracks");

    if (timeLine !== null) {
      const tracksNodes = timeLine.childNodes;

      tracksNodes.forEach((com, idx) => {
        if (com.firstChild.nextSibling !== null) {
          const targetChildNodes = com.firstChild.nextSibling.childNodes;
          targetChildNodes.forEach((com2, idx2) => {
            com2.firstChild.childNodes.forEach((com3, idx3) => {
              const targetTrack =
                option.tracks[idx].tracks[idx2].elements[idx3];
              const spans = document.createElement("span");
              spans.className = "rt-element__title3";
              spans.innerHTML = targetTrack.actual_end
                ? "[완료] " + targetTrack.title
                : "[계획] " + targetTrack.title;
              if (idx3 % 2 === 0) {
                spans.style.top = "-10px";
                // spans.style.left = "-50px";
                spans.style.fontSize = "0.9rem";
                spans.style.width = "200px";
                spans.style.fontWeight = "600";
                spans.style.position = "absolute";

                spans.style.whiteSpace = "nowrap";
                spans.style.textAlign = "center";
              } else {
                spans.style.top = "65px";
                // spans.style.left = "-50px";
                spans.style.fontSize = "0.9rem";
                spans.style.width = "200px";
                spans.style.fontWeight = "600";
                spans.style.position = "absolute";

                spans.style.whiteSpace = "nowrap";
                spans.style.textAlign = "center";
              }

              if (com3.childNodes.length === 1) {
                com3.appendChild(spans);
                spans.style.width = 200 + "px";
                // spans.style.textAlign = "left";
              } else {
                spans.style.width = 200 + (option.zoom - 1) * 50 + "px";
                // spans.style.left = -50 - (option.zoom - 1) * 25 + "px";
              }
              com3.appendChild(spans);
            });
          });
        }
      });
    }
  }, [option.tracks, option.zoom]);

  return (
    <>
      <ButtonDiv>
        <CustomButton
          title="블록 온오프"
          // iconClass="k-icon k-font-icon k-i-grid"
          onClick={handleButtonClick}
        >
          <ArrowBackIcon />
        </CustomButton>
      </ButtonDiv>
      <div className="qualityCriticalProcess">
        <div className="permitMilestoneHeader">Quality Critical Process</div>
        <div className="qualityCriticalProcessContents">
          <div className="iconslengend">
            <div className="iconslengendBox">
              <div>Plan Date:</div>
              <div className="k-icon k-font-icon k-i-circle qualityPlan"></div>
            </div>
            <div className="iconslengendBox">
              <div>Ahead Actual Date:</div>
              <div
                className="k-icon k-font-icon k-i-circle qualityActAhead"
                style={{ marginTop: 0 }}
              ></div>
            </div>
            <div className="iconslengendBox">
              <div>Behind Actual Date:</div>
              <div
                className="k-icon k-font-icon k-i-circle qualityActBehind"
                style={{ marginTop: 0 }}
              ></div>
            </div>
          </div>
        </div>

        {option.tracks.length > 0 && (
          <Timeline
            scale={{
              start,
              end,
              zoom: option.zoom,
              zoomMin: MIN_ZOOM,
              zoomMax: MAX_ZOOM,
            }}
            isOpen={option.open}
            toggleOpen={handleToggleOpen}
            zoomIn={handleZoomIn}
            zoomOut={handleZoomOut}
            clickElement={clickElement}
            timebar={timeBar}
            tracks={option.tracks.map((track) => ({
              ...track,
              isOpen: isOpen && track.isOpen, // isOpen 상태를 반영하여 트랙이 열려있을 때만 렌더링
            }))}
            now={now}
            toggleTrackOpen={handleToggleTrackOpen}
            enableSticky
            scrollToNow
          />
        )}
        {toggleDialog && (
          <DialogComponent
            handleDialog={handleDialog}
            // trackItems={trackItems}
            dialogContents={dialogContents}
            projectStartEnd={projectStartEnd}
          ></DialogComponent>
        )}
      </div>
    </>
  );
};

export default CriticalProcess2;

const ButtonDiv = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  margin: 10px;
  z-index: 100;
  font-size: 30px;
`;

const CustomButton = styled(Button)`
  background-color: #7f84ab;
  border: none;
  box-shadow: none;
  color: white;
`;

/**
 * Start Date을 기준으로 1분기 전을 반환하는 함수
 * @param {Date} date StartDate
 * @returns
 */
const settingStartDate = (date) => {
  const targetDate = new Date(date);

  targetDate.setDate(1);

  const month = new Date(date).getMonth();

  if (month === 0 && month <= 2) {
    targetDate.setFullYear(targetDate.getFullYear() - 1);
    targetDate.setMonth(9);
  } else if (month > 2 && month <= 5) {
    targetDate.setMonth(0);
  } else if (month > 5 && month <= 8) {
    targetDate.setMonth(3);
  } else if (month > 8 && month <= 11) {
    targetDate.setMonth(6);
  }

  return new Date(targetDate);
};

/**
 * End Date을 기준으로 1분기 후를 반환하는 함수
 * @param {Date} date StartDate
 * @returns
 */
const settingEndDate = (date) => {
  const targetDate = new Date(date);

  const month = new Date(date).getMonth();

  if (month === 0 && month <= 2) {
    targetDate.setMonth(5);
  } else if (month > 2 && month <= 5) {
    targetDate.setMonth(8);
  } else if (month > 5 && month <= 8) {
    targetDate.setMonth(11);
  } else if (month > 8 && month <= 11) {
    targetDate.setFullYear(targetDate.getFullYear() + 1);
    targetDate.setMonth(2);
  }

  const lastDay = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    0
  ).getDate();

  targetDate.setDate(lastDay);

  return new Date(targetDate);
};
