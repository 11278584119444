import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import axios from 'axios';
import moment from 'moment-timezone';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const SafetyTraining = ({ positiondata, order, blockH }) => {


    const { project_code } = useParams();

    const [BoxHeight, setBoxHeight] = useState(250);
  
  
    useEffect(() => {
  
      const boxHeight = positiondata[order];
      setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
  }, [positiondata, order, blockH]);
  
  
    const [data, setdata] = useState([]);
  
  
    useEffect(() => {
      const fetchData = async () => {
  
        let body =
        {
          "bpname": "안전교육",
          "lineitem": "no",
        }
        const res = await axios.post(`http://43.200.223.224:5000/getbprecord?path=${project_code}`, body)
        setdata(_.sortBy(res.data.data.data, 'record_no').reverse()[0])
  
  
      };
  
      fetchData();
    }, [project_code]);


  

    return (

        <GridLayout style={{ height: BoxHeight }}
          rows={[
            {
              height: '90%',
            },            {
                height: '10%',
              },
      
    
          ]}
    
          cols={[
            {
              width: '100%',
            }

    
    
          ]}
          gap={{
            rows: 0,
            cols: 0,
          }}>
    
          <GridLayoutItem row={1} col={1} rowSpan={1} style={{ fontSize: '1rem' , overflow:'auto' }}>
            {data && data.length}
    
          </GridLayoutItem>
          <GridLayoutItem row={2} col={1} rowSpan={1}  style={{ position: 'absolute', bottom: '5px', left: '50%', transform: `translate(-50%)` }}>
            <div>{data && data.genSubmitDO &&  moment(new Date(data.genSubmitDO.slice(0,10))).format("YYYY년 MM월 DD일")}</div>
            </GridLayoutItem>

    
        
    
        </GridLayout>
      )
    
    

}

export default SafetyTraining
