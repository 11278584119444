import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from "moment";
import {generateUpdatedDate} from "../common/datadate";

const {REACT_APP_URI2} = process.env;

const ConcernWeekly = ({ positiondata , order, blockH }) => {
  const params = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [tableData, setTableData] = useState([]);
  const [sort, setSort] = useState([{}]);
  const findParentNode = useRef();

  useEffect(() => {
    const addClassName = " concernWeeklyBody";
    const parentNode = findParentNode.current.parentElement;
    parentNode.className = parentNode.className + addClassName;
  }, []);


  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await axios.get(
        `http://43.200.223.224:5000/getConcernWeekly/${params.project_code}`    , 
      );

      const { unifierData } = fetchData.data.data;

      const makeTableData = unifierData.map((com, idx) => {
        return {
          ...com,
          id: idx + 1,
          date: generateUpdatedDate(new Date(com.genWeeklyReportDO)),
          fileName: `${moment(new Date(com.genWeeklyReportDO)).format(
            "YY년MM월DD일"
          )}.zip`,
        };
      });

      setTableData(makeTableData);
    };
    fetchData();
  }, [params]);

  const handleDownload = async (filename) => {
    const config = {
      method: "GET",
      url: `http://43.200.223.224:5000/getConcernWeekly/download/${params.project_code}/${filename}`,
      responseType: "blob",
      
    };

    try {
      const fetchData = await axios(config);

      const name = filename;
      const downloadurl = window.URL.createObjectURL(
        new Blob([fetchData.data])
      );
      const link = document.createElement("a");

      link.href = downloadurl;

      console.log(downloadurl);
      link.setAttribute("download", name);
      link.style.cssText = "display:none";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      alert("첨부파일 없음");
    }
  };

  const CustomCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
        onClick={() => handleDownload(value)}
        style={{cursor:"pointer"}}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            className="k-icon k-font-icon k-i-save k-i-floppy"
            style={{ margin: "5px 0px" }}
          ></p>
        </div>
      </td>
    );
  };

  const CustomCell2 = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
        onClick={() => handleDownload(value)}
      >
        <div style={{ paddingLeft: "5px" }}>{value}.</div>
      </td>
    );
  };

  const CustomHeaderCell = (props) => {
    const { field, title } = props;

    const handleSort = (field) => {
      if (title === "No.") {
        const findSortIndex = sort.findIndex((e) => e.field === field);

        const findSort = sort.find((e) => e.field === field);

        if (findSort === undefined) {
          if (sort[0]["field"] === undefined) {
            sort.push({ field: field, dir: "asc" });
            setSort([...sort]);
          }
        } else {
          if (findSort["dir"] === "asc") {
            sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
            setSort([...sort]);
          } else if (findSort["dir"] === "desc") {
            sort.splice(findSortIndex, 1);
            setSort([...sort]);
          }
        }
      }
    };

    const hadleSortArrow = (field) => {
      const findSort = sort.find((e) => e.field === field);

      return findSort !== undefined ? findSort["dir"] : false;
    };

    return (
      <div
        className="customHeaderCell"
        style={{
          cursor: title === "No." && "pointer",
        }}
        onClick={() => {
          handleSort(field);
        }}
      >
        <div className="customHeaderCell_t">
          {props.title}{" "}
          <div
            className={
              hadleSortArrow(field) === "asc"
                ? "k-icon k-font-icon k-i-sort-asc-sm"
                : hadleSortArrow(field) === "desc"
                ? "k-icon k-font-icon k-i-sort-desc-sm"
                : ""
            }
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div ref={findParentNode}>
      <Grid
        data={orderBy(tableData, sort)}
        className="concernWeekly"
        style={{ maxHeight: BoxHeight }}
        sortable={{
          allowUnsort: false,
          mode: "multiple",
        }}
        sort={sort}
      >
        <GridColumn
          field="id"
          title="No."
          cell={CustomCell2}
          headerCell={CustomHeaderCell}
        />
        <GridColumn field="date" title="Date" headerCell={CustomHeaderCell} />
        <GridColumn
          field="fileName"
          title="att"
          cell={CustomCell}
          headerCell={CustomHeaderCell}
        />
      </Grid>
    </div>
  );
};

export default ConcernWeekly;
