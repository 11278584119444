import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";

const DialogComponent = ({ handleDialog, dialogContents }) => {
  const [dialogContent, setDialogContent] = useState({});

  useEffect(() => {
    setDialogContent({
      title: dialogContents.title,
      plan_start: moment(new Date(dialogContents.plan_start)).format(
        "YY년 MM월 DD일"
      ),
      plan_end: moment(new Date(dialogContents.plan_end)).format(
        "YY년 MM월 DD일"
      ),
      actual_start:
        dialogContents.actual_start === undefined ||
        dialogContents.actual_start === null
          ? "-"
          : moment(new Date(dialogContents.actual_start)).format(
              "YY년 MM월 DD일"
            ),
      actual_end:
        dialogContents.actual_end === undefined ||
        dialogContents.actual_end === null
          ? "-"
          : moment(new Date(dialogContents.actual_end)).format(
              "YY년 MM월 DD일"
            ),
    });
  }, [dialogContents]);

  return (
    <Dialog title={"Quality Critical Process"} onClose={handleDialog}>
      <div className="permitTable" style={{ width: 500 }}>
        <div className="permitTableNormalBox">
          <div className="permitTableHeader">
            <div style={{ wordBreak: "keep-all" }}>Activity Name</div>
          </div>
          <div className="permitTableContents">{dialogContents.title}</div>
        </div>
        <div className="permitTableMergeBox">
          <div className="permitTableMergeHeader">
            <div style={{ wordBreak: "keep-all" }}>Plan Date</div>
          </div>
          <div className="permitTableHeader2">
            <div>Start</div>
            <div>End</div>
          </div>
          <div className="permitTableContents2">
            <div>{dialogContent.plan_start}</div>
            <div>{dialogContent.plan_end}</div>
          </div>
        </div>
        <div className="permitTableMergeBox">
          <div className="permitTableMergeHeader">
            <div>Actual Date</div>
          </div>
          <div className="permitTableHeader2">
            <div>Start</div>
            <div>End</div>
          </div>
          <div className="permitTableContents2">
            <div>{dialogContent.actual_start}</div>
            <div>{dialogContent.actual_end}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogComponent;
