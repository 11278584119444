import React, { useState, useEffect } from 'react';
import { Paper, Button, Typography, TextField, List, ListItem, ListItemText, Box, Select, MenuItem, FormControl } from '@mui/material';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import axios from "axios";

const InfoBoxSquadLast = ({ documentList, onSelectDocument, recordNo, pdftronInstance }) => {
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedStatus, setSelectedStatus] = useState({});
  const { project_code } = useParams();
  const [codeList, setCodeList] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState({}); // Tracks whether each document has been submitted


  useEffect(() => {
    // 초기 선택 및 버튼 활성화 상태 검사
    if (documentList && documentList.length > 0 && !selectedDocument) {
      setSelectedDocument(documentList[0].id);
      onSelectDocument(documentList[0].id, documentList[0].title);
    }
  }, [documentList, selectedStatus]);

  useEffect(() => {
    const fetchData = async () => {
      if (project_code) {
        const res = await axios.get(`http://43.200.223.224:5000/getsquadcode?path=${project_code}`);
        if (res.data.success) {
          setCodeList(res.data.data.data.map(v => v.SKDMApprovalDecision))
        }
      }
    }
    fetchData()
  }, [project_code])

  const handleListItemClick = (event, id, title) => {
    setSelectedDocument(id);
    onSelectDocument(id, title);
  };

  const handleStatusChange = (event, id) => {
    setSelectedStatus({ ...selectedStatus, [id]: event.target.value });
  };


  const handleSubmitReview = async (documentId) => {
    const document = documentList.find(doc => doc.id === documentId);

    const willSend = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to send the data?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!'
    });

    if (willSend.isConfirmed) {
      if (pdftronInstance) {
        const { documentViewer, annotationManager } = pdftronInstance.Core;
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        const data = await doc.getFileData({
          xfdfString
        });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });

        const formData = new FormData();
        formData.append("file", blob, 'annotated-document.pdf');



        // Preparing dataObject with the record number and titles of selected documents
        const dataObject = {
          record_no: recordNo,
          document_titles: document.title // Only include titles of selected documents
        };
        formData.append("data", JSON.stringify(dataObject));

        try {
          const response = await axios.post(`http://43.200.223.224:5000/updatesqdlast?project=${project_code}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.data.success) {
            await Swal.fire({
              title: 'Success!',
              text: `${document.title}이 성공적으로 전송되었습니다.`,
              icon: 'success',
              confirmButtonText: 'OK'
            });
            setSubmissionStatus(prev => ({ ...prev, [documentId]: 'sent' }));
          } else {
            await Swal.fire({
              title: 'Failed to send data',
              text: response.data.message,
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        } catch (error) {
          console.error('An error occurred:', error);
          await Swal.fire({
            title: 'Error!',
            text: 'An unexpected error occurred: ' + error.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      }
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '16px', width: '500px', height: '100%', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box style={{ marginBottom: '16px' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Initiator</Typography>

      </Box>

      <List component="nav" aria-label="document list" sx={{ height: "73vh", overflowY: 'auto' }}>
  {documentList && documentList.map((doc) => (
    <ListItem
      button
      key={doc.id}
      selected={selectedDocument === doc.id}
      onClick={(event) => handleListItemClick(event, doc.id, doc.title)}
      sx={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }} // Ensure vertical layout for each item
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <ListItemText
          primary={doc.title}
          secondary={selectedStatus[doc.id] || 'No selection'}
          primaryTypographyProps={{ style: { color: selectedDocument === doc.id ? '#34568B' : 'black' } }}
          sx={{ minWidth: '250px' }} // Fixed width for the text area
        />
        {selectedDocument === doc.id ? (
          <FormControl sx={{ width: '150px' }}> 
            <Select
              value={selectedStatus[doc.id] || ''}
              onChange={(event) => handleStatusChange(event, doc.id)}
              size="small"
              displayEmpty
              renderValue={selected => selected || 'Select Status'} // Placeholder when not selected

            >
              {codeList.map((v, index) =>
                <MenuItem key={index} value={v}>{v}</MenuItem>
              )}
            </Select>
          </FormControl>
        ) : <div style={{ width: '150px' }} />} {/* Placeholder to maintain alignment */}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}> {/* Explicitly set the width and justify content to flex-end */}
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => handleSubmitReview(doc.id)}
          disabled={submissionStatus[doc.id] === 'sent'|| !selectedStatus[doc.id]}
        >
          Submit and Save Document
        </Button>
      </Box>
    </ListItem>
  ))}
</List>



    </Paper>
  );
};

export default InfoBoxSquadLast;
