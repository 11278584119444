import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
 import axios from "axios";import Cookies from 'js-cookie';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import "./CProgress.css";
import { useTranslation } from 'react-i18next';


const CWP = ({ positiondata, order, blockH, pdata, data }) => {

  const yesterday = moment(new Date()).format("YYYY-MM-DD");
  const yesterday7 = moment(new Date()).subtract(7, "day").format("YYYY-MM-DD");
  const [BoxHeight, setBoxHeight] = useState(250);
  const { t, i18n } = useTranslation();

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);



  const lastdate = _.max(
    data.map(
      (v) =>
        v.uuu_record_last_update_date.slice(6, 10) +
        v.uuu_record_last_update_date.slice(0, 2) +
        v.uuu_record_last_update_date.slice(3, 5)
    )
  );

  // console.log(_.countBy(data, 'd_activity_status'))

  const total_wv = _.sumBy(data, "d_weight_value");


  var wv_l4 = _(
    _.sortBy(data, "Lv_4_Name").map((v) => ({
      ...v,
      actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      planwv: (v.d_weight_value * v.d_plan_progress) / 100,

    }))
  )
    .groupBy("Lv_4_Name")
    .map((objs, key) => ({
      공종: key,
      WV: (_.sumBy(objs, "d_weight_value") / total_wv) * 100,
      PV: isNaN(
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100,
      EV: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100,
      Dev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100,
    }))
    .value();


  var wv_l4p = _(
    _.sortBy(pdata, "Lv_4_Name").map((v) => ({
      ...v,
      actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
      planwv: (v.d_weight_value * v.d_plan_progress) / 100,

    }))
  )
    .groupBy("Lv_4_Name")
    .map((objs, key) => ({
      공종: key,
      pPV: isNaN(
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100,
      pEV: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100,
      pDev: isNaN(
        (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100
      )
        ? 0
        : (_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100 -
        (_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) *
        100,
    }))
    .value();

  const tabledata = wv_l4.map((v) => ({
    ...v,
    ...wv_l4p.find((sp) => sp.공종 === v.공종),
  }));

  const c_count = _.filter(data, function (elem) {
    return elem.d_activity_status === "Completed";
  }).length;
  const n_count = _.filter(data, function (elem) {
    return elem.d_activity_status === "Not Started";
  }).length;
  const i_count = _.filter(data, function (elem) {
    return elem.d_activity_status === "In Progress";
  }).length;
  const i_data = _.filter(data, function (elem) {
    return elem.d_activity_status === "In Progress";
  });
  const a_count = _.filter(i_data, function (elem) {
    return elem.d_actual_progress >= elem.d_plan_progress;
  }).length;
  const d_count = _.filter(i_data, function (elem) {
    return elem.d_actual_progress < elem.d_plan_progress;
  }).length;

  const discList = _.uniqBy(data, "Lv_4_Name").map((v) => v.Lv_4_Name);

  var progressby = _(_.sortBy(data, "Lv_4_Name").map((v) => ({
    ...v,
    actualwv: (v.d_weight_value * v.d_actual_progress) / 100,
    planwv: (v.d_weight_value * v.d_plan_progress) / 100,

  })))
    .groupBy("Lv_4_Name")
    .map((objs, key) => ({
      Lv_4_Name: key,
      Plan:
        isNaN((_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100) ? "N/A" : ((_.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100),
      Actual:
        isNaN((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100) ? "N/A" : ((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value")) * 100),
      Dev:
        isNaN((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value") -
          _.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100) ? "N/A" : ((_.sumBy(objs, "actualwv") / _.sumBy(objs, "d_weight_value") -
            _.sumBy(objs, "planwv") / _.sumBy(objs, "d_weight_value")) * 100),
    }))
    .value();




  const colmunNo = Math.round(progressby.length / 2);

  const columnList = [];

  for (var i = 1; i <= colmunNo * 2; i++) {
    columnList.push({ width: `${100 / colmunNo / 2}%` });
  }

  // const columnList = progressby.map((v, index)=> ({width:`${(100/colmunNo)/2}%`}))

  const hidden = {
    visible: false,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        <div>{t("계획")}: {  typeof points[0].value.target === 'number'  ? points[0].value.target.toFixed(2)+"%":'N/A'}</div>
        <div>{t("실적")}: { typeof points[0].value.current === 'number'  ? points[0].value.current.toFixed(2)+"%":'N/A'}</div>
        <div>
        {t("차이")}: {typeof points[0].value.target === 'number' && typeof points[0].value.current === 'number' ?(points[0].value.current - points[0].value.target).toFixed(2)+'%':'N/A'}
        </div>
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const [sort, setSort] = useState([{}]);

  const PercentCell = (props) => {
    const field = props.field || "";
    const Dev = props.dataItem[field];
    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        {typeof Dev === "undefined" ? "" : Dev.toFixed(2)}%
      </td>
    );
  };

  const SimpleSumCell = (props) => {
    const field = props.field || "";
    const total = tabledata.reduce((acc, current) => acc + current[field], 0);
    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        {total.toFixed(2)}%
      </td>
    );
  };

  const RateSumCell = (props) => {
    const field = props.field || "";
    const total = tabledata.reduce(
      (acc, current) => acc + (current[field] * current["WV"]) / 100,
      0
    );
    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        {total.toFixed(2)}%
      </td>
    );
  };

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];
    return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>{cell}</td>;
  };

  const HeaderCell = (props) => {
    const { field, title } = props;

    const handleSort = (field) => {
      const findSortIndex = sort.findIndex((e) => e.field === field);

      const findSort = sort.find((e) => e.field === field);

      if (findSort === undefined) {
        if (sort[0]["field"] === undefined) {
          sort.push({ field: field, dir: "asc" });
          setSort([...sort]);
        }
      } else {
        if (findSort["dir"] === "asc") {
          sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
          setSort([...sort]);
        } else if (findSort["dir"] === "desc") {
          sort.splice(findSortIndex, 1);
          setSort([...sort]);
        }
      }
    };

    const hadleSortArrow = (field) => {
      const findSort = sort.find((e) => e.field === field);

      return findSort !== undefined ? findSort["dir"] : false;
    };

    const hadleSortIndex = (field) => {
      const findSortIndex = sort.findIndex((e) => e.field === field);

      return findSortIndex === -1 ? "" : findSortIndex;
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          font: "normal normal normal 0.8rem Pretendard",

          cursor: !(title.includes("까지")||title.includes("till") )? "pointer" : "default",
        }}
        onClick={() => {
          handleSort(field);
        }}
      >
        <p style={{ margin: "0" }} ref={findParentHeaderNode}>
          {title}{" "}
        </p>
        <div
          className={
            hadleSortArrow(field) === "asc"
              ? "k-icon k-font-icon k-i-sort-asc-sm"
              : hadleSortArrow(field) === "desc"
                ? "k-icon k-font-icon k-i-sort-desc-sm"
                : ""
          }
        ></div>
        {!(title.includes("까지")||title.includes("till") ) && (
          <div style={{ color: "#ff6358" }}>{hadleSortIndex(field)}</div>
        )}
      </div>
    );
  };

  const [visibleDialog, setVisibleDialog] = useState(true);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const labelContent = (props) => {
    const { points } = props;

    return (
      <div>
        {points.map((point, index) => (
          <span>{point.value}</span>
        ))}
      </div>
    );
  };

  const findParentNode = useRef();

  useEffect(() => {
    if (!visibleDialog) {
      const addClassName = " progressCWPTableBody";
      const parentNode = findParentNode.current.parentElement;
      parentNode.className = parentNode.className + addClassName;
    }
  }, [visibleDialog]);

  const findParentHeaderNode = useRef();

  useEffect(() => {
    if (!visibleDialog) {
      const parentNode =
        findParentHeaderNode.current.parentNode.parentNode.parentNode
          .previousSibling;

      const secondNode = parentNode.childNodes[1];
      const thirdNode = parentNode.childNodes[2];

      secondNode.style.border = 0;
      thirdNode.style.border = 0;
    }
  }, [visibleDialog]);

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            zIndex: "100",
          }}
        >
          <Button
            title={t("테이블로 보기")}
            iconClass="k-icon k-font-icon k-i-grid"
            fillMode="flat"
            onClick={toggleDialog}
          ></Button>
        </div>
        {/* <GridLayoutItem col={((columnList.length - 6) / 2) + 1} row={1} colSpan={1} rowSpan={2} style={{ textAlign: 'center' }}><div style={{ fontSize: '0.8rem' }}>Total</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{c_count + n_count + i_count}</div></GridLayoutItem>
                <GridLayoutItem col={((columnList.length - 6) / 2) + 2} row={1} colSpan={1} rowSpan={2} style={{ textAlign: 'center' }}><div style={{ fontSize: '0.8rem' }}>완료</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{c_count}</div></GridLayoutItem>
                <GridLayoutItem col={((columnList.length - 6) / 2) + 3} row={1} colSpan={1} rowSpan={2} style={{ textAlign: 'center' }}><div style={{ fontSize: '0.8rem' }}>시작안함</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{n_count}</div></GridLayoutItem>
                <GridLayoutItem col={((columnList.length - 6) / 2) + 4} row={1} colSpan={1} rowSpan={2} style={{ textAlign: 'center' }}><div style={{ fontSize: '0.8rem' }}>진행중</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{i_count}</div></GridLayoutItem>
                <GridLayoutItem col={((columnList.length - 6) / 2) + 5} row={1} colSpan={1} rowSpan={2} style={{ textAlign: 'center' }}><div style={{ fontSize: '0.8rem' }}>선행중</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{a_count}</div></GridLayoutItem>
                <GridLayoutItem col={((columnList.length - 6) / 2) + 6} row={1} colSpan={1} rowSpan={2} style={{ textAlign: 'center' }}><div style={{ fontSize: '0.8rem' }}>지연중</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{d_count}</div></GridLayoutItem> */}

        {progressby.map((v, index) => (
          <GridLayoutItem
            key={index}
            col={
              index + 1 > columnList.length * 0.5
                ? index * 2 + 1 - columnList.length
                : index * 2 + 1
            }
            row={index + 1 > columnList.length * 0.5 ? 5 : 3}
            colSpan={2}
            rowSpan={2}
            style={{ textAlign: "center" }}
          >
            <Chart
              style={{
                height: BoxHeight * 0.52,
              }}
              className="bullet"
            >
              <ChartTooltip shared={true} render={sharedTooltipRender} />

              <ChartTitle
                text={`${v.Lv_4_Name} \n ${(v.Actual && v.Plan && typeof v.Actual === 'number' && typeof v.Plan === 'number' ? (v.Actual - v.Plan).toFixed(2)+'%' : 'N/A')}\n (${t("계획")}:${v.Plan && typeof v.Plan === 'number' ? v.Plan.toFixed(2)+'%'  : 'N/A'} ${t("실적")}:${v.Actual && typeof v.Actual === 'number' ? v.Actual.toFixed(2)+'%'  : 'N/A'})`}
                font="0.7rem pretendard-B bold"
              />
              <ChartSeries>
                <ChartSeriesItem
                  type="bullet"
                  color={v.Actual - v.Plan < 0 ? "#CD212A" : "#00A170"}
                  data={[v.Actual, v.Plan]}
                  overlay={{ gradient: "glass" }}
                />
              </ChartSeries>
              <ChartValueAxis>
                <ChartValueAxisItem
                  majorGridLines={hidden}
                  minorTicks={hidden}
                  min={0}
                  max={100}
                  labels={{ font: "0.7rem pretendard-R" }}
                />
              </ChartValueAxis>
            </Chart>
          </GridLayoutItem>
        ))}

        {!visibleDialog && (
          <Dialog
            title={t("공종별 공정률 상세")}
            onClose={toggleDialog}
            width={"60%"}
          >
            <div ref={findParentNode}>
              <Grid
                data={orderBy(tabledata, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                className="progressCWP"
              >
                <GridColumn
                  field="공종"
                  title={t("공종")}
                  cell={normalcell}
                  headerCell={HeaderCell}
                />
                <GridColumn
                  title= {i18n.language === "ko"? `${yesterday7} 까지`:`till ${yesterday7}`}
                  headerCell={HeaderCell}
                  className="testHeader"
                >
                  <GridColumn
                    field="WV"
                    title={t("비중")}
                    cell={PercentCell}
                    footerCell={SimpleSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="pPV"
                    title={t("계획")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="pEV"
                    title={t("실적")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="pDev"
                    title={t("차이")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                </GridColumn>
                <GridColumn title={i18n.language === "ko"? `${yesterday} 까지`:`till ${yesterday}`}
                headerCell={HeaderCell}>
                  <GridColumn
                    field="PV"
                    title={t("계획")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="EV"
                    title={t("실적")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                  <GridColumn
                    field="Dev"
                    title={t("차이")}
                    cell={PercentCell}
                    footerCell={RateSumCell}
                    headerCell={HeaderCell}
                  />
                </GridColumn>
              </Grid>
            </div>
          </Dialog>
        )}
      </GridLayout>
    </>
  );
};

export default CWP;
